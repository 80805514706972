import { useState } from 'react';
import webApiClient from './../../Utils/webApiClient';
import { msalInstance } from './../../index';
import config from './../../Config/config';
import useRequestHandler from '../useRequestHandler';

/** Hook is used to read project entities by id */
const useReadProject = () => {

    const { errorHandler, successHandler } = useRequestHandler();
    const apiClient = webApiClient();
    const [ project, setProject ] = useState({});

    /** Read project by id
     * @param {integer} id - id of the project entity */
    const readProject = async (id) => {
        try {

            // Account is set in the index.js
            const account = msalInstance.getActiveAccount();

            const response = await msalInstance.acquireTokenSilent({
                scopes: config.webApi.scopes,
                account: account
            });

            const result = await apiClient.read(response.accessToken, "projects", id);  

            const data = result && result.data ? result.data : null;
            const resultData = data.resultData || null;
            let content = null;
            
            if (resultData && resultData.Id) content = result;
            if (resultData && resultData[0] && resultData[0].Id) content = resultData[0];

            if (content) setProject(content);

            return successHandler(result, "useReadProject", "read", { hide: true });

        } catch (error) {
            return errorHandler(error, "useReadProject", "read");
        }
    }

    return { project, readProject };

}

export default useReadProject;
