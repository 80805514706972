//import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import webApiClient from './../../Utils/webApiClient';
import { msalInstance } from './../../index';
import config from './../../Config/config';
import useRequestHandler from '../useRequestHandler';

/** Hook is used to update project entity */
const useUpdateProject = () => {

    const { t } = useTranslation();
    const { errorHandler, successHandler } = useRequestHandler();
    const apiClient = webApiClient();

    /** Update project entity
     * @param {object} object - Object which describes the project entity
     * @example object
     * {
     *      "id": 1,
     *      "salesPerson": 1,
     *      "businessPartner": 1,
     *      "title": "Otsikko",
     *      "description": "Kuvaus",
     *      "status": 1,
     *      "startDate": "2011-10-05T14:48:00.000Z",
     *      "endDate": "2011-10-05T14:48:00.000Z",
     *      "endReason": "Ei syytä",
     *      "failedOrDeniedReason": "Ei syytä"
     *      "customerSegment1": "???",
     *      "customerSegment2": "???",
     *      "customerSegment3": "???",
     *      "deleted": false
     * } 
     **/
    const updateProject = async (object) => {
        try {

            // Account is set in the index.js
            const account = msalInstance.getActiveAccount();

            const response = await msalInstance.acquireTokenSilent({
                scopes: config.webApi.scopes,
                account: account
            });

            const result = await apiClient.update(response.accessToken, "projects", object);  

            let message = t('success.projectUpdated') + " (id=" + object.id + ")";

            return successHandler(result, "useUpdateProject", "update", { message: message });

        } catch (error) {

            return errorHandler(error, "useUpdateProject", "update");

        }
    }

    return { updateProject };

}

export default useUpdateProject;
