import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container } from 'semantic-ui-react';

/** New release
 * @param {string} version release version
 * @param {string} description release description <optional>
 * @param {string} date release date <optional>
 * @param {array} changes array of strings <optional>
 * @param {array} features array of strings <optional>
 * @param {array} bugs array of strings  <optional> */
const Release = ({version, description, date, changes, features, bugs}) => {

    const { t } = useTranslation();

    return (
        <Container className="release">

            <h3>{t('releases.title') + " " + version}</h3>

            <Container style={{ paddingLeft: "20px", marginBottom: "20px" }}>
                <b>{t('releases.info')}</b>
                <ul>
                    {date && <li>{t('releases.date') + ": " + date}</li>}
                    {description && <li>{t('releases.description') + ": " + description}</li>}
                </ul>
            </Container>

            {changes && changes.length > 0 && 
                <Container style={{ paddingLeft: "20px" }}>
                    <b>{t('releases.changes')}</b>
                    <ul>
                        {changes.map((item, index) => <li key={index}>{item}</li>)}
                    </ul>
                </Container>
            }

            {features && features.length > 0 && 
                <Container style={{ paddingLeft: "20px" }}>
                    <b>{t('releases.features')}</b>
                    <ul>
                        {features.map((item, index) => <li key={index}>{item}</li>)}
                    </ul>
                </Container>
            }

            {bugs && bugs.length > 0 && 
                <Container style={{ paddingLeft: "20px" }}>
                    <b>{t('releases.bugs')}</b>
                    <ul>
                        {bugs.map((item, index) => <li key={index}>{item}</li>)}
                    </ul>
                </Container>
            }

            <hr/>
        </Container>
    );
}

export default Release;
