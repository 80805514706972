import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Container, Message, Icon } from 'semantic-ui-react';
import {useDropzone} from 'react-dropzone';

/* https://react-dropzone.js.org/#section-accepting-specific-file-types */
/** Add file dropzone
 * @param {object} files - react state for file
 * @param {function} setFiles - react set state function to set file
 * @param {integer} maxFiles - maximum files allowed to be uploaded - default is not limited = 0
 * @param {boolean} showTitle - show or hide title - default is show
 * @param {string} title - drop files component title
 * @param {string} acceptedMimeTypes - Comma separated list of accepted mime types - default is 'application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/plain, text/csv, application/json, image/jpeg, image/png, application/xml, application/zip'
 *                                      Common mime types: https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
 * @param {boolean} showAddedFilesList - Show list of added files - default is true 
*/
const FileDropZone = ({
    files,
    setFiles,
    maxFiles = 0,
    showTitle = true,
    title,
    acceptedFileTypes='application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/plain, text/csv, application/json, image/jpeg, image/png, application/xml, application/zip',
    showAddedFilesList=true
}) => {

    const { t } = useTranslation();

    const onDropAccepted = (files) => { setWrongFileExtension(false); };

    const onDropRejected = (fileRejections) => { setWrongFileExtension(true); };

    const onDrop = useCallback(acceptedFiles => {
        if (acceptedFiles && acceptedFiles.length) {

            // Get file extension
            let file = acceptedFiles[0];
            let path = file.path || "";
            let fileExtension = path.substring(path.lastIndexOf('.'));

            // Add file extension
            acceptedFiles[0].fileExtension = fileExtension;
            
            // Set files
            setFiles(acceptedFiles);

        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    const [ wrongFileExtension, setWrongFileExtension ] = useState(false);

    const getClassName = (isDragActive, isDragReject) => {
        if (isDragReject) return {className: "dropzone reject"};
        if (isDragActive) return {className: "dropzone active"};
        //{ className: `dropzone${isDragActive?" active":""}${isDragReject?" reject":""}`})
        return {className: "dropzone"};
    }

    const { 
        getRootProps,
        getInputProps,
        isDragActive,
        //isDragAccept,
        isDragReject
    } = useDropzone({ 
        onDrop,
        onDropAccepted,
        onDropRejected,
        accept: acceptedFileTypes,
        maxFiles:maxFiles
    });

return (
        <Container style={{ marginBottom: "20px" }}>

            {/* Choose attachment */}
            {showTitle && <Form.Field label={title ? title : t('general.files')} />}
            <section className="container">
                <div{...getRootProps(getClassName(isDragActive, isDragReject))
                }>
                    <input {...getInputProps()} />
                    {
                        isDragActive ?
                            <p>Drop the files here ...</p> :
                        <>
                            <p>{t('attachment.dragNDropFiles')}</p>
                            <em>{t('attachment.onlyFilesAreAccepted')}</em>
                        </>
                    }
                </div>
            </section>

            {/* Errors */}
            <Message error visible={wrongFileExtension} content={t('errors.wrongFileExtension')} />

            {/* List added files */}
            <Form.Field label={t('attachment.addedAttachement')} />

            {files && files.length === 0 && <p>{t('attachment.noAttachmentAreAdded')}</p>}

            {showAddedFilesList && files && files.length > 0 &&
                files.map((file, index) => {
                    return (
                        <p key={index}>

                            {/* Supported file icons - add more here if needed */}
                            {file.fileExtension === '.pdf' && <Icon name='file pdf outline' /> }
                            {file.fileExtension === '.doc' && <Icon name='file word outline' /> }
                            {file.fileExtension === '.docx' && <Icon name='file word outline' /> }
                            {file.fileExtension === '.xls' && <Icon name='file excel outline' /> }
                            {file.fileExtension === '.xlsx' && <Icon name='file excel outline' /> }
                            {file.fileExtension === '.jpeg' && <Icon name='file image outline' /> }
                            {file.fileExtension === '.png' && <Icon name='file image outline' /> }
                            {file.fileExtension === '.txt' && <Icon name='file outline' /> }
                            {file.fileExtension === '.csv' && <Icon name='file code outline' /> }
                            {file.fileExtension === '.json' && <Icon name='file code outline' /> }
                            {file.fileExtension === '.xlm' && <Icon name='file code outline' /> }

                            {file.path} - {file.size} {t('general.bytes')}

                        </p>
                    )
                })
            }
            
        </Container>
    );
}

export { FileDropZone };