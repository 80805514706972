//import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import webApiClient from './../../Utils/webApiClient';
import { msalInstance } from '../../index';
import config from '../../Config/config';
import useRequestHandler from '../useRequestHandler';

/** Hook is used to create new notification entity */
const useCreateNotification = () => {
    
    const { t } = useTranslation();
    const { errorHandler, successHandler } = useRequestHandler();
    const apiClient = webApiClient();

    /** Create new notification
     * @param {object} object - Object which describes the notification
     * @example object
     * {
     *      "name": "",
     *      "activity": 1,
     *      "date": "2011-10-05T14:48:00.000Z",
     *      "title": "",
     *      "businessPartner": 1,
     *      "description": "",
     *      "acknowledgementTime": "2011-10-05T14:48:00.000Z",
     *      "salesPerson": 1,
     *      "deleted": false
     *  }
     **/
    const createNotification = async (object) => {
        try {

            // Account is set in the index.js
            const account = msalInstance.getActiveAccount();

            const response = await msalInstance.acquireTokenSilent({
                scopes: config.webApi.scopes,
                account: account
            });

            const result = await apiClient.create(response.accessToken, "notifications", object);

            return successHandler(result, "useCreateNotification", "create", { message: t('success.create') });

        } catch (error) {
            return errorHandler(error, "useCreateNotification", "create");
        }
    }

    return { createNotification };

}

export default useCreateNotification;
