import { useState } from 'react';
import webApiClient from './../../Utils/webApiClient';
import { msalInstance } from '../../index';
import config from '../../Config/config';
import useRequestHandler from '../useRequestHandler';

/** Hook is used to read product entity by project id */
const useReadProjectProduct = () => {

    const { errorHandler, successHandler } = useRequestHandler();
    const [ product, setProduct ] = useState({});
    const apiClient = webApiClient();

    /** Read product by project id
     * @param {integer} id - id of the project entity */
    const readProjectProduct = async (projectId, productId) => {
        try {

            // Account is set in the index.js
            const account = msalInstance.getActiveAccount();

            const response = await msalInstance.acquireTokenSilent({
                scopes: config.webApi.scopes,
                account: account
            });

            // Create resource path
            let resource = "projects/" + projectId.toString() + "/products/" + productId.toString();
            
            const result = await apiClient.read(response.accessToken, resource);  

            const data = result && result.data ? result.data : null;
            const resultData = data.resultData || null;
            const content = resultData[0] || null;

            if (content) setProduct(content);

            return successHandler(result, "useReadProjectProduct", "read", { hide: true });

        } catch (error) {
            return errorHandler(error, "useReadProjectProduct", "read");
        }
    }

    return { product, readProjectProduct };

}

export default useReadProjectProduct;
