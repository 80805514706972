import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Accordion, Grid, Icon, Header, Input } from 'semantic-ui-react';
import useReadBusinessPartner from '../../Hooks/BusinessPartners/useReadBusinessPartner';
import { SearchItems, SelectedItemLight } from '../UI/Content';
import PagedTable from '../UI/Lists';

/** Bang & Bonsomer - Search and Select Products Component
 *
 * @param {boolean} isProductListCollapsed 
 *
 * @param {boolean} isLoading - React state for loading
 * @param {function} setIsLoading - React set state function for loading
 * 
 * @param {string} title - Component title
 * @param {string} placeholder - Component placeholder
 * @param {string} productSearchTerm - Default searchterm
 * 
 * @param {object} selectedProduct - React state for selected product
 * @param {function} setSelectedProduct - React set state function for product

 * @param {object} selectedSupplier - React state for supplier (business partner)
 * @param {function} setSelectedSupplier - React set state function for supplier (business partner)
 * 
 * @param {object} products - React state for products
 * @param {function} setProducts - React set state function for products
 * 
 * @param {boolean} selectedItemIsRequired
 * @param {boolean} isValidSelectedItem - selected item is valid

 * @param {function} searchProducts - Search products function
*/
const SearchAndSelectProducts = ({

    required,
    isProductListCollapsed = false,

    isLoading,
    setIsLoading,

    title,
    placeholder,
    productSearchTerm = "",

    selectedProduct,
    setSelectedProduct,

    selectedSupplier,
    setSelectedSupplier,

    products,
    setProducts,

    selectedItemIsRequired = false,
    isValidSelectedItem,

    searchProducts

}) => {

    const { t } = useTranslation();
    const [ filterProductList, setFilterProductList ] = useState('');
    const [ activeIndex, setActiveIndex ] = useState(isProductListCollapsed ? -1 : 0);
    const { readBusinessPartner } = useReadBusinessPartner();

    // If user select supplier set product list open
    useEffect(() => {
        isProductListCollapsed ? setActiveIndex(-1) : setActiveIndex(0);
    }, [isProductListCollapsed]);

    return (
        <Container className="fieldcontent" style={{ marginTop: '20px' }}>
            {!selectedProduct && 
                <>
                    <SearchItems
                        required={required ? required : false}
                        title={title || t('activity.searchProducts')}
                        placeholder={placeholder || t('activity.searchProducts')}
                        searchItems={async (searchTerm) => {
                            setIsLoading(true);
                            if (searchTerm.length > 1) {
                                let result = await searchProducts(searchTerm);
                                setActiveIndex(0);
                                if (!result.requestCancelled) setIsLoading(false);
                                return;
                            }
                            // if search term is only 1 char long, stop loading and remove search results;
                            setIsLoading(false);
                            setProducts([]);
                            setActiveIndex(-1);
                        }}
                        defaultSearchTerm={ productSearchTerm || ""}
                        setIsLoading={setIsLoading}
                        className=""
                    />

                    {/* Search results */}
                    <Container>
                        <Accordion>

                            <Accordion.Title
                                active={activeIndex === 0}
                                index={0}
                                onClick={() => { setActiveIndex(activeIndex === 0 ? -1 : 0); }}
                                style={{paddingBottom: 0}}
                            >
                                <Header as='h3' attached='top' style={{ border: '1px solid #e2e2e2' }}>
                                    <Grid>
                                        <Grid.Column width="8" textAlign="left">
                                            <b>{t('general.results')}</b>
                                        </Grid.Column>
                                        <Grid.Column  width="8" textAlign="right">
                                            <Icon name='dropdown' />
                                        </Grid.Column>
                                    </Grid>
                                </Header>
                            </Accordion.Title>

                            <Accordion.Content active={activeIndex === 0} style={{ paddingTop: '5px' }}>
                                <Container>

                                    {/* Filter */}
                                    <Container style={{ marginBottom: '4px'}} >
                                        <Input
                                            action={{
                                                labelPosition: 'left',
                                                icon: 'delete',
                                                content: t('general.clear'),
                                                style: {margin: 0},
                                                onClick:(ev) => {
                                                    ev.preventDefault();
                                                    setFilterProductList('');
                                                }
                                            }}
                                            onChange={(ev, {value} ) => {
                                                ev.preventDefault();
                                                setFilterProductList(value)
                                            }}
                                            fluid
                                            //actionPosition='right'
                                            placeholder={t('general.filter')}
                                            value={filterProductList}
                                        />
                                    </Container>

                                    {/* Selection list */}
                                    <PagedTable unstackable celled striped compact='very' pageSize={10}
                                        header={[
                                            t('general.name'),
                                            t('general.code'),
                                            t('businessPartner.businessUnit'),
                                            t('product.salesUnit'),
                                            t('general.action')
                                        ]}
                                        content={
                                            products.filter((sup) => sup.Name.toLowerCase().indexOf(filterProductList.toLowerCase()) !== -1)
                                        }
                                        resetPageOnContentChange={true}
                                        contentKeys={[
                                            {key: 'Name'},
                                            {key: 'Code'},
                                            {key: 'BusinessUnitName'},
                                            {key: 'SalesUnit'},
                                            {key: 'Id', type: 'action', text: t('product.selectProduct'), action: async (ev, row, cellKey) => { 
                                                try {

                                                    // CASE 1: Sales person select product first
                                                    // fetch suppliers for the product and select supplier automatically
                                                    setIsLoading(true);
                                                    const product = products.find(item => item.Id === row.Id);
                                                    if (product) setSelectedProduct(product);

                                                    // Select automatically default supplier for the product
                                                    if (product && product.BusinessPartner) {
                                                        let result = null;
                                                        
                                                        if (setSelectedSupplier) result = await readBusinessPartner(product.BusinessPartner);
                                                        let data = result.data || null;
                                                        let resultData = data.resultData || null;
                                                        let supplier = resultData[0] || null;
                                                        if (setSelectedSupplier) setSelectedSupplier(supplier);
                                                    }

                                                    // Set supplier to null if product supplier is not defined
                                                    if (product && !product.BusinessPartner) {
                                                        if (setSelectedSupplier) setSelectedSupplier(null);
                                                    }
                                                    
                                                    setActiveIndex(-1);
                                                    setIsLoading(false);

                                                } catch (error) {
                                                    setActiveIndex(-1);
                                                }
                                            }}
                                        ]}
                                    />

                                </Container>
                            </Accordion.Content>
                        </Accordion>
                    </Container>
                </>
            }
            {selectedProduct && 
                <SelectedItemLight
                    title={t('product.selectedProduct')}
                    item={selectedProduct}
                    headers={[t('general.name'), t('product.code'), t('businessPartner.businessUnit'), t('product.salesUnit')]}
                    fields={['Name', 'Code', 'BusinessUnitName', 'SalesUnit']}
                    required={selectedItemIsRequired}
                    isValidSelectedItem={isValidSelectedItem}
                    onRemoveSelection={(ev) => {
                        setSelectedProduct(null);
                    }}
                />
            }
        </Container>
    );

}

export default SearchAndSelectProducts;
