import React, { useState, useContext, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { Form, Input, Container, Message, TextArea } from 'semantic-ui-react';
import { Loading } from '../../Components/UI/Loaders';
import { FileDropZone } from '../../Components/Files/Files';
import { SubmitButton, CancelButton } from '../../Components/UI/Buttons';
import { PageTitle } from '../../Components/UI/Content';
import { StateContext } from '../../Application/Context/StateContext';
import useCreateProjectAttachement from './../../Hooks/ProjectAttachments/useCreateProjectAttachment';
import useReadProject from './../../Hooks/Project/useReadProject';

const NewProjectAttachement = (props) => {

    const { t } = useTranslation();
    const history = useHistory();
    const scollToTopRef = useRef();
    const { projectId } = useParams();

    const [ isLoading, setIsLoading ] = useState(false);
    const [ attachementIsNotAdded, setAttachementIsNotAdded ] = useState(false);

    const [ files, setFiles ] = useState([]);
    const [ title, setTitle ] = useState("");
    const [ description, setDescription ] = useState("");

    const { userProfile } = useContext(StateContext);
    const { project, readProject } = useReadProject();
    const { createProjectAttachement } = useCreateProjectAttachement();
    
    const userIsAdmin = userProfile && userProfile.IsAdmin ? true : false;
    const projectHasEnded = project && project.EndDate ? true : false; // If project has end date, project is ended and only admin can edit it!
    const projectIsNotActive = project && project.Status > 7 ? true : false; // if project status is greater than 7 (not active anymore) only admin can edit it!
    const disableEditing = (projectHasEnded || projectIsNotActive) && !userIsAdmin ? true : false;
    
    useEffect(() => {
        (async () => {
            scollToTopRef.current.scrollIntoView();
            if (projectId) {
                setIsLoading(true);
                await readProject(projectId);
                setIsLoading(false);
            }
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const onSubmit = async () => {
        try {

            if (!projectId) return null;
            if (files.length === 0) {
                setAttachementIsNotAdded(true);
                return null;
            } else {
                setAttachementIsNotAdded(false);
            }

            setIsLoading(true);

            const file = files[0];

            const metadata = {
                title: title,
                description: description,
                project: parseInt(projectId),
                activity: null,
                pathParam: null,
                salesPerson: userProfile.Id
            }

            const result = await createProjectAttachement(projectId, file, metadata);

            if (result && result.status && result.status === 200 && result.data) {
                return history.goBack();
            }

            setIsLoading(false);

        } catch (error) {
            setIsLoading(false);
        }
    }

    return (
        <Form className="attachement" onSubmit={(ev) => { ev.preventDefault(); onSubmit(); }} >
        
            <div ref={scollToTopRef}></div>

            <Loading active={ isLoading } text={t('general.loading')} />

            <PageTitle active title={t('attachment.newProjectAttachement')} />

            <Message warning visible={projectHasEnded || projectIsNotActive} content={t('warnings.projectIsNotActive')} />

            <FileDropZone
                files={files}
                setFiles={setFiles}
                maxFiles={1}
            />
            
            {/* Title */}
            <Form.Field
                required
                label={t('general.title')}
                placeholder={t('general.title')}
                maxLength="255"
                control={Input}
                value={title}
                onChange={(ev, {value}) => setTitle(value) }
                type="Text"
            />

            {/* Description */}
            <Form.Field
                required
                label={t('general.description')}
                placeholder={t('general.description')}
                control={TextArea}
                value={description}
                onChange={(ev, {value}) => setDescription(value) }
                type="Text"
            />

            {/* Errors */}
            <Message error visible={!projectId} content={t('errors.missingProjectId')} />
            <Message warning visible={attachementIsNotAdded} content={t('errors.attachementIsNotAdded')} />

            {/* Buttons */}
            <Container className='button-container'>
                <CancelButton onClick={(ev) => {ev.preventDefault(); history.goBack(); }} />
                <SubmitButton disabled={disableEditing && !userIsAdmin} className="bb-lblue" icon="save" title={t('general.save')} type="submit" />
            </Container>

        </Form>
    );

}

export default NewProjectAttachement;
