import React, { useState, useEffect, useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { Form, Container, Message, Grid } from 'semantic-ui-react';
import { Loading } from '../../Components/UI/Loaders';
import { EditButton, CancelButton, DeleteButton } from '../../Components/UI/Buttons';
import { PageTitle } from '../../Components/UI/Content';
import { YesNoModal } from '../../Components/UI/Modals';

import { StateContext } from '../../Application/Context/StateContext';
import useReadProjectProduct from '../../Hooks/ProjectProducts/useReadProjectProduct';
import useDeleteProjectProduct from '../../Hooks/ProjectProducts/useDeleteProjectProduct';
import useReadProject from '../../Hooks/Project/useReadProject';
import Utils from '../../Utils/utils';

const ViewProjectProduct = (props) => {

    const { t } = useTranslation();
    const history = useHistory();
    const { projectId, id } = useParams();
    const scollToTopRef = useRef();

    const [ isLoading, setIsLoading ] = useState(false);
    const [ modalIsOpen, setModalIsOpen ] = useState(false);

    const { userProfile } = useContext(StateContext);
    const { project, readProject } = useReadProject();
    const { product, readProjectProduct } = useReadProjectProduct();
    const { deleteProjectProduct } = useDeleteProjectProduct();

    useEffect(() => {
        (async() => {
            scollToTopRef.current.scrollIntoView();
            setIsLoading(true);
            await readProject(projectId);
            await readProjectProduct(projectId, id);
            setIsLoading(false);
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const userIsAdmin = userProfile && userProfile.IsAdmin ? true : false;
    const projectHasEnded = project && project.EndDate ? true : false; // If project has end date, project is ended and only admin can edit it!
    const projectIsNotActive = project && project.Status > 7 ? true : false; // if project status is greater than 7 (not active anymore) only admin can edit it!
    const disableEditing = (projectHasEnded || projectIsNotActive) && !userIsAdmin ? true : false;

    return (
        <Container className="product" >
            <Form>
                
                <div ref={scollToTopRef}></div>

                <Loading active={ isLoading } text={t('general.loading')} />

                <PageTitle active title={t('product.ViewProjectProduct')} />

                <Message warning visible={projectHasEnded || projectIsNotActive} content={t('warnings.projectIsNotActive')} />

                {/* Delete project product modal */}
                <YesNoModal
                    type='ready'
                    header={t('general.delete')}
                    text={t('general.areYouSure')}
                    method={async (answer) => {
                        setIsLoading(true);
                        if (answer) {
                            await deleteProjectProduct(projectId, id);
                            return history.goBack();
                        }
                        setIsLoading(false);
                    }}
                    item={id}
                    open={modalIsOpen}
                    setOpen={setModalIsOpen}
                    noClick={false}
                />
                
                <Grid stackable>

                    {/* Business Partner Name */}
                    <Grid.Row>
                        <Grid.Column width={4}><Form.Field label={t('businessPartner.supplier')} /></Grid.Column>
                        <Grid.Column width={12}><p>{product.BusinessPartnerName}</p></Grid.Column>
                    </Grid.Row>

                    {/* Project Product Name */}
                    <Grid.Row>
                        <Grid.Column width={4}><Form.Field label={t('product.product')} /></Grid.Column>
                        <Grid.Column width={12}><p>{product.ProductName}</p></Grid.Column>
                    </Grid.Row>

                    {/* Product Description */}
                    <Grid.Row>
                        <Grid.Column width={4}><Form.Field label={t('general.description')} /></Grid.Column>
                        <Grid.Column width={12}><p>{product.ProductDescription}</p></Grid.Column>
                    </Grid.Row>

                    {/* Project product Description */}
                    <Grid.Row>
                        <Grid.Column width={4}><Form.Field label={t('product.userDefinedProductDescription')} /></Grid.Column>
                        <Grid.Column width={12}><p>{product.Description}</p></Grid.Column>
                    </Grid.Row>

                    {/* Product Code */}
                    <Grid.Row>
                        <Grid.Column width={4}><Form.Field label={t('product.code')} /></Grid.Column>
                        <Grid.Column width={12}><p>{product.ProductCode}</p></Grid.Column>
                    </Grid.Row>

                    {/* ProductPrice */}
                    <Grid.Row>
                        <Grid.Column width={4}><Form.Field label={t('product.price')} /></Grid.Column>
                        <Grid.Column width={12}><p>{product.ProductPrice}</p></Grid.Column>
                    </Grid.Row>

                    {/* ProductInUse */}
                    <Grid.Row>
                        <Grid.Column width={4}><Form.Field label={t('product.inUse')} /></Grid.Column>
                        <Grid.Column width={12}><p>{product.ProductInUse ? t('general.yes') : t('general.no')}</p></Grid.Column>
                    </Grid.Row>

                    {/* Product Stock Unit */}
                    <Grid.Row>
                        <Grid.Column width={4}><Form.Field label={t('product.stockUnit')} /></Grid.Column>
                        <Grid.Column width={12}><p>{product.ProductStockUnit}</p></Grid.Column>
                    </Grid.Row>

                    {/* Product Sales Unit */}
                    <Grid.Row>
                        <Grid.Column width={4}><Form.Field label={t('product.salesUnit')} /></Grid.Column>
                        <Grid.Column width={12}><p>{product.ProductSalesUnit}</p></Grid.Column>
                    </Grid.Row>

                    {/* Product Count Unit */}
                    <Grid.Row>
                        <Grid.Column width={4}><Form.Field label={t('product.countUnit')} /></Grid.Column>
                        <Grid.Column width={12}><p>{product.ProductCountUnit}</p></Grid.Column>
                    </Grid.Row>

                    {/* ProductItemGroup */}
                    <Grid.Row>
                        <Grid.Column width={4}><Form.Field label={t('product.itemGroup')} /></Grid.Column>
                        <Grid.Column width={12}><p>{product.ProductItemGroup}</p></Grid.Column>
                    </Grid.Row>

                    {/* Product Country Of Origin */}
                    <Grid.Row>
                        <Grid.Column width={4}><Form.Field label={t('product.countryOfOrigin')} /></Grid.Column>
                        <Grid.Column width={12}><p>{product.ProductCountryOfOrigin}</p></Grid.Column>
                    </Grid.Row>

                    {/* Product Net Weight */}
                    <Grid.Row>
                        <Grid.Column width={4}><Form.Field label={t('product.netWeight')} /></Grid.Column>
                        <Grid.Column width={12}><p>{product.ProductNetWeight}</p></Grid.Column>
                    </Grid.Row>

                    {/* ProductPackageTypeQty */}
                    <Grid.Row>
                        <Grid.Column width={4}><Form.Field label={t('product.packageTypeQty')} /></Grid.Column>
                        <Grid.Column width={12}><p>{product.ProductPackageTypeQty}</p></Grid.Column>
                    </Grid.Row>

                    {/* ProductPackageType */}
                    <Grid.Row>
                        <Grid.Column width={4}><Form.Field label={t('product.packageType')} /></Grid.Column>
                        <Grid.Column width={12}><p>{product.ProductPackageType}</p></Grid.Column>
                    </Grid.Row>

                    {/* Potential Volume In Sales Unit Of Measuree */}
                    <Grid.Row>
                        <Grid.Column width={4}><Form.Field label={t('product.potentialVolumeInSalesUnitOfMeasure')} /></Grid.Column>
                        <Grid.Column width={12}><p>{Utils.roundNumber(product.PotentialVolumeInSalesUnitOfMeasure, 2)}</p></Grid.Column>
                    </Grid.Row>

                    {/* Potential Sales */}
                    <Grid.Row>
                        <Grid.Column width={4}><Form.Field label={t('project.potentialSales')} /></Grid.Column>
                        <Grid.Column width={12}><p>{Utils.roundNumber(product.PotentialSales, 2)}</p></Grid.Column>
                    </Grid.Row>

                    {/* Potential Margin */}
                    <Grid.Row>
                        <Grid.Column width={4}><Form.Field label={t('project.potentialMargin')} /></Grid.Column>
                        <Grid.Column width={12}><p>{Utils.roundNumber(product.PotentialMargin, 2)}</p></Grid.Column>
                    </Grid.Row>

                    {/* Margin Percentage */}
                    <Grid.Row>
                        <Grid.Column width={4}><Form.Field label={t('project.marginPercentage')} /></Grid.Column>
                        <Grid.Column width={12}><p>{Utils.roundNumber(product.MarginPercentage, 2)}</p></Grid.Column>
                    </Grid.Row>

                    {/* Created */}
                    <Grid.Row>
                        <Grid.Column width={4}><Form.Field label={t('general.created')} /></Grid.Column>
                        <Grid.Column width={12}><p>{product.Created ? new Date(product.Created).toLocaleDateString("fi-FI") : ''}</p></Grid.Column>
                    </Grid.Row>

                    {/* Modified */}
                    <Grid.Row>
                        <Grid.Column width={4}><Form.Field label={t('general.modified')} /></Grid.Column>
                        <Grid.Column width={12}><p>{product.Modified ? new Date(product.Modified).toLocaleDateString("fi-FI") : ''}</p></Grid.Column>
                    </Grid.Row>

                    {/* Deleted */}
                    <Grid.Row>
                        <Grid.Column width={4}><Form.Field label={t('general.deleted')} /></Grid.Column>
                        <Grid.Column width={12}><p>{product.Deleted ? t('general.yes') : t('general.no')}</p></Grid.Column>
                    </Grid.Row>
                    
                </Grid>

                {/* Errors */}
                <Message error visible={!projectId} content={t('errors.missingProjectId')} />
                <Message error visible={!id} content={t('errors.missingProductId')} />

                {/* Buttons */}
                <Container className='button-container' textAlign="right">
                    <CancelButton onClick={(ev) => {ev.preventDefault(); history.goBack(); }} />
                    <div>
                        <EditButton
                            disabled={disableEditing}
                            icon='save'
                            className='bb-lblue'
                            title={t('general.edit')}
                            onClick={(ev) => { ev.preventDefault(); history.push('/projects/' + projectId + '/products/edit/' + id); }}
                        />
                        <DeleteButton color="red" onClick={() => setModalIsOpen(true)}/>
                    </div>
                </Container>

            </Form>
        </Container>
    );

}

export default ViewProjectProduct;
