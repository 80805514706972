import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Form, Accordion, Grid, Icon, Header, Input, Checkbox } from 'semantic-ui-react';
import useSearchBusinessPartners from '../../Hooks/Search/useSearchBusinessPartners';
import { SearchItems, SelectedItemLight } from '../UI/Content';
import PagedTable from '../UI/Lists';

const SearchAndSelectCustomer = ({
    
    required,

    setIsLoading,

    selectedCustomer,
    setSelectedCustomer,

    myOwnCustomersOnly,
    setMyOwnCustomersOnly,

    activeCustomersOnly,
    setActiveCustomersOnly,

    readBusinessPartnerStatistics

}) => {

    const { t } = useTranslation();
    const [ filterResults, setFilterResults ] = useState('');
    const [ activeIndex, setActiveIndex ] = useState(0);
    const { businessPartners, setBusinessPartners, searchBusinessPartners } = useSearchBusinessPartners();

    let activeStatusCodes = [1,2,3,4,5,6,7];
    let allStatusCodes = [1,2,3,4,5,6,7,8,9,10,11];

    // Filter search results using text filter
    const filteredBusinessPartners = businessPartners.filter(bp => {
        let matchName = bp.Name ? bp.Name.toLowerCase().indexOf(filterResults.toLowerCase()) !== -1 : false;
        let matchAreaName = bp && bp.AreaName ? bp.AreaName.toLowerCase().indexOf(filterResults.toLowerCase()) !== -1 : false;
        let matchCode = bp && bp.Code ? bp.Code.toLowerCase().indexOf(filterResults.toLowerCase()) !== -1 : false;
        let matchAssociationNumber = bp && bp.AssociationNumber ? bp.AssociationNumber.toLowerCase().indexOf(filterResults.toLowerCase()) !== -1 : false;
        if (matchName || matchAreaName || matchCode || matchAssociationNumber) return true;
        return false;
    });

    useEffect(() => {
        if (selectedCustomer) {
            (async () => {
                await readBusinessPartnerStatistics(selectedCustomer.Id, myOwnCustomersOnly, activeCustomersOnly);
            })();            
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Container className="fieldcontent" style={{ marginTop: '20px' }}>

            {/* Customer is not selected - Show search box*/}
            {!selectedCustomer &&
                <>

                    {/* Search */}
                    <SearchItems
                        required={required ? required : false}
                        title={t('general.customer')}
                        placeholder={t('businessPartner.searchCustomer')}
                        searchItems={async (searchTerm) => {
                            setIsLoading(true);
                            if (searchTerm.length > 1) {
                                let statusCodes = activeCustomersOnly ? activeStatusCodes : allStatusCodes;
                                let result = await searchBusinessPartners(searchTerm, 200, 'customer', myOwnCustomersOnly, statusCodes);
                                setActiveIndex(0);
                                if (!result.requestCancelled) setIsLoading(false);
                                return;
                            }
                            // if search term is only 1 char long, stop loading and remove search results;
                            setIsLoading(false);
                            setBusinessPartners([]);
                            setActiveIndex(-1);
                        }}
                        defaultSearchTerm=""
                        setIsLoading={setIsLoading}
                        //setError={setError}
                        className=""
                        filters={
                            <>
                                {/* My Customers Only */}
                                <Container>
                                    <Form.Field
                                        control={Checkbox}
                                        label={t('businessPartner.myOwnProjectsAndActivitiesOnly')}
                                        placeholder={t('businessPartner.myOwnProjectsAndActivitiesOnly')}
                                        checked={myOwnCustomersOnly}
                                        onChange={(ev, {checked}) => {
                                            setSelectedCustomer(null); // If user changed search, remove selected customer
                                            setBusinessPartners([]);
                                            setMyOwnCustomersOnly(checked)}
                                        }
                                    />
                                </Container>

                                {/* Active Projects Only */}
                                <Container>
                                    <Form.Field
                                        control={Checkbox}
                                        label={t('businessPartner.activeProjectsOnly')}
                                        placeholder={t('businessPartner.activeProjectsOnly')}
                                        checked={activeCustomersOnly}
                                        onChange={(ev, {checked}) => {
                                            setSelectedCustomer(null); // If user changed search, remove selected customer
                                            setBusinessPartners([]);
                                            setActiveCustomersOnly(checked)
                                        }}
                                    />
                                </Container>
                            </>
                        }
                    >
                    </SearchItems>

                    {/* Search results */}
                    <Container>
                        <Accordion>
                            <Accordion.Title
                                active={activeIndex === 0}
                                index={0}
                                onClick={() => { setActiveIndex(activeIndex === 0 ? -1 : 0); }}
                                style={{paddingBottom: 0}}
                            >
                                <Header as='h3' attached='top' style={{ border: '1px solid #e2e2e2' }}>
                                    <Grid>
                                        <Grid.Column width="8" textAlign="left">
                                            <b>{t('general.results')}</b>
                                        </Grid.Column>
                                        <Grid.Column  width="8" textAlign="right">
                                            <Icon name='dropdown' />
                                        </Grid.Column>
                                    </Grid>
                                </Header>
                            </Accordion.Title>
                            <Accordion.Content active={activeIndex === 0} style={{ paddingTop: 0 }}>
                                <Container style={{ marginTop: '5px' }}>

                                    {/* Filter */}
                                    <Container style={{ marginBottom: '5px'}}>
                                        <Input
                                            action={{
                                                labelPosition: 'left',
                                                icon: 'delete',
                                                content: t('general.clear'),
                                                style: {margin: 0},
                                                onClick:(ev) => {
                                                    ev.preventDefault();
                                                    setFilterResults('');
                                                }
                                            }}
                                            onChange={(ev, {value} ) => {
                                                ev.preventDefault();
                                                setFilterResults(value)
                                            }}
                                            fluid
                                            //actionPosition='right'
                                            placeholder={t('general.filter')}
                                            value={filterResults}
                                        />
                                    </Container>

                                    {/* Selection list */}
                                    <PagedTable unstackable celled striped compact='very' pageSize={10}
                                        header={[
                                            t('general.name'),
                                            t('businessPartner.area'),
                                            t('businessPartner.code'),
                                            t('general.businessId'),
                                            t('general.action')
                                        ]}
                                        content={filteredBusinessPartners}
                                        resetPageOnContentChange={true}
                                        contentKeys={[
                                            {key: 'Name'},
                                            {key: 'AreaName'},
                                            {key: 'Code'},
                                            {key: 'AssociationNumber'},
                                            {key: 'Id', type: 'action', text: t('businessPartner.selectCustomer'), action: async (ev, row, cellKey) => { 
                                                
                                                const customer = businessPartners.find(item => item.Id === row.Id);
                                                
                                                if (customer) {
                                                    setIsLoading(true);
                                                    setSelectedCustomer(customer);
                                                    await readBusinessPartnerStatistics(customer.Id, myOwnCustomersOnly, activeCustomersOnly);
                                                    setActiveIndex(-1);
                                                    setIsLoading(false);
                                                }

                                            }}
                                        ]}
                                    />

                                </Container>

                            </Accordion.Content>
                        </Accordion>
                    </Container>
                </>
            }

            {/* Customer is selected */}
            {selectedCustomer &&
                <SelectedItemLight
                    title={t('businessPartner.selectedCustomer')}
                    item={selectedCustomer}
                    headers={[t('general.name'),t('businessPartner.isCustomer'),t('general.associationNumber'),t('businessPartner.area'),t('general.code')]}
                    fields={['Name','IsCustomer','AssociationNumber','AreaName','Code']}
                    onRemoveSelection={(ev) => {
                        setSelectedCustomer(null);
                    }}
                />
            }

        </Container>
    );

}

export default SearchAndSelectCustomer;