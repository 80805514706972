
/** Function return status code filter array
 * @description When user search projects, results can be filtered by status. 
 *      This function return array of status id:s by given selection
 *      -All = return all status codes 0-11 except 12
 *      -Active = return active status codes 0-7
 *      -Passive = return passive status codes 10
 *      -Closed = return closed status codes 11
 *      -Customer = return code with Checked = true
 * @param {array} statusCodes Array of status codes objects [{ checked: true, DOrder: 1, Id: 1, Name: "Planned" }]
 * @param {string} filterSet name of the filter set to filter status codes "active"
 * @returns {array} statusCodes ["1","2","3"] */
const getSearchProjectStatusCodeFilters = (statusCodes, filterSet) => {
    switch (filterSet) {
        case 'all':
            return statusCodes.filter(item => item.Id < 12).map(item => item.Id.toString());
        case 'active':
            return statusCodes.filter(item => item.Id < 8).map(item => item.Id.toString());
        case 'passive':
            //return statusCodes.filter(item => item.Id >= 8 && item.Id < 11).map(item => item.Id.toString());
            return statusCodes.filter(item => item.Id === 10).map(item => item.Id.toString());
        case 'closed':
            //return statusCodes.filter(item => item.Id === 11).map(item => item.Id.toString());
            return statusCodes.filter(item => {
                return (item.Id === 9 || item.Id === 8 || item.Id === 11);
            }).map(item => item.Id.toString());
        case 'custom':
            return statusCodes.filter(item => item.Checked).map(item => item.Id.toString());
        default:
            return statusCodes.map(item => item.Id.toString());
    }
}

/** Create JSON array from external attendees textfield and external attendees contact links 
 * Used in project activities forms
 * @param {string} externalAttendees string field containing external attendees as text
 * @param {array} selectedContacts array of selected external attendees id´s [ 1,2,3 ]
 * @param {array} contacts array of contact objects { Id: 1, Name: "" } */
const createExternalAttendeesJson = (externalAttendees, selectedContacts, contacts) => {

    // Convert External attendees to json format 
    let externalAttendeesJson = [
        { Name: externalAttendees, Id: null, UiField: "FormField" }
    ];

    let filteredContacts = contacts.filter(item => {
        return selectedContacts.includes(item.Id);
    });

    filteredContacts.forEach(item => {
        externalAttendeesJson.push({ Name: item.Name, Id: item.Id, UiField: "Dropdown" });
    });

    return externalAttendeesJson;

};

/** Get external attendees as single string
 * @param {string} externalAttendeesJsonString externalAttendees JSON string
 * @example
 * [
 *      { Name: "Project Manager", Id: null, UiField: "FormField" }
 *      { Name: "Sergey Shuskova", Id: 1, UiField: "Dropdown" }
 *      { Name: "Mariah Carey", Id: 2, UiField: "Dropdown" }
 * ] */
 const viewExternalAttendees = (externalAttendeesJsonString) => {
    if (externalAttendeesJsonString) {
        try {

            let externalAttendeesString = "";

            let externalAttendeesJson = JSON.parse(externalAttendeesJsonString);
            
            externalAttendeesJson.forEach((item,index) => {
                if (index === 0) externalAttendeesString += item.Name;
                else externalAttendeesString +=  ", " + item.Name;
            });
        
            return externalAttendeesString;

        } catch (error) {
            // Return values as a string if JSON parse failed
            return externalAttendeesJsonString.toString();
        }
    }
}

/** Get external attendees as json (filtered by uiField)
 * @param {string} externalAttendeesJsonString externalAttendees JSON string
 * @param {string} uiField typeof the field to be returned [Dropdown, FormField]
 * @example
 * [
 *      { Name: "Project Manager", Id: null, UiField: "FormField" }
 *      { Name: "Sergey Shuskova", Id: 1, UiField: "Dropdown" }
 *      { Name: "Mariah Carey", Id: 2, UiField: "Dropdown" }
 * ] */
 const editExternalAttendees = (externalAttendeesJsonString, uiField) => {
    if (externalAttendeesJsonString && uiField) {
        try {

            let externalAttendeesJson = JSON.parse(externalAttendeesJsonString);

            let filtered = externalAttendeesJson.filter(item => item.UiField === uiField);

            // Return single string only
            if (uiField === 'FormField') {
                let str = "";
                filtered.forEach((item,index) => {
                    if (index === 0) str += item.Name;
                    else str += ", " + item.Name;
                });
                return str;
            }

            // Return array of contact id+s
            let mapped = filtered.map(item => {
                return item.Id;
            });
            return mapped;

        } catch (error) {
            // Return as string if json parse failed
            return externalAttendeesJsonString.toString();
        }

    }
};

export {
    getSearchProjectStatusCodeFilters,
    createExternalAttendeesJson,    
    viewExternalAttendees,
    editExternalAttendees
};