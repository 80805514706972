import axios from 'axios';
import config from '../Config/config';

/**  API client to make API calls which uses axios
 * @example
 *  import webApiClient from './../Utils/webApiClient';
 *  const apiClient = webApiClient();
 *  apiClient.list("accessToken", "/api/v1/users");
 *  apiClient.create("accessToken", "/api/v1/users", { "name": "John" });
 *  apiClient.read("accessToken", "/api/v1/users/", 1);
 *  apiClient.update("accessToken", "/api/v1/users", { "id": 1, "name": "John2" });
 *  apiClient.delete("accessToken", "/api/v1/users/", 1); */
 const webApiClient = () => {

    /** List items
     * @param {string} accessToken - Access token is added to Authentication header if defined
     * @param {string} resource - api endpoint name f.e. /api/v1/users
     * @param {object} options - optional options object
     *                          - cancelToken = axios.CalcelToken.source()
     * @returns {array} items - array of items
    */
    const listItem = async (accessToken, resource, options) => {
        try {

            if (!resource || typeof resource !== "string") throw Error("Resource is invalid: " + resource);

            const opts = { "headers": { "Content-Type": "application/json" } }
            if (accessToken) opts.headers.Authorization = "Bearer " + accessToken;
            if (options && options.cancelToken) opts.cancelToken = options.cancelToken.token;

            const apiVersion = config.webApi.apiVersion;
            const root = config.webApi.rootPath;
            const url = new URL(apiVersion + resource, root);

            return  await axios.get(url, opts);

        } catch (error) {
            // If request is calcelled, then this is not an error, just return!
            if (axios.isCancel(error)) return { requestCancelled: true };
            // Throw error response so that errorHandler can show messages correclty
            if (error && error.response) throw error.response;
            // throw error if error is not from the api call
            throw error; 
        }
    }

    /** Create item
     * @param {string} accessToken - Access token is added to Authentication header if defined
     * @param {string} resource - api endpoint name f.e. /api/v1/users
     * @param {object} body - object which describes the item
     * @param {object} options - optional options object
     *                         - contentType =  { "Content-Type": "multipart/form-data" };
     *                         - cancelToken = axios.CalcelToken.source()
    */
    const createItem = async (accessToken, resource, body, options) => {
        try {

            if (!resource || typeof resource !== "string") throw Error("Resource is invalid: " + resource);
            if (!body || typeof body !== "object") throw Error("Body is invalid: " + body);

            // options
            const contentType = options && options.contentType ? options.contentType : { "Content-Type": "application/json" };
            const opts = { "headers": contentType }

            if (accessToken) opts.headers.Authorization = "Bearer " + accessToken;
            if (options && options.cancelToken) opts.cancelToken = options.cancelToken.token;

            const apiVersion = config.webApi.apiVersion;
            const root = config.webApi.rootPath;
            const url = new URL(apiVersion + resource, root);

            return await axios.post(url, body, opts);

        } catch (error) {
            // If request is calcelled, then this is not an error, just return!
            if (axios.isCancel(error)) return { requestCancelled: true };
            // Throw error response so that errorHandler can show messages correclty
            if (error && error.response) throw error.response;
            // throw error if error is not from the api call
            throw error; 
        }
    }

    /** Read item
     * @param {string} accessToken - Access token is added to Authentication header if defined
     * @param {string} resource - api endpoint name f.e. /api/v1/users
     * @param {integer} id - item id
     * @param {object} options - options object
     *                         - cancelToken = axios.CalcelToken.source()
     * @returns {object} item - item object
    */
    const readItem = async (accessToken, resource, id, options) => {
        try {

            if (!resource || typeof resource !== "string") throw Error("Resource is invalid: " + resource);

            let opts = { "headers": { "Content-Type": "application/json" } }

            if (accessToken) opts.headers.Authorization = "Bearer " + accessToken;
            if (options && options.cancelToken) opts.cancelToken = options.cancelToken.token;
            if (options && options.responseType) opts.responseType = options.responseType;

            const apiVersion = config.webApi.apiVersion;
            const root = config.webApi.rootPath;
            const param = id ? "/" + id : "";
            const url = new URL(apiVersion + resource + param, root);

            //console.log("opts");
            //console.log(opts);

            return await axios.get(url, opts);

        } catch (error) {
            // If request is calcelled, then this is not an error, just return!
            if (axios.isCancel(error)) return { requestCancelled: true };
            // Throw error response so that errorHandler can show messages correclty
            if (error && error.response) throw error.response;
            // throw error if error is not from the api call
            throw error; 
        }
    }

    /** Update item
     * @param {string} accessToken - Access token is added to Authentication header if defined
     * @param {string} resource - api endpoint name f.e. /api/v1/users
     * @param {object} body - object which describes the item
     * @param {object} options - options object
     *                         - cancelToken = axios.CalcelToken.source()
    */
    const updateItem = async (accessToken, resource, body, options) => {
        try {

            if (!resource || typeof resource !== "string") throw Error("Resource is invalid: " + resource);
            if (!body || typeof body !== "object") throw Error("Body is invalid: " + body.toString());

            const opts = { "headers": { "Content-Type": "application/json" } }
            if (accessToken) opts.headers.Authorization = "Bearer " + accessToken;
            if (options && options.cancelToken) opts.cancelToken = options.cancelToken.token;

            const apiVersion = config.webApi.apiVersion;
            const root = config.webApi.rootPath;
            const url = new URL(apiVersion + resource, root);

            return await axios.put(url, body, opts);

        } catch (error) {
            // If request is calcelled, then this is not an error, just return!
            if (axios.isCancel(error)) return { requestCancelled: true };
            // Throw error response so that errorHandler can show messages correclty
            if (error && error.response) throw error.response;
            // throw error if error is not from the api call
            throw error; 
        }
    }

    /** Delete item
     * @param {string} accessToken - Access token is added to Authentication header if defined
     * @param {string} resource - api endpoint name f.e. /api/v1/users
     * @param {integer} id - item id
     * @param {object} options - options object
     *                         - cancelToken = axios.CalcelToken.source()
    */
    const deleteItem = async (accessToken, resource, id, options) => {
        try {

            if (!resource || typeof resource !== "string") throw Error("Resource is invalid: " + resource);

            const opts = { "headers": { "Content-Type": "application/json" } }
            if (accessToken) opts.headers.Authorization = "Bearer " + accessToken;
            if (options && options.cancelToken) opts.cancelToken = options.cancelToken.token;

            const apiVersion = config.webApi.apiVersion;
            const root = config.webApi.rootPath;
            const param = id ? "/" + id : "";
            const url = new URL(apiVersion + resource + param, root);

            return await axios.delete(url, opts);

        } catch (error) {
            // If request is calcelled, then this is not an error, just return!
            if (axios.isCancel(error)) return { requestCancelled: true };
            // Throw error response so that errorHandler can show messages correclty
            if (error && error.response) throw error.response;
            // throw error if error is not from the api call
            throw error; 
        }
    }

    // Return Public properties and methods
    return {
        list: listItem,
        create: createItem,
        read: readItem,
        update: updateItem,
        delete: deleteItem
    }

}
    
export default webApiClient;