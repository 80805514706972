import webApiClient from '../../Utils/webApiClient';
import { msalInstance } from '../../index';
import config from '../../Config/config';
import useRequestHandler from '../useRequestHandler';

/** ADMIN Hook is used to delete contact */
const useDeleteContact = () => {

    const { errorHandler, successHandler } = useRequestHandler();
    const apiClient = webApiClient();

    /** Delete contact by id
     * @param {integer} id - id of the contact entity */
    const deleteContact = async (contactId) => {
        try {

            // Account is set in the index.js
            const account = msalInstance.getActiveAccount();

            const response = await msalInstance.acquireTokenSilent({
                scopes: config.webApi.scopes,
                account: account
            });

            const result = await apiClient.delete(response.accessToken, "contacts", contactId);  

            return successHandler(result, "useDeleteContact", "delete", { hide: false });

        } catch (error) {
            return errorHandler(error, "useDeleteContact", "delete");
        }
    }

    return { deleteContact };

}

export default useDeleteContact;
