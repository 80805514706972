import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Accordion, Grid, Icon, Header, Input } from 'semantic-ui-react';
import useSearchBusinessPartners from '../../Hooks/Search/useSearchBusinessPartners';
import useListBusinessPartnerProducts  from '../../Hooks/BusinessPartnerProducts/useListBusinessPartnerProducts';
import { SearchItems, SelectedItemLight } from '../UI/Content';
import PagedTable from '../UI/Lists';

/** Bang & Bonsomer - Search and Select Business Partner Component
 * @param {boolean} isLoading - React state for loading
 * @param {function} setIsLoading - React set state function for loading
 * @param {string} title - Component title
 * @param {string} placeholder - Component placeholder
 * @param {string} selectText - Select business partner text
 * @param {string} businessPartnerType - filter for the search endpoint, possible values => null, customer, supplier
 * @param {object} defaultBusinessPartnerSearchTerm - Default searchterm
 * @param {object} selectedBusinessPartner - React state for selected business partner
 * @param {function} setSelectedBusinessPartner - React set state function for selected business partner
 * @param {function} setIsProductListCollapsed - React set state function for collapse product list (OPTIONAL)
 * @param {function} setProducts - React set state function for setting products (OPTIONAL)
 * @param {object} selectedProduct - React state object for selected product (OPTIONAL)
 * @param {string} selectedText - selected business partner title
 * @param {boolean} isValidSelectedItem - selected item is valid
 * @example usage
 * 
 *   import SearchAndSelectBusinessPartners from './../../../BbComponents/SearchAndSelectBusinessPartners';
 *   
 *   <SearchAndSelectBusinessPartners
 *      isLoading={isLoading}
 *      setIsLoading={setIsLoading}
 *      title={t('businessPartner.customer')}
 *      placeholder={t('businessPartner.searchCustomer')}
 *      selectText={t('businessPartner.selectCustomer')}
 *      businessPartnerType={'customer'}
 *      defaultBusinessPartnerSearchTerm={''}
 *      selectedBusinessPartner={selectedBusinessPartner}
 *      setSelectedBusinessPartner={setSelectedBusinessPartner}
 *   />
 * 
 * */
 const SearchAndSelectBusinessPartners = ({
    required,
    setIsLoading,
    title,
    placeholder,
    selectText,
    businessPartnerType, 
    defaultBusinessPartnerSearchTerm = "",
    selectedBusinessPartner,
    setSelectedBusinessPartner,
    setIsProductListCollapsed,
    setProducts,
    selectedProduct,
    selectedText,
    selectedItemIsRequired=false,
    isValidSelectedItem
}) => {

    const { t } = useTranslation();
    const [ filterBusinessPartnerList, setFilterBusinessPartnerList ] = useState('');
    const [ activeIndex, setActiveIndex ] = useState(-1);
    const { businessPartners, setBusinessPartners, searchBusinessPartners } = useSearchBusinessPartners();
    const { listBusinessPartnerProducts } = useListBusinessPartnerProducts();

    return (
        <Container className="fieldcontent" style={{ marginTop: '20px' }}>
            {!selectedBusinessPartner &&
                <>
                    <SearchItems
                        required={required ? required : false}
                        title={title || t('businessPartner.searchBusinessPartner')}
                        placeholder={placeholder || t('businessPartner.searchBusinessPartner')}
                        searchItems={async (searchTerm) => {
                            setIsLoading(true);
                            if (searchTerm.length > 1) {
                                let result = await searchBusinessPartners(searchTerm, 200, businessPartnerType);
                                setActiveIndex(0);
                                if (!result.requestCancelled) setIsLoading(false);
                                return;
                            }
                            // if search term is only 1 char long, stop loading and remove search results;
                            setIsLoading(false);
                            setBusinessPartners([]);
                            setActiveIndex(-1);
                        }}
                        className=""
                        defaultSearchTerm={ defaultBusinessPartnerSearchTerm}
                        setIsLoading={setIsLoading}
                    >
                    </SearchItems>

                    {/* Search results */}
                    <Container className="fieldcontent">
                        <Accordion>
                            <Accordion.Title
                                active={activeIndex === 0}
                                index={0}
                                onClick={() => { setActiveIndex(activeIndex === 0 ? -1 : 0); }}
                                style={{ paddingBottom: 0 }}
                            >
                                <Header as='h3' attached='top' style={{ border: '1px solid #e2e2e2' }}>
                                    <Grid>
                                        <Grid.Column width="8" textAlign="left"> <b>{t('general.results')}</b></Grid.Column>
                                        <Grid.Column  width="8" textAlign="right"><Icon name='dropdown' /></Grid.Column>
                                    </Grid>
                                </Header>
                            </Accordion.Title>

                            <Accordion.Content active={activeIndex === 0} style={{ paddingTop: '5px' }}>
                                <Container>

                                    {/* Filter */}
                                    <Container style={{ marginBottom: '5px'}} >
                                        <Input
                                            action={{
                                                labelPosition: 'left',
                                                icon: 'delete',
                                                content: t('general.clear'),
                                                style: {margin: 0},
                                                onClick:(ev) => {
                                                    ev.preventDefault();
                                                    setFilterBusinessPartnerList('');
                                                }
                                            }}
                                            onChange={(ev, {value} ) => {
                                                ev.preventDefault();
                                                setFilterBusinessPartnerList(value);
                                            }}
                                            fluid
                                            placeholder={t('general.filter')}
                                            value={filterBusinessPartnerList}
                                        />
                                    </Container>

                                    {/* Selection list */}
                                    <PagedTable unstackable celled striped compact='very' pageSize={10}
                                        header={[
                                            t('general.name'),
                                            t('businessPartner.area'),
                                            t('businessPartner.code'),
                                            t('general.businessId'),
                                            t('general.action')
                                        ]}
                                        content={
                                            businessPartners.filter((sup) => sup.Name.toLowerCase().indexOf(filterBusinessPartnerList.toLowerCase()) !== -1)
                                        }
                                        resetPageOnContentChange={true}
                                        contentKeys={[
                                            {key: 'Name'},
                                            {key: 'AreaName'},
                                            {key: 'Code'},
                                            {key: 'AssociationNumber'},
                                            {key: 'Id', type: 'action', text: selectText || t('general.select'), action: async (ev, row, cellKey) => { 

                                                // CASE 2: Sales person select businesspartner first
                                                // fetch businesspartner products and show product selection list
                                                const bp = businessPartners.find(item => item.Id === row.Id);
                                                if (bp) {
                                                    setSelectedBusinessPartner(bp);
                                                    setIsProductListCollapsed(false);
                                                    setActiveIndex(-1);
                                                } 

                                                if (!selectedProduct) {
                                                    let result = null;
                                                    if (listBusinessPartnerProducts) result = await listBusinessPartnerProducts(row.Id);
                                                    if (result && result.data) {
                                                        if (setProducts && result.data.resultData) setProducts(result.data.resultData);
                                                        if (setProducts && !result.data.resultData) setProducts([]);
                                                        if (setIsProductListCollapsed) {
                                                            setIsProductListCollapsed(false);
                                                        }
                                                    }
                                                }

                                            }}
                                        ]}
                                    />
                                </Container>
                            </Accordion.Content>
                        </Accordion>
                    </Container>
                </>
            }
            {selectedBusinessPartner && 
                <SelectedItemLight
                    title={selectedText || t('businessPartner.selectedBusinessPartner')}
                    item={selectedBusinessPartner}
                    headers={[t('businessPartner.supplier'), t('businessPartner.area'), t('general.businessId')]}
                    fields={['Name', 'AreaName', 'AssociationNumber']}
                    required={selectedItemIsRequired}
                    isValidSelectedItem={isValidSelectedItem}
                    onRemoveSelection={(ev) => {
                        setSelectedBusinessPartner(null);
                    }}
                />
            }

        </Container>
    );

}

export default SearchAndSelectBusinessPartners;
