import { useState } from 'react';
import webApiClient from '../../Utils/webApiClient';
import { msalInstance } from '../../index';
import config from '../../Config/config';
import useRequestHandler from '../useRequestHandler';

/** ADMIN ONLY: Hook is used to read parameter entities by id */
const useReadParameter = () => {

    const { errorHandler, successHandler } = useRequestHandler();
    const apiClient = webApiClient();
    const [ parameter, setParameter ] = useState({});

    /** Read parameter by id
     * @param {integer} id - id of the parameter entity */
    const readParameter = async (id) => {
        try {

            // Account is set in the index.js
            const account = msalInstance.getActiveAccount();

            const response = await msalInstance.acquireTokenSilent({
                scopes: config.webApi.scopes,
                account: account
            });

            const result = await apiClient.read(response.accessToken, "parameters", id);  

            const data = result && result.data ? result.data : null;
            const resultData = data.resultData || null;
            let content = null;
            
            if (resultData && resultData.Id) content = result;
            if (resultData && resultData[0] && resultData[0].Id) content = resultData[0];

            if (content) setParameter(content);

            return successHandler(result, "useReadParameter", "read", { hide: true });

        } catch (error) {
            return errorHandler(error, "useReadParameter", "read");
        }
    }

    return { parameter, readParameter };

}

export default useReadParameter;
