import { useState } from 'react';
import webApiClient from './../../Utils/webApiClient';
import { msalInstance } from '../../index';
import config from '../../Config/config';
import useRequestHandler from '../useRequestHandler';

/** Hook is used to fetch list of notifications */
const useListNotifications = () => {

    const [ notifications, setNotifications ] = useState([]);
    const { errorHandler, successHandler } = useRequestHandler();
    const apiClient = webApiClient();

    /** List notifications
     * @param {boolean} own - list only my projects or all projects
     * @param {boolean} active - list only my active projects or passive projects also
     * @param {integer} top - return top n items f.e. 100 or 200 */
    const listNotifications = async (own = false, active = true, top = 200) => {
        try {

            // Account is set in the index.js
            const account = msalInstance.getActiveAccount();

            const response = await msalInstance.acquireTokenSilent({
                scopes: config.webApi.scopes,
                account: account
            });

            // Create resource path
            let resource = "notifications";
            resource += "?own=" + own.toString();
            resource += "&active=" + active.toString();
            resource += "&top=" + top.toString();

            const result = await apiClient.list(response.accessToken, resource);

            const data = result.data || {};
            const content = data.resultData || null;

            if (content) setNotifications(content);

            return successHandler(result, "useListNotifications", "list", { hide: true });

        } catch (error) {

            return errorHandler(error, "useListNotifications", "list");

        }
    }

    return { notifications, listNotifications };

}

export default useListNotifications;
