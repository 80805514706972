import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Container } from 'semantic-ui-react';
import PagedTable from '../UI/Lists';

const MyProjects = ({title, projects}) => {

    const { t } = useTranslation();

    return (
        <Container>

            {/* Projects list header */}
            <h2>{title}</h2>

            {/* Projects list */}
            <PagedTable unstackable celled striped compact='very' pageSize={5}
                header={[
                    t('general.title'),
                    t('businessPartner.businessPartner'),
                    t('general.description'),
                    t('general.status'),
                    //t('project.passivatingDate'),
                    //t('project.closingDate'),
                    t('project.passivatedDate'),
                    t('project.endDate'),
                    t('general.updated'),
                    t('general.salesPerson')
                ]}
                content={projects}
                resetPageOnContentChange={true}
                contentKeys={[
                    {key: 'Title', target: '/projects/view/', type: 'link', targetKey: 'Id'},
                    {key: 'BusinessPartnerName' },
                    {key: 'Description' },
                    {key: 'StatusName'},
                    //{key: 'PassivatingDate', type: 'date'},
                    //{key: 'ClosingDate', type: 'date'},
                    {key: 'PassivatedDate', type: 'date'},
                    {key: 'EndDate', type: 'date'},
                    {key: 'DataUpdated', type: 'date'},
                    {key: 'SalesPersonName'}
                ]}
                style={{ marginBottom: '20px' }}
            />

            {/* Link to all projects */}
            <Container textAlign="right">
                <Link to="/projects">{t('project.projects') + " >>>"}</Link>
            </Container>

        </Container>
    );

}

export default MyProjects;
