import React, { useState, useContext, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Form, Input, TextArea, Container, Dropdown, Message, Checkbox } from 'semantic-ui-react';
import { Loading } from '../../Components/UI/Loaders';
import { SubmitButton, CancelButton } from '../../Components/UI/Buttons';
import { PageTitle, SelectedItemLight } from '../../Components/UI/Content';
import { StateContext } from '../../Application/Context/StateContext';
import useCreateProject from '../../Hooks/Project/useCreateProject';
import SearchBusinessPartners from '../../Components/BusinessPartners/SearchBusinessPartners';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const NewProject = (props) => {

    const { t } = useTranslation();
    const history = useHistory();
    const scollToTopRef = useRef();

    const [ isLoading, setIsLoading ] = useState(false);
    const [ disableSave, setDisableSave] = useState(false);
    const [ showSuppliers, setShowSuppliers ] = useState(false);
    const [ selectedBusinessPartner, setSelectedBusinessPartner ] = useState(null);
    const [ title, setTitle ] = useState("");
    const [ description, setDescription ] = useState("");
    const [ businessUnit, setBusinessUnit ] = useState(0);
    const [ startDate, setStartDate ] = useState(new Date());
    const [ status, setStatus ] = useState(2);
    const [ application, setApplication ] = useState("");

    const { userProfile, businessUnits } = useContext(StateContext);
    const { createProject } = useCreateProject();

    /* Get current user as sales person from global state */
    const [ isValidSelectedCustomer, setIsValidSelectedCustomer ] = useState(true);

    const onSubmit = async () => {
        try {

            if (!selectedBusinessPartner) return setIsValidSelectedCustomer(false);
            if (!userProfile) return null;
            if (businessUnit === 0) return null;

            setDisableSave(true);
            setIsLoading(true);

            const newProject = {
                salesPerson: userProfile.Id,
                title: title,
                description: description,
                businessPartner: null,
                customerSegment1: null,
                customerSegment2: null,
                customerSegment3: null,
                startDate: null,
                businessUnit: businessUnit,
                status: status,
                application: application
            }

            if (selectedBusinessPartner && selectedBusinessPartner.Id) {
                newProject.businessPartner = parseInt(selectedBusinessPartner.Id);
            }

            if (status === 2) {
                let startDateISO = startDate ? startDate.toISOString() : null;
                newProject.startDate = startDateISO;
            }

            const result = await createProject(newProject);

            if (result && result.status && result.status === 200 && result.data && result.data.createdId) {
               return history.replace('/projects/view/' + result.data.createdId);
            }

            setDisableSave(false);
            setIsLoading(false);

        } catch (error) {
            setDisableSave(false);
            setIsLoading(false);
        }
    }

    let mappedBusinessUnits = [{ key: 0, value: 0, text: t('general.choose') + "..."}];
    if (businessUnits) {
        mappedBusinessUnits = businessUnits.map(bu => {
            return { key: bu.Id, value: bu.Id, text: bu.Name }
        });
        mappedBusinessUnits.unshift({ key: 0, value: 0, text: t('general.choose') + "..."});
    }
    
    useEffect(() => {
        scollToTopRef.current.scrollIntoView();
    }, []);

    return (
        <Form className="project" onSubmit={(ev) => { ev.preventDefault(); onSubmit(); }} >
        
            <div ref={scollToTopRef}></div>

            <Loading active={ isLoading } text={t('general.loading')} />

            <PageTitle active title={t('project.newProject')} />

            {/* Sales person */}
            <Form.Field label={t('project.salesPerson')} style={{ marginBottom: '0px' }}/>

            <Form.Field
                disabled={true}
                //label={t('project.salesPerson')}
                placeholder={t('project.salesPerson')}
                maxLength="255"
                control={Input}
                value={userProfile.Name}
                //onChange={(ev, {value}) => setSalesPerson(value) }
                type="Text"
            />

            {/* Business unit */}
            <Form.Field
                required
                label={t('businessPartner.businessUnit')}
                style={{ marginBottom: '0px' }}
            />
            
            <div className="fieldcontent">
                <Dropdown
                    required
                    placeholder={t('businessPartner.businessUnit')}
                    fluid
                    search
                    selection
                    options={mappedBusinessUnits}
                    onChange={(ev, {value}) => setBusinessUnit(value) }
                    value={businessUnit}
                    error={businessUnit === 0}
                />
            </div>

            {/* Customer */}
            {!selectedBusinessPartner &&
                <>
                    <Form.Field
                        required
                        label={t('businessPartner.searchCustomer')}
                        style={{ marginBottom: '0px' }}
                        value={selectedBusinessPartner}
                    />

                    <Form.Field
                        control={Checkbox}
                        label={t('businessPartner.showSuppliers')}
                        placeholder={t('businessPartner.showSuppliers')}
                        checked={showSuppliers}
                        onChange={(ev, {checked}) => setShowSuppliers(checked) }
                        style={{ marginBottom: '2px' }}
                    />

                    <SearchBusinessPartners
                        required
                        //title={t('businessPartner.searchCustomer')}
                        placeholder={t('businessPartner.searchCustomer')}
                        isLoading={isLoading}
                        setIsLoading={setIsLoading}
                        selectedBusinessPartner={selectedBusinessPartner}
                        setSelectedBusinessPartner={setSelectedBusinessPartner}
                        setIsValidSelectedBusinessPartner={setIsValidSelectedCustomer}
                        type={showSuppliers ? null : "customer"}
                    />
                </>
            }
            {selectedBusinessPartner &&
                <SelectedItemLight
                    title={t('businessPartner.selectedCustomer')}
                    item={selectedBusinessPartner}
                    headers={[t('general.name'), t('businessPartner.area'), t('general.businessId') , t('general.type')]}
                    fields={['Name', 'AreaName', 'AssociationNumber', 'Type']}
                    required={true}
                    isValidSelectedItem={isValidSelectedCustomer}
                    onRemoveSelection={(ev) => {
                        setSelectedBusinessPartner(null);
                    }}
                />
            }

            {/* Title */}
            <Form.Field
                focus
                required
                label={t('general.title')}
                placeholder={t('general.title')}
                maxLength="255"
                control={Input}
                value={title}
                onChange={(ev, {value}) => setTitle(value) }
                type="Text"
            />

            {/* Application */}
            <Form.Field
                focus
                //required
                label={t('project.application')}
                placeholder={t('project.application')}
                maxLength="255"
                control={Input}
                value={application}
                onChange={(ev, {value}) => setApplication(value) }
                type="Text"
            />

            {/* Description */}
            <Form.Field
                //required
                label={t('general.description')}
                placeholder={t('general.description')}
                control={TextArea}
                value={description}
                onChange={(ev, {value}) => setDescription(value) }
                type="Text"
            />

            {/* Status title */}
            <Form.Field label={t('general.status')} style={{ marginBottom: '0px' }}/>

            {/* Project Status */}
            <div className="fieldcontent">
                <Dropdown
                    required
                    placeholder={t('project.status')}
                    fluid
                    search
                    selection
                    value={status}
                    options={[
                        {
                            key: 1,
                            text: t('status.planned'),
                            value: 1
                        },
                        {
                            key: 2,
                            text: t('status.started'),
                            value: 2
                        }
                    ]}
                    onChange={(ev, {value}) => setStatus(value) }
                />
            </div>

            {/* Start Date */}
            {status === 2 && 
                <div className="fieldcontent">
                    <Form.Field label={t('project.startDate')} style={{ marginBottom: '0px' }}/>
                    <DatePicker
                        required
                        label={t('project.startDate')}
                        selected={startDate}
                        dateFormat={navigator.language === "fi-FI" ? "dd.MM.yyyy" : "yyyy/MM/dd"}
                        onChange={(date) => setStartDate(date)}
                    />
                </div>
            }

            {/* Errors */}
            <Message error visible={userProfile && !userProfile.Id} content={t('errors.missingLoggedInUser')} />
            
            <Message warning visible={showSuppliers} content={"Warning: " + t('warnings.youHaveChosenSupplierAsProjectCustomer') + "!"} />

            {/* Buttons */}
            <Container className='button-container'>
                <CancelButton onClick={(ev) => {ev.preventDefault(); history.goBack(); }} />
                <SubmitButton disabled={disableSave} className="bb-lblue" icon="save" title={t('general.save')} type="submit" />
            </Container>

        </Form>
    );

}

export default NewProject;
