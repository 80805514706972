import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Container } from 'semantic-ui-react';
import { NewButton } from '../Components/UI/Buttons';
import { Loading } from '../Components/UI/Loaders';
import MyProjects from '../Components/Projects/MyProjects';
import MyTop5Notifications from '../Components/Notifications/MyTop5Notifications';
import useListProjects from '../Hooks/Project/useListProjects';
import useListNotifications from '../Hooks/Notifications/useListNotifications';

const Landing = (props) => {

    const { t } = useTranslation();
    const history = useHistory();
    const [ isLoading, setIsLoading ] = useState(false);

    const [ myTop5ActiveProjects, setMyTop5ActiveProjects ] = useState([]);
    const [ myPassivatedProjects, setMyPassivatedProjects ] = useState([]);

    const { listProjects } = useListProjects();
    const { notifications, listNotifications } = useListNotifications();

    useEffect(() => {
        (async() => {

            setIsLoading(true);

            // List top 5 projects
            const active = await listProjects({ own: true, active: true, passive: false, top: 5, businesspartner: null });
            if (active && active.data && active.data.resultData) setMyTop5ActiveProjects(active.data.resultData);

            // List my passivated projects
            const passivated = await listProjects({ own: true, active: false, passive: true, top: 1000, businesspartner: null });
            if (passivated && passivated.data && passivated.data.resultData) setMyPassivatedProjects(passivated.data.resultData);

            // List notifications
            await listNotifications(true, true, 5);

            setIsLoading(false);

        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="landing">

            <Loading active={ isLoading } text={t('general.loading')} />

            <Container textAlign="center" className="fieldcontent">
                <NewButton title={t('project.newProject')} fluid href="" className="bb-lblue" onClick={(ev) => { ev.preventDefault(); history.push('/projects/new')} }/>
                <NewButton title={t('activity.newActivity')} fluid href="" className="bb-lblue" onClick={(ev) => { ev.preventDefault(); history.push('/activities/new')} }/>
            </Container>

            <MyProjects projects={myTop5ActiveProjects} title={t("project.myRecentProjects")} />

            <MyTop5Notifications notifications={notifications} setIsLoading={setIsLoading} listNotifications={listNotifications} />

            <MyProjects projects={myPassivatedProjects} title={t("project.myPassivatedProjects")} />

        </div>
    );

}

export default Landing;
