import { useState } from 'react';
import webApiClient from '../../Utils/webApiClient';
import { msalInstance } from '../../index';
import config from '../../Config/config';
import useRequestHandler from '../useRequestHandler';

/** Hook is used to fetch list of business partner products */
const useListBusinessPartnerProducts = () => {

    const [ businessPartnerProducts, setBusinessPartnerProducts ] = useState([]);
    const { errorHandler, successHandler } = useRequestHandler();
    const apiClient = webApiClient();

    /** List business partner products
     * @param {integer} businessPartnerId - business partner id*/
    const listBusinessPartnerProducts = async (businessPartnerId) => {
        try {

            // Account is set in the index.js
            const account = msalInstance.getActiveAccount();

            const response = await msalInstance.acquireTokenSilent({
                scopes: config.webApi.scopes,
                account: account
            });

            let resource = "businesspartners/" + businessPartnerId.toString() + "/products";

            const result = await apiClient.list(response.accessToken, resource);

            const data = result.data || {};
            const content = data.resultData || null;

            if (content) setBusinessPartnerProducts(content);

            return successHandler(result, "useListBusinessPartnerProducts", "list", { hide: true });

        } catch (error) {

            return errorHandler(error, "useListBusinessPartnerProducts", "list");

        }
    }

    return { businessPartnerProducts, listBusinessPartnerProducts };

}

export default useListBusinessPartnerProducts;
