import { useState } from 'react';
import webApiClient from './../../Utils/webApiClient';
import { msalInstance } from '../../index';
import config from '../../Config/config';
import useRequestHandler from '../useRequestHandler';

/** Hook is used to fetch list of project products */
const useListProjectProducts = () => {

    const [ projectProducts, setProjectProducts ] = useState([]);
    const { errorHandler, successHandler } = useRequestHandler();
    const apiClient = webApiClient();

    /** List project products
     * @param {boolean} projectId - Project id */
    const listProjectProducts = async (projectId = null) => {
        try {

            // Account is set in the index.js
            const account = msalInstance.getActiveAccount();

            const response = await msalInstance.acquireTokenSilent({
                scopes: config.webApi.scopes,
                account: account
            });

            // Create resource path
            let resource = "projects/" + projectId.toString() + "/products";

            const result = await apiClient.list(response.accessToken, resource);

            const data = result.data || {};
            const content = data && data.resultData ? data.resultData : [];

            if (content) setProjectProducts(content);

            return successHandler(result, "useListProjectProducts", "list", { hide: true });

        } catch (error) {

            return errorHandler(error, "useListProjectProducts", "list");

        }
    }

    return { projectProducts, listProjectProducts };

}

export default useListProjectProducts;
