import React, { useState, useContext, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { Form, Container, Dropdown, Input, Message, Checkbox, TextArea } from 'semantic-ui-react';
import { Loading } from '../../Components/UI/Loaders';
import { SubmitButton, CancelButton } from '../../Components/UI/Buttons';
import { PageTitle, SelectedItemLight } from '../../Components/UI/Content';
import { StateContext } from '../../Application/Context/StateContext';
import useReadProject from '../../Hooks/Project/useReadProject';
//import useListProjectContacts from '../../../Hooks/ProjectContacts/useListProjectContacts';
import useSearchContacts from '../../Hooks/Search/useSearchContacts';
import useReadBusinessPartner from '../../Hooks/BusinessPartners/useReadBusinessPartner';
import useCreateActivity from '../../Hooks/Activities/useCreateActivity';
import useCreateNotification from '../../Hooks/Notifications/useCreateNotification';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { createExternalAttendeesJson } from '../../Utils/ActionArenaUtils';
import NewCalendarEvent from '../../Components/Outlook/OutlookEvent';
import useCreateCalendarEvent from '../../Hooks/CalendarEvents/useCreateCalendarEvent';

const NewProjectActivity = (props) => {

    const { t } = useTranslation();
    const history = useHistory();
    const scollToTopRef = useRef();
    const { projectId } = useParams();

    const [ isLoading, setIsLoading ] = useState(false);

    const [ title, setTitle ] = useState("");
    const [ activityDate, setActivityDate ] = useState(new Date());
    const [ activityType, setActivityType ] = useState(1);
    const [ status, setStatus ] = useState(1);
    const [ businessUnit, setBusinessUnit ] = useState(0);   
    
    const [ internalAttendees, setInternalAttendees ] = useState("");
    const [ externalAttendees, setExternalAttendees ] = useState(""); // SAVE AS JSON STRING
    const [ searchContactQuery, setSearchContactQuery ] = useState('');
    const [ searchContactValue, setSearchContactValue ] = useState([]);

    const [ objective, setObjective ] = useState("");
    const [ internalNotes, setInternalNotes ] = useState("");
    const [ externalNotes, setExternalNotes ] = useState("");

    const [ createNotificationIsSelected, setCreateNotificationIsSelected ] = useState(false);
    const [ notificationDate, setNotificationDate] = useState(new Date());
    const [ notificationDescription, setNotificationDescription] = useState("");

    const { userProfile,statusCodes, activityTypes, businessUnits } = useContext(StateContext);
    const { project, readProject } = useReadProject();
    const { businessPartner, readBusinessPartner } = useReadBusinessPartner();
    const { createActivity } = useCreateActivity();
    const { createNotification } = useCreateNotification();
    //const { projectContacts, listProjectContacts } = useListProjectContacts();
    const { contacts, searchContacts } = useSearchContacts();
    const { createCalendarEvent } = useCreateCalendarEvent();

    /* Outlook event */
    const [ addToOutlook, setAddToOutlook ] = useState(false);
    const [ startDate, setStartDate ] = useState(() => {
        const d = new Date(notificationDate);
        d.setHours(d.getHours() + 1);
        d.setMinutes(0);
        d.setSeconds(0);
        d.setMilliseconds(0);
        return d;
    });
    const [ endDate, setEndDate ] = useState(() => {
        const d = new Date(notificationDate);
        d.setHours(d.getHours() + 2);
        d.setMinutes(0);
        d.setSeconds(0);
        d.setMilliseconds(0);
        return d;
    });
    const [ location, setLocation ] = useState("");

    const userIsAdmin = userProfile && userProfile.IsAdmin ? true : false;
    const projectHasEnded = project && project.EndDate ? true : false; // If project has end date, project is ended and only admin can edit it!
    const projectIsNotActive = project && project.Status > 7 ? true : false; // if project status is greater than 7 (not active anymore) only admin can edit it!
    const disableEditing = (projectHasEnded || projectIsNotActive) && !userIsAdmin ? true : false;

    // Save data on submit
    const onSubmit = async () => {
        try {

            if (!projectId) return null;
            if (!project) return null;
            if (!project.BusinessPartner) return null;

            setIsLoading(true);

            const activityObj = {
                "title": title,
                "type": activityType,
                //"contactDate": activityDate ? activityDate.toISOString() : null,
                //"project": selectedProject && selectedProject.Id ? selectedProject.Id : null,
                "project": projectId,
                //"projectNewStatus": status,
                //"businessUnit": businessUnit,
                //"businessPartner": selectedBusinessPartner && selectedBusinessPartner.Id ? selectedBusinessPartner.Id : null,
                "businessPartner": project.BusinessPartner,
                "internalAttendee": internalAttendees,
                "externalAttendee": JSON.stringify(createExternalAttendeesJson(externalAttendees, searchContactValue, contacts)),
                "objective": objective,
                "externalNotes": externalNotes,
                "internalNotes": internalNotes,
                "salesPerson": userProfile && userProfile.Id ? userProfile.Id : null,
                "contact": null
            }

            // Add status only if activity type is status change"
            if (activityType && activityType === 5) {
                activityObj.projectNewStatus = status;
            }

            // Add date only if type is not status change!
            if (activityType && activityType !== 5) {
                activityObj.contactDate = activityDate ? activityDate.toISOString() : null;
            }

            // Add businessUnit only if type is not status change!
            if (businessUnit && businessUnit !== 0) {
                activityObj.businessUnit = businessUnit;
            }

            let result = await createActivity(activityObj);

            if (result && result.status === 200) {

                let createdId = null;

                if (result && result.data && result.data.createdId) createdId = result.data.createdId;
                if (result && result.createdId) createdId = result.createdId;

                if (createdId && createNotificationIsSelected) {

                    const notificationObj = {
                        "name":"Project activity notification",
                        "title": "Project activity notification",
                        "activity": createdId,
                        "date": notificationDate ? notificationDate.toISOString() : null,
                        "description": "Project activity notification: " + title + ", " + createdId,
                        "businessPartner": project.BusinessPartner,
                        "salesPerson": userProfile && userProfile.Id ? userProfile.Id : null,
                        "project": parseInt(projectId),
                    }

                   await createNotification(notificationObj);

                   if (addToOutlook) {
                    
                        const outlookEvent = {
                            "subject": title.trim().toString(),
                            "body": {
                                "contentType": "HTML",
                                "content": notificationDescription.trim()
                            },
                            "start": {
                                "dateTime": startDate.toISOString(),
                                "timeZone": "FLE Standard Time"
                            },
                            "end": {
                                "dateTime": endDate.toISOString(),
                                "timeZone": "FLE Standard Time"
                            },
                            "location": {
                                "displayName": location.trim().toString()
                            }
                        }

                        await createCalendarEvent(outlookEvent);
                        // Do not block if event creation fails.
                        //if (res && res.status && res.status !== 200) return;

                    }

                }

                return history.goBack();
            }

            setIsLoading(false);

        } catch (error) {
            setIsLoading(false);
        }
    }

    // Read data on page load
    useEffect(() => {
        (async() => {
            scollToTopRef.current.scrollIntoView();
            setIsLoading(true);
            if(projectId) {
                await readProject(projectId);
                //await listProjectContacts(projectId);
                await searchContacts();
            }
            setIsLoading(false);
        })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Read business partner
    useEffect(() => {
        if (project) {

            if (project.BusinessPartner) {
                (async() => {
                    setIsLoading(true);
                    if(projectId) await readBusinessPartner(project.BusinessPartner);
                    setIsLoading(false);
                })();
            }
            
            if (project.BusinessUnit) {
                setBusinessUnit(project.BusinessUnit);
            }

        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [project]);

    let mappedBusinessUnits = [];
    if (businessUnits) {
        mappedBusinessUnits = businessUnits.map(bu => {
            return { key: bu.Id, value: bu.Id, text: bu.Name }
        });
        mappedBusinessUnits.unshift({ key: 0, value: 0, text: t('general.select')+"..." });
    }

    // Sort contacts by selected businesspartner or project
    let businessPartnerIdfilter = businessPartner && businessPartner.Id ? businessPartner.Id : 0;

    // 1.Filter contacts by business partner of project selection
    const filteredBusinessPartnerContacts = contacts.filter(item => {
        return item.BusinessPartner === businessPartnerIdfilter;
    });

    // 2.Filter other contacts
    const filteredOtherContacts = contacts.filter(item => {
        return item.BusinessPartner !== businessPartnerIdfilter;
    });

    // 3. Combine arrays.
    let orderedContacts = [];
    if (filteredBusinessPartnerContacts.length > 0 || filteredOtherContacts.length > 0 ) {
        orderedContacts = [...filteredBusinessPartnerContacts, ...filteredOtherContacts];
    } else {
        orderedContacts = [...contacts];
    }

    return (
        <Form className="activity" onSubmit={(ev) => { ev.preventDefault(); onSubmit(); }} >
        
            <div ref={scollToTopRef}></div>

            <Loading active={ isLoading } text={t('general.loading')} />

            <PageTitle active title={t('activity.newProjectActivity')} />
            
            <Message warning visible={projectHasEnded || projectIsNotActive} content={t('warnings.projectIsNotActive')} />

            {/* Activity Type */}
            <Form.Field label={t('activity.activityType')} />
            <div className="fieldcontent">
                <Dropdown
                    required
                    placeholder={t('activity.activityType')}
                    fluid
                    search
                    selection
                    options={activityTypes
                        .filter(type => {
                            return type.Id !== 7; //Do not show sales person change
                        })
                        .map(type => {
                        return {
                            key: type.Id,
                            value: type.Id,
                            text: type.Name
                        }
                    })}
                    value={activityType}
                    onChange={(ev, {value}) => {
                        setActivityType(value);
                        // automatically suggest next available status id activity type is status change (5). Regular business is last suggested status (8).
                        if (value === 5 && project && project.Status && project.Status < 8) {
                            const foundStatus = statusCodes.find(item => item.Id === project.Status + 1);
                            setStatus(foundStatus.Id);
                            setTitle("Status change -> " + foundStatus.Name);
                        }
                        if (value === 5 && project && project.Status && project.Status === 8) {
                            setStatus(project.Status);
                        }
                    }}
                />
            </div>

            {/* Project status (activity type 5 = project status change) */}
            { activityType && activityType === 5 && 
                <>
                    <Form.Field label={t('project.newStatus')} />
                    <div className="fieldcontent">
                        <Dropdown
                            required
                            placeholder={t('general.status')}
                            fluid
                            search
                            selection
                            options={statusCodes.filter(item => {
                                /*Dont allow user to set status 10 - 12 */
                                if (item.Id < 10) return true;
                                return false;
                            }).map((type, index) => {
                                return {
                                    key: index + 1,
                                    value: type.Id,
                                    text: type.Name
                                }
                            })}
                            value={status}
                            onChange={(ev, {value}) => {
                                const foundStatus = statusCodes.find(item => item.Id === value);
                                setStatus(value);
                                setTitle("Status change -> " + foundStatus.Name);
                            }}
                        />
                    </div>

                </>
            }

            {/* Title */}
            <Form.Field
                required
                label={t('general.title')}
                //placeholder={t('general.title')}
                maxLength="255"
                control={Input}
                value={title}
                onChange={(ev, {value}) => setTitle(value) }
                type="Text"
            />

            {/* Project activity Date - Visible only if not status change */}
            {activityType !== 5
                ?
                    <Container className="fieldcontent">
                        <Form.Field
                            required={activityType !== 5}
                            control={DatePicker}
                            label={t('activity.projectActivityDate')}
                            selected={activityDate}
                            dateFormat={navigator.language === "fi-FI" ? "dd.MM.yyyy" : "yyyy/MM/dd"}
                            onChange={(date) => setActivityDate(date)}
                        />
                    </Container>
                :
                    <Container className="fieldcontent">
                        <Form.Field
                            disabled
                            control={DatePicker}
                            label={t('activity.projectActivityDate')}
                            selected={activityDate}
                            dateFormat={navigator.language === "fi-FI" ? "dd.MM.yyyy" : "yyyy/MM/dd"}
                        />
                    </Container>
            }

            <SelectedItemLight
                title={t('project.project')}
                item={project}
                headers={[t('general.title'), t('project.currentStatus'), t('general.businessPartner'), t('businessPartner.businessUnit')]}
                fields={['Title', 'StatusName', 'BusinessPartnerName', 'BusinessUnitName']}
                //required={true}
                //isValidSelectedItem={true}
            />

            {/* Business unit */}
            <Form.Field label={t('businessPartner.businessUnit')} />
            <div className="fieldcontent">
                <Dropdown
                    disabled
                    //required={activityType !== 5}
                    placeholder={t('businessPartner.businessUnit')}
                    fluid
                    search
                    selection
                    options={mappedBusinessUnits}
                    onChange={(ev, {value}) => setBusinessUnit(value) }
                    value={businessUnit}
                />
            </div>

            {/* Objective */}
            <Form.Field
                //required={activityType !== 5}
                label={t('activity.objective')}
                placeholder={t('activity.objective')}
                maxLength="255"
                control={Input}
                value={objective}
                onChange={(ev, {value}) => setObjective(value) }
                type="Text"
            />

            {/* External notes */}
            <Form.Field
                //required={activityType !== 5}
                label={t('activity.externalNotes')}
                placeholder={t('activity.externalNotes')}
                control={TextArea}
                value={externalNotes}
                rows={5}
                onChange={(ev, {value}) => setExternalNotes(value) }
                type="Text"
            />

            {/* Internal notes */}
            <Form.Field
                label={t('activity.internalNotes')}
                placeholder={t('activity.internalNotes')}
                control={TextArea}
                value={internalNotes}
                rows={15}
                onChange={(ev, {value}) => setInternalNotes(value) }
                type="Text"
            />

            {/* Internal attendees */}
            <Form.Field
                label={t('activity.internalAttendees')}
                placeholder={t('activity.internalAttendees')}
                maxLength="255"
                control={Input}
                value={internalAttendees}
                onChange={(ev, {value}) => setInternalAttendees(value) }
                type="Text"
            />

            {/* External attendees */}
            <Form.Field label={t('activity.externalAttendees')} />
            <Dropdown
                placeholder={t('activity.selectExternalAttendeesFromContacts')}
                clearable
                fluid
                multiple
                search
                selection
                options={orderedContacts.map(item => {
                    if (item && item.BusinessPartnerName) {
                        item.NameWithBP = item.Name + " [" + item.BusinessPartnerName + "]";
                    } else {
                        item.NameWithBP = item.Name;
                    }
                    return item;
                }).map(item => {
                    return {
                        key: item.Id,
                        text: item.NameWithBP,
                        value: item.Id,
                    }
                })}
                onChange={(e, { searchQuery, value }) => {
                    setSearchContactQuery(searchQuery);
                    setSearchContactValue(value);
                }}
                onSearchChange={(e, { searchQuery, value }) => {
                    setSearchContactQuery(searchQuery);
                }}
                style={{ marginBottom: "1px" }}
                searchQuery={searchContactQuery}
                value={searchContactValue}
            />
            <Form.Field
                maxLength="255"
                control={Input}
                value={externalAttendees}
                onChange={(ev, {value}) => setExternalAttendees(value) }
                type="Text"
                placeholder={t('activity.externalAttendees')}
            />

            {/* Create notification? */}
            <Form.Field
                control={Checkbox}
                label={t('activity.createNotification')}
                placeholder={t('activity.createNotification')}
                checked={createNotificationIsSelected}
                onChange={(ev, {checked}) => setCreateNotificationIsSelected(checked) }
            />
            
            {createNotificationIsSelected && 
                <Container className="fieldcontent">

                    {/* Notification date */}
                    <Container className="fieldcontent">
                        <Form.Field label={t('notification.notificationDate')} style={{ marginBottom: '0px' }}/>
                        <DatePicker
                            required
                            label={t('notification.notificationDate')}
                            selected={notificationDate}
                            dateFormat={navigator.language === "fi-FI" ? "dd.MM.yyyy" : "yyyy/MM/dd"}
                            onChange={(date) => setNotificationDate(date)}
                        />
                    </Container>

                    {/* Notification description */}
                    <Container className="fieldcontent">
                        <Form.Field
                            required
                            label={t('notification.description')}
                            placeholder={t('notification.description')}
                            control={TextArea}
                            value={notificationDescription}
                            onChange={(ev, {value}) => setNotificationDescription(value) }
                            type="Text"
                        />
                    </Container>

                    <NewCalendarEvent
                        addToOutlook={addToOutlook}
                        setAddToOutlook={setAddToOutlook}
                        title={title}
                        body={notificationDescription}
                        startDate={startDate}
                        setStartDate={setStartDate}
                        endDate={endDate}
                        setEndDate={setEndDate}
                        location={location}
                        setLocation={setLocation}
                    />

                </Container>
            }

            {/* Errors */}
            <Message error visible={!projectId} content={t('errors.missingProjectId')} />

            {/* Buttons */}
            <Container className='button-container'>
                <CancelButton onClick={(ev) => {ev.preventDefault(); history.goBack(); }} />
                <SubmitButton disabled={disableEditing} type="submit" className="bb-lblue" icon="save" title={t('general.save')} />
            </Container>

        </Form>
    );

}

export default NewProjectActivity;
