import { useState } from 'react';
import webApiClient from '../../Utils/webApiClient';
import { msalInstance } from '../../index';
import config from '../../Config/config';
import useRequestHandler from '../useRequestHandler';

/** Hook is used to fetch list of users */
const useListUsers = () => {

    const [ users, setUsers ] = useState([]);
    const { errorHandler, successHandler } = useRequestHandler();
    const apiClient = webApiClient();

    /** List users (sales persons)
     * @param {boolean} deleted return also deleted users
     * @param {boolean} inactive return also inactive users
    */
    const listUsers = async (deleted = false, inactive = false) => {
        try {

            // Account is set in the index.js
            const account = msalInstance.getActiveAccount();

            const response = await msalInstance.acquireTokenSilent({
                scopes: config.webApi.scopes,
                account: account
            });

            // Create resource path
            let resource = "users";
                resource += "?deleted=" + deleted;
                resource += "&inactive=" + inactive;

            const result = await apiClient.list(response.accessToken, resource);
            const data = result.data || {};
            const content = data.resultData && Array.isArray(data.resultData) ? data.resultData : [];

            if (content) {
                let mappedUsers = content.map(user => {
                    if (user.Deleted) {
                        user.Name += " [DELETED]";
                    }
                    if (user.IsActive === false) {
                        user.Name += " [INACTIVE]";
                    }
                    return user;
                });
                setUsers(mappedUsers)
            };

            return successHandler(result, "useListUsers", "list", { hide: true });

        } catch (error) {

            return errorHandler(error, "useListUsers", "list");

        }
    }

    return { users, listUsers };

}

export default useListUsers;
