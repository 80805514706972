import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { Form, Input, Container, Message, TextArea } from 'semantic-ui-react';
import { Loading } from '../../Components/UI/Loaders';
import { SubmitButton, CancelButton } from '../../Components/UI/Buttons';
import { PageTitle } from '../../Components/UI/Content';
import SearchAndSelectBusinessPartners from './../../Components/BusinessPartners/SearchAndSelectBusinessPartners';
import SearchAndSelectProducts from '../../Components/Product/SearchAndSelectProducts';
import useCreateProjectProduct from '../../Hooks/ProjectProducts/useCreateProjectProduct';
import useSearchProducts from '../../Hooks/Search/useSearchProducts';
import Utils from '../../Utils/utils';

const NewProjectProduct = (props) => {

    const { t } = useTranslation();
    const history = useHistory();
    const scollToTopRef = useRef();
    const { projectId } = useParams();

    const [ isLoading, setIsLoading ] = useState(false);
    const [ isValidSelectedProduct, setIsValidSelectedProduct ] = useState(true);
    const [ isValidSelectedSupplier, seIsValidSelectedSupplier ] = useState(true);

    const [ isProductListCollapsed, setIsProductListCollapsed ] = useState(true);
    const [ selectedProduct, setSelectedProduct ] = useState(null);
    const [ selectedSupplier, setSelectedSupplier ] = useState(null);
    const [ description, setDescription ] = useState("");
    const [ potentialVolumeInSalesUnitOfMeasure, setPotentialVolumeInSalesUnitOfMeasure ] = useState(0);
    const [ potentialSales, setPotentialSales ] = useState(0);
    const [ potentialMargin, setPotentialMargin ] = useState(0);

    const { createProjectProduct } = useCreateProjectProduct();
    const { products, setProducts, searchProducts } = useSearchProducts();

    const onSubmit = async () => {
        try {

            if (!selectedProduct || !selectedProduct.Id) return setIsValidSelectedProduct(false);
            if (!selectedSupplier || !selectedSupplier.Id) return seIsValidSelectedSupplier(false);

            setIsLoading(true);

            if (!projectId) {
                setIsLoading(false);
                return null;
            }

            const object = {	
                "project": parseInt(projectId),
                "product": selectedProduct.Id,
                "businessPartner": selectedSupplier.Id,
                "description": description,
                "potentialVolumeInSalesUnitOfMeasure": parseFloat(potentialVolumeInSalesUnitOfMeasure),
                "potentialSales": parseFloat(potentialSales),
                "potentialMargin": parseFloat(potentialMargin),
                "marginPercentage": parseFloat(Utils.calculateMarginPercentage(potentialSales, potentialMargin))
            }

            const result = await createProjectProduct(projectId, object);

            //const productId = result.data.productId;

            if (result && result.status && result.status === 200 && result.data) {
                return history.goBack();
            }

            setIsLoading(false);

        } catch (error) {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        scollToTopRef.current.scrollIntoView();
    }, []);

    return (
        <Form className="notification" onSubmit={(ev) => { ev.preventDefault(); onSubmit(); }} >

            <div ref={scollToTopRef}></div>

            <Loading active={ isLoading } text={t('general.loading')} />

            <PageTitle active title={t('product.newProjectProduct')} />

            <SearchAndSelectProducts
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                title={t('product.searchProductByName')}
                placeholder={t('product.searchProductByName')}
                productSearchTerm = ""
                selectedProduct={selectedProduct}
                setSelectedProduct={setSelectedProduct}
                isProductListCollapsed={isProductListCollapsed}
                setSelectedSupplier={setSelectedSupplier}
                selectedItemIsRequired={true}
                isValidSelectedItem={isValidSelectedProduct}
                products={products}
                setProducts={setProducts}
                searchProducts={searchProducts}
            />

            <SearchAndSelectBusinessPartners
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                title={t('businessPartner.searchSupplierByName')}
                placeholder={t('businessPartner.searchSupplierByName')}
                selectText={t('businessPartner.selectSupplier')}
                businessPartnerType="supplier"
                defaultBusinessPartnerSearchTerm = ""
                selectedBusinessPartner={selectedSupplier}
                setSelectedBusinessPartner={setSelectedSupplier}
                setIsProductListCollapsed={setIsProductListCollapsed}
                selectedProduct={selectedProduct}
                selectedText={t('businessPartner.selectedSupplier')}
                selectedItemIsRequired={true}
                isValidSelectedItem={isValidSelectedSupplier}
                products={products}
                setProducts={setProducts}
            />           

            {/* Description */}
            <Form.Field
                label={t('general.description')}
                placeholder={t('product.addOptionalProductDescription')}
                control={TextArea}
                value={description}
                onChange={(ev, {value}) => setDescription(value) }
                type="Text"
                rows={5}
            />

            {/* Potential Volume In Sales Unit Of Measure */}
            <Form.Field
                icon='hashtag'
                required
                label={t('product.potentialVolumeInSalesUnitOfMeasure')}
                placeholder={t('product.potentialVolumeInSalesUnitOfMeasure')}
                maxLength="255"
                control={Input}
                value={potentialVolumeInSalesUnitOfMeasure}
                onChange={(ev, {value}) => setPotentialVolumeInSalesUnitOfMeasure(value) }
                type="Number"
            />

            {/* Potential Sales */}
            <Form.Field
                icon='euro'
                required
                label={t('product.potentialSales')}
                placeholder={t('product.potentialSales')}
                control={Input}
                value={potentialSales}
                onChange={(ev, {value}) => {
                    setPotentialSales(value);
                }}
                type="Number"
            />

            {/* Potential Margin */}
            <Form.Field
                icon='euro'
                required
                label={t('product.potentialMargin')}
                placeholder={t('product.potentialMargin')}
                control={Input}
                value={potentialMargin}
                onChange={(ev, {value}) => {
                    setPotentialMargin(value);
                }}
                type="Number"
            />

            <Form.Field label={t('product.marginPercentage')} />
            <p>{Utils.roundNumber(Utils.calculateMarginPercentage(potentialSales, potentialMargin), 2)}</p>

            <p>{t('product.marginPercentageCalculationInfo')}</p>
            <p>{t('product.calculatedUnitPrice')} : {Utils.roundNumber(Utils.calculateUnitPrice(potentialSales, potentialVolumeInSalesUnitOfMeasure), 2) + " €"}</p>

            {/* Errors */}
            <Message error visible={!projectId} content={t('errors.missingProjectId')} />
            
            {/* Buttons */}
            <Container className='button-container'>
                <CancelButton onClick={(ev) => {ev.preventDefault(); history.goBack(); }}/>
                <SubmitButton disabled={!selectedProduct} className="bb-lblue" icon="save" title={t('general.save')} type="submit" />
            </Container>

        </Form>
    );

}

export default NewProjectProduct;
