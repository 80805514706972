import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Form, Grid } from 'semantic-ui-react';
import { PageTitle } from '../../Components/UI/Content';
import { StateContext } from '../../Application/Context/StateContext';

const Profile = (props) => {

    const { t } = useTranslation();
    const { userProfile } = useContext(StateContext);

    return (
        <Container className="profile">
            <Form>

                <PageTitle title={t('profile.profile')} />

                <Grid stackable>

                    {/* Id */}
                    <Grid.Row>
                        <Grid.Column width={4}><Form.Field label={t('general.id')} /></Grid.Column>
                        <Grid.Column width={12}><p>{userProfile.Id}</p></Grid.Column>
                    </Grid.Row>

                    {/* AzureAdUserId */}
                    <Grid.Row>
                        <Grid.Column width={4}><Form.Field label={t('general.azureAdUserId')} /></Grid.Column>
                        <Grid.Column width={12}><p>{userProfile.AzureAdUserId}</p></Grid.Column>
                    </Grid.Row>

                    {/* Name */}
                    <Grid.Row>
                        <Grid.Column width={4}><Form.Field label={t('general.name')} /></Grid.Column>
                        <Grid.Column width={12}><p>{userProfile.Name}</p></Grid.Column>
                    </Grid.Row>

                    {/* FirstName */}
                    <Grid.Row>
                        <Grid.Column width={4}><Form.Field label={t('general.firstName')} /></Grid.Column>
                        <Grid.Column width={12}><p>{userProfile.FirstName}</p></Grid.Column>
                    </Grid.Row>

                    {/* LastName */}
                    <Grid.Row>
                        <Grid.Column width={4}><Form.Field label={t('general.lastName')} /></Grid.Column>
                        <Grid.Column width={12}><p>{userProfile.LastName}</p></Grid.Column>
                    </Grid.Row>

                    {/* Phone */}
                    <Grid.Row>
                        <Grid.Column width={4}><Form.Field label={t('general.phone')} /></Grid.Column>
                        <Grid.Column width={12}><p>{userProfile.Phone}</p></Grid.Column>
                    </Grid.Row>

                    {/* Email */}
                    <Grid.Row>
                        <Grid.Column width={4}><Form.Field label={t('general.email')} /></Grid.Column>
                        <Grid.Column width={12}><p>{userProfile.email}</p></Grid.Column>
                    </Grid.Row>

                    {/* IsAdmin */}
                    <Grid.Row>
                        <Grid.Column width={4}><Form.Field label={t('general.isAdmin')} /></Grid.Column>
                        <Grid.Column width={12}><p>{userProfile.IsAdmin ? t('general.yes') : t('general.no')}</p></Grid.Column>
                    </Grid.Row>

                    {/* Lastlogin */}
                    <Grid.Row>
                        <Grid.Column width={4}><Form.Field label={t('general.lastlogin')} /></Grid.Column>
                        <Grid.Column width={12}><p>{userProfile.Lastlogin ? new Date(userProfile.Lastlogin).toLocaleDateString("fi-FI") + " " + new Date(userProfile.Lastlogin).toLocaleTimeString("fi-FI"): ""}</p></Grid.Column>
                    </Grid.Row>

                    {/* Created */}
                    <Grid.Row>
                        <Grid.Column width={4}><Form.Field label={t('general.created')} /></Grid.Column>
                        <Grid.Column width={12}><p>{userProfile.Created ? new Date(userProfile.Created).toLocaleDateString("fi-FI") : ""}</p></Grid.Column>
                    </Grid.Row>

                    {/* Modified */}
                    <Grid.Row>
                        <Grid.Column width={4}><Form.Field label={t('general.modified')} /></Grid.Column>
                        <Grid.Column width={12}><p>{userProfile.Modified ? new Date(userProfile.Modified).toLocaleDateString("fi-FI") : ""}</p></Grid.Column>
                    </Grid.Row>

                    {/* Deleted */}
                    <Grid.Row>
                        <Grid.Column width={4}><Form.Field label={t('general.deleted')} /></Grid.Column>
                        <Grid.Column width={12}><p>{userProfile.Deleted ? t('general.yes') : t('general.no')}</p></Grid.Column>
                    </Grid.Row>

                </Grid>
            </Form>
        </Container>
    );

}

export default Profile;
