import { useState } from 'react';
import webApiClient from './../../Utils/webApiClient';
import { msalInstance } from '../../index';
import config from '../../Config/config';
import useRequestHandler from '../useRequestHandler';

/** Hook is used to fetch list of statusCodes */
const useListStatusCodes = () => {

    const [ statusCodes, setStatusCodes ] = useState([]);
    const { errorHandler, successHandler } = useRequestHandler();
    const apiClient = webApiClient();

    /** List status codes */
    const listStatusCodes = async () => {
        try {

            // Account is set in the index.js
            const account = msalInstance.getActiveAccount();

            const response = await msalInstance.acquireTokenSilent({
                scopes: config.webApi.scopes,
                account: account
            });
            
            const result = await apiClient.list(response.accessToken, "statuscodes");

            const data = result.data || {};
            const content = data.resultData || null;

            if (content) setStatusCodes(content);

            return successHandler(result, "useListStatusCodes", "list", { hide: true });

        } catch (error) {

            return errorHandler(error, "useListStatusCodes", "list");

        }
    }

    return { statusCodes, listStatusCodes };

}

export default useListStatusCodes;
