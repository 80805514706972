//import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import webApiClient from './../../Utils/webApiClient';
import { msalInstance } from '../../index';
import config from '../../Config/config';
import useRequestHandler from '../useRequestHandler';

/** Hook is used to create new project contact entity */
const useCreateProjectContact = () => {

    const { t } = useTranslation();
    const { errorHandler, successHandler } = useRequestHandler();
    const apiClient = webApiClient();

    /** Create new project contact
     * @param {object} projectId - Project id
     * @param {integer} contactId - Contact id **/
    const createProjectContact = async (projectId, contactId) => {
        try {

            // Account is set in the index.js
            const account = msalInstance.getActiveAccount();

            const response = await msalInstance.acquireTokenSilent({
                scopes: config.webApi.scopes,
                account: account
            });

            // Create resource path
            let resource = "projects/" + projectId.toString() + "/contacts/" + contactId.toString();

            // Create data object 
            const data = {
                projectId: projectId,
                contactId: contactId
            }

            const result = await apiClient.create(response.accessToken, resource, data);

            return successHandler(result, "useCreateProjectContact", "create", { message: t('success.create') });

        } catch (error) {
            return errorHandler(error, "useCreateProjectContact", "create");
        }
    }

    return { createProjectContact };

}

export default useCreateProjectContact;
