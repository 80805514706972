import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Input } from 'semantic-ui-react';
import { SearchButton } from '../../Components/UI/Buttons';
import { getSearchProjectStatusCodeFilters } from '../../Utils/ActionArenaUtils';
import { Accordion , Header, Grid, Icon, Segment } from 'semantic-ui-react';

/** Search items box component
 * @param {Function} setIsLoading - React setState function for updating loading state
 * @param {String} searchTerm - React state value which is added to the searchTerm
 * @param {String} setSearchTerm - React useState which set the searchTerm state
 * @param {Function} searchProjectProducts - Search Project Products function. f.e. reach hook "searchProjectProducts"
 * @param {Array} statusCodes
 * @param {String} filterByStatuRadio
 * @param {Boolean} filterMyOnlyCheckbox
 * @param {Boolean} filterBySalesPersonCheckbox
 * @param {String} selectedSalesPerson
 * @param {Integer} selectedBusinessUnit
 * @param {Object} filters - additional components f.e. filter checkbox  */
const SearchAllProjectProducts = ({
    setIsLoading,
    searchTerm,
    setSearchTerm,
    searchProjectProducts,
    statusCodes,
    filterByStatuRadio,
    filterMyOnlyCheckbox,
    filterBySalesPersonCheckbox,
    selectedSalesPerson,
    selectedBusinessUnit,
    filters
}) => {

   const { t } = useTranslation();
   const [ activeIndex, setActiveIndex ] = useState(0);

    /* Search items when user clicks search */
    const onClickSearch = async (searchTerm) => {

        setIsLoading(true);
        const statusCodeFilters = getSearchProjectStatusCodeFilters(statusCodes, filterByStatuRadio);
        const options = {
            own: filterMyOnlyCheckbox,
            top: 200,
            status: statusCodeFilters,
            salesPerson: filterBySalesPersonCheckbox ? selectedSalesPerson : null,
            businessunit: selectedBusinessUnit
        }
        let result = await searchProjectProducts(searchTerm, options);
        if (!result.requestCancelled) setIsLoading(false);
    }

    return (       
       <Container className={"fieldcontent"}>
           <Accordion>
               
               <Accordion.Title
                   active={activeIndex === 0}
                   index={0}
                   onClick={() => { setActiveIndex(activeIndex === 0 ? -1 : 0); }}
                   style={{paddingBottom: 0}}
               >
                   <Header as='h3' attached='top' className="search-items-header">
                       <Grid>
                           <Grid.Column width="8" textAlign="left">
                               <b>{t('general.search')}</b>
                           </Grid.Column>
                           <Grid.Column  width="8" textAlign="right">
                               <Icon name='dropdown' />
                           </Grid.Column>
                       </Grid>
                   </Header>
               </Accordion.Title>
               
               <Accordion.Content active={activeIndex === 0} style={{paddingTop: 0}}>
                   <Segment attached style={{ borderRadius: "0 0 5px 5px" }}>

                       <Input
                           fluid
                           icon='search'
                           placeholder={t('general.search')}
                           value={searchTerm}
                           onKeyPress={(ev, data) => {
                               if (ev.key === 'Enter') {
                                   ev.preventDefault();
                                   onClickSearch(searchTerm);
                               }
                           }}
                           onChange={(ev, {value}) => {
                               setSearchTerm(value);
                               //onClickSearch(value);
                           }}
                           className="fieldcontent"
                       />

                       {/* Additional filters */}
                       <Container>
                           {filters}
                       </Container>

                       {/* Search items button */}
                       <Container textAlign="right">
                           <SearchButton className="bb-lblue" type="reset" onClick={(ev) => { onClickSearch(searchTerm); }} />
                       </Container>

                   </Segment>
               </Accordion.Content>

           </Accordion>
       </Container>
    );
}


export default SearchAllProjectProducts;
