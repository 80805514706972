import React, { useState, useContext, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { Form, Container, Input, TextArea } from 'semantic-ui-react';
import { Loading } from '../../../Components/UI/Loaders';
import { SubmitButton, CancelButton } from '../../../Components/UI/Buttons';
import { PageTitle  } from '../../../Components/UI/Content';
import Forbidden from '../../System/Forbidden';
import { StateContext } from '../../../Application/Context/StateContext';
import useReadParameter from '../../../Hooks/Parameters/useReadParameter';
import useUpdateParameter from '../../../Hooks/Parameters/useUpdateParameter';
import "react-datepicker/dist/react-datepicker.css";

const EditParameter = (props) => {

    const { t } = useTranslation();
    const history = useHistory();
    const scollToTopRef = useRef();
    const { parameterId } = useParams();

    const [ isLoading, setIsLoading ] = useState(false);
    const [ pKey, setPKey ] = useState("");
    const [ pValue, setPValue ] = useState("");
    const [ description, setDescription ] = useState("");

    const { parameter, readParameter } = useReadParameter();
    const { updateParameter } = useUpdateParameter();
    const { userProfile } = useContext(StateContext);

    // Read notification
    useEffect(() => {
        if (parameterId) {
            (async() => {
                if (scollToTopRef && scollToTopRef.current) scollToTopRef.current.scrollIntoView();
                setIsLoading(true);
                await readParameter(parameterId);
                setIsLoading(false);
            })();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [parameterId]);
   
    // Set data
    useEffect(() => {
        if (parameter) {
            if (parameter.PKey) setPKey(parameter.PKey);
            if (parameter.PValue) setPValue(parameter.PValue);
            if (parameter.Description) setDescription(parameter.Description);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [parameter]);

    // Save data on submit
    const onSubmit = async () => {
        try {

            setIsLoading(true);
    
            const parameterObj = {
                "Id": parseInt(parameterId),             
                "PKey": pKey.trim(),
                "PValue": pValue.trim(),
                "description": description.trim()
            }

            const result = await updateParameter(parameterId, parameterObj); 

            if (result && result.status && result.status === 200) {
                return history.goBack();
            }

            setIsLoading(false);

        } catch (error) {
            setIsLoading(false);
        }
    }

    const isAdmin = userProfile && userProfile.IsAdmin ? userProfile.IsAdmin : false;

    if (!isAdmin) return <Forbidden />;

    return (
        <Form className="notification" onSubmit={(ev) => { ev.preventDefault(); onSubmit(); }} >
                
            <div ref={scollToTopRef}></div>
            
            <Loading active={ isLoading } text={t('general.loading')} />

            <PageTitle active title={t('management.editParameter')} />

            {/* Id */}
            <Form.Field label={t('general.id')} />
            <p>{parameter.Id}</p>

            {/* PKey */}
            <Form.Field label={t('general.key')} />
            <p>{parameter.PKey}</p>

            {/* PValue */}
            <Form.Field
                focus
                required
                label={t('general.value')}
                placeholder={t('general.value')}
                maxLength="255"
                control={Input}
                value={pValue}
                onChange={(ev, {value}) => setPValue(value) }
                type="Text"
            />
        
            {/* Description */}
            <Form.Field
                required
                label={t('general.description')}
                placeholder={t('general.description')}
                control={TextArea}
                value={description}
                onChange={(ev, {value}) => setDescription(value) }
                type="Text"
            />

            {/* Buttons */}
            <Container className='button-container'>
                <CancelButton onClick={(ev) => { ev.preventDefault(); history.goBack(); }} />
                <SubmitButton type="submit" disabled={!isAdmin} className="bb-lblue" icon="save" title={t('general.save')} />
            </Container>

        </Form>
    );

}

export default EditParameter;
