import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Form , Dropdown, Checkbox, Container, Radio, Input, Message, Modal, Header, List } from 'semantic-ui-react';
import { PageTitle, SearchItems } from '../../../Components/UI/Content';
import PagedTable from '../../../Components/UI/Lists';
import { NewButton, NoButton, YesButton, CancelButton } from '../../../Components/UI/Buttons';
import { Loading } from '../../../Components/UI/Loaders';
import { getSearchProjectStatusCodeFilters } from '../../../Utils/ActionArenaUtils';
import { StateContext } from '../../../Application/Context/StateContext';
import { YesNoModal } from '../../../Components/UI/Modals';
import useSearchProjects from '../../../Hooks/Search/useSearchProjects';
import useListUsers from '../../../Hooks/User/useListUsers';
import useUpdateProject from '../../../Hooks/Project/useUpdateProject';
import Forbidden from '../../System/Forbidden';

const ManageParameters = (props) => {

    const { t } = useTranslation();
    const history = useHistory();

    const [ isLoading, setIsLoading ] = useState(true);
    const [ itemsPerPage, setItemsPerPage ] = useState(50);

    const [ filterMyProjectsOnlyCheckbox, setFilterMyProjectsOnlyCheckbox ] = useState(true);
    const [ filterProjectsBySalesPersonCheckbox, setFilterProjectsBySalesPersonCheckbox ] = useState(false);
    const [ selectedSalesPerson, setSelectedSalesPersonActivities ] = useState(null);
    const [ searchProjectsByCategoryRadio, setSearchProjectsByCategoryRadio ] = useState('project');
    const [ filterProjectsByStatusRadio, setFilterProjectsByStatusRadio ] = useState('active');
    const [ filterProjectsListInput, setFilterProjectsListInput ]= useState('');
    const [ statusFilters, setStatusFilters ] = useState([]); // Filter list is send to the backend
    const [ moveProjectModalIsOpen, setMoveProjectModalIsOpen ] = useState(false);
    const [ newSalesPerson, setNewSalesPerson ] = useState(null);

    const { statusCodes } = useContext(StateContext);
    const { projects, setProjects, searchProjects } = useSearchProjects();
    const { updateProject } = useUpdateProject();
    const { users, listUsers } = useListUsers();
    const { userProfile } = useContext(StateContext);

    // Load top 200 own active projects
    useEffect(() => {
        (async() => {
            // Do not make api call unless all needed properties are found
            if ( statusCodes && statusCodes.length) {
                setIsLoading(true);

                const statusFilters = getSearchProjectStatusCodeFilters(statusCodes, filterProjectsByStatusRadio);

                const options = {
                    own: filterMyProjectsOnlyCheckbox,
                    top: 200,
                    status: statusFilters,
                    salesPerson: selectedSalesPerson
                }

                await searchProjects("", searchProjectsByCategoryRadio, options);
                await listUsers(true, true);

                const filteredStatusCodes = statusCodes.filter(item => item.Id < 12);
                const mappedStatusCodes = filteredStatusCodes.map(item => {
                    item.Checked = false;
                    if (item.Id < 8) item.Checked = true;
                    return item;
                });

                setStatusFilters(mappedStatusCodes);

                setIsLoading(false);
            }
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [statusCodes]);

    const isAdmin = userProfile && userProfile.IsAdmin ? userProfile.IsAdmin : false;

    if (!isLoading && !isAdmin) return <Forbidden />;

    let projectToBeMoved = projects.map(item => {
        if (item && !item.moveToAnotherSalesPerson) item.moveToAnotherSalesPerson = false;
        return item;
    });

    let selectedProjects = projectToBeMoved.filter(item => item.moveToAnotherSalesPerson) || [];

    const selectAllProject = () => {
        let selectedProject = projects.map(item => {
            item.moveToAnotherSalesPerson = true;
            return item;
        });
        setProjects(selectedProject);
    };

    const unselectAllProject = () => {
        let unselectedProject = projects.map(item => {
            item.moveToAnotherSalesPerson = false;
            return item;
        });
        setProjects(unselectedProject);
    };

    return (
        <div className="management">

            <PageTitle
                active
                title={t('management.manageProjects')}
                button={
                    <NewButton
                        className="bb-lblue"
                        mini
                        onClick={(ev) => { ev.preventDefault(); history.push('/projects/new'); }}
                    />
                }
            />

            {/* Move project to another salesperson? */}
            <YesNoModal
                type='move'
                header={t('management.moveProjects')}
                text={" "}
                method={async (answer) => {

                    setIsLoading(true);

                    if (answer && newSalesPerson && selectedProjects && selectedProjects.length > 0) {

                        for (let index = 0; index < selectedProjects.length; index++) {

                            console.log("Move project to selected salesperson " + newSalesPerson);
                            console.log(selectedProjects[index]);

                            await updateProject({ id: selectedProjects[index].Id, salesPerson: newSalesPerson });

                            selectedProjects = selectedProjects.map(item => {
                                item.projectIsMoved = true;
                                return item;
                            });

                        }

                        const options = {
                            own: filterMyProjectsOnlyCheckbox,
                            top: 200,
                            status: statusFilters,
                            salesPerson: selectedSalesPerson
                        }
        
                        await searchProjects("", searchProjectsByCategoryRadio, options);

                        //return history.goBack();
                    }

                    setMoveProjectModalIsOpen(false);
                    setIsLoading(false);

                }}
                open={moveProjectModalIsOpen}
                setOpen={setMoveProjectModalIsOpen}
                noClick={false}
                disableYesButton={!newSalesPerson || selectedProjects.length === 0}
                handleClose={true}
            >

                <Modal.Description>
                    <Header>{t('management.moveProjects')}</Header>

                        <Container className="fieldcontent">
                            {/*<Form.Field><b>{t('management.selectSalesPerson')}</b></Form.Field>
                            <br/>*/}
                            <Dropdown
                                placeholder={t('general.salesPerson')}
                                fluid
                                search
                                selection
                                value={newSalesPerson}
                                options={users.map(item => {
                                    return {
                                        key: item.Id,
                                        value: item.Id,
                                        text: item.Name ? item.Name : "<Missing name>"
                                    }
                                })}
                                onChange={(ev, {value}) => {
                                    setNewSalesPerson(value);
                                }}
                            />
                        </Container>
                        
                        {newSalesPerson && selectedProjects.length > 0 &&
                            <Container style={{ height:"200px", maxHeight: "200px", overflowY: "scroll" }}>
                                <Form.Field><b>{t('management.theseProjectWillBeMovedToAnotherSalesPerson')}</b></Form.Field>
                                <List ordered>
                                    {selectedProjects.map(item => 
                                        <List.Item key={item.Id}>
                                            {item.Title}
                                        </List.Item>
                                    )}
                                </List>
                            </Container>
                        }

                        {!newSalesPerson && 
                            <p className="error" style={{ fontSize: "12px" }} >{t('management.noSalespersonIsSelected')}!</p>
                        }

                        {selectedProjects.length === 0 && 
                            <p className="error" style={{ fontSize: "12px" }} >{t('management.noProjectsSelected')}!</p>
                        }

                </Modal.Description>

            </YesNoModal>

            <Loading active={ isLoading } text={t('general.loading')} />
            
            <SearchItems
                title={t('general.search')}
                searchItems={async (searchTerm) => {
                    setIsLoading(true);
                    const statusCodeFilters = getSearchProjectStatusCodeFilters(statusCodes, filterProjectsByStatusRadio);
                    const options = {
                        own: filterMyProjectsOnlyCheckbox,
                        top: 200,
                        status: statusCodeFilters,
                        salesPerson: filterProjectsBySalesPersonCheckbox ? selectedSalesPerson : null
                    }
                    let result = await searchProjects(searchTerm, searchProjectsByCategoryRadio, options);
                    if (!result.requestCancelled) setIsLoading(false);
                }}
                //searchTerm={searchTerm}
                setIsLoading={setIsLoading}
                filters={
                    <>

                        {/* Filters */}
                        <Container className="fieldcontent">
                            <Form.Field
                                control={Checkbox}
                                label={t('project.myProjectsOnly')}
                                placeholder={t('project.myProjectsOnly')}
                                checked={filterMyProjectsOnlyCheckbox}
                                onChange={(ev, {checked}) => {
                                    setProjects([]);
                                    setFilterProjectsBySalesPersonCheckbox(false);
                                    setFilterMyProjectsOnlyCheckbox(checked);
                                }}
                            />
                            <Form.Field
                                control={Checkbox}
                                label={t('project.filterProjectsBySalesPerson')}
                                placeholder={t('project.filterProjectsBySalesPerson')}
                                checked={filterProjectsBySalesPersonCheckbox}
                                onChange={(ev, {checked}) => {
                                    setProjects([]);
                                    setFilterMyProjectsOnlyCheckbox(false);
                                    setFilterProjectsBySalesPersonCheckbox(checked);
                                }}
                            />
                        </Container> 

                        {/* Users */}
                        {filterProjectsBySalesPersonCheckbox && 
                            <Container className="fieldcontent">
                                <Form.Field>
                                    <b>{t('general.salesPerson')}</b>
                                </Form.Field>
                                <Dropdown
                                    placeholder={t('general.salesPerson')}
                                    fluid
                                    search
                                    selection
                                    value={selectedSalesPerson}
                                    options={users.map(item => {
                                        return {
                                            key: item.Id,
                                            value: item.Id,
                                            text: item.Name ? item.Name : "<Missing name>"
                                        }
                                    })}
                                    onChange={(ev, {value}) => {
                                        setSelectedSalesPersonActivities(value);
                                    }}
                                />
                            </Container>
                        }

                        {/* Select search by */}
                        <Container className="fieldcontent">
                            <Form.Field>
                                <b>{t('project.searchBy')}</b>
                            </Form.Field>
                            <Form.Field
                                control={Radio}
                                label={t('project.searchByProject')}
                                name='searchprojectsby'
                                placeholder={t('project.searchByProject')}
                                checked={searchProjectsByCategoryRadio === 'project' ? true : false}
                                onChange={(ev, {checked}) => setSearchProjectsByCategoryRadio('project')}
                            />
                            <Form.Field
                                control={Radio}
                                label={t('project.searchByCustomer')}
                                name='searchprojectsby'
                                placeholder={t('project.searchByCustomer')}
                                checked={searchProjectsByCategoryRadio === 'customer' ? true : false}
                                onChange={(ev, {checked}) => setSearchProjectsByCategoryRadio('customer')}
                            />
                            <Form.Field
                                control={Radio}
                                label={t('project.searchBySupplier')}
                                name='searchprojectsby'
                                placeholder={t('project.searchBySupplier')}
                                checked={searchProjectsByCategoryRadio === 'supplier' ? true : false}
                                onChange={(ev, {checked}) => setSearchProjectsByCategoryRadio('supplier') }
                            />
                            <Form.Field
                                control={Radio}
                                label={t('project.searchByProduct')}
                                name='searchprojectsby'
                                placeholder={t('project.searchByProduct')}
                                checked={searchProjectsByCategoryRadio === 'product' ? true : false}
                                onChange={(ev, {checked}) => setSearchProjectsByCategoryRadio('product') }
                            />
                        </Container>

                        {/* Status choices
                            - Set default everytime user select status set.
                            - All = all status codes. "Project removed by user" is filtered out
                            - Active = Planned,Started,Sample sent, Sample approved, Trial Agrees, Trial Approved, Commercial negotiations
                            - Passive = Regular business, Project failed, Project automatically passivated,
                            - Closed = Project automatically closed
                            - Custom = user select. "Project removed by user" is filtered out
                        */}
                        <Container className="fieldcontent">
                            <Form.Field>
                                <b>{t('project.chooseStatusSet')}</b>
                            </Form.Field>
                            <Form.Field
                                control={Radio}
                                label={t('general.all')}
                                name='chooseStatusSet'
                                placeholder={t('general.all')}
                                checked={filterProjectsByStatusRadio === 'all' ? true : false}
                                onChange={(ev) => {
                                    setFilterProjectsByStatusRadio('all');
                                    const mapped = statusFilters.map(item => {
                                        item.Checked = false;
                                        if (item.Id < 12) item.Checked = true;
                                        return item;
                                    });
                                    setStatusFilters(mapped);
                                }}
                            />
                            <Form.Field
                                control={Radio}
                                label={t('general.active')}
                                name='chooseStatusSet'
                                placeholder={t('general.active')}
                                checked={filterProjectsByStatusRadio === 'active' ? true : false}
                                onChange={(ev) => {
                                    setFilterProjectsByStatusRadio('active');
                                    const mapped = statusFilters.map(item => {
                                        item.Checked = false;
                                        if (item.Id < 8) item.Checked = true;
                                        return item;
                                    });
                                    setStatusFilters(mapped);
                                }}
                            />
                            <Form.Field
                                control={Radio}
                                label={t('general.passive')}
                                name='chooseStatusSet'
                                placeholder={t('general.passive')}
                                checked={filterProjectsByStatusRadio === 'passive' ? true : false}
                                onChange={(ev) => {
                                    setFilterProjectsByStatusRadio('passive');
                                    const mapped = statusFilters.map(item => {
                                        item.Checked = false;
                                        //if (item.Id >= 8 && item.Id < 11) item.Checked = true;
                                        if (item.Id === 10) item.Checked = true;
                                        return item;
                                    });
                                    setStatusFilters(mapped);
                                }}
                            />
                            <Form.Field
                                control={Radio}
                                label={t('general.closed')}
                                name='chooseStatusSet'
                                placeholder={t('general.closed')}
                                checked={filterProjectsByStatusRadio === 'closed' ? true : false}
                                onChange={(ev) => {
                                    setFilterProjectsByStatusRadio('closed');
                                    const mapped = statusFilters.map(item => {
                                        item.Checked = false;
                                        //if (item.Id === 11) item.Checked = true;
                                        if (item.Id === 8 || item.Id === 9 || item.Id === 11) item.Checked = true;
                                        return item;
                                    });
                                    setStatusFilters(mapped);
                                }}
                            />
                            <Form.Field
                                control={Radio}
                                label={t('general.custom')}
                                name='chooseStatusSet'
                                placeholder={t('general.custom')}
                                checked={filterProjectsByStatusRadio === 'custom' ? true : false}
                                onChange={(ev) => {
                                    setFilterProjectsByStatusRadio('custom');
                                    const mapped = statusFilters.map(item => {
                                        item.Checked = false;
                                        if (item.Id < 12) item.Checked = true;
                                        return item;
                                    });
                                    setStatusFilters(mapped);

                                }}
                            />
                        </Container>

                        {/* Select status codes 
                            - Set filterProjectsByStatusRadio to custom everytime user select or unselect single status.
                            - change status filter selection */}
                        <Container className="fieldcontent">
                            <Form.Field>
                                <b>{t('status.statusFilters')}</b>
                            </Form.Field>
                            {statusFilters.map((code, index) => {
                                return <Form.Field
                                    key={index}
                                    control={Checkbox}
                                    label={code.Name}
                                    placeholder={code.Name}
                                    name='statusFilters'
                                    checked={code.Checked}
                                    onChange={(ev, {checked}) => {
                                        if (filterProjectsByStatusRadio !== 'custom') setFilterProjectsByStatusRadio('custom');
                                        const mapped = statusFilters.map(item => {
                                            if (item.Id === code.Id) item.Checked = checked;
                                            return item;
                                        })
                                        setStatusFilters(mapped);
                                    }}
                                />
                            })}
                        </Container>

                    </>
                }
            >
            </SearchItems>

            <Message info size='mini' hidden={(filterProjectsBySalesPersonCheckbox || filterMyProjectsOnlyCheckbox) || (projectToBeMoved.length < 201 && !filterProjectsBySalesPersonCheckbox && !filterMyProjectsOnlyCheckbox)}>
                <p>{t('warnings.searchReturnedOver200Results')}</p>
            </Message>

            {/* Page size */}
            <Container className="fieldcontent" textAlign="right">
                <Form.Field>
                    <b>{t('general.pageSize')}</b>
                </Form.Field>
                <Dropdown
                    selection
                    value={itemsPerPage}
                    options={[
                        { key: 0, text: 5, value: 5 },
                        { key: 1, text: 10, value: 10 },
                        { key: 2, text: 25, value: 25 },
                        { key: 3, text: 50, value: 50 }
                    ]}
                    onChange={(ev, {value}) => setItemsPerPage(value) }
                />
            </Container>

            {/* Filter */}
            <Container className="fieldcontent">
                <Input
                    action={{
                        labelPosition: 'left',
                        icon: 'delete',
                        content: t('general.clear'),
                        style: {margin: 0},
                        onClick:(ev) => {
                            ev.preventDefault();
                            setFilterProjectsListInput('');
                        }
                    }}
                    onChange={(ev, {value} ) => {
                        ev.preventDefault();
                        setFilterProjectsListInput(value)
                    }}
                    fluid
                    //actionPosition='right'
                    placeholder={t('general.filter')}
                    value={filterProjectsListInput}
                />
            </Container>

            <Container className="fieldcontent">
                <PagedTable unstackable celled striped compact='very' pageSize={itemsPerPage}
                    header={[
                        t('general.select'),
                        t('general.title'),
                        t('general.salesPerson'),
                        t('businessPartner.customer'),
                        t('businessPartner.businessUnit'),
                        t('project.startDate'),
                        //t('project.passivatingDate'),
                        //t('project.closingDate'),
                        t('project.endDate'),
                        t('general.status')
                        //t('general.action')
                    ]}
                    content={projectToBeMoved ? projectToBeMoved.filter(project => {
                        let matchTitle = project.Title ? project.Title.toLowerCase().indexOf(filterProjectsListInput.toLowerCase()) !== -1 : false;
                        let matchSalesPersonName = project && project.SalesPersonName ? project.SalesPersonName.toLowerCase().indexOf(filterProjectsListInput.toLowerCase()) !== -1 : false;
                        let matchBusinessPartnerName = project && project.BusinessPartnerName ? project.BusinessPartnerName.toLowerCase().indexOf(filterProjectsListInput.toLowerCase()) !== -1 : false;
                        let matchBusinessUnitName = project && project.BusinessUnitName ? project.BusinessUnitName.toLowerCase().indexOf(filterProjectsListInput.toLowerCase()) !== -1 : false;
                        let matchStartDate = project && project.StartDate ? new Date(project.StartDate).toLocaleDateString("fi-FI").indexOf(filterProjectsListInput.toLowerCase()) !== -1 : false;
                        let matchStatusName = project && project.StatusName ? project.StatusName.toLowerCase().indexOf(filterProjectsListInput.toLowerCase()) !== -1 : false;
                        if (matchTitle || matchSalesPersonName || matchBusinessPartnerName || matchBusinessUnitName || matchStartDate  || matchStatusName) return true;
                        return false;
                    }) : []}
                    resetPageOnContentChange={false}
                    contentKeys={[
                        {key: 'Select', checked: 'moveToAnotherSalesPerson', type: 'select', action: (ev, row, cellKey) => {
                            let mapped = projectToBeMoved.map(item => {
                                if (item.Id === row.Id) item.moveToAnotherSalesPerson = !item.moveToAnotherSalesPerson;
                                return item;
                            });
                            setProjects(mapped);
                        }},
                        {key: 'Title', target: '/projects/view/', type: 'link', targetKey: 'Id'},
                        {key: 'SalesPersonName' },
                        {key: 'BusinessPartnerName' },
                        {key: 'BusinessUnitName' },
                        {key: 'StartDate', type: 'date'},
                        //{key: 'PassivatingDate', type: 'date'},
                        //{key: 'ClosingDate', type: 'date'},
                        {key: 'EndDate', type: 'date'},
                        {key: 'StatusName'}
                        //{key: 'Title', type: 'linkWithTwoTargetsAndKeys', target1: '/projects/', targetKey1: 'Id', target2: '/activities/new', targetKey2:'', text: t('activity.newActivity')}
                    ]}
                />
            </Container>

            {/* Buttons */}
            <Container>

                <CancelButton title={t('general.back')} onClick={(ev) => {ev.preventDefault(); history.goBack(); }} />

                <YesButton style={{ float: "right" }} disabled={selectedProjects.length === 0} title={t('management.moveProjects')} className="bb-lblue" icon="clone outline" onClick={(ev) => { setMoveProjectModalIsOpen(true)}} />
                <NoButton style={{ float: "right" }} disabled={selectedProjects.length === 0} title={t('management.unselectAllProjects')} className="bb-lblue" onClick={(ev) => { unselectAllProject(); }} />
                <YesButton style={{ float: "right" }} disabled={projects.length === 0} title={t('management.selectAllProjects')} className="bb-lblue" onClick={(ev) => { selectAllProject(); }} />

            </Container>

        </div>
    );

};

export default ManageParameters;
