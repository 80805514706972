import { useState } from 'react';
import webApiClient from '../../Utils/webApiClient';
import { msalInstance } from '../../index';
import config from '../../Config/config';
import useRequestHandler from '../useRequestHandler';

/** ADMIN ONLY: Hook is used to fetch list of parameters */
const useListParameters = () => {

    const [ parameters, setParameters ] = useState([]);
    const { errorHandler, successHandler } = useRequestHandler();
    const apiClient = webApiClient();

    /** List parameters
     * @param {object} options - options object **/
    const listParameters = async (options) => {
        try {

            // Account is set in the index.js
            const account = msalInstance.getActiveAccount();

            const response = await msalInstance.acquireTokenSilent({
                scopes: config.webApi.scopes,
                account: account
            });

            const result = await apiClient.list(response.accessToken, "parameters");

            const data = result.data || {};
            const content = data.resultData || null;

            if (content) setParameters(content);

            return successHandler(result, "useListParameters", "list", { hide: true });

        } catch (error) {

            return errorHandler(error, "useListParameters", "list");

        }
    }

    return { parameters, listParameters };

}

export default useListParameters;
