import React, { useState, useEffect, useContext, useRef  } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { Form, Container, Message, Grid  } from 'semantic-ui-react';
import { Loading } from '../../Components/UI/Loaders';
import { EditButton, CancelButton, DeleteButton } from '../../Components/UI/Buttons';
import { PageTitle } from '../../Components/UI/Content';
import { YesNoModal } from '../../Components/UI/Modals';
import { StateContext } from '../../Application/Context/StateContext';
import useReadProject from '../../Hooks/Project/useReadProject';
import useReadActivity from '../../Hooks/Activities/useReadActivity';
import useDeleteActivity from '../../Hooks/Activities/useDeleteActivity';
import { viewExternalAttendees } from '../../Utils/ActionArenaUtils';

const ViewActivity = (props) => {

    const { t } = useTranslation();
    const history = useHistory();
    const { activityId } = useParams();
    const scollToTopRef = useRef();

    const [ isLoading, setIsLoading ] = useState(false);
    const [ modalIsOpen, setModalIsOpen ] = useState(false);

    const { project, readProject } = useReadProject();
    const { activity, readActivity } = useReadActivity();
    const { deleteActivity } = useDeleteActivity();
    const { userProfile, businessUnits } = useContext(StateContext);

    // Disable activity editing only if activity is project activity and project is inactivated!
    const userIsAdmin = userProfile && userProfile.IsAdmin ? true : false;
    const projectHasEnded = project && project.EndDate ? true : false; // If project has end date, project is ended and only admin can edit it!
    const projectIsNotActive = project && project.Status > 7 ? true : false; // if project status is greater than 7 (not active anymore) only admin can edit it!
    const disableEditing = (projectHasEnded || projectIsNotActive) && !userIsAdmin ? true : false;

    // Read activity data
    useEffect(() => {
        (async() => {
            scollToTopRef.current.scrollIntoView();
            setIsLoading(true);
            if (activityId) await readActivity(activityId);
            setIsLoading(false);
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activityId]);

    // Read project data if activity is project actvity
    useEffect(() => {
        if (activity && activity.Project && typeof activity.Project === 'number') {
            (async() => {
                setIsLoading(true);
                await readProject(activity.Project);
                setIsLoading(false);
            })();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activity]);

    // Add businessunit data to activity
    useEffect(() => {
        if (activity && businessUnits && businessUnits.length) {
            let bu = businessUnits.find(item => item.Id === activity.Businessunit);
            if (bu) {
                activity.BusinessUnitId = bu.Id;
                activity.BusinessUnitCode = bu.Code;
                activity.BusinessUnitName = bu.Name;
                activity.BusinessUnitMDSCode = bu.MDSCode;
                activity.BusinessUnitUseAllowed = bu.UseAllowed;
                activity.BusinessUnitParentId = bu.ParentId;
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activity, businessUnits]);

    return (
        <Form className="activity" >
                
            <div ref={scollToTopRef}></div>
            
            <Loading active={ isLoading } text={t('general.loading')} />
          
            <Message warning visible={projectHasEnded || projectIsNotActive} content={t('warnings.projectIsNotActive')} />
	
            <PageTitle
                active
                title={t('activity.viewActivity')}
                button={
                    <EditButton
                        mini
                        className="bb-lblue"
                        onClick={(ev) => { ev.preventDefault(); history.push("/activities/edit/" + activityId); }}
                    />
                }
            />

            {/* Delete activity modal */}
            <YesNoModal
                type='ready'
                header={t('general.delete')}
                text={t('general.areYouSure')}
                method={async (answer) => {
                    setIsLoading(true);
                    if (answer) {
                        await deleteActivity(activityId);
                        return history.goBack();
                    }
                    setIsLoading(false);
                }}
                item={activityId}
                open={modalIsOpen}
                setOpen={setModalIsOpen}
                noClick={false}
            />
               
            <Grid stackable>

                {/* Activity id */}
                <Grid.Row>
                    <Grid.Column width={4}><Form.Field label={t('general.id')} /></Grid.Column>
                    <Grid.Column width={12}><p>{activity.Id}</p></Grid.Column>
                </Grid.Row>

                {/* Title */}
                <Grid.Row>
                    <Grid.Column width={4}><Form.Field label={t('general.title')} /></Grid.Column>
                    <Grid.Column width={12}><p>{activity.Title}</p></Grid.Column>
                </Grid.Row>

                {/* Contact date */}
                <Grid.Row>
                    <Grid.Column width={4}><Form.Field label={t('general.date')} /></Grid.Column>
                    <Grid.Column width={12}><p>{activity.ContactDate ? new Date(activity.ContactDate).toLocaleDateString("fi-FI") : ""}</p></Grid.Column>
                </Grid.Row>

                {/* Activity type */}
                <Grid.Row>
                    <Grid.Column width={4}><Form.Field label={t('general.type')} /></Grid.Column>
                    <Grid.Column width={12}><p>{activity.ActivityTypeName}</p></Grid.Column>
                </Grid.Row>

                {/* Project details */}
                { activity && activity.Project && 
                    <>
                        <Grid.Row>
                            <Grid.Column width={4}><Form.Field label={t('project.projectId')} /></Grid.Column>
                            <Grid.Column width={12}><p>{project && project.Id}</p></Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={4}><Form.Field label={t('project.projectTitle')} /></Grid.Column>
                            <Grid.Column width={12}><p>{project && project.Title}</p></Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={4}><Form.Field label={t('project.projectDescription')} /></Grid.Column>
                            <Grid.Column width={12}><p>{project && project.Description}</p></Grid.Column>
                        </Grid.Row>
                        {/* Project new status */}
                        <Grid.Row>
                            <Grid.Column width={4}><Form.Field label={t('project.projectNewStatus')} /></Grid.Column>
                            <Grid.Column width={12}><p>{activity.ProjectNewStatusName}</p></Grid.Column>
                        </Grid.Row>
                    </>
                }

                {/* Business partner / supplier details */}
                { activity && activity.BusinessPartner && 
                    <>

                        {/* Supplier */}
                        {activity.BusinessPartnerType === 'supplier' && 
                            <Grid.Row>
                                <Grid.Column width={4}><Form.Field label={t('businessPartner.supplier')} /></Grid.Column>
                                <Grid.Column width={12}><p>{activity.BusinessPartnerName}</p></Grid.Column>
                            </Grid.Row>
                        }

                        {/* Business partner */}
                        {(activity.BusinessPartnerType === 'customer' || activity.BusinessPartnerType === 'none') && 
                            <Grid.Row>
                                <Grid.Column width={4}><Form.Field label={t('businessPartner.businessPartner')} /></Grid.Column>
                                <Grid.Column width={12}><p>{activity.BusinessPartnerName}</p></Grid.Column>
                            </Grid.Row>
                        }

                    </>
                }

                {/* Business unit */}
                <Grid.Row>
                    <Grid.Column width={4}><Form.Field label={t('businessPartner.businessUnit')} /></Grid.Column>
                    <Grid.Column width={12}><p>
                        {activity.BusinessUnitName}
                    </p></Grid.Column>
                </Grid.Row>

                {/* Internal attendees */}
                <Grid.Row>
                    <Grid.Column width={4}><Form.Field label={t('activity.internalAttendees')} /></Grid.Column>
                    <Grid.Column width={12}><p>{activity.InternalAttendee}</p></Grid.Column>
                </Grid.Row>

                {/* External attendees */}
                <Grid.Row>
                    <Grid.Column width={4}><Form.Field label={t('activity.externalAttendees')} /></Grid.Column>
                    <Grid.Column width={12}><p>{viewExternalAttendees(activity.ExternalAttendee)}</p></Grid.Column>
                </Grid.Row>

                {/* Objective */}
                <Grid.Row>
                    <Grid.Column width={4}><Form.Field label={t('activity.objective')} /></Grid.Column>
                    <Grid.Column width={12}><p>{activity.Objective}</p></Grid.Column>
                </Grid.Row>

                {/* External notes */}
                <Grid.Row>
                    <Grid.Column width={4}><Form.Field label={t('activity.externalNotes')} /></Grid.Column>
                    <Grid.Column width={12}><p>{activity.ExternalNotes}</p></Grid.Column>
                </Grid.Row>

                {/* Internal notes */}
                <Grid.Row>
                    <Grid.Column width={4}><Form.Field label={t('activity.internalNotes')} /></Grid.Column>
                    <Grid.Column width={12}><p className='wrap-newline'>{activity.InternalNotes}</p></Grid.Column>
                </Grid.Row>

                {/* Sales person */}
                <Grid.Row>
                    <Grid.Column width={4}><Form.Field label={t('activity.salesPerson')} /></Grid.Column>
                    <Grid.Column width={12}><p>{activity.SalesPersonName}</p></Grid.Column>
                </Grid.Row>

                {/* Created */}
                <Grid.Row>
                    <Grid.Column width={4}><Form.Field label={t('general.created')} /></Grid.Column>
                    <Grid.Column width={12}><p>{activity.Created ? new Date(activity.Created).toLocaleDateString("fi-FI") : ''}</p></Grid.Column>
                </Grid.Row>

                {/* Created by */}
                <Grid.Row>
                    <Grid.Column width={4}><Form.Field label={t('general.createdBy')} /></Grid.Column>
                    <Grid.Column width={12}><p>{activity.CreatedByName}</p></Grid.Column>
                </Grid.Row>

                {/* Modified*/}
                <Grid.Row>
                    <Grid.Column width={4}><Form.Field label={t('general.modified')} /></Grid.Column>
                    <Grid.Column width={12}><p>{activity.Modified ? new Date(activity.Modified).toLocaleDateString("fi-FI") : ''}</p></Grid.Column>
                </Grid.Row>

                {/* Modified by */}
                <Grid.Row>
                    <Grid.Column width={4}><Form.Field label={t('general.modifiedBy')} /></Grid.Column>
                    <Grid.Column width={12}><p>{activity.ModifiedByName}</p></Grid.Column>
                </Grid.Row>

                {/* Deleted */}
                <Grid.Row>
                    <Grid.Column width={4}><Form.Field label={t('general.deleted')} /></Grid.Column>
                    <Grid.Column width={12}><p>{activity.Deleted ? t('general.yes') : t('general.no')}</p></Grid.Column>
                </Grid.Row> 

            </Grid>            

            {/* Errors */}
            <Message error visible={!activityId} content={t('errors.missingActivityId')} />

            {/* Buttons */}
            <Container className='button-container'>
                <CancelButton onClick={(ev) => {ev.preventDefault(); history.goBack(); }} />
                <div>
                    <EditButton
                        disabled={disableEditing || activity.Type === 5 || activity.Type === 7}
                        className="bb-lblue"
                        icon="edit"
                        title={t('activity.changeActivityType')}
                        onClick={(ev) => { ev.preventDefault(); history.push("/activities/changetype/" + activityId); }}
                    />
                    <EditButton
                        disabled={disableEditing}
                        className="bb-lblue"
                        icon="save"
                        title={t('general.edit')}
                        onClick={(ev) => { ev.preventDefault(); history.push("/activities/edit/" + activityId); }}
                    />
                    {/* Status change activities (type=5) must not be deleted! */}
                    <DeleteButton disabled={disableEditing || (activity && activity.Type && activity.Type === 5)} color="red" onClick={() => setModalIsOpen(true)}/>
                </div>
            </Container>

        </Form>
    );

}

export default ViewActivity;
