import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from 'semantic-ui-react';
import { PageTitle } from '../../Components/UI/Content';
import Forbidden from '../System/Forbidden';
import SettingsBox from '../../Components/Management/SettingsBox';
import { StateContext } from '../../Application/Context/StateContext';

const Management = (props) => {

    const { t } = useTranslation();
    const { userProfile } = useContext(StateContext);

    const isAdmin = userProfile && userProfile.IsAdmin ? userProfile.IsAdmin : false;

    if (!isAdmin) return <Forbidden />;

    return (
        <div className="management">

            <PageTitle active title={t('management.management')} />

            <Grid columns={3} stackable>
                <Grid.Row>
                    <Grid.Column>
                        <SettingsBox title={t('management.projects')}
                                description={t('management.manageProjects')}
                                icon='archive'
                                items={[
                                    { to: '/management/projects', title: 'management.manageProjects' }
                                ]}
                            />
                    </Grid.Column>
                    <Grid.Column>
                        <SettingsBox title={t('management.activities')}
                            description={t('management.manageActivities')}
                            icon='check'
                            items={[]}
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <SettingsBox title={t('management.notifications')}
                            description={t('management.manageNotifications')}
                            icon='bell'
                            items={[]}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <SettingsBox title={t('management.contacts')}
                            description={t('management.manageContacts')}
                            icon='user'
                            items={[
                                { to: '/management/contacts', title: 'management.manageContacts' },
                                { to: '/contacts/new', title: 'management.newContact'}
                            ]}
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <SettingsBox title={t('management.customers')}
                            description={t('management.manageCustomers')}
                            icon='building'
                            items={[]}
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <SettingsBox title={t('management.parameters')}
                            description={t('management.manageParameters')}
                            icon='building'
                            items={[
                                { to: '/management/parameters', title: 'management.manageParameters'}
                            ]}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>

        </div>
    );

}

export default Management;
