//import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import webApiClient from '../../Utils/webApiClient';
import { msalInstance } from '../../index';
import config from '../../Config/config';
import useRequestHandler from '../useRequestHandler';

/** Hook is used to acknowledge notification */
const useAcknowledgeNotification = () => {

    const { t } = useTranslation();
    const { errorHandler, successHandler } = useRequestHandler();
    const apiClient = webApiClient();

    /** Acknoledge notification entity
     * @param {integer} id - Id of the acknowledged notification
     **/
    const acknowledgeNotification = async (id) => {
        try {

            // Account is set in the index.js
            const account = msalInstance.getActiveAccount();

            const response = await msalInstance.acquireTokenSilent({
                scopes: config.webApi.scopes,
                account: account
            });

            const object = {
                "id": id,
                "acknowledge": true
            }

            const result = await apiClient.update(response.accessToken, "notifications/acknowledge", object);  

            return successHandler(result, "useAcknowledgeNotification", "acknowledge", { message: t('success.acknowledge') });

        } catch (error) {

            return errorHandler(error, "useAcknowledgeNotification", "acknowledge");

        }
    }

    return { acknowledgeNotification };

}

export default useAcknowledgeNotification;
