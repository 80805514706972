import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Form, Grid } from 'semantic-ui-react';
import { PageTitle } from '../Components/UI/Content';
import Release from '../Components/VersionHistory/VersionHistoryRelease';

const VersionHistory = (props) => {

    const { t } = useTranslation();

    return (
        <Container className="versions">
            <Form>

                <PageTitle title={t('navigation.releases')} />

                <Grid stackable>
                   
                    {/* < ADD NEW VERSION ALLWAYS ON TOP BELOW THIS LINE > */}

                    <Release version="3.2.5"
                        date="01.10.2024"
                        changes={[
                            "Edit Project Header: Added Dropdown where user can select project propability rate",
                            "View Project Header: Added propability rate field",
                            "New Project Activity: When user create project activity and activity type is 'project status change', The date shown as disabled.",
                            "New Activity: When user create project activity and activity type is 'project status change', The date shown as disabled.",
                        ]}
                        features={[
                        ]}
                        bugs={[
                            "Manage Projects: Fixed when user select page 2+ and select project then the paging is thrown to page 1.",
                        ]}
                    />

                    <Release version="3.2.4"
                        date="28.03.2024"
                        changes={[
                        ]}
                        features={[
                        ]}
                        bugs={[
                            "Search Projects: fixed statuscode not set if user navigated from customer statistics page",
                            "Activity: Added asterix for required fields"
                        ]}
                    />

                    <Release version="3.2.3"
                        date="26.01.2024"
                        changes={[
                            "Search Activities: settings are now saved between page changes",
                            "Search Contacts: settings are now saved between page changes",
                            "Search Notifications: settings are now saved between page changes",
                            "Search Projects: settings are now saved between page changes",
                            "Search Project Products: settings are now saved between page changes",
                        ]}
                        features={[
                            "View Activity: User can change activity type",
                            "View Project Activity: User can change activity type"
                        ]}
                        bugs={[]}
                    />

                    <Release version="3.2.2"
                        date="11.4.2023"
                        changes={[
                            "Added filter by product name to search project products view",
                            "Changed all business partner searches to return top 200 results",
                            "Added outlook event option to new activity, new project product and new project notification forms"
                        ]}
                        features={[]}
                        bugs={[]}
                    />

                    <Release version="3.2.1"
                        date="30.3.2023"
                        changes={[
                            "Added vertical scrollbar to simple search components",
                            "List of forms where the change took affect: New project, Edit Project, New Activity, Edit activity, New notification, Edit notification, New contact and Edit contact",
                        ]}
                        features={[]}
                        bugs={[]}
                    />

                    <Release version="3.2.0"
                        date="25.1.2023"
                        changes={[
                            "Notifications: Removed 'Name' field",
                            "Create contact: Changed business partner field as mandatory",
                            "Date inputs: Forced Finnish localization",
                            "Sales persons: [Deleted] text is shows after sales person name if sales person is deleted",
                            "Sales persons: [Inactive] text is shows after sales person name if sales person is deactivated",
                            "Landing page: Changed project name to title",
                            "Activity details: If activity is related to supplier, business partner name is shown as 'Supplier name'"
                        ]}
                        features={[
                            "Top bar: Added version number",
                            "Top bar: Added portal update notification feature",
                            "Create Notification: Added create outlook event feature",
                            "Update Notification: Added create outlook event feature",
                            "Summary by customer: Last search settings are saved if user switch to another page and come back",
                            "Search project product: Added new search page for project products",
                            "Project header: Added calculated margin percentage",
                            "Project activity: Added posibility to link contacts to external attendees field",
                            "Management/parameters: Added manage parameters features"
                        ]}
                        bugs={[
                            "Create activity: Fixed wrong notification body"
                        ]}
                    />

                    {/* < OLD VERSIONS BELOW THIS LINE > */}

                    <Release version="3.1.0"
                        date="20.6.2022"
                        changes={[
                            "Landing page: Optimized loading time",
                            "View project contact: Changed delete button text"
                        ]}
                        features={[
                            "Landing page: Added my passivated projects list",
                            "Landing page: Added passivating date and closing date fields to project list",
                            "Create Activity: Added business partner name to search results when related to project",
                            "Create activity: Added 'activity is related to supplier' option",
                            "Create activity: Added status details and next available status automatic selection ",
                            "View activity: Added project related fields (id, title, description) when activity is related to project",
                            "Update activity: Added 'activity is related to supplier' option",
                            "Search projects: Added filter by sales person and filter by status selection",
                            "Search projects: Added narrow you search info if more than 200 result are returned and own or sales person is not selected",
                            "Create project: Added 'Select supplier as project customer' option",
                            "Create project: Added show searchbox only when product is not selected and remove selection feature",
                            "Create project product: Added optional description field",
                            "View project product: Added description field",
                            "Update project product: Added optional description field",
                            "Create project: Added show searchbox only when product is not selected and remove selection feature",
                            "Create project activity: Added status details and next available status automatic",
                            "View project activity: Added project related fields (id, title, description)",
                            "Version history: Added version history page",
                            "Management: Added management page for admins",
                            "Management/Contacts: Added delete contact action for admin"
                        ]}
                        bugs={[
                            "Search activities: Fixed activity is not found if project or customer id missing.",
                            "Search activities: Fixed activity can´t be opened to view form because title is missing.",
                            "Create activity: Fixed create activity notification cant be created bug",
                            "View Project: Fixed activities list not updated if passivated project is activated"
                        ]}
                    />

                </Grid>
            </Form>
        </Container>
    );

}

export default VersionHistory;
