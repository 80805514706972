//import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import webApiClient from '../../Utils/webApiClient';
import { msalInstance } from '../../index';
import config from '../../Config/config';
import useRequestHandler from '../useRequestHandler';

/** Hook is used to update activity entity */
const useUpdateActivity = () => {

    const { t } = useTranslation();
    const { errorHandler, successHandler } = useRequestHandler();
    const apiClient = webApiClient();

    /** Update activity entity
     * @param {object} object - Object which describes the activity entity
     * @example object
     * {
     *      "id": 1,
     *      "title": "",
     *      "contactDate": "2011-10-05T14:48:00.000Z",
     *      "type": 1,
     *      "project": 1,
     *      "businessPartner": 1,
     *      "internalAttendee": "",
     *      "externalAttendee": "",
     *      "objective": "",
     *      "externalNotes": "",
     *      "internalNotes": "",
     *      "salesPerson": 1,
     *      "contact": 1,
     *      "deleted": false
     * }
     **/
    const updateActivity = async (object) => {
        try {

            // Account is set in the index.js
            const account = msalInstance.getActiveAccount();

            const response = await msalInstance.acquireTokenSilent({
                scopes: config.webApi.scopes,
                account: account
            });

            const result = await apiClient.update(response.accessToken, "activities", object);  

            return successHandler(result, "useUpdateActivity", "update", { message: t('success.update') });

        } catch (error) {

            return errorHandler(error, "useUpdateActivity", "update");

        }
    }

    return { updateActivity };

}

export default useUpdateActivity;
