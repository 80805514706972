import { useState } from 'react';
import webApiClient from '../../Utils/webApiClient';
import { msalInstance } from '../../index';
import config from '../../Config/config';
import useRequestHandler from '../useRequestHandler';

/** Hook is used to read business partner statistics by business partner id */
const useReadBusinessPartnerStatistics = () => {

    const { errorHandler, successHandler } = useRequestHandler();
    const apiClient = webApiClient();
    const [ businessPartnerStatistics, setBusinessPartnerStatistics ] = useState({});

    /** Read business partner statistics by business parter id
     * @param {integer} id - id of the business partner entity */
    const readBusinessPartnerStatistics = async (id, ownOnly, activeOnly) => {
        try {

            // Account is set in the index.js
            const account = msalInstance.getActiveAccount();

            const response = await msalInstance.acquireTokenSilent({
                scopes: config.webApi.scopes,
                account: account
            });

            let url = 'businesspartners/' + id + '/statistics';
            url += ownOnly ? '?own=true' : '?own=false';
            url += activeOnly ? '&active=true' : '?activeOnly=false';

            const result = await apiClient.read(response.accessToken, url);  

            const data = result && result.data ? result.data : null;
            const content = data && data.resultData ? data.resultData : null;

            if (content) setBusinessPartnerStatistics(content);

            return successHandler(result, "useReadBusinessPartnerStatistics", "read", { hide: true });

        } catch (error) {
            return errorHandler(error, "useReadBusinessPartnerStatistics", "read");
        }
    }

    return { businessPartnerStatistics, readBusinessPartnerStatistics };

}

export default useReadBusinessPartnerStatistics;
