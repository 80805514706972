import React, { useState, useEffect, useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { Form, Container, Message, Icon, Grid, TextArea } from 'semantic-ui-react';
import { Loading } from '../../Components/UI/Loaders';
import { NewButton, EditButton, CancelButton, ApproveButton, DeleteButton } from '../../Components/UI/Buttons';
import { YesNoModal } from '../../Components/UI/Modals';
import { PageTitle } from '../../Components/UI/Content';
import PagedTable from '../../Components/UI/Lists';
//import Config from './../../../Config/config';

import { StateContext } from '../../Application/Context/StateContext';
import useReadProject from '../../Hooks/Project/useReadProject';
import useListProjectContacts from '../../Hooks/ProjectContacts/useListProjectContacts';
import useListProjectProducts from '../../Hooks/ProjectProducts/useListProjectProducts';
import useListProjectActivities from '../../Hooks/ProjectActivities/useListProjectActivities';
import useListProjectAttachments from '../../Hooks/ProjectAttachments/useListProjectAttachments';
import useListProjectNotifications from '../../Hooks/ProjectNotifications/useListProjectNotifications';
import useDeleteProjectAttachment from '../../Hooks/ProjectAttachments/useDeleteProjectAttachment';
import useDeleteProjectContact from '../../Hooks/ProjectContacts/useDeleteProjectContact';
import useCreateActivity from '../../Hooks/Activities/useCreateActivity';
import useDeleteProject from '../../Hooks/Project/useDeleteProject';
import useReadProjectAttachement from '../../Hooks/ProjectAttachments/useReadProjectAttachement';

const containerStyle = { marginBottom: '20px', marginTop: '40px' }

const ProjectHeader = ({ project = {}, products, projectId, disableEditing, userProfile, userIsAdmin, readProject, listProjectActivities, setIsLoading }) => {

    const { t } = useTranslation();
    const history = useHistory();

    const [ removeProjectModalIsOpen, setRemoveProjectModalIsOpen ] = useState(false);
    const [ deleteProjectModalIsOpen, setDeleteProjectModalIsOpen ] = useState(false);
    const [ activatePassivatedProjectModalIsOpen, setActivatePassivatedProjectModalIsOpen ] = useState(false);
    const [ activateClosedProjectodalIsOpen, setActivateClosedProjectodalIsOpen ] = useState(false);
    const [ activationOrDeletionReason, setActivationOrDeletionReason ] = useState("");

    let potentialSales = null;
    let potentialMargin = null;

    const { createActivity } = useCreateActivity();
    const { deleteProject } = useDeleteProject();

    if (products && products.length) {
        products.forEach(item => {
            potentialSales = potentialSales + item.PotentialSales;
            potentialMargin = potentialMargin + item.PotentialMargin

        });
    }

    return (
        <Container style={containerStyle}>

            {/* {project && project.Status && (project.Status === 1 || project.Status === 2) && products.length === 0 && */}
            {products.length === 0 &&
                <Message attached='bottom' warning visible={true}>
                    <Icon name='exclamation' />
                    {t('project.rememberToAddProducts')}
                </Message>
            }

            {/* Remove project modal (project status is changed to removed) */}
            <YesNoModal
                type='ready'
                header={t('project.removeProject')}
                text={t('general.areYouSure')}
                method={async (answer) => {
                    setIsLoading(true);
                    if (answer) {
                        const activity = {
                            "title": "Status change -> Project removed by user",
                            "contactDate": new Date().toISOString(),
                            "type": 5,
                            "project": parseInt(projectId),
                            "businessUnit": project && project.BusinessUnit ? project.BusinessUnit : null,                      
                            "projectNewStatus": 12,
                            "businessPartner": project && project.BusinessPartner ? project.BusinessPartner : null,
                            "internalAttendee": null,
                            "externalAttendee": null,
                            "objective": null,
                            "externalNotes": null,
                            "internalNotes": activationOrDeletionReason,
                            "salesPerson": userProfile && userProfile.Id ? userProfile.Id : null,
                            "contact": null
                        }
                        await createActivity(activity);
                        await readProject(projectId);
                        await listProjectActivities(projectId)
                    }
                    setActivationOrDeletionReason("");
                    setIsLoading(false);
                }}
                item={projectId}
                open={removeProjectModalIsOpen}
                setOpen={setRemoveProjectModalIsOpen}
                noClick={false}
                disableYesButton={activationOrDeletionReason.length === 0}
            >

                {/* Project removal reason */}
                <Form>
                    <Form.Field
                        required
                        label={t('project.projectRemovalReason')}
                        placeholder={t('project.projectRemovalReason')}
                        control={TextArea}
                        maxLength="255"
                        value={activationOrDeletionReason}
                        onChange={(ev, {value}) => setActivationOrDeletionReason(value) }
                        type="Text"
                    />
                </Form>

            </YesNoModal>

            {/* Delete project modal (project deleted bit is set) */}
            <YesNoModal
                type='ready'
                header={t('general.delete')}
                text={t('general.areYouSure')}
                method={async (answer) => {
                    setIsLoading(true);
                    if (answer) {
                        await deleteProject(projectId);
                        return history.goBack();
                    }
                    setIsLoading(false);
                }}
                item={projectId}
                open={deleteProjectModalIsOpen}
                setOpen={setDeleteProjectModalIsOpen}
                noClick={false}
                //disableYesButton={activationOrDeletionReason.length === 0}
            />

            {/* Activate passivated project modal */}
            <YesNoModal
                type='ready'
                header={t('project.activatePassivatedProject')}
                text={t('general.areYouSure')}
                method={async (answer) => {
                    setIsLoading(true);
                    if (answer) {

                        let activity = {
                            //"title": null,
                            "contactDate": new Date().toISOString(),
                            "type": 5,
                            "project": projectId,
                            "businessUnit": project && project.BusinessUnit ? project.BusinessUnit : null,
                            "projectNewStatus": null,
                            "businessPartner": null,
                            "internalAttendee": null,
                            "externalAttendee": null,
                            "objective": null,
                            "externalNotes": null,
                            "internalNotes": activationOrDeletionReason,
                            "salesPerson": userProfile && userProfile.Id ? userProfile.Id : null,
                            "contact": null
                        }

                        await createActivity(activity);
                        await readProject(projectId);
                        await listProjectActivities(projectId);

                    }
                    setActivationOrDeletionReason("");
                    setIsLoading(false);
                }}
                item={projectId}
                open={activatePassivatedProjectModalIsOpen}
                setOpen={setActivatePassivatedProjectModalIsOpen}
                noClick={false}
                disableYesButton={activationOrDeletionReason.length === 0}
            >
                {/* Project activation reason */}
                <Form>
                   <Form.Field
                       required
                       label={t('project.projectActivationReason')}
                       placeholder={t('project.projectActivationReason')}
                       control={TextArea}
                       maxLength="255"
                       value={activationOrDeletionReason}
                       onChange={(ev, {value}) => setActivationOrDeletionReason(value) }
                       type="Text"
                   />
               </Form>
            </YesNoModal>

            {/* Activate closed project modal (ADMIN ONLY) */}
            <YesNoModal
                type='ready'
                header={t('project.activateClosedProject')}
                text={t('general.areYouSure')}
                method={async (answer) => {
                    setIsLoading(true);
                    if (answer) {

                        let activity = {
                            //"title": null
                            "contactDate": new Date().toISOString(),
                            "type": 5,
                            "project": projectId,
                            "businessUnit": project && project.BusinessUnit ? project.BusinessUnit : null,
                            "projectNewStatus": null,
                            "businessPartner": null,
                            "internalAttendee": null,
                            "externalAttendee": null,
                            "objective": null,
                            "externalNotes": null,
                            "internalNotes": activationOrDeletionReason,
                            "salesPerson": userProfile && userProfile.Id ? userProfile.Id : null,
                            "contact": null
                        }

                        await createActivity(activity);
                        await readProject(projectId);
                        await listProjectActivities(projectId);

                    }
                    setActivationOrDeletionReason("");
                    setIsLoading(false);
                }}
                item={projectId}
                open={activateClosedProjectodalIsOpen}
                setOpen={setActivateClosedProjectodalIsOpen}
                noClick={false}
                disableYesButton={activationOrDeletionReason.length === 0}
            >
                {/* Project activation reason */}
                <Form>
                   <Form.Field
                       required
                       label={t('project.projectActivationReason')}
                       placeholder={t('project.projectActivationReason')}
                       control={TextArea}
                       maxLength="255"
                       value={activationOrDeletionReason}
                       onChange={(ev, {value}) => setActivationOrDeletionReason(value) }
                       type="Text"
                   />
               </Form>
            </YesNoModal>

            <Grid stackable>

                {/* Id */}
                <Grid.Row>
                    <Grid.Column width={4}><Form.Field label={t('general.id')} /></Grid.Column>
                    <Grid.Column width={12}><p>{project.Id}</p></Grid.Column>
                </Grid.Row>

                {/* Sales person */}
                <Grid.Row>
                    <Grid.Column width={4}><Form.Field label={t('project.salesPerson')} /></Grid.Column>
                    <Grid.Column width={12}><p>{project.SalesPersonName}</p></Grid.Column>
                </Grid.Row>

                {/* Business Partner / customer */}
                <Grid.Row>
                    <Grid.Column width={4}><Form.Field label={t('businessPartner.customer')} /></Grid.Column>
                    <Grid.Column width={12}><p>{project.BusinessPartnerName}</p></Grid.Column>
                </Grid.Row>

                {/* Title */}
                <Grid.Row>
                    <Grid.Column width={4}><Form.Field label={t('general.title')} /></Grid.Column>
                    <Grid.Column width={12}><p>{project.Title}</p></Grid.Column>
                </Grid.Row>
                
                {/* Description */}
                <Grid.Row>
                    <Grid.Column width={4}><Form.Field label={t('general.description')} /></Grid.Column>
                    <Grid.Column width={12}><p>{project.Description}</p></Grid.Column>
                </Grid.Row>

                {/* Status */}
                <Grid.Row>
                    <Grid.Column width={4}><Form.Field label={t('general.status')} /></Grid.Column>
                    <Grid.Column width={12}><p>{project.StatusName}</p></Grid.Column>
                </Grid.Row>

                {/* Start Date */}
                <Grid.Row>
                    <Grid.Column width={4}><Form.Field label={t('project.startDate')} /></Grid.Column>
                    <Grid.Column width={12}><p>{project.StartDate ? new Date(project.StartDate).toLocaleDateString("fi-FI") : ''}</p></Grid.Column>
                </Grid.Row>

                {/* End Date */}
                <Grid.Row>
                    <Grid.Column width={4}><Form.Field label={t('project.endDate')} /></Grid.Column>
                    <Grid.Column width={12}><p>{project.EndDate ? new Date(project.EndDate).toLocaleDateString("fi-FI") : ''}</p></Grid.Column>
                </Grid.Row>

                {/* Application */}
                <Grid.Row>
                    <Grid.Column width={4}><Form.Field label={t('project.application')} /></Grid.Column>
                    <Grid.Column width={12}><p>{project.Application}</p></Grid.Column>
                </Grid.Row>

                {/* Business unit */}
                <Grid.Row>
                    <Grid.Column width={4}><Form.Field label={t('businessPartner.businessUnit')} /></Grid.Column>
                    <Grid.Column width={12}><p>{project.BusinessUnitName}</p></Grid.Column>
                </Grid.Row>
               
                {/* Margin percentage */}
                <Grid.Row>
                    <Grid.Column width={4}><Form.Field label={t('project.marginPercentage')} /></Grid.Column>
                    <Grid.Column width={12}><p>{project.marginPercentage} %</p></Grid.Column>
                </Grid.Row>

                {/* Propability rate */}
                <Grid.Row>
                    <Grid.Column width={4}><Form.Field label={t('project.propabilityRate')} /></Grid.Column>
                    <Grid.Column width={12}><p>{project.ProbabilityRate} %</p></Grid.Column>
                </Grid.Row>

            </Grid>

            {/* Buttons */}
            <Container className='button-container'>

                <CancelButton onClick={(ev) => {ev.preventDefault(); history.goBack(); }} />
                <div>
                    <EditButton
                        disabled={disableEditing}
                        icon='save'
                        className='bb-lblue'
                        title={t('general.edit')}
                        onClick={(ev) => { ev.preventDefault(); history.push('/projects/edit/' + projectId); }}
                    />

                    {/* Remove project (User and Admin) - Change status and adds activity (removed) */}
                    {project && project.Status && (project.Status === 1 || project.Status === 2) && 
                        <DeleteButton
                            color='red'
                            title={t('project.removeProject')}
                            onClick={() => setRemoveProjectModalIsOpen(true)}
                            
                        />
                    }

                    {/* Delete project (Admin only) - set deleted bit to 1 */}
                    {project && userIsAdmin && 
                        <DeleteButton
                            color='red'
                            title={t('general.delete') + " (Admin)"}
                            onClick={() => setDeleteProjectModalIsOpen(true)}
                        />
                    }

                    {/* Activate automatically passivated project - visible if project is automatically passivated (User and Admin) */}
                    {project && project.Status && project.Status === 10 && 
                        <ApproveButton
                            color='green'
                            title={t('project.activatePassivatedProject')}
                            onClick={() => setActivatePassivatedProjectModalIsOpen(true)}
                        />
                    }

                    {/* Activate automatically closed project - visible if project is automatically closed  (ADMIN ONLY) */}
                    {project && project.Status && project.Status === 11 && userIsAdmin &&
                        <ApproveButton
                            color='green'
                            title={t('project.activateClosedProject') + " (Admin)"}
                            onClick={() => setActivateClosedProjectodalIsOpen(true)}
                        />
                    }
                </div>

            </Container>

        </Container>
    );

}

const ProjectAttachment = ({ setIsLoading, attachments = [], projectId, disableEditing, listProjectAttachments }) => {
    
    const { t } = useTranslation();
    const history = useHistory();
    const [ modalIsOpen, setModalIsOpen ] = useState(false);
    const [ attachmentId, setAttachementId ] = useState(0);
    const { deleteProjectAttachement } = useDeleteProjectAttachment();
    const { readProjectAttachment } = useReadProjectAttachement();
    
    return (
        <Container style={containerStyle}>

            <PageTitle
                active
                title={t('attachment.attachments')}
                button={
                    <NewButton
                        disabled={disableEditing}
                        title={t('attachment.addAttachment')}
                        //mini
                        className='bb-lblue'
                        onClick={(ev) => { ev.preventDefault(); history.push('/projects/' + projectId + '/attachments/new'); }}
                    />
                }
            />

            {/* Delete attachment modal */}
            <YesNoModal
                type='ready'
                header={t('general.delete')}
                text={t('general.areYouSure')}
                method={async (answer, id) => {

                    setIsLoading(true);

                    if (answer) {
                        await deleteProjectAttachement(projectId, id);
                        await listProjectAttachments(projectId);
                    }

                    setIsLoading(false);

                }}
                item={attachmentId}
                open={modalIsOpen}
                setOpen={setModalIsOpen}
                noClick={false}
            />

            <PagedTable unstackable celled striped compact='very' pageSize={5}
                header={[
                    //t('general.id'),
                    t('general.title'),
                    //t('general.description'),
                    t('general.fileName'),
                    //t('general.originalFileName'),
                    //t('general.fileType'),
                    //t('general.fileSizeKB'),
                    //t('general.created'),
                    //t('general.createdBy'),
                    t('general.modified'),
                    //t('general.modifiedBy'),
                    //t('project.project'),
                    //t('general.activity'),
                    //t('general.actions'),
                    //'',
                    ''
                ]}
                content={attachments}
                resetPageOnContentChange={true}
                contentKeys={[
                    //{key: 'Id' },
                    {key: 'Title' },
                    //{key: 'Title', target: '/projects/' + projectId + '/attachments/view/', type: 'link', targetKey: 'Id'},
                    //{key: 'Description' },
                    //{key: 'FileName' },
                    //{key: 'OriginalFileName' },
                    //{key: 'OriginalFileName', target: Config.storage.attachementsPath, type: 'externalLink', targetKey: 'FileName'},
                    {key: 'OriginalFileName', type: 'action', action: async (ev, row, cellKey) => { 
                        await readProjectAttachment(row.FileName);
                    }},
                    //{key: 'FileType' },
                    //{key: 'FileSizeKB' },
                    //{key: 'Created' },
                    //{key: 'CreatedBy' },
                    {key: 'Modified', type: 'date' },
                    //{key: 'ModifiedBy' },
                    //{key: 'project' },
                    //{key: 'activity' },
                    //{key: 'Id', target: '/projects/' + projectId + '/attachments/view/', type: 'link', text: t('general.view'), targetKey: 'Id'},
                    //{key: 'Id', target: '/projects/' + projectId + '/attachments/edit/', type: 'link', text: t('general.edit'), targetKey: 'Id'},
                    {key: 'Id', type: 'action', text: t('general.delete'), action: (ev, row, cellKey) => { 
                        setAttachementId(row.Id);
                        if(!disableEditing) setModalIsOpen(true);
                    }}
                    
                ]}
            />

        </Container>
    )
}

const ProjectContacts = ({
    setIsLoading,
    contacts = [],
    projectId,
    disableEditing,
    listProjectContacts
}) => {

    const { t } = useTranslation();
    const history = useHistory();
    const { deleteProjectContact } = useDeleteProjectContact();
    const [ modalIsOpen, setModalIsOpen ] = useState(false);
    const [ projectContactId, setProjectContactId ] = useState(0);

    return (
        <Container style={containerStyle}>

            <PageTitle
                active
                title={t('contact.contacts')}
                button={
                    <NewButton
                        disabled={disableEditing}
                        title={t('contact.addContact')}
                        //mini
                        className='bb-lblue'
                        onClick={(ev) => { ev.preventDefault(); history.push('/projects/' + projectId + '/contacts/new'); }}
                    />
                }
            />

            {/* Delete project contact modal */}
            <YesNoModal
                type='ready'
                header={t('general.delete')}
                text={t('general.areYouSure')}
                method={async (answer, id) => {
                    setIsLoading(true);
                    if (answer) {
                        await deleteProjectContact(projectId, id);
                        await listProjectContacts(projectId);
                    }
                    setIsLoading(false);
                }}
                item={projectContactId}
                open={modalIsOpen}
                setOpen={setModalIsOpen}
                noClick={false}
            />

            <PagedTable unstackable celled striped compact='very' pageSize={5}
                header={[
                    //t('general.id'),
                    t('general.name'),
                    //t('general.firstName'),
                    //t('general.lastName'),
                    t('general.position'),
                    t('general.email'),
                    t('general.phone') + " 1",
                    //t('general.phone') + " 2",
                    //t('businessPartner.businessPartner'),
                    //t('general.created'),
                    //t('general.createdBy'),
                    //t('general.modified'),
                    //t('general.modifiedBy'),
                    //t('general.actions'),
                    //'',
                    ''
                ]}
                content={contacts}
                resetPageOnContentChange={true}
                contentKeys={[
                    //{key: 'Id' },
                    //{key: 'Name'},
                    {key: 'Name', target: '/projects/' + projectId + '/contacts/view/', type: 'link', targetKey: 'Id'},
                    //{key: 'FirstName' },
                    //{key: 'LastName'},
                    {key: 'Position'},
                    {key: 'EmailAddress'},
                    {key: 'PhoneNumber1'},
                    //{key: 'PhoneNumber2'},
                    //{key: 'BusinessPartner'},
                    //{key: 'Created' },
                    //{key: 'CreatedBy' },
                    //key: 'Modified', type: 'date' },
                    //{key: 'ModifiedBy' },
                    //{key: 'Id', target: '/projects/' + projectId + '/contacts/view/', type: 'link', text: t('general.view'), targetKey: 'Id'},
                    //{key: 'Id', target: '/projects/' + projectId + '/contacts/edit/', type: 'link', text: t('general.edit'), targetKey: 'Id'},
                    {key: 'Id', type: 'action', text: t('projectContact.remove'), action: (ev, row, cellKey) => { 
                        setProjectContactId(row.Id);
                        if(!disableEditing) setModalIsOpen(true);
                    }}
                ]}
            />

        </Container>
    )
}

const ProjectProducts = ({ products = [], projectId, disableEditing }) => {

    const { t } = useTranslation();
    const history = useHistory();

    return (
        <Container style={containerStyle}>

            <PageTitle 
                active
                title={t('product.products')}
                button={
                    <NewButton
                        disabled={disableEditing}
                        title={t('product.addProduct')}
                        //mini
                        className='bb-lblue'
                        onClick={(ev) => { ev.preventDefault(); history.push('/projects/' + projectId + '/products/new'); }}
                    />
                }
            />

            <PagedTable unstackable celled striped compact='very' pageSize={5}
                header={[
                    //t('project.project'),
                    //t('general.product'),
                    //t('businessPartner.businessPartner'),
                    t('product.product'),
                    t('businessPartner.supplier'),
                    //t('general.description'),
                    //t('product.code'),
                    ///t('product.price'),
                    //t('product.inUse'),
                    //t('product.stockUnit'),
                    t('product.salesUnit'),
                    //t('product.countUnit'),
                    //t('product.itemGroup'),
                    //t('product.countryOfOrigin'),
                    //t('product.netWeight'),
                    //t('product.packageTypeQty'),
                    //t('product.packageType'),
                    t('product.quantity'),
                    //t('project.potentialSales'),
                    t('product.margin'),
                    //t('project.marginPercentage'),
                    //t('general.created'),
                    //t('general.createdBy'),
                    //t('general.modified'),
                    //t('general.modifiedBy'),
                    //t('general.deleted'),
                    //t('general.actions'),
                    ''
                ]}
                content={products}
                resetPageOnContentChange={true}
                contentKeys={[
                    //{key: 'project'},
                    //{key: 'product'},
                    //{key: 'BusinessPartner' },
                    {key: 'ProductName', target: '/projects/' + projectId + '/products/view/', type: 'link', targetKey: 'Id'},
                    {key: 'BusinessPartnerName' },
                    //{key: 'ProductDescription' },
                    //{key: 'ProductCode' },
                    //{key: 'ProductPrice' },
                    //{key: 'ProductInUse' },
                    //{key: 'ProductStockUnit' },
                    {key: 'ProductSalesUnit' },
                    //{key: 'ProductCountUnit' },
                    //{key: 'ProductItemGroup' },
                    //{key: 'ProductCountryOfOrigin' },
                    //{key: 'ProductNetWeight' },
                    //{key: 'ProductPackageTypeQty' },
                    //{key: 'ProductPackageType' },
                    {key: 'PotentialVolumeInSalesUnitOfMeasure', type: 'float', decimal: 2 },
                    //{key: 'PotentialSales'},
                    {key: 'PotentialMargin', type: 'float', decimal: 2 },
                    //{key: 'MarginPercentage'},
                    //{key: 'Created', type:'date'},
                    //{key: 'CreatedBy'},
                    //{key: 'Modified', type:'date'},
                    //{key: 'ModifiedBy'},
                    //{key: 'Deleted', type:'boolean'},
                    //{key: 'Id', target: '/projects/' + projectId + '/products/view/', type: 'link', text: t('general.view'), targetKey: 'product'}
                    {key: 'Id', target: '/projects/' + projectId + '/products/edit/', type: 'link', text: t('general.edit'), targetKey: 'Id'}
                ]}
            />

        </Container>
    )
}

const ProjectActivities = ({ activities = [], projectId, disableEditing }) => {

    const { t } = useTranslation();
    const history = useHistory();

    return (
        <Container style={containerStyle}>

            <PageTitle
                active
                title={t('activity.activities')}
                button={
                    <NewButton
                        disabled={disableEditing}
                        title={t('activity.addActivity')}
                        //mini
                        className='bb-lblue'
                        onClick={(ev) => { ev.preventDefault(); history.push('/projects/' + projectId + '/activities/new'); }}
                    />
                }
            />

            <PagedTable unstackable celled striped compact='very' pageSize={5}
                header={[
                    //t('general.id'),
                    t('general.title'),
                    t('activity.contactDate'),
                    t('activity.type'),
                    //t('project.project'),
                    //t('project.projectNewStatus'),
                   // t('businessPartner.businessPartner'),
                    //t('businessPartner.businessUnit'),
                    //t('activity.internalAttendees'),
                    //t('activity.externalAttendees'),
                    //t('activity.objective'),
                    //t('activity.externalNotes'),
                    //t('activity.internalNotes'),
                    //t('activity.contact'),
                    //t('activity.salesPerson'),
                    //t('general.created'),
                    //t('general.createdBy'),
                    //t('general.modified'),
                    //t('general.modifiedBy'),
                    //t('general.deleted'),
                    //t('general.actions')
                    ''
                ]}
                content={activities}
                resetPageOnContentChange={true}
                contentKeys={[
                    //{key: 'Id'},
                    {key: 'Title', target: '/projects/' + projectId + '/activities/view/', type: 'link', targetKey: 'Id'},
                    {key: 'ContactDate', type: 'date'},
                    {key: 'ActivityTypeName'},
                    //{key: 'project'},
                    //{key: 'projectNewStatus'},
                    //{key: 'BusinessPartner'},
                    //{key: 'BusinessUnit'},
                    //{key: 'InternalAttendee'},
                    //{key: 'ExternalAttendee'},
                    //{key: 'Objective'},
                    //{key: 'ExternalNotes'},
                    //{key: 'InternalNotes'},
                    //{key: 'Contact'},
                    //{key: 'SalesPerson'},
                    //{key: 'Created', type:'date'},
                    //{key: 'CreatedBy'},
                    //{key: 'Modified', type:'date'},
                    //{key: 'ModifiedBy'},
                    //{key: 'Deleted', type:'boolean'},
                    //{key: 'Id', target: '/projects/' + projectId + '/activities/view/', type: 'link', text: t('general.view'), targetKey: 'Id'}
                    {key: 'Id', target: '/projects/' + projectId + '/activities/edit/', type: 'link', text: t('general.edit'), targetKey: 'Id'}
                ]}
            />
        </Container>
    )
}

const ProjectNotifications = ({ notifications = [], projectId, disableEditing }) => {

    const { t } = useTranslation();
    const history = useHistory();

    return (
        <Container style={containerStyle}>

            <PageTitle
                active
                title={t('notification.notifications')}
                button={
                    <NewButton
                        disabled={disableEditing}
                        title={t('notification.addNotification')}
                        //mini
                        className='bb-lblue'
                        onClick={(ev) => { ev.preventDefault(); history.push('/projects/' + projectId + '/notifications/new'); }}
                    />
                }
            />

            <PagedTable unstackable celled striped compact='very' pageSize={5}
                header={[
                    t('general.title'),
                    t('notification.sendNotificationDate'),
                    t('notification.acknowledgementTime'),
                    ""
                ]}
                content={notifications}
                resetPageOnContentChange={true}
                contentKeys={[
                    {key: 'Title', target: '/projects/' + projectId + '/notifications/view/', type: 'link', targetKey: 'Id'},
                    {key: 'Date', type: 'date'},
                    {key: 'AcknowledgementTime', type: 'date'},
                    {key: 'Id', target: '/projects/' + projectId + '/notifications/edit/', type: 'link', text: t('general.edit'), targetKey: 'Id'}
                ]}
            />
        </Container>

    )
}

const ViewProject = (props) => {

    const { t } = useTranslation();
    const history = useHistory();
    const { projectId } = useParams();
    const scollToTopRef = useRef();

    const { userProfile } = useContext(StateContext);
    const { project, readProject } = useReadProject();
    const { projectContacts, listProjectContacts } = useListProjectContacts();
    const { projectProducts, listProjectProducts } = useListProjectProducts();
    const { projectActivities, listProjectActivities } = useListProjectActivities();
    const { projectAttachments, listProjectAttachments } = useListProjectAttachments();
    const { projectNotifications, listProjectNotifications } = useListProjectNotifications();
    const [ isLoading, setIsLoading ] = useState(true);

    useEffect(() => {
        (async () => {
            scollToTopRef.current.scrollIntoView();
            if (projectId) {
                setIsLoading(true);
                await readProject(projectId);
                await listProjectContacts(projectId);
                await listProjectProducts(projectId);
                await listProjectActivities(projectId);
                await listProjectAttachments(projectId);
                await listProjectNotifications(projectId);
                setIsLoading(false);
            }
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const userIsAdmin = userProfile && userProfile.IsAdmin ? true : false;
    const projectHasEnded = project && project.EndDate ? true : false; // If project has end date, project is ended and only admin can edit it!
    const projectIsNotActive = project && project.Status > 7 ? true : false; // if project status is greater than 7 (not active anymore) only admin can edit it!
    const disableEditing = (projectHasEnded || projectIsNotActive) && !userIsAdmin ? true : false;

    return (
        <Form className='project' >

            <div ref={scollToTopRef}></div>

            <Loading active={ isLoading } text={t('general.loading')} />

            <PageTitle active title={t('project.viewProject')} />

            <Message warning visible={projectHasEnded || projectIsNotActive} content={t('warnings.projectIsNotActive')} />

            <ProjectHeader
                project={project}
                projectId={projectId}
                products={projectProducts}                
                disableEditing={disableEditing}
                userProfile={userProfile}
                userIsAdmin={userIsAdmin}
                readProject={readProject}
                listProjectActivities={listProjectActivities}
                setIsLoading={setIsLoading}
            />

            <ProjectProducts
                products={projectProducts}
                projectId={projectId}
                disableEditing={disableEditing}
            />

            <ProjectActivities
                activities={projectActivities}
                projectId={projectId}
                disableEditing={disableEditing}
            />

            <ProjectContacts
                setIsLoading={setIsLoading}
                contacts={projectContacts}
                projectId={projectId}
                disableEditing={disableEditing}
                listProjectContacts={listProjectContacts}
            />

            <ProjectNotifications
                notifications={projectNotifications}
                projectId={projectId}
                disableEditing={disableEditing}
            />

            <ProjectAttachment
                setIsLoading={setIsLoading}
                attachments={projectAttachments}
                projectId={projectId}
                disableEditing={disableEditing}
                listProjectAttachments={listProjectAttachments}
            />
            
            {/* Errors */}
            <Message error visible={!projectId} content={t('errors.missingProjectId')} />

            {/* Buttons */}
            <Container className='button-container'>

                <CancelButton onClick={(ev) => {ev.preventDefault(); history.goBack(); }} />
                <EditButton
                    onClick={(ev) => { ev.preventDefault(); history.push('/projects/edit/' + projectId); }}
                    disabled={disableEditing}
                    icon='edit'
                    className='bb-lblue'
                    title={t('general.edit')}
                />

            </Container>

        </Form>
    );

}

export default ViewProject;
