import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { Container, Header, Icon, List, Segment, Divider } from 'semantic-ui-react';

const SettingsBox = ({title, description, icon, items}) => {

    const { t } = useTranslation();

    return (
        <Container style={{ marginBottom: "20px" }}>
            <Segment>
            <Header as='h3' style={{ marginBottom: "8px" }}>
                <Icon name={icon} />
                <Header.Content>{title}
                    <Header.Subheader>{description}</Header.Subheader>
                </Header.Content>
            </Header>
            <Divider/>
            <Container className="settings" style={{paddingLeft: '42px'}}>
                <List>
                    {items.length === 0 && <p>{t('management.linksNotAdded')}</p>}
                    {items.map((item, index) => 
                        <Fragment key={index}>
                            <NavLink className="navlink" to={item.to} >
                                {t(item.title)}
                            </NavLink>
                            <br/>
                        </Fragment>
                    )}
                </List>
            </Container>
            </Segment>
        </Container>
    )
}

export default SettingsBox;