import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Container} from 'semantic-ui-react';
import PagedTable from '../../../Components/UI/Lists';
import { PageTitle } from '../../../Components/UI/Content';
import { Loading } from '../../../Components/UI/Loaders';
import { CancelButton } from '../../../Components/UI/Buttons';
import Forbidden from '../../System/Forbidden';
import useListParameters from '../../../Hooks/Parameters/useListParameters';
import { StateContext } from '../../../Application/Context/StateContext';

const ManageParameters = (props) => {

    const { t } = useTranslation();
    const history = useHistory();
    const [ isLoading, setIsLoading ] = useState(true);
    const { parameters, listParameters } = useListParameters();
    const { userProfile } = useContext(StateContext);

    useEffect(() => {
        (async() => {
            setIsLoading(true);
            await listParameters("");
            setIsLoading(false);
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);    

    const isAdmin = userProfile && userProfile.IsAdmin ? userProfile.IsAdmin : false;

    if (!isAdmin) return <Forbidden />;

    return (
        <div className="management">

            <PageTitle active title={t('management.manageParameters')} />

            <Loading active={ isLoading } text={t('general.loading')} />
            
            <Container className="fieldcontent">
                <PagedTable unstackable celled striped compact='very' pageSize={20}
                    header={[
                        t('general.id'),
                        t('general.name'),
                        t('general.actions'),
                        ''
                    ]}
                    content={parameters}
                    resetPageOnContentChange={true}
                    contentKeys={[
                        {key: 'Id'},
                        {key: 'PKey', target: '/management/parameters/view/', type: 'link', targetKey: 'Id'},
                        {key: 'Id', target: '/management/parameters/view/', type: 'link', text: t('general.view'), targetKey: 'Id'},
                        {key: 'Id', target: '/management/parameters/edit/', type: 'link', text: t('general.edit'), targetKey: 'Id'}
                    ]}
                />
            </Container>

            <Container  className='button-container'>
                <CancelButton title={t('general.back')} onClick={(ev) => { history.goBack(); }} />
            </Container>

        </div>
    );

}

export default ManageParameters;
