//import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import webApiClient from '../../Utils/webApiClient';
import { msalInstance } from '../../index';
import config from '../../Config/config';
import useRequestHandler from '../useRequestHandler';

/** ADMIN ONLY: Hook is used to update parameter entity */
const useUpdateParameter = () => {

    const { t } = useTranslation();
    const { errorHandler, successHandler } = useRequestHandler();
    const apiClient = webApiClient();

    /** Update parameter entity
     * @param {object} object - Object which describes the parameter entity
     * @example object
     * {
     *      "Id": 1,
     *      "PKey": "DBLogLevel",
     *      "PValue": "3",
     *      "description": "Defines what is logged from DB transactions into log table. Value is one of 0(error)/1(warning)/2(info)/3(debug)"
     * } 
     **/
    const updateParameter = async (id, object) => {
        try {

            // Account is set in the index.js
            const account = msalInstance.getActiveAccount();

            const response = await msalInstance.acquireTokenSilent({
                scopes: config.webApi.scopes,
                account: account
            });

            let resource = "parameters/" + id;

            const result = await apiClient.update(response.accessToken, resource, object);  

            return successHandler(result, "useUpdateParameter", "update", { message: t('success.update') });

        } catch (error) {

            return errorHandler(error, "useUpdateParameter", "update");

        }
    }

    return { updateParameter };

}

export default useUpdateParameter;
