import { useState } from 'react';
import webApiClient from '../../Utils/webApiClient';
import { msalInstance } from '../../index';
import config from '../../Config/config';
import useRequestHandler from '../useRequestHandler';

/** Hook is used to fetch list of project notifications */
const useListProjectNotifications = () => {

    const [ projectNotifications, setProjectNotifications ] = useState([]);
    const { errorHandler, successHandler } = useRequestHandler();
    const apiClient = webApiClient();

    /** List project notifications
     * @param {integer} projectId - project id */
    const listProjectNotifications = async (projectId) => {
        try {

            // Account is set in the index.js
            const account = msalInstance.getActiveAccount();

            const response = await msalInstance.acquireTokenSilent({
                scopes: config.webApi.scopes,
                account: account
            });

            // Create resource path
            let resource = "projects/" + projectId.toString() + "/notifications";

            const result = await apiClient.list(response.accessToken, resource);

            const data = result.data || {};
            const content = data && data.resultData ? data.resultData : [];

            if (content) setProjectNotifications(content);

            return successHandler(result, "useListProjectNotifications", "list", { hide: true });

        } catch (error) {

            return errorHandler(error, "useListProjectNotifications", "list");

        }
    }

    return { projectNotifications, listProjectNotifications };

}

export default useListProjectNotifications;
