import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './Application/App';
import { Loading } from './Components/UI/Layout';
import './Utils/i18n'; // import i18n (needs to be bundled ;))
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import config from "./Config/config";

export const msalInstance = new PublicClientApplication(config.msalConfig);

// Account selection logic is app dependent. Adjust as needed for different use cases.
//https://www.npmjs.com/package/@azure/msal-react
const accounts = msalInstance.getAllAccounts();

if (accounts.length > 0) msalInstance.setActiveAccount(accounts[0]);

msalInstance.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
        //console.log("msal instance callback called");
        const account = event.payload.account;
        msalInstance.setActiveAccount(account);
    }
});

ReactDOM.render(
    <Suspense fallback={<Loading />}>
        <MsalProvider instance={msalInstance}>
            <App />
        </MsalProvider>
    </Suspense>,
  document.getElementById('root')
);
