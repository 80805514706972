import { useState, useRef } from 'react';
import webApiClient from './../../Utils/webApiClient';
import { msalInstance } from '../../index';
import config from '../../Config/config';
import useRequestHandler from '../useRequestHandler';
import axios from 'axios';

/** Hook is used to search activities by search term */
const useSearchActivities = () => {

    const { errorHandler, successHandler } = useRequestHandler();
    const [ activities, setActivities ] = useState([]);
    const apiClient = webApiClient();

    const CancelToken = axios.CancelToken;
    const cancelTokenSource = useRef(null);

    /** Search activities by search term
     * @param {string} searchterm - search term
     * @param {object} options - search options
     * @param {boolean} options.own - list only my activities or all 
     * @param {integer} options.businesspartner - business partner id
     * @param {integer} options.project - project id 
     * @param {integer} options.top - select top n rows
     * @param {integer} options.salesPerson - salesperson*/
    const searchActivities = async (searchterm = "", { own = true, businesspartner = null, project = null, top = 200, salesPerson = null }) => {
        try {

            if (cancelTokenSource.current) {
                //console.log('Cancel previous request');
                cancelTokenSource.current.cancel('Cancel previous request');
            }
            
            cancelTokenSource.current = CancelToken.source();

            // Account is set in the index.js
            const account = msalInstance.getActiveAccount();

            const response = await msalInstance.acquireTokenSilent({
                scopes: config.webApi.scopes,
                account: account
            });

            let resource = "search/activities?searchterm=" + searchterm.toString();
            resource += "&own=" + own.toString();
            if (businesspartner) resource += "&businesspartner=" + businesspartner.toString();
            if (project) resource += "&project=" + project.toString();
            resource += "&top=" + top.toString();
            if (salesPerson) resource += "&salesperson=" + salesPerson.toString();

            const result = await apiClient.read(response.accessToken, resource, null, { cancelToken: cancelTokenSource.current });

            const data = result && result.data ? result.data : null;
            const content = data && data.resultData ? data.resultData : [];

            if (content) setActivities(content);

            return successHandler(result, "useSearchActivities", "search", { hide: true });

        } catch (error) {
            return errorHandler(error, "useSearchActivities", "search");
        }
    }

    return { activities, setActivities, searchActivities };

}

export default useSearchActivities;
