import { useState, useRef } from 'react';
import webApiClient from './../../Utils/webApiClient';
import { msalInstance } from '../../index';
import config from '../../Config/config';
import useRequestHandler from '../useRequestHandler';
import axios from 'axios';

/** Hook is used to search contacts by search term */
const useSearchContacts = () => {

    const { errorHandler, successHandler } = useRequestHandler();
    const [ contacts, setContacts ] = useState([]);
    const apiClient = webApiClient();

    const CancelToken = axios.CancelToken;
    const cancelTokenSource = useRef(null);

    /** Search contacts by search term
     * @param {string} searchterm - search term */
    const searchContacts = async (searchterm = "") => {
        try {

            if (cancelTokenSource.current) {
                //console.log('Cancel previous request');
                cancelTokenSource.current.cancel('Cancel previous request');
            }
            
            cancelTokenSource.current = CancelToken.source();

            // Account is set in the index.js
            const account = msalInstance.getActiveAccount();

            const response = await msalInstance.acquireTokenSilent({
                scopes: config.webApi.scopes,
                account: account
            });

            let resource = "search/contact?searchterm=" + searchterm.toString();

            const result = await apiClient.read(response.accessToken, resource, null, { cancelToken: cancelTokenSource.current });

            const data = result && result.data ? result.data : null;
            const content = data && data.resultData ? data.resultData : [];

            if (content && content.length) setContacts(content);
            if (!content || content.length === 0) setContacts([]);

            return successHandler(result, "useSearchContacts", "search", { hide: true });

        } catch (error) {
            return errorHandler(error, "useSearchContacts", "search");
        }
    }

    return { contacts, setContacts, searchContacts };

}

export default useSearchContacts;
