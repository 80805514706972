import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import webApiClient from './../../Utils/webApiClient';
import { msalInstance } from '../../index';
import config from '../../Config/config';
import useRequestHandler from '../useRequestHandler';

/** Hook is used to delete notification entity */
const useDeleteNotification = () => {

    const { t } = useTranslation();
    const { errorHandler, successHandler } = useRequestHandler();
    const apiClient = webApiClient();
    const [ doesNotExist, setDoesNotExist ] = useState(false);

    /** Delete notification
     * @param {integer} notificationId - notification id
     **/
    const deleteNotification = async (notificationId) => {
        try {

            // Account is set in the index.js
            const account = msalInstance.getActiveAccount();

            const response = await msalInstance.acquireTokenSilent({
                scopes: config.webApi.scopes,
                account: account
            });

            const result = await apiClient.delete(response.accessToken, "notifications", notificationId.toString());            

            return successHandler(result, "useDeleteNotification", "delete", { header: t('success.delete'), message: t('success.delete') });

        } catch (error) {

            const status = error.status || null;
            const data = error.data || {};
            const errorCode = data.errorCode || 0;

            if (status === 400 && errorCode === -1) setDoesNotExist(true);

            return errorHandler(error, "useDeleteNotification", "delete");

        }
    }

    return { doesNotExist, setDoesNotExist, deleteNotification };

}

export default useDeleteNotification;
