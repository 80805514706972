//import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import webApiClient from './../../Utils/webApiClient';
import { msalInstance } from './../../index';
import config from './../../Config/config';
import useRequestHandler from './../useRequestHandler';

/** Hook is used to create new project entity */
const useCreateProject = () => {
    
    const { t } = useTranslation();
    const { errorHandler, successHandler } = useRequestHandler();
    const apiClient = webApiClient();

    /** Create new project
     * @param {object} object - Object which describes the project entity
     * @example object
     * {
     *      "salesPerson": 1,
     *      "businessPartner": 1,
     *      "title": "Otsikko",
     *      "description": "Kuvaus",
     *      "status": 1,
     *      "startDate": "2011-10-05T14:48:00.000Z",
     *      "endDate": "2011-10-05T14:48:00.000Z",
     *      "endReason": "Ei syytä",
     *      "failedOrDeniedReason": "Ei syytä"
     *      "customerSegment1": "???",
     *      "customerSegment2": "???",
     *      "customerSegment3": "???",
     *      "deleted": false
     * } 
     **/
    const createProject = async (object) => {
        try {

            // Account is set in the index.js
            const account = msalInstance.getActiveAccount();

            const response = await msalInstance.acquireTokenSilent({
                scopes: config.webApi.scopes,
                account: account
            });

            const result = await apiClient.create(response.accessToken, "projects", object);

            return successHandler(result, "useCreate", "create", { message: t('success.create') });

        } catch (error) {
            return errorHandler(error, "useCreate", "create");
        }
    }

    return { createProject };

}

export default useCreateProject;
