import { useState } from 'react';
import webApiClient from '../../Utils/webApiClient';
import { msalInstance } from '../../index';
import config from '../../Config/config';
import useRequestHandler from '../useRequestHandler';

/** Hook is used to fetch list of project attachment */
const useListProjectAttachment = () => {

    const [ projectAttachments, setProjectAttachment ] = useState([]);
    const { errorHandler, successHandler } = useRequestHandler();
    const apiClient = webApiClient();

    /** List project attachments
     * @param {boolean} projectId - Project id */
    const listProjectAttachments = async (projectId = null) => {
        try {

            // Account is set in the index.js
            const account = msalInstance.getActiveAccount();

            const response = await msalInstance.acquireTokenSilent({
                scopes: config.webApi.scopes,
                account: account
            });

            // Create resource path
            let resource = "projects/" + projectId.toString() + "/attachments";

            const result = await apiClient.list(response.accessToken, resource);

            const data = result.data || {};
            const content = data && data.resultData ? data.resultData : [];

            if (content) setProjectAttachment(content);

            return successHandler(result, "useListProjectAttachment", "list", { hide: true });

        } catch (error) {

            return errorHandler(error, "useListProjectAttachment", "list");

        }
    }

    return { projectAttachments, listProjectAttachments };

}

export default useListProjectAttachment;
