import { useState } from 'react';
import webApiClient from './../../Utils/webApiClient';
import { useTranslation } from 'react-i18next';
import { msalInstance } from '../../index';
import config from '../../Config/config';
import useRequestHandler from '../useRequestHandler';

/** Hook is used to read project attachment */
const useReadProjectAttachment = () => {

    const { t } = useTranslation();
    const { errorHandler, successHandler } = useRequestHandler();
    const apiClient = webApiClient();
    const [ projectAttachment, setProjectAttachment ] = useState({});

    /** Read project attachment by filename
     * @param {string} filename - filename of the project attachment entity */
    const readProjectAttachment = async (filename) => {
        try {

            // Account is set in the index.js
            const account = msalInstance.getActiveAccount();

            const response = await msalInstance.acquireTokenSilent({
                scopes: config.webApi.scopes,
                account: account
            });

            const options = { responseType: 'blob' };
            
            const result = await apiClient.read(response.accessToken, "attachments", encodeURIComponent(filename.toString()), options);  

            // https://stackoverflow.com/questions/59465413/convert-blob-url-to-file-object-with-axios
            if (result && result.status && result.status === 200 && result.data) {
                
                let newfile = new File([result.data], filename);

                const link = document.createElement('a');
                const url = URL.createObjectURL(newfile);
                
                link.href = url;
                link.download = filename;
                link.click();

                if (newfile) setProjectAttachment(newfile);

                return successHandler(result, "useReadProjectAttachment", "read", { hide: true });
            }

            throw Error();

        } catch (error) {
            let status = error && error.status ? error.status : null;
            let message = status && status === 404 ? t('errors.attachmentNotFound') : t('errors.readAttachmentFailed');
            return errorHandler(error, "useReadProjectAttachment", "read", { message: message });
        }
    }

    return { projectAttachment, readProjectAttachment };

}

export default useReadProjectAttachment;
