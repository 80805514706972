//import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import webApiClient from './../../Utils/webApiClient';
import { msalInstance } from '../../index';
import config from '../../Config/config';
import useRequestHandler from '../useRequestHandler';

/** Hook is used to update notification */
const useUpdateNotification = () => {

    const { t } = useTranslation();
    const { errorHandler, successHandler } = useRequestHandler();
    const apiClient = webApiClient();

    /** Update notification entity
     * @param {object} object - Object which describes the notification entity
     * @example object
     * {
     *      "id": 1,
     *      "name": "",
     *      "activity": 1,
     *      "date": "2011-10-05T14:48:00.000Z",
     *      "title": "",
     *      "businessPartner": 1,
     *      "description": "",
     *      "acknowledgementTime": "2011-10-05T14:48:00.000Z",
     *      "salesPerson": 1,
     *      "deleted": false
     * }
     **/
    const updateNotification = async (object) => {
        try {

            // Account is set in the index.js
            const account = msalInstance.getActiveAccount();

            const response = await msalInstance.acquireTokenSilent({
                scopes: config.webApi.scopes,
                account: account
            });

            const result = await apiClient.update(response.accessToken, "notifications", object);  

            return successHandler(result, "useUpdateNotification", "update", { message: t('success.update') });

        } catch (error) {

            return errorHandler(error, "useUpdateNotification", "update");

        }
    }

    return { updateNotification };

}

export default useUpdateNotification;
