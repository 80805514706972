import React, { useState, useEffect, useContext  } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Form, Container, Checkbox, Input, Dropdown, Message } from 'semantic-ui-react';
import { PageTitle } from '../../Components/UI/Content';
import { Loading } from '../../Components/UI/Loaders';
import { NewButton, CancelButton } from '../../Components/UI/Buttons';
import PagedTable from '../../Components/UI/Lists';
import useSearchActivities from '../../Hooks/Search/useSearchActivities';
import useListUsers from '../../Hooks/User/useListUsers';
import { StateContext } from '../../Application/Context/StateContext';
import { Accordion, Header, Grid, Icon, Segment } from 'semantic-ui-react'; 
import { SearchButton } from '../../Components/UI/Buttons';

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const months = {
    "January": "01",
    "February": "02",
    "March": "03",
    "April": "04",
    "May": "05",
    "June": "06",
    "July": "07",
    "August": "08",
    "September": "09",
    "October": "10",
    "November": "11",
    "December": "12",
}

/** Search items box component
  * @param {String} searchTerm - React state value which is added to the searchField
  * @param {String} setSearchTerm - React setState function which update the search term
  * @param {Boolean} searchMyOnlyActivities
  * @param {Boolean} searchBySalesPersonActivities
  * @param {Function} searchActivities - Search activitied method
  * @param {Object} filters - React components
  * @param {Boolean} selectedSalesPerson
**/
const SearchAllActivities = ({
    searchTerm,
    setSearchTerm,
    setIsLoading,
    searchMyOnlyActivities,
    searchBySalesPersonActivities,
    searchActivities,
    filters,
    selectedSalesPerson
}) => {

   const { t } = useTranslation();
   const [ activeIndex, setActiveIndex ] = useState(0);

   /* Search items when user clicks search */
   const onClickSearch = async (searchTerm) => {

        setIsLoading(true);

        const options = {
            own: searchMyOnlyActivities,
            businesspartner: null,
            project: null,
            top: 500,
            salesPerson: searchBySalesPersonActivities && selectedSalesPerson ? selectedSalesPerson : null
        }

        let result = await searchActivities(searchTerm, options);

        if (!result.requestCancelled) setIsLoading(false);

   };

   return (       
       <Container className={"fieldcontent"}>
           <Accordion>
               
               <Accordion.Title
                   active={activeIndex === 0}
                   index={0}
                   onClick={() => { setActiveIndex(activeIndex === 0 ? -1 : 0); }}
                   style={{paddingBottom: 0}}
               >
                   <Header as='h3' attached='top' className="search-items-header">
                       <Grid>
                           <Grid.Column width="8" textAlign="left">
                               <b>{t('general.search')}</b>
                           </Grid.Column>
                           <Grid.Column  width="8" textAlign="right">
                               <Icon name='dropdown' />
                           </Grid.Column>
                       </Grid>
                   </Header>
               </Accordion.Title>
               
               <Accordion.Content active={activeIndex === 0} style={{paddingTop: 0}}>
                   <Segment attached style={{ borderRadius: "0 0 5px 5px" }}>

                       <Input
                           fluid
                           icon='search'
                           placeholder={t('activity.searchActivities')}
                           value={searchTerm}
                           onKeyPress={(ev, data) => {
                               if (ev.key === 'Enter') {
                                   ev.preventDefault();
                                   onClickSearch(searchTerm);
                               }
                           }}
                           onChange={(ev, {value}) => {
                               setSearchTerm(value);
                           }}
                           className="fieldcontent"
                       />

                       {/* Additional filters */}
                       <Container>
                           {filters}
                       </Container>

                       {/* Search items button */}
                       <Container textAlign="right">
                           <SearchButton className="bb-lblue" type="reset" onClick={(ev) => { onClickSearch(searchTerm); }} />
                       </Container>

                   </Segment>
               </Accordion.Content>

           </Accordion>
       </Container>
   );
}

const Activities = (props) => {

    const { t } = useTranslation();
    const history = useHistory();
    let query = useQuery();

    const { activities, setActivities, searchActivities } = useSearchActivities();
    const { users, listUsers } = useListUsers();
    const [ isLoading, setIsLoading ] = useState(false);
    const [ itemsPerPage, setItemsPerPage ] = useState(10);
    const [ filterSearchResults, setFilterSearchResults ]= useState('');
    const [ customerName, setCustomerName ] = useState(null);

    const {
        searchTermActivities, setSearchTermActivities,
        searchMyOnlyActivities, setSearchMyOnlyActivities,
        searchBySalesPersonActivities, setSearchBySalesPersonActivities,
        selectedSalesPersonActivities, setSelectedSalesPersonActivities
    } = useContext(StateContext);

    // Load own activities on page load
    useEffect(() => {
        (async() => {

            setIsLoading(true);
            
            // DEFAULT CASE
            let defaultSearchterm = searchTermActivities || "";
            let defaultOwn = searchMyOnlyActivities;
            let defaultBusinessPartner = null;
            let defaultSalesPerson = selectedSalesPersonActivities;

            // CASE: User navigated from customer statistics page
            let queryParamCustomerName = query.get("name") ? query.get("name") : null;
            let queryParamId = query.get("id") ? query.get("id") : null;
            let queryParamOwn = query.get("own") ? query.get("own") : null;
            let queryParamMonth = query.get("month") ? query.get("month") : null;
            let queryParamYear = query.get("year") ? query.get("year") : null;
            //let queryParamActive = query.get("active") ? query.get("active") : null;

            // Change default search settings if query parameters are found
            if (queryParamCustomerName) defaultSearchterm = queryParamCustomerName;
            if (queryParamId) defaultBusinessPartner = queryParamId;
            if (queryParamOwn && queryParamOwn === "true") defaultOwn = true;
            if (queryParamOwn && queryParamOwn === "false") defaultOwn = false;
            if (queryParamId) defaultSalesPerson = null;

            // Get users
            await listUsers(true, true);

            const options = {
                own: defaultOwn,
                businesspartner: defaultBusinessPartner,
                project: null,
                top: 500,
                salesPerson: defaultSalesPerson
            }

            let result = await searchActivities(defaultSearchterm, options);
            let data = result && result.data ? result.data : null;
            let resultData = data && data.resultData ? data.resultData : [];

            // Filter by year and by month if query params are found!
            if (resultData.length && queryParamYear && queryParamMonth) {
                let filterByYear = resultData.filter(item => {
                    return item.ContactDate && item.ContactDate.substring(0,4) === queryParamYear;
                });
                let filterByMonth = filterByYear.filter(item => {
                    //console.log(item.ContactDate.substring(5,7));
                    return item.ContactDate && item.ContactDate.substring(5,7) === months[queryParamMonth];
                });
                setActivities(filterByMonth);
            }

            // Change state
            setSearchTermActivities(defaultSearchterm);
            setSearchMyOnlyActivities(defaultOwn);
            if (queryParamId) {
                // Clear search by sales person and selected sales person when user navigate from customer statistics page.
                setSearchBySalesPersonActivities(false);
                setSelectedSalesPersonActivities(null);
            }
            if (queryParamCustomerName) setCustomerName(queryParamCustomerName); // Visible only when user navigated from customer statistics page
            setIsLoading(false);

        })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const filteredActivitiesByActivityType = activities.filter((activity) => {
        //if (!showStatusChanges) return activity.Type !== 5;
        return activity;
    });

    const filteredActivities = filteredActivitiesByActivityType.filter(activity => {
        let matchTitle = activity.Title ? activity.Title.toLowerCase().indexOf(filterSearchResults.toLowerCase()) !== -1 : false;
        let matchContactDate = activity && activity.ContactDate ? new Date(activity.ContactDate).toLocaleDateString("fi-FI").indexOf(filterSearchResults.toLowerCase()) !== -1 : false;
        let matchProjectYes = activity && activity.Project && filterSearchResults.toLowerCase() === 'yes' ? true : false;
        let matchProjectNo = activity && !activity.Project && filterSearchResults.toLowerCase() === 'no' ? true : false;
        let matchBusinessPartnerName = activity && activity.BusinessPartnerName ? activity.BusinessPartnerName.toLowerCase().indexOf(filterSearchResults.toLowerCase()) !== -1 : false;
        let matchBusinessUnitName = activity && activity.BusinessUnitName ? activity.BusinessUnitName.toLowerCase().indexOf(filterSearchResults.toLowerCase()) !== -1 : false;
        let matchObjective = activity && activity.Objective ? activity.Objective.toLowerCase().indexOf(filterSearchResults.toLowerCase()) !== -1 : false;
        if (matchTitle || matchContactDate || matchProjectYes || matchProjectNo || matchBusinessPartnerName || matchBusinessUnitName || matchObjective) return true;
        return false;
    });

    return (
        <div className="activities">

            <Loading active={ isLoading } text={t('general.loading')} />

            <PageTitle
                active
                title={t('activity.activities')}
                button={
                    <NewButton
                        mini
                        className="bb-lblue"
                        onClick={(ev) => { ev.preventDefault(); history.push('/activities/new'); }}
                    />
                }
            />

            <SearchAllActivities
                searchTerm={searchTermActivities}
                setSearchTerm={setSearchTermActivities}
                searchMyOnlyActivities={searchMyOnlyActivities}
                searchBySalesPersonActivities={searchBySalesPersonActivities}
                selectedSalesPerson={selectedSalesPersonActivities}
                searchActivities={searchActivities}
                setIsLoading={setIsLoading}
                filters={
                    <>

                        {/** if user navigates from the customer statistics page, show additional info */}
                        <Message info size='mini' hidden={customerName ? false : true}>
                            <p>{t('activity.searchedCustomerByName') + " '" + customerName + "'"} </p>
                        </Message>

                        <Form.Field
                            control={Checkbox}
                            label={t('activity.myActivitiesOnly')}
                            placeholder={t('activity.myActivitiesOnly')}
                            checked={searchMyOnlyActivities}
                            onChange={(ev, {checked}) => {
                                setActivities([]);
                                setSearchMyOnlyActivities(checked);
                                setSearchBySalesPersonActivities(false);
                            }}
                        />

                        <Form.Field
                            control={Checkbox}
                            label={t('activity.filterActivitiesBySalesPerson')}
                            placeholder={t('activity.filterActivitiesBySalesPerson')}
                            checked={searchBySalesPersonActivities}
                            onChange={(ev, {checked}) => {
                                setActivities([]);
                                setSearchMyOnlyActivities(false);
                                setSearchBySalesPersonActivities(checked);
                            }}
                        />

                        {/* This filtering is done only in the front 
                        <Form.Field
                            control={Checkbox}
                            label={t('activity.showStatusChanges')}
                            placeholder={t('activity.showStatusChanges')}
                            checked={showStatusChanges}
                            onChange={(ev, {checked}) => setShowStatusChanges(checked) }
                        />*/}

                        {/* Sales persons (users) */}
                        {searchBySalesPersonActivities && 
                            <Container className="fieldcontent" style={{ marginTop: "20px" }}>
                                <Form.Field>
                                    <b>{t('general.salesPerson')}</b>
                                </Form.Field>
                                <Dropdown
                                    placeholder={t('general.salesPerson')}
                                    fluid
                                    search
                                    selection
                                    value={selectedSalesPersonActivities}
                                    options={users.map(item => {
                                        return {
                                            key: item.Id,
                                            value: item.Id,
                                            text: item.Name ? item.Name : "<Missing name>"
                                        }
                                    })}
                                    onChange={(ev, {value}) => {
                                        setSelectedSalesPersonActivities(value);
                                    }}
                                />
                            </Container>
                        }

                    </>
                }
            />

            {/* Page size */}
            <Container className="fieldcontent" textAlign="right">
                <Form.Field>
                    <b>{t('general.pageSize')}</b>
                </Form.Field>
                <Dropdown
                    selection
                    value={itemsPerPage}
                    options={[
                        { key: 0, text: 5, value: 5 },
                        { key: 1, text: 10, value: 10 },
                        { key: 2, text: 25, value: 25 },
                        { key: 3, text: 50, value: 50 }
                    ]}
                    onChange={(ev, {value}) => setItemsPerPage(value) }
                />
            </Container>

            {/* Filter */}
            <Container className="fieldcontent">
                <Input
                    action={{
                        labelPosition: 'left',
                        icon: 'delete',
                        content: t('general.clear'),
                        style: {margin: 0},
                        onClick:(ev) => {
                            ev.preventDefault();
                            setFilterSearchResults('');
                        }
                    }}
                    onChange={(ev, {value} ) => {
                        ev.preventDefault();
                        setFilterSearchResults(value)
                    }}
                    fluid
                    //actionPosition='right'
                    placeholder={t('general.filter')}
                    value={filterSearchResults}
                />
            </Container>

            <PagedTable unstackable celled striped compact='very' pageSize={itemsPerPage}
                header={[
                    //t('general.id'),
                    t('general.title'),
                    t('activity.contactDate'),
                    //t('general.type'),
                    t('activity.projectActivity'),
                    //t('businessPartner.businessPartner'),
                    t('businessPartner.customer'),
                    t('businessPartner.businessUnit'),
                    //t('activity.internalAttendees'),
                    ///t('activity.externalAttendees'),
                    t('activity.objective'),
                    //t('activity.externalNotes'),
                    //t('activity.internalNotes'),
                    //t('general.salesPerson'),
                    //t('general.created'),
                    //t('general.createdBy'),
                    //t('general.modified'),
                    //t('general.modifiedBy'),
                    //t('general.deleted'),
                    t('general.actions')
                ]}
                content={filteredActivities}
                resetPageOnContentChange={true}
                contentKeys={[
                    //{key: 'Id' },
                    {key: 'Title', target: '/activities/view/', type: 'link', targetKey: 'Id'},
                    {key: 'ContactDate', type: 'date'},
                    //{key: 'Type'},
                    {key: 'Project', type: 'boolean'},
                    //{key: 'BusinessPartner' },
                    {key: 'BusinessPartnerName' },
                    //{key: 'BusinessUnit' },
                    {key: 'BusinessUnitName' },
                    //{key: 'InternalAttendee' },
                    //{key: 'ExternalAttendee' },
                    {key: 'Objective' },
                    //{key: 'ExternalNotes' },
                    //{key: 'InternalNotes' },
                    //{key: 'SalesPerson' },
                    //{key: 'Created', type: 'date'},
                    //{key: 'CreatedBy'},
                    //{key: 'Modified', type: 'date'},
                    //{key: 'ModifiedBy'},
                    //{key: 'Deleted', type: 'boolean'}
                    {key: 'Id', target: '/activities/edit/', type: 'link', text: t('general.edit'), targetKey: 'Id'}
                ]}
            />

            {/* Buttons */}
            <Container className='button-container'>
                <CancelButton title={t('general.back')} onClick={(ev) => { ev.preventDefault(); history.goBack(); }}/>
            </Container>

        </div>
    );

}

export default Activities;
