import React, { useState, useEffect, useContext  } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { Form, Container, Message, Grid, Dropdown } from 'semantic-ui-react';
import { Loading } from '../../Components/UI/Loaders';
import { CancelButton, SaveButton } from '../../Components/UI/Buttons';
import { PageTitle } from '../../Components/UI/Content';
import { StateContext } from '../../Application/Context/StateContext';
import useReadActivity from '../../Hooks/Activities/useReadActivity';
import useUpdateActivity from './../../Hooks/Activities/useUpdateActivity';

const ChangeActivityType = (props) => {

    const { t } = useTranslation();
    const history = useHistory();
    const { activityId } = useParams();
    const [ isLoading, setIsLoading ] = useState(false);
    const { activityTypes } = useContext(StateContext);
    const { activity, readActivity } = useReadActivity();
    const { updateActivity } = useUpdateActivity();
    const [ newActivityType, setNewActivityType ] = useState(null);
    const [ activityTypeError, setActivityTypeError ] = useState(false);

    useEffect(() => {
        (async() => {
            setIsLoading(true);
            if (activityId) await readActivity(activityId);
            setIsLoading(false);
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activityId]);

    const onSubmit = async () => {

        setIsLoading(true);

        if (!newActivityType) return setActivityTypeError(true);

        const activityObj = {
            "id": activityId,
            "type": newActivityType,
            "businessUnit": activity.Businessunit
        }

        let result = await updateActivity(activityObj);

        if (result && result.status === 200 && result.data) {
            return history.goBack();
        }

        setIsLoading(false);
    }

    return (
        <Form className="activity" onSubmit={onSubmit}>

            <Loading active={ isLoading } text={t('general.loading')} />

            <PageTitle active title={t('activity.changeActivityType')} />
               
            <Grid stackable>

                {/* Title */}
                <Grid.Row>
                    <Grid.Column width={4}><Form.Field label={t('general.title')} /></Grid.Column>
                    <Grid.Column width={12}><p>{activity.Title}</p></Grid.Column>
                </Grid.Row>

                {/* Activity type */}
                <Grid.Row>
                    <Grid.Column width={4}><Form.Field label={t('general.type')} /></Grid.Column>
                    <Grid.Column width={12}><p>{activity.ActivityTypeName}</p></Grid.Column>
                </Grid.Row>

                {/* New activity type */}
                <Grid.Row>
                    <Grid.Column width={4}>
                        <Form.Field label={t('activity.newActivityType')} />
                    </Grid.Column>
                    <Grid.Column width={12}>
                        <Dropdown
                            required
                            placeholder={t('activity.selectActivityType')}                            
                            selection
                            options={activityTypes
                                .filter(item => {
                                    // Project status change and Sales person change is not allowed!
                                    // Removed also the current type
                                    return item.AllowChange === true && item.Id !== activity.Type;
                                })
                                .map(type => {
                                    return {
                                        key: type.Id,
                                        value: type.Id,
                                        text: type.Name
                                    }
                                })
                            }
                            value={newActivityType}
                            onChange={(ev, data) => {
                                setActivityTypeError(false);
                                setNewActivityType(data.value)
                            }}
                            error={activityTypeError}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>            

            {/* Errors */}
            <Message error visible={!activityId} content={t('errors.missingActivityId')} />

            {/* Buttons */}
            <Container className='button-container'>
                <CancelButton onClick={(ev) => {ev.preventDefault(); history.goBack(); }} />
                <SaveButton
                    className="bb-lblue"
                    icon="save"
                    title={t('general.save')}
                    type="submit"
                />
            </Container>

        </Form>
    );

}

export default ChangeActivityType;
