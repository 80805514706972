import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Grid, Button, Icon, Popup, Input, Segment, List, Header, Accordion, Form } from 'semantic-ui-react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { SearchButton, DeleteButton } from './Buttons';
import Utils from '../../Utils/utils';

/** Page title
 * @param {string} title - Page title
 * @param {boolean} active - hr color is changd when active
 * @param {object} button - Button component
 */
const PageTitle = ({ title, active, button }) => {

    const { t } = useTranslation();

    return (
        <Container className="page-title">
            <div className="title">
                <h1>
                    <Grid columns="2">                    
                        <Grid.Column textAlign="left">{title || t('general.title')}</Grid.Column>
                        <Grid.Column textAlign="right">{button}</Grid.Column>
                    </Grid>
                </h1>
                <hr className={active ? "blue" : ""} />
            </div>
        </Container>
    );

}

/** Copy value to clipboard */
const ClipboardCopy = (props) => {
    
    const { t } = useTranslation();
    const [ open, setOpen ] = useState(false); 

    const [ message, setMessage ] = useState(t('general.copied')); 
    const [ borderColor, setBorderColor ] = useState('#21ba45'); 

    const onCopy = (value) => {
        
        console.log(value);

        // If value is returned, the cvopy was succesfull
        if (value) {
            setMessage(t('general.copied'));
            setBorderColor('#21ba45');
        }

        // If value is null, copy was not succesfull
        if (!value) {
            setMessage(t('warnings.emptyValuesCantBeCopied'));
            setBorderColor('#db2828');
        }

        setOpen(true);

    }

    // Empty span is needed for the trigger to show the popup. Popup didnt work if trigger was CopyToClipBoard component
    return (
        <>
            <Popup
                content={message}
                pinned
                onClose={() => setOpen(false)}
                hideOnScroll
                style={{borderColor: borderColor}}
                trigger={<span></span>}
                open={open}
            />
            <CopyToClipboard className='no-print' text={props.value} onCopy={(val) => onCopy(val)} {...props}>
                <Button size='tiny' icon><Icon name='copy outline'/>{t('general.copy')}</Button>
            </CopyToClipboard>
        </>
    )

}

/** Search items box component
 * @param {String} title - title of the search component - "Search contacts"
 * @param {String} placeholder - placeholder helptext - "Search contacts by firstname"
 * @param {Function} searchItems - Search items function. f.e. reach hook "searchContacts"
 * @param {String} defaultSearchTerm - React state value which is added to the searchField
 * @param {Function} setIsLoading - React setState function for updating loading state
 * @param {Function} setError - React setState function for settings fetch error - OPTIONAL
 * @param {Boolean} visible - show or hide component
 * @param {Object} filters - additional components f.e. filter checkbox 
 * @param {Object} children - additional components f.e. selection list */
 const SearchItems = ({
     title,
     placeholder,
     searchItems,
     defaultSearchTerm,
     //setIsLoading,
     setError,
     visible = true,
     collapsed = false,
     filters,
     children,
     className = "fieldcontent"
}) => {

    const { t } = useTranslation();
    const [ searchTerm, setSearchTerm ] = useState("");
    const [ activeIndex, setActiveIndex ] = useState(collapsed ? -1 : 0);

    useEffect(() => { setSearchTerm(defaultSearchTerm || "") }, [defaultSearchTerm]);

    /* Search items when user clicks search */
    const onClickSearch = async (searchTerm) => {
        try {
            //setIsLoading(true);
            await searchItems(searchTerm);
            //setIsLoading(false);
        } catch (error) {
            //setIsLoading(false);
            if (setError) setError(error);
        }
    }

    if (!visible) return null;

    return (       
        <Container className={className}>
            <Accordion>
                
                <Accordion.Title
                    active={activeIndex === 0}
                    index={0}
                    onClick={() => { setActiveIndex(activeIndex === 0 ? -1 : 0); }}
                    style={{paddingBottom: 0}}
                >
                    <Header as='h3' attached='top' className="search-items-header">
                        <Grid>
                            <Grid.Column width="8" textAlign="left">
                                <b>{title || t('general.search')}</b>
                            </Grid.Column>
                            <Grid.Column  width="8" textAlign="right">
                                <Icon name='dropdown' />
                            </Grid.Column>
                        </Grid>
                    </Header>
                </Accordion.Title>
                
                <Accordion.Content active={activeIndex === 0} style={{paddingTop: 0}}>
                    <Segment attached style={{ borderRadius: "0 0 5px 5px" }}>

                        <Input
                            fluid
                            icon='search'
                            placeholder={placeholder ||  t('general.search')}
                            value={searchTerm}
                            onKeyPress={(ev, data) => {
                                if (ev.key === 'Enter') {
                                    ev.preventDefault();
                                    onClickSearch(searchTerm);
                                }
                            }}
                            onChange={(ev, {value}) => {
                                setSearchTerm(value);
                                //onClickSearch(value);
                            }}
                            className="fieldcontent"
                        />

                        {/* Additional filters */}
                        <Container>
                            {filters}
                        </Container>

                        {/* Search items button */}
                        <Container textAlign="right">
                            <SearchButton className="bb-lblue" type="reset" onClick={(ev) => { onClickSearch(searchTerm); }} />
                        </Container>

                        {/* Additional content */}
                        <Container>
                            {children}
                        </Container>

                    </Segment>
                </Accordion.Content>

            </Accordion>
        </Container>
    );

}

/** Show selected item without accordion 
 * @param {string} title - Component title
 * @param {object} item - Selected item object
 * @param {Boolean} required - is the content required or not
 * @param {boolean} isValidSelectedItem - if false - error is shown
 * @param {Object} children - additional components f.e. filter checkbox
 * @param {Function} onRemoveSelection - Function which is executed when delete button is clicked */
const SelectedItemLight = ({
    title,
    item,
    headers = null,
    fields = ['Title', 'Name'],
    required=false,
    isValidSelectedItem=false,
    children,
    onRemoveSelection=null
}) => {

    const { t } = useTranslation();

    return (
        <Container style={{ marginTop: "10px"}} className={required && !isValidSelectedItem && !item ? "fieldcontent selected-item-error" : "fieldcontent"} >

            <Form.Field label={<b style={{ fontSize: "0.92857143em"}}>{title || t('general.selected')}{required && <span className="error"> *</span>}</b>} style={{ marginBottom: "5px" }} />

            <Segment attached style={{ borderRadius: "5px" }} className={item ? " item-selected" : ""}>
                <Container style={{ fontSize: "16px"}} >
                
                    {!item && <p>{t('errors.itemNotSelected')}</p>}

                    {!fields && <p>{t('errors.missingFieldsProps')}</p>}

                    {item && fields &&
                        <Grid columns={2} >
                            <Grid.Column>
                                <List>
                                    {fields.map((field, index) => {
                                        return <List.Item key={index}>
                                            <>
                                                {headers && <b>{headers[index] + ": "}</b>}
                                                {!headers && typeof field === 'string' && <b>{Utils.capitalizeFirstLetter(field) + ": "}</b>}

                                                {typeof item[field] === 'boolean'
                                                    ? 
                                                        item[field] === true ? t('general.yes') : t('general.no')
                                                    :
                                                        item && field && item[field] && typeof item[field] === 'string' 
                                                        ?
                                                            Utils.capitalizeFirstLetter(item[field])
                                                        : ""
                                                }
                                            </>
                                        </List.Item>
                                    })}
                                </List>
                            </Grid.Column>
                            <Grid.Column textAlign="right">
                                {onRemoveSelection &&
                                    <DeleteButton color={"blue"} mini onClick={onRemoveSelection}/>
                                }
                            </Grid.Column>
                        </Grid>             
                    }
                    
                </Container>
            </Segment>

            {/* Additional content f.e. filters */}
            <Container>
                {children}
            </Container>

        </Container>
    );

}

export {
    PageTitle,
    ClipboardCopy,
    SearchItems,
    SelectedItemLight
};