import { useState } from 'react';
import webApiClient from './../../Utils/webApiClient';
import { msalInstance } from '../../index';
import config from '../../Config/config';
import useRequestHandler from '../useRequestHandler';

/** Hook is used to fetch list of activity types */
const useListActivityTypes = () => {

    const [ activityTypes, setActivityTypes ] = useState([]);
    const { errorHandler, successHandler } = useRequestHandler();
    const apiClient = webApiClient();

    /** List activity types */
    const listActivityTypes = async () => {
        try {

            // Account is set in the index.js
            const account = msalInstance.getActiveAccount();

            const response = await msalInstance.acquireTokenSilent({
                scopes: config.webApi.scopes,
                account: account
            });
            
            const result = await apiClient.list(response.accessToken, "activitytypes");
            
            const data = result.data || {};
            const content = data.resultData || null;

            if (content) setActivityTypes(content);

            return successHandler(result, "useListActivityTypes", "list", { hide: true });

        } catch (error) {

            return errorHandler(error, "useListActivityTypes", "list");

        }
    }

    return { activityTypes, listActivityTypes };

}

export default useListActivityTypes;
