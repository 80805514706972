import React, { useState, useEffect, useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { Form, Container, Message, Input, TextArea } from 'semantic-ui-react';
import { Loading } from '../../Components/UI/Loaders';
import { SubmitButton, CancelButton } from '../../Components/UI/Buttons';
import { PageTitle } from '../../Components/UI/Content';
import { StateContext } from '../../Application/Context/StateContext';
import useReadNotification from '../../Hooks/Notifications/useReadNotification';
import useUpdateNotification from '../../Hooks/Notifications/useUpdateNotification';
import useReadBusinessPartner from '../../Hooks/BusinessPartners/useReadBusinessPartner';
import useReadProject from '../../Hooks/Project/useReadProject';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const EditProjectNotification = (props) => {

    const { t } = useTranslation();
    const history = useHistory();
    const scollToTopRef = useRef();

    const { projectId, notificationId } = useParams();
    const [ isLoading, setIsLoading ] = useState(false);
    const [ notificationIsNotProjectNotificationError, setNotificationIsNotProjectNotificationError ] = useState(false)
    const [ date, setDate ] = useState(new Date());
    const [ title, setTitle ] = useState("");
    const [ description, setDescription ] = useState("");
    const { userProfile } = useContext(StateContext);
    const { notification, readNotification } = useReadNotification();
    const { project, readProject } = useReadProject([]);
    const { businessPartner, readBusinessPartner } = useReadBusinessPartner([]);
    const { updateNotification } = useUpdateNotification();
    const userIsAdmin = userProfile && userProfile.IsAdmin ? true : false;
    const projectHasEnded = project && project.EndDate ? true : false; // If project has end date, project is ended and only admin can edit it!
    const projectIsNotActive = project && project.Status > 7 ? true : false; // if project status is greater than 7 (not active anymore) only admin can edit it!
    const disableEditing = (projectHasEnded || projectIsNotActive) && !userIsAdmin ? true : false;
    
    // Read data
    useEffect(() => {
        (async() => {
            scollToTopRef.current.scrollIntoView();
            setIsLoading(true);
            await readProject(projectId);
            await readNotification(notificationId);
            setIsLoading(false);
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Read additional data
    useEffect(() => {
        if (notification && !notification.Project) {
            setNotificationIsNotProjectNotificationError(true);
        } else {
            setNotificationIsNotProjectNotificationError(false);
            (async() => {
                setIsLoading(true);
                if (notification && notification.Project) {
                    await readProject(notification.Project);
                }
                if (notification && notification.BusinessPartner) {
                    await readBusinessPartner(notification.BusinessPartner);
                }
                setIsLoading(false);
            })();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [notification]);

    // Set notification data
    useEffect(() => {
        if (notification) {
            if (notification.Date) setDate(new Date(notification.Date));
            if (notification.Title) setTitle(notification.Title);
            if (notification.Description) setDescription(notification.Description);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [notification]);

    // Save data on submit
    const onSubmit = async () => {
        try {

            if (!projectId) return null;
            if (!notificationId) return null;

            setIsLoading(true);

            const notificationObj = {
                "id": parseInt(notificationId),
                "date": date ? date.toISOString() : null,
                "title": title.trim(),
                "description": description.trim(),
                "project":  parseInt(projectId),
                //"businessUnit": parseInt(businessUnit)
                //"activity":  null,
                //"businessPartner":  null,
                //"salesPerson": null
            }

            if (project && project.Id) {
                notificationObj.project = project.Id;
            }

            const result = await updateNotification(notificationObj); 

            if (result && result.status && result.status === 200) {
                return history.goBack();
            }

            setIsLoading(false);

        } catch (error) {
            setIsLoading(false);
        }
    }

    return (
        <Form className="notification" onSubmit={(ev) => { ev.preventDefault(); onSubmit(); }}>
                
            <div ref={scollToTopRef}></div>
            
            <Loading active={ isLoading } text={t('general.loading')} />

            <PageTitle active title={t('notification.editProjectNotification')} />

            <Message warning visible={projectHasEnded || projectIsNotActive} content={t('warnings.projectIsNotActive')} />

            <Message warning visible={notificationIsNotProjectNotificationError ? true : false} content={t('errors.notificationIsNotProjectNotification')} />

            {/* Title */}
            <Form.Field
                focus
                required
                label={t('general.title')}
                placeholder={t('general.title')}
                maxLength="255"
                control={Input}
                value={title}
                onChange={(ev, {value}) => setTitle(value) }
                type="Text"
            />

            {/* Description */}
            <Form.Field
                required
                label={t('general.description')}
                placeholder={t('general.description')}
                control={TextArea}
                value={description}
                onChange={(ev, {value}) => setDescription(value) }
                type="Text"
            />

            {/* Date */}
            <Form.Field label={t('notification.sendNotificationDate')} style={{ marginBottom: '0px' }}/>
            <DatePicker
                required
                label={t('notification.sendNotificationDate')}
                selected={date}
                dateFormat={navigator.language === "fi-FI" ? "dd.MM.yyyy" : "yyyy/MM/dd"}
                onChange={(date) => setDate(date)}
            />

            {/* Business partner name */}
            <Container className="fieldcontent" style={{ marginTop: "20px" }}>
                <Form.Field label={t('businessPartner.businessPartner')} />
                <p>{businessPartner.Name}</p>
            </Container>

            {/* Project name */}
            <Container className="fieldcontent">
                <Form.Field label={t('project.project')} />
                <p>{project.Title}</p>
            </Container>

            {/* Errors */}
            <Message error visible={!projectId} content={t('errors.missingProjectId')} />
            <Message error visible={!notificationId} content={t('errors.missingNotificationId')} />

            {/* Buttons */}
            <Container className='button-container'>
                <CancelButton onClick={(ev) => {ev.preventDefault(); history.goBack(); }} />
                <SubmitButton type="submit" disabled={notificationIsNotProjectNotificationError || (disableEditing && !userIsAdmin)} className="bb-lblue" icon="save" title={t('general.save')} />
            </Container>

        </Form>
    );

}

export default EditProjectNotification;
