import React, { useState, useEffect, useContext, useRef  } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { Form, Container, Message, Grid } from 'semantic-ui-react';
import { Loading } from '../../Components/UI/Loaders';
import { DeleteButton, CancelButton } from '../../Components/UI/Buttons';
import { YesNoModal } from '../../Components/UI/Modals';
import { PageTitle, ClipboardCopy } from '../../Components/UI/Content';
import { StateContext } from '../../Application/Context/StateContext';
import useReadContact from '../../Hooks/Contacts/useReadContact';
import useReadProject from '../../Hooks/Project/useReadProject';
import useDeleteProjectContact from '../../Hooks/ProjectContacts/useDeleteProjectContact';

const ViewProjectContact = (props) => {

    const { t } = useTranslation();
    const history = useHistory();
    const { projectId, contactId } = useParams();
    const scollToTopRef = useRef();

    const [ isLoading, setIsLoading ] = useState(false);
    const [ modalIsOpen, setModalIsOpen ] = useState(false);

    const { userProfile } = useContext(StateContext);
    const { contact, readContact} = useReadContact();
    const { project, readProject} = useReadProject();
    const { deleteProjectContact } = useDeleteProjectContact();

    // Read data 
    useEffect(() => {
        (async() => {
            scollToTopRef.current.scrollIntoView();
            setIsLoading(true);
            await readProject(projectId)
            await readContact(contactId);
            setIsLoading(false);
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const userIsAdmin = userProfile && userProfile.IsAdmin ? true : false;
    const projectHasEnded = project && project.EndDate ? true : false; // If project has end date, project is ended and only admin can edit it!
    const projectIsNotActive = project && project.Status > 7 ? true : false; // if project status is greater than 7 (not active anymore) only admin can edit it!
    const disableEditing = (projectHasEnded || projectIsNotActive) && !userIsAdmin ? true : false;
    
    return (
        <Form className="contact" >
                
            <div ref={scollToTopRef}></div>
            
            <Loading active={ isLoading } text={t('general.loading')} />

            <PageTitle
                active
                title={t('contact.viewProjectContact')}
                /*button={
                    <EditButton
                        className="bb-lblue" 
                        mini
                        onClick={(ev) => { ev.preventDefault(); history.push("/contacts/edit/" + contactId); }}
                    />
                }*/
            />

            <Message warning visible={projectHasEnded || projectIsNotActive} content={t('warnings.projectIsNotActive')} />

            {/* Delete project contact modal */}
            <YesNoModal
                type='ready'
                header={t('general.delete')}
                text={t('general.areYouSure')}
                method={async (answer, id) => {
                    setIsLoading(true);
                    if (answer) {
                        await deleteProjectContact(projectId, id);
                        return history.goBack();
                    }
                    setIsLoading(false);
                }}
                item={contactId}
                open={modalIsOpen}
                setOpen={setModalIsOpen}
                noClick={false}
            />

            <Grid stackable>

                {/* Project Title */}
                <Grid.Row>
                    <Grid.Column width={4}><Form.Field label={t('project.project')} /></Grid.Column>
                    <Grid.Column width={12}><p>{project.Title}</p></Grid.Column>
                </Grid.Row>

                {/* Id
                <Grid.Row>
                    <Grid.Column width={4}><Form.Field label={t('general.id')} /></Grid.Column>
                    <Grid.Column width={12}><p>{contact.Id}</p></Grid.Column>
                </Grid.Row>
                */}

                {/* Name */}
                <Grid.Row>
                    <Grid.Column width={4}><Form.Field label={t('general.name')} /></Grid.Column>
                    <Grid.Column width={12}><p>{contact.Name}</p></Grid.Column>
                </Grid.Row>

                {/* Firstname */}
                <Grid.Row>
                    <Grid.Column width={4}><Form.Field label={t('general.firstName')} /></Grid.Column>
                    <Grid.Column width={12}><p>{contact.FirstName}</p></Grid.Column>
                </Grid.Row>

                {/* Lastname */}
                <Grid.Row>
                    <Grid.Column width={4}><Form.Field label={t('general.lastName')} /></Grid.Column>
                    <Grid.Column width={12}><p>{contact.LastName}</p></Grid.Column>
                </Grid.Row>

                {/* Position */}
                <Grid.Row>
                    <Grid.Column width={4}><Form.Field label={t('general.position')} /></Grid.Column>
                    <Grid.Column width={12}><p>{contact.Position}</p></Grid.Column>
                </Grid.Row>

                {/* Phone 1 */}
                <Grid.Row>
                    <Grid.Column width={4}><Form.Field label={t('general.phone') + " 1"} /></Grid.Column>
                    <Grid.Column width={10}><p>
                        {contact.PhoneNumber1 && 
                            
                            <a href={"tel:" + contact.PhoneNumber1}>{contact.PhoneNumber1}</a>
                        }
                    </p></Grid.Column>
                    <Grid.Column width={2}><p>
                        <ClipboardCopy value={contact.PhoneNumber1} />
                    </p></Grid.Column>
                </Grid.Row>

                {/* Phone 2 */}
                <Grid.Row>
                    <Grid.Column width={4}><Form.Field label={t('general.phone') + " 2"} /></Grid.Column>
                    <Grid.Column width={10}><p>
                        {contact.PhoneNumber2 && 
                            <a href={"tel:" + contact.PhoneNumber2}>{contact.PhoneNumber2}</a>
                        }
                    </p></Grid.Column>
                    <Grid.Column width={2}><p>
                        <ClipboardCopy value={contact.PhoneNumber2} />
                    </p></Grid.Column>
                </Grid.Row>

                {/* Email */}
                <Grid.Row>
                    <Grid.Column width={4}><Form.Field label={t('general.email')} /></Grid.Column>
                    <Grid.Column width={10}><p>
                        {contact.EmailAddress && 
                            <a href={"mailto:" + contact.EmailAddress}>{contact.EmailAddress}</a>
                        }
                    </p></Grid.Column>
                    <Grid.Column width={2}><p>
                        <ClipboardCopy value={contact.EmailAddress} />
                    </p></Grid.Column>
                </Grid.Row>

                {/* Business partner 
                <Grid.Row>
                    <Grid.Column width={4}><Form.Field label={t('businessPartner.businessPartner')} /></Grid.Column>
                    <Grid.Column width={12}><p>{contact.BusinessPartner}</p></Grid.Column>
                </Grid.Row>
                */}

                {/* Business partner name */}
                <Grid.Row>
                    <Grid.Column width={4}><Form.Field label={t('businessPartner.businessPartner')} /></Grid.Column>
                    <Grid.Column width={12}><p>{contact.BusinessPartnerName}</p></Grid.Column>
                </Grid.Row>

                {/* Created */}
                <Grid.Row>
                    <Grid.Column width={4}><Form.Field label={t('general.created')} /></Grid.Column>
                    <Grid.Column width={12}><p>{contact.Created ? new Date(contact.Created).toLocaleDateString("fi-FI") : ""}</p></Grid.Column>
                </Grid.Row>

                {/* Created By id
                <Grid.Row>
                    <Grid.Column width={4}><Form.Field label={t('general.createdBy')} /></Grid.Column>
                    <Grid.Column width={12}><p>{contact.CreatedBy}</p></Grid.Column>
                </Grid.Row>
                */}

                {/* Modified */}
                <Grid.Row>
                    <Grid.Column width={4}><Form.Field label={t('general.modified')} /></Grid.Column>
                    <Grid.Column width={12}><p>{contact.Modified ?  new Date(contact.Modified).toLocaleDateString("fi-FI") : ""}</p></Grid.Column>
                </Grid.Row>

                {/* Modified By id 
                <Grid.Row>
                    <Grid.Column width={4}><Form.Field label={t('general.modifiedBy')} /></Grid.Column>
                    <Grid.Column width={12}><p>{contact.ModifiedBy}</p></Grid.Column>
                </Grid.Row>
                */}

            </Grid>

            {/* Errors */}
            <Message error visible={!projectId} content={t('errors.missingProjectId')} />
            <Message error visible={!contactId} content={t('errors.missingContactId')} />

            {/* Buttons */}
            <Container className='button-container'>
                <CancelButton onClick={(ev) => {ev.preventDefault(); history.goBack(); }} />
                <div>
                    {/*<EditButton
                        disabled={disableEditing}
                        className="bb-lblue"
                        icon="save"
                        title={t('general.edit')}
                        onClick={(ev) => { ev.preventDefault(); history.push("/contacts/edit/" + contactId); }}
                    />*/}                    
                    <DeleteButton title={t('project.removeProjectContact')} color="red" disabled={disableEditing} onClick={() => setModalIsOpen(true) }/>
                </div>
            </Container>

        </Form>
    );

}

export default ViewProjectContact;
