import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link} from 'react-router-dom';
import { Table } from 'semantic-ui-react';
import { Loading } from '../../Components/UI/Loaders';
import { PageTitle } from '../../Components/UI/Content';
import SearchAndSelectCustomer from '../../Components/BusinessPartners/SearchAndSelectCustomer';
import useReadBusinessPartnerStatistics from './../../Hooks/BusinessPartnerStatistics/useReadBusinessPartnerStatistics';
import { StateContext } from '../../Application/Context/StateContext';

const BusinessPartner = (props) => {

    const { t } = useTranslation();
    const [ isLoading, setIsLoading ] = useState(false);
    const { businessPartnerStatistics, readBusinessPartnerStatistics } = useReadBusinessPartnerStatistics();
    const { selectedCustomer, setSelectedCustomer } = useContext(StateContext); // Business partner object
    const { myOwnCustomersOnly, setMyOwnCustomersOnly } = useContext(StateContext);
    const { activeCustomersOnly, setActiveCustomersOnly  } = useContext(StateContext);
    // Here active means ACTIVE or ALL projects (not active vs passive!)

    return (
        <div className="businessPartners">

            <PageTitle title={t('businessPartner.summaryByCustomer')} />

            <Loading active={ isLoading } text={t('general.loading')} />

            <SearchAndSelectCustomer 

                setIsLoading={setIsLoading}

                selectedCustomer={selectedCustomer}
                setSelectedCustomer={setSelectedCustomer}

                myOwnCustomersOnly={myOwnCustomersOnly}
                setMyOwnCustomersOnly={setMyOwnCustomersOnly}

                activeCustomersOnly={activeCustomersOnly}
                setActiveCustomersOnly={setActiveCustomersOnly}

                businessPartnerStatistics={businessPartnerStatistics}
                readBusinessPartnerStatistics={readBusinessPartnerStatistics}

            />

            {selectedCustomer && 
                <>

                    <h2>{t('businessPartner.projectStatistics')}</h2>
                    {businessPartnerStatistics && businessPartnerStatistics.projectStatistics && businessPartnerStatistics.projectStatistics.ProjectsbyStatus && selectedCustomer &&
                        <Table fixed stackable>
                            <Table.Body>
                                {businessPartnerStatistics.projectStatistics.ProjectsbyStatus.map((item, index) => {
                                    return <Table.Row key={index}>
                                        <Table.Cell>{item.Name}</Table.Cell>
                                        <Table.Cell>{item.Count}</Table.Cell>
                                        <Table.Cell textAlign="right">{
                                            <Link to={`projects?name=${selectedCustomer.Name}&id=${selectedCustomer.Id}&status=${item.Status}&statusname=${item.Name}&own=${myOwnCustomersOnly}&active=${activeCustomersOnly}`}>{t('general.list')}</Link>
                                        }</Table.Cell>
                                    </Table.Row>
                                })}
                            </Table.Body>
                            <Table.Footer>
                                <Table.Row>
                                    <Table.HeaderCell><b>{t('general.total')}</b></Table.HeaderCell>
                                    <Table.HeaderCell><b>{businessPartnerStatistics.projectStatistics.Total}</b></Table.HeaderCell>
                                    <Table.HeaderCell textAlign="right">
                                        <b>
                                            {/* STATUS is 0 if we status filter is not meant to be set. Else 1-12 */}
                                            <Link to={`projects?name=${selectedCustomer.Name}&id=${selectedCustomer.Id}&status=0&own=${myOwnCustomersOnly}&active=${activeCustomersOnly}`}>{t('general.list')}</Link>
                                        </b>
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Footer>
                        </Table>
                    }

                    <h2>{t('businessPartner.activityStatistics')}</h2>
                    {businessPartnerStatistics && businessPartnerStatistics.activityStatistics && businessPartnerStatistics.activityStatistics.Activities && selectedCustomer &&
                        <Table fixed stackable>
                            <Table.Body>
                                {businessPartnerStatistics.activityStatistics.Activities.map((item, index) => {
                                    return <Table.Row key={index}>
                                        <Table.Cell>{item.Month} {item.Year}</Table.Cell>
                                        <Table.Cell>{item.Count}</Table.Cell>
                                        <Table.Cell textAlign="right">
                                            <Link to={`activities?name=${selectedCustomer.Name}&id=${selectedCustomer.Id}&month=${item.Month}&year=${item.Year}&own=${myOwnCustomersOnly}&active=${activeCustomersOnly}`}>{t('general.list')}</Link>
                                        </Table.Cell>
                                    </Table.Row>
                                })}
                            </Table.Body>
                            <Table.Footer>
                                <Table.Row>
                                    <Table.HeaderCell><b>{t('activity.totalIn12Months')}</b></Table.HeaderCell>
                                    <Table.HeaderCell><b>{businessPartnerStatistics.activityStatistics.TotalIn12Months}</b></Table.HeaderCell>
                                    <Table.HeaderCell textAlign="right">
                                        <b>
                                            <Link to={`activities?name=${selectedCustomer.Name}&id=${selectedCustomer.Id}&own=${myOwnCustomersOnly}&active=${activeCustomersOnly}`}>{t('general.list')}</Link>
                                        </b>
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Footer>
                        </Table>
                    }
                    
                    <h2>{t('businessPartner.contactStatistics')}</h2>
                    {businessPartnerStatistics && businessPartnerStatistics.contactStatistics && selectedCustomer &&
                        
                        <Table fixed stackable>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>{t('general.contacts')}</Table.HeaderCell>
                                    <Table.HeaderCell><b>{businessPartnerStatistics.contactStatistics[0] ? businessPartnerStatistics.contactStatistics[0].Contacts : ""}</b></Table.HeaderCell>
                                    <Table.HeaderCell textAlign="right">
                                        <Link to={`contacts?name=${selectedCustomer.Name}&id=${selectedCustomer.Id}&own=${myOwnCustomersOnly}&active=${activeCustomersOnly}`}>{t('general.list')}</Link>
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                        </Table>
                    }
                
                </>
            }

            {/* Buttons 
            <Container className='button-container'>
                <CancelButton style={{ marginLeft: 'right'}} title={t('landing.toLandingPage')} onClick={(ev) => {ev.preventDefault(); history.goBack(); }} />
            </Container>*/}

        </div>
    );

}

export default BusinessPartner;
