import React, { useState, useEffect,useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Container, Input} from 'semantic-ui-react';
import PagedTable from '../../../Components/UI/Lists';
import { PageTitle, SearchItems } from '../../../Components/UI/Content';
import { YesNoModal } from '../../../Components/UI/Modals';
import { Loading } from '../../../Components/UI/Loaders';
import { CancelButton } from '../../../Components/UI/Buttons';
import Forbidden from '../../System/Forbidden';
import useSearchContacts from '../../../Hooks/Search/useSearchContacts';
import useDeleteContact from '../../../Hooks/Contacts/useDeleteContact';
import { StateContext } from '../../../Application/Context/StateContext';

const ManageContacts = (props) => {

    const { t } = useTranslation();
    const history = useHistory();
    const [ contactId, setContactId ] = useState(null);
    const [ deleteModalIsOpen, setDeleteModalIsOpen ] = useState(false);
    const [ isLoading, setIsLoading ] = useState(false);
    const [ filterContactList, setFilterContactList ] = useState('');
    const { contacts, setContacts, searchContacts } = useSearchContacts();
    const { deleteContact } = useDeleteContact();

    useEffect(() => {
        (async() => {
            setIsLoading(true);
            await searchContacts("");
            setIsLoading(false);
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);    

    const filteredContacts = contacts.filter(contact => {
        let matchName = contact.Name ? contact.Name.toLowerCase().indexOf(filterContactList.toLowerCase()) !== -1 : false;
        let matchEmailAddress = contact && contact.EmailAddress ? contact.EmailAddress.toLowerCase().indexOf(filterContactList.toLowerCase()) !== -1 : false;
        let matchPhoneNumber1 = contact && contact.PhoneNumber1 ? contact.PhoneNumber1.toLowerCase().indexOf(filterContactList.toLowerCase()) !== -1 : false;
        if (matchName || matchEmailAddress || matchPhoneNumber1) return true;
        return false;
    });


    const { userProfile } = useContext(StateContext);
    const isAdmin = userProfile && userProfile.IsAdmin ? userProfile.IsAdmin : false;

    if (!isAdmin) return <Forbidden />;


    return (
        <div className="management">

            <PageTitle active title={t('management.manageContacts')} />
            <Loading active={ isLoading } text={t('general.loading')} />

            <YesNoModal
                type='ready'
                header={t('general.delete')}
                text={t('general.areYouSure')}
                method={async (answer) => {
                    setIsLoading(true);
                    if (answer && contactId) {
                        const res = await deleteContact(contactId);
                        if (res && res.status && res.status === 200) {
                            const temp = contacts.filter(item => item.Id !== contactId);
                            setContacts(temp);
                        }                               
                    }
                    setIsLoading(false);
                }}
                item={contactId}
                open={deleteModalIsOpen}
                setOpen={setDeleteModalIsOpen}
                noClick={false}
            />

            <SearchItems
                title={t('general.search')}
                placeholder={t('contact.searchContactsByName')}
                searchItems={async (searchTerm) => {
                    setIsLoading(true);
                    let result = await searchContacts(searchTerm);
                    if (!result.requestCancelled) setIsLoading(false);
                }}
                defaultSearchTerm={''}
                setIsLoading={setIsLoading}
            />
            
            <Container className="fieldcontent">
                <Input
                    action={{
                        labelPosition: 'left',
                        icon: 'delete',
                        content: t('general.clear'),
                        style: {margin: 0},
                        onClick:(ev) => {
                            ev.preventDefault();
                            setFilterContactList('');
                        }
                    }}
                    onChange={(ev, {value} ) => {
                        ev.preventDefault();
                        setFilterContactList(value)
                    }}
                    fluid
                    //actionPosition='right'
                    placeholder={t('general.filter')}
                    value={filterContactList}
                />
            </Container>

            <Container className="fieldcontent">
                <PagedTable unstackable celled striped compact='very' pageSize={20}
                    header={[
                        t('general.name'),
                        t('general.email'),
                        t('general.phone') + " 1",
                        t('businessPartner.customer'),
                        t('general.actions'),
                        '',
                        ''
                        //''
                    ]}
                    content={filteredContacts}
                    resetPageOnContentChange={true}
                    contentKeys={[
                        {key: 'Name'},
                        //{key: 'Name', target: '/contacts/view/', type: 'link', targetKey: 'Id'},
                        {key: 'EmailAddress'},
                        {key: 'PhoneNumber1'},
                        {key: 'BusinessPartnerName'},
                        {key: 'Id', target: '/contacts/view/', type: 'link', text: t('general.view'), targetKey: 'Id'},
                        {key: 'Id', target: '/contacts/edit/', type: 'link', text: t('general.edit'), targetKey: 'Id'},
                        {key: 'Id', type: 'action', text: t('general.delete'), action: (ev, row, cellKey) => { 
                            setContactId(row.Id);
                            setDeleteModalIsOpen(true);
                        }}
                    ]}
                />
            </Container>

            <Container  className='button-container'>
                <CancelButton title={t('general.back')} onClick={(ev) => { history.goBack(); }} />
            </Container>

        </div>
    );

}

export default ManageContacts;
