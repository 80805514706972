import React, { useState, useEffect, useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { Form, Container, Grid } from 'semantic-ui-react';
import { Loading } from '../../../Components/UI/Loaders';
import { EditButton, CancelButton } from '../../../Components/UI/Buttons';
import { PageTitle } from '../../../Components/UI/Content';
import Forbidden from '../../System/Forbidden';
import { StateContext } from '../../../Application/Context/StateContext';
import useReadParameter from '../../../Hooks/Parameters/useReadParameter';

const ViewParameter = (props) => {

    const { t } = useTranslation();
    const history = useHistory();
    const { parameterId } = useParams();
    const scollToTopRef = useRef();

    const [ isLoading, setIsLoading ] = useState(true);
    const { userProfile } = useContext(StateContext);
    const { parameter, readParameter } = useReadParameter();

    // Read parameter
    useEffect(() => {
        (async() => {
            if (scollToTopRef && scollToTopRef.current) scollToTopRef.current.scrollIntoView();
            setIsLoading(true);
            await readParameter(parameterId);
            setIsLoading(false);
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const isAdmin = userProfile && userProfile.IsAdmin ? userProfile.IsAdmin : false;

    if (!isAdmin) return <Forbidden />;

    return (
        <Form className="parameter" >
                
            <div ref={scollToTopRef}></div>
            
            <Loading active={ isLoading } text={t('general.loading')} />

            <PageTitle
                active
                title={t('management.viewParameter')}
                button={
                    <EditButton
                        mini
                        className="bb-lblue"
                        onClick={(ev) => { ev.preventDefault(); history.push("/management/parameters/edit/" + parameterId); }}
                    />
                }
            />

            <Grid stackable>

                {/* Id  */}
                <Grid.Row>
                    <Grid.Column width={4}><Form.Field label={t('general.id')} /></Grid.Column>
                    <Grid.Column width={12}><p>{parameter.Id}</p></Grid.Column>
                </Grid.Row>

                {/* PKey */}
                <Grid.Row>
                    <Grid.Column width={4}><Form.Field label={t('general.key')} /></Grid.Column>
                    <Grid.Column width={12}><p>{parameter.PKey}</p></Grid.Column>
                </Grid.Row>

                {/* PValue */}
                <Grid.Row>
                    <Grid.Column width={4}><Form.Field label={t('general.value')} /></Grid.Column>
                    <Grid.Column width={12}><p>{parameter.PValue}</p></Grid.Column>
                </Grid.Row>

                {/* Description */}
                <Grid.Row>
                    <Grid.Column width={4}><Form.Field label={t('general.description')} /></Grid.Column>
                    <Grid.Column width={12}><p>{parameter.Description}</p></Grid.Column>
                </Grid.Row>

            </Grid>

            {/* Buttons */}
            <Container className='button-container'>
                <CancelButton onClick={(ev) => { history.goBack(); }} />
                <EditButton
                    className="bb-lblue"
                    icon="save"
                    title={t('general.edit')}
                    onClick={(ev) => { ev.preventDefault(); history.push("/management/parameters/edit/" + parameterId); }}
                />
            </Container>

        </Form>
    );

}

export default ViewParameter;
