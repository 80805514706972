import { useState } from 'react';
import webApiClient from './../../Utils/webApiClient';
import { msalInstance } from '../../index';
import config from '../../Config/config';
import useRequestHandler from '../useRequestHandler';

/** Hook is used to read notification */
const useReadNotification = () => {

    const { errorHandler, successHandler } = useRequestHandler();
    const apiClient = webApiClient();
    const [ notification, setNotification ] = useState({});
    
    /** Read notification by id
     * @param {integer} id - id of the notification entity */
    const readNotification = async (notificationId) => {
        try {

            // Account is set in the index.js
            const account = msalInstance.getActiveAccount();

            const response = await msalInstance.acquireTokenSilent({
                scopes: config.webApi.scopes,
                account: account
            });
            
            const result = await apiClient.read(response.accessToken, "/notifications/", notificationId.toString());  

            const data = result && result.data ? result.data : null;
            const resultData = data.resultData || null;
            const content = resultData[0] || null;

            if (content) setNotification(content);

            return successHandler(result, "useReadNotification", "read", { hide: true });

        } catch (error) {
            return errorHandler(error, "useReadNotification", "read");
        }
    }

    return { notification, readNotification };

}

export default useReadNotification;
