//import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import webApiClient from '../../Utils/webApiClient';
import { msalInstance } from '../../index';
import config from '../../Config/config';
import useRequestHandler from '../useRequestHandler';

/** Hook is used to create new project attachment entity */
const useCreateProjectAttachement = () => {
    
    const { t } = useTranslation();
    const { errorHandler, successHandler } = useRequestHandler();
    const apiClient = webApiClient();

    /** Create new project attachment
     * @param {boolean} projectId - Project id
     * @param {object} object - Object which describes the attachment entity
     * @example object
     * {
     *      "file": { }
     *      "title": "",
     *      "description": "",
     *      "fileName": "",
     *      "fileType": "",
     *      "project": 1,
     *      "activity": 1,
     *      "fileSizeKB": 5.5,
     *      "pathParameter": 1
     * }
     **/
    const createProjectAttachement = async (projectId, file, metadata) => {
        try {

            // Account is set in the index.js
            const account = msalInstance.getActiveAccount();

            const response = await msalInstance.acquireTokenSilent({
                scopes: config.webApi.scopes,
                account: account
            });

            // Create resource path
            let resource = "projects/" + projectId.toString() + "/attachments/";

            const data = new FormData();

            data.append("file", file, encodeURIComponent(file.name));
            data.append("title", metadata.title || ""); 
            data.append("description", metadata.description || ""); 
            data.append("project", metadata.project || null); 
            data.append("activity", metadata.activity || null); 
            data.append("pathParameter", metadata.pathParameter || null); 

            const options = { 
                contentType: { "Content-Type": "multipart/form-data" }
            }

            const result = await apiClient.create(response.accessToken, resource, data, options);

            return successHandler(result, "useCreateProjectAttachement", "create", { message: t('success.create') });

        } catch (error) {
            return errorHandler(error, "useCreateProjectAttachement", "create");
        }
    }

    return { createProjectAttachement };

}

export default useCreateProjectAttachement;
