import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Form, Container, Checkbox, Input } from 'semantic-ui-react';
import { NewButton, CancelButton } from '../../Components/UI/Buttons';
import { PageTitle } from '../../Components/UI/Content';
import { Loading } from '../../Components/UI/Loaders';
import PagedTable from '../../Components/UI/Lists';
import useSearchNotification from '../../Hooks/Search/useSearchNotifications';
import { StateContext } from '../../Application/Context/StateContext';
import { Accordion, Header, Grid, Icon, Segment } from 'semantic-ui-react';
import { SearchButton } from '../../Components/UI/Buttons';

/** Search items box component
 * @param {String} searchTerm - React state value which is added to the searchField
 * @param {Function} setSearchTerm - React setState function for settings searchField value
 * @param {Function} searchNotifications - Search notifications function. f.e. reach hook "searchNotifications" 
 * @param {Function} setIsLoading - React setState function for updating loading state
 * @param {Boolean} upcomingNotificationsOnly - Search only upcoming notifications
 * @param {Object} filters - additional components f.e. filter checkbox  */
const SearchAllNotifications = ({
    setIsLoading,
    searchTerm,
    setSearchTerm,
    searchNotifications,
    upcomingNotificationsOnly,
    filters
}) => {

   const { t } = useTranslation();
   const [ activeIndex, setActiveIndex ] = useState(0);

   /* Search items when user clicks search */
   const onClickSearch = async (searchTerm) => {
        setIsLoading(true);
        let result = await searchNotifications(searchTerm, upcomingNotificationsOnly);
        if (!result.requestCancelled) setIsLoading(false);
   }

   return (       
       <Container className={"fieldcontent"}>
           <Accordion>
               
               <Accordion.Title
                   active={activeIndex === 0}
                   index={0}
                   onClick={() => { setActiveIndex(activeIndex === 0 ? -1 : 0); }}
                   style={{paddingBottom: 0}}
               >
                   <Header as='h3' attached='top' className="search-items-header">
                       <Grid>
                           <Grid.Column width="8" textAlign="left">
                               <b>{t('general.search')}</b>
                           </Grid.Column>
                           <Grid.Column  width="8" textAlign="right">
                               <Icon name='dropdown' />
                           </Grid.Column>
                       </Grid>
                   </Header>
               </Accordion.Title>
               
               <Accordion.Content active={activeIndex === 0} style={{paddingTop: 0}}>
                   <Segment attached style={{ borderRadius: "0 0 5px 5px" }}>

                       <Input
                           fluid
                           icon='search'
                           placeholder={t('notification.searchNotification')}
                           value={searchTerm}
                           onKeyPress={(ev, data) => {
                               if (ev.key === 'Enter') {
                                   ev.preventDefault();
                                   onClickSearch(searchTerm);
                               }
                           }}
                           onChange={(ev, {value}) => {
                               setSearchTerm(value);
                               //onClickSearch(value);
                           }}
                           className="fieldcontent"
                       />

                       {/* Additional filters */}
                       <Container>
                           {filters}
                       </Container>

                       {/* Search items button */}
                       <Container textAlign="right">
                           <SearchButton className="bb-lblue" type="reset" onClick={(ev) => { onClickSearch(searchTerm); }} />
                       </Container>

                   </Segment>
               </Accordion.Content>

           </Accordion>
       </Container>
   );
}

const Notifications = (props) => {

    const { t } = useTranslation();
    const history = useHistory();
    const { searchNotificationsTerm, setSearchNotificationsTerm } = useContext(StateContext);
    const { upcomingNotificationsOnly, setUpcomingNotificationsOnly } = useContext(StateContext);
    const { notifications, searchNotifications } = useSearchNotification();
    const [ isLoading, setIsLoading ] = useState(true);
    const [ filterNotificationList, setFilterNotificationList ]= useState('');

    // Load notification when user enter the page
    useEffect(() => {
        (async() => {
            setIsLoading(true);
            await searchNotifications(searchNotificationsTerm, upcomingNotificationsOnly);
            setIsLoading(false);
        })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const filteredNotifications = notifications.filter(notification => {
        let matchTitle = notification.Title ? notification.Title.toLowerCase().indexOf(filterNotificationList.toLowerCase()) !== -1 : false;
        let matchDate = notification && notification.Date ? new Date(notification.Date).toLocaleDateString("fi-FI").indexOf(filterNotificationList.toLowerCase()) !== -1 : false;
        let matchActivityTitle = notification && notification.ActivityTitle ? notification.ActivityTitle.toLowerCase().indexOf(filterNotificationList.toLowerCase()) !== -1 : false;
        let matchProjectTitle = notification && notification.ProjectTitle ? notification.ProjectTitle.toLowerCase().indexOf(filterNotificationList.toLowerCase()) !== -1 : false;
        let matchBusinessPartnerName = notification && notification.BusinessPartnerName ? notification.BusinessPartnerName.toLowerCase().indexOf(filterNotificationList.toLowerCase()) !== -1 : false;
        let matchAcknowledgementTime = notification && notification.AcknowledgementTime  ? new Date(notification.AcknowledgementTime).toLocaleDateString("fi-FI").indexOf(filterNotificationList.toLowerCase()) !== -1 : false;
        if (matchTitle || matchDate || matchActivityTitle || matchProjectTitle || matchBusinessPartnerName  || matchAcknowledgementTime) return true;
        return false;
    });

    return (
        <div className="notifications">

            <Loading active={ isLoading } text={t('general.loading')} />

            <PageTitle
                active
                title={t('notification.notifications')}
                button={
                    <NewButton
                        mini
                        className="bb-lblue"
                        onClick={(ev) => { ev.preventDefault(); history.push('/notifications/new'); }}
                    />
                }
            />

            <SearchAllNotifications
                setIsLoading={setIsLoading}
                searchTerm={searchNotificationsTerm}
                setSearchTerm={setSearchNotificationsTerm}
                searchNotifications={searchNotifications}
                upcomingNotificationsOnly={upcomingNotificationsOnly}
                filters={
                    <Container>
                        <Form.Field
                            control={Checkbox}
                            label={t('notification.upcomingOnly')}
                            placeholder={t('notification.upcomingOnly')}
                            checked={upcomingNotificationsOnly}
                            onChange={(ev, {checked}) => setUpcomingNotificationsOnly(checked) }
                        />
                    </Container>
                }
            />

            {/* Filter */}
            <Container className="fieldcontent">
                <Input
                    action={{
                        labelPosition: 'left',
                        icon: 'delete',
                        content: t('general.clear'),
                        style: {margin: 0},
                        onClick:(ev) => {
                            ev.preventDefault();
                            setFilterNotificationList('');
                        }
                    }}
                    onChange={(ev, {value} ) => {
                        ev.preventDefault();
                        setFilterNotificationList(value)
                    }}
                    fluid
                    placeholder={t('general.filter')}
                    value={filterNotificationList}
                />
            </Container>

            <Container className="fieldcontent">
                    
                <PagedTable unstackable celled striped compact='very' pageSize={5}
                    header={[
                        t('general.title'),
                        t('notification.sendNotificationDate'),
                        t('general.activity'),
                        t('project.project'),
                        t('businessPartner.customer'),
                        t('notification.acknowledgementTime'),
                        ''
                    ]}
                    content={filteredNotifications}
                    resetPageOnContentChange={true}
                    contentKeys={[
                        {key: 'Title', target: '/notifications/view/', type: 'link', targetKey: 'Id'},
                        {key: 'Date', type: 'date'},
                        {key: 'ActivityTitle'},
                        {key: 'ProjectTitle'},
                        {key: 'BusinessPartnerName'},
                        {key: 'AcknowledgementTime', type: 'date'},
                        {key: 'Id', target: '/notifications/edit/', type: 'link', text: t('general.edit'), targetKey: 'Id'}
                    ]}
                />
            </Container>

            <Container className='button-container'>
                <CancelButton title={t('general.back')} onClick={(ev) => { ev.preventDefault(); history.push('/'); }} />
            </Container>

        </div>
    );

}

export default Notifications;
