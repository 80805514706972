import { useState, useRef } from 'react';
import webApiClient from '../../Utils/webApiClient';
import { msalInstance } from '../../index';
import config from '../../Config/config';
import useRequestHandler from '../useRequestHandler';
import axios from 'axios';

/** Hook is used to search project products by search term */
const useSearchProjectProducts = () => {

    const { errorHandler, successHandler } = useRequestHandler();
    const [ projectProducts, setProjectProducts ] = useState([]);
    const apiClient = webApiClient();
    
    const CancelToken = axios.CancelToken;
    const cancelTokenSource = useRef(null);

    /** Search project products by search term
     * @param {String} searchterm - search term
     * @param {Object} options - additional search options
     * @example options
     * {
     *      own: queryOwn,      - list only my projects or all
     *      top: 200,           - list top n projects
     *      status: [1,2,3,4]   - array of status codes
     *      salesPerson: 1      - filter by sales person
     *      businessunit: 0     - <optional> null or integer
     * } */
    const searchProjectProducts = async (searchterm = "", options = {}) => {
        try {

            if (cancelTokenSource.current) {
                //console.log('Cancel previous request');
                cancelTokenSource.current.cancel('Cancel previous request');
            }
            
            cancelTokenSource.current = CancelToken.source();

            // Account is set in the index.js
            const account = msalInstance.getActiveAccount();

            const response = await msalInstance.acquireTokenSilent({
                scopes: config.webApi.scopes,
                account: account
            });

            let resource = "search/projectproducts?searchterm=" + searchterm.toString();

            if (options) {
                if (options.own) resource += "&own=" + options.own.toString();
                if (options.top) resource += "&top=" + options.top.toString();
                if (options.status) resource += "&status=" + options.status.toString();
                if (options.salesPerson) resource += "&salesperson=" + options.salesPerson.toString();
                if (options.businessunit) resource += "&businessunit=" + options.businessunit.toString();
            }

            const result = await apiClient.read(response.accessToken, resource, null, { cancelToken: cancelTokenSource.current });  

            const data = result && result.data ? result.data : null;
            const content = data && data.resultData ? data.resultData : [];

            if (content) setProjectProducts(content);

            return successHandler(result, "useSearchProjectProducts", "search", { hide: true });

        } catch (error) {
            return errorHandler(error, "useSearchProjectProducts", "search");
        }
    }

    return { projectProducts, setProjectProducts, searchProjectProducts };

}

export default useSearchProjectProducts;
