import React, { useState, useEffect, useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { Form, Container, Dropdown, Input, Message, Radio, TextArea } from 'semantic-ui-react';
import { Loading } from '../../Components/UI/Loaders';
import { SubmitButton, CancelButton } from '../../Components/UI/Buttons';
import { PageTitle, SelectedItemLight } from '../../Components/UI/Content';
import { StateContext } from '../../Application/Context/StateContext';
import useReadProject from '../../Hooks/Project/useReadProject';
import useReadActivity from '../../Hooks/Activities/useReadActivity';
import useReadBusinessPartner from '../../Hooks/BusinessPartners/useReadBusinessPartner';
import useUpdateActivity from '../../Hooks/Activities/useUpdateActivity';
import SearchBusinessPartners from '../../Components/BusinessPartners/SearchBusinessPartners';
import SearchProjects from '../../Components/Projects/SearchProjects';
import useSearchContacts from '../../Hooks/Search/useSearchContacts';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { createExternalAttendeesJson, editExternalAttendees } from '../../Utils/ActionArenaUtils';

const EditActivity = (props) => {

    const { t } = useTranslation();
    const history = useHistory();
    const { activityId } = useParams();
    const scollToTopRef = useRef();

    const [ activityRelation, setActivityRelation ] = useState("project");
    const [ selectedBusinessPartner, setSelectedBusinessPartner ] = useState(null); // Customer object
    const [ selectedSupplier, setSelectedSupplier ] = useState(null); // Supplier partner object
    const [ selectedProject, setSelectedProject ] = useState(null); // Project object
    const [ searchContactQuery, setSearchContactQuery ] = useState('');
    const [ searchContactValue, setSearchContactValue ] = useState([]);

    const [ title, setTitle ] = useState("");
    const [ activityDate, setActivityDate ] = useState(new Date());
    const [ activityType, setActivityType ] = useState(1);
    const [ businessUnit, setBusinessUnit ] = useState(1);   
    const [ internalAttendees, setInternalAttendees ] = useState("");
    const [ externalAttendees, setExternalAttendees ] = useState("");
    const [ objective, setObjective ] = useState("");
    const [ internalNotes, setInternalNotes ] = useState("");
    const [ externalNotes, setExternalNotes ] = useState("");

    const [ isLoading, setIsLoading ] = useState(false);
    const [ isValidSelectedCustomer, setIsValidSelectedCustomer ] = useState(true);
    const [ isValidSelectedSupplier, setIsValidSelectedSupplier ] = useState(true);
    const [ isValidSelectedProject, setIsValidSelectedProject ] = useState(true);

    const { activityTypes, businessUnits, userProfile, statusCodes } = useContext(StateContext);
    const { activity, readActivity } = useReadActivity([]);
    const { project, readProject } = useReadProject([]);
    const { businessPartner, readBusinessPartner } = useReadBusinessPartner([]);
    const { updateActivity } = useUpdateActivity();
    const { contacts, searchContacts } = useSearchContacts();

    const userIsAdmin = userProfile && userProfile.IsAdmin ? true : false;
    const projectHasEnded = project && project.EndDate ? true : false; // If project has end date, project is ended and only admin can edit it!
    const projectIsNotActive = project && project.Status > 7 ? true : false; // if project status is greater than 7 (not active anymore) only admin can edit it!
    const disableEditing = (projectHasEnded || projectIsNotActive) && !userIsAdmin ? true : false;

    let searchProjectsStatusOptions = statusCodes.map((status, index) => {
        return {
            "key": status.Id,
            "text": status.Name,
            "value": status.Id
        }
    });
    searchProjectsStatusOptions.unshift({ "key": 0, "text": t('general.all'), "value": 0 });

    const onSubmit = async () => {
        try {

            if (activityRelation === 'customer' && !selectedBusinessPartner) return setIsValidSelectedCustomer(false);
            if (activityRelation === 'supplier' && !selectedSupplier) return setIsValidSelectedSupplier(false);
            if (activityRelation === 'project' && !selectedProject) return setIsValidSelectedProject(false);
            if (!activityId) return null;

            setIsLoading(true);

            const activityObj = {
                "id": activityId,
                "title": title,
                "contactDate": activityDate ? activityDate.toISOString() : null,
                "type": activityType,
                "businessPartner": null,
                "businessUnit": businessUnit,
                "internalAttendee": internalAttendees,
                "externalAttendee": JSON.stringify(createExternalAttendeesJson(externalAttendees, searchContactValue, contacts)),
                "objective": objective,
                "externalNotes": externalNotes,
                "internalNotes": internalNotes,
                "salesPerson": userProfile && userProfile.Id ? userProfile.Id : null,
                "contact": null
            }

            if (activityRelation === 'customer' && selectedBusinessPartner && selectedBusinessPartner.Id) {
                activityObj.businessPartner = parseInt(selectedBusinessPartner.Id);
            }

            if (activityRelation === 'supplier' && selectedSupplier && selectedSupplier.Id) {
                activityObj.businessPartner = parseInt(selectedSupplier.Id);
            }

            if (activityRelation === 'project' && selectedProject && selectedProject.Id) {
                activityObj.project = parseInt(selectedProject.Id);
            }

            let result = await updateActivity(activityObj);

            if (result && result.status === 200 && result.data) {
                return history.goBack();
            }

            setIsLoading(false);

        } catch (error) {
            setIsLoading(false);
        }
    }

    // Read data
    useEffect(() => {
        (async() => {
            scollToTopRef.current.scrollIntoView();
            setIsLoading(true);
            if (activityId) await readActivity(activityId);
            await searchContacts();
            setIsLoading(false);
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Set state data when activity data is fetched
    useEffect(() => {
        if (activity) {
            if (activity.Title) setTitle(activity.Title);
            if (activity.ContactDate) setActivityDate(new Date(activity.ContactDate));
            if (activity.Type) setActivityType(activity.Type);
            // if (activity.ProjectNewStatus) setStatus(activity.ProjectNewStatus);
            if (activity.Businessunit) setBusinessUnit(activity.Businessunit);
            if (activity.InternalAttendee) setInternalAttendees(activity.InternalAttendee);
            if (activity.ExternalAttendee) {
                let extAttendeeContactsArray = editExternalAttendees(activity.ExternalAttendee, 'Dropdown');
                let extAttendeeString = editExternalAttendees(activity.ExternalAttendee, 'FormField');
                setSearchContactValue(extAttendeeContactsArray);
                setExternalAttendees(extAttendeeString);
            }
            if (activity.Objective) setObjective(activity.Objective);
            if (activity.InternalNotes) setInternalNotes(activity.InternalNotes);
            if (activity.ExternalNotes) setExternalNotes(activity.ExternalNotes);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activity]);

    // Read project data if activity is project activity
    useEffect(() => {
        if (activity && activity.Project && typeof activity.Project === 'number') {
            (async() => {
                setIsLoading(true);
                await readProject(activity.Project);
                setIsLoading(false);
            })();
        }
        if (activity && activity.BusinessPartner && typeof activity.BusinessPartner === 'number') {
            (async() => {
                setIsLoading(true);
                await readBusinessPartner(activity.BusinessPartner);
                setIsLoading(false);
            })();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activity]);

    // Set project state when project data is fetched
    useEffect(() => {
        if (project) {

            if (project.Id) {
                setSelectedProject(project);
                setActivityRelation("project");
            }

            if (project.BusinessUnit) {
                setBusinessUnit(project.BusinessUnit);
            }

        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [project]);

    // Set business partner state when data is fetched
    useEffect(() => {
        if (businessPartner && businessPartner.Id) {
            if (businessPartner.IsSupplier) {
                setActivityRelation("supplier");
                setSelectedSupplier(businessPartner);
            }
            if (businessPartner.IsCustomer) {
                setActivityRelation("customer");
                setSelectedBusinessPartner(businessPartner);
            }            
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [businessPartner]);

    // Sort contacts by selected businesspartner or project
    let businessPartnerIdfilter = 0;
    if (activityRelation && activityRelation === "customer" && selectedBusinessPartner) businessPartnerIdfilter = selectedBusinessPartner.Id;
    if (activityRelation && activityRelation === "supplier" && selectedSupplier) businessPartnerIdfilter = selectedSupplier.Id;
    if (activityRelation && activityRelation === "project" && selectedProject) businessPartnerIdfilter = selectedProject.BusinessPartner;

    // 1.Filter contacts by business partner of project selection
    const filteredBusinessPartnerContacts = contacts.filter(item => {
        return item.BusinessPartner === businessPartnerIdfilter;
    });

    // 2.Filter other contacts
    const filteredOtherContacts = contacts.filter(item => {
        return item.BusinessPartner !== businessPartnerIdfilter;
    });

    // 3. Combine arrays.
    let orderedContacts = [];
    if (filteredBusinessPartnerContacts.length > 0 || filteredOtherContacts.length > 0 ) {
        orderedContacts = [...filteredBusinessPartnerContacts, ...filteredOtherContacts];
    } else {
        orderedContacts = [...contacts];
    }

    return (
        <Form className="activity" onSubmit={(ev) => { ev.preventDefault(); onSubmit(); }} >

            <div ref={scollToTopRef}></div>

            <Loading active={ isLoading } text={t('general.loading')} />

            <PageTitle active title={t('activity.editActivity')} />

            <Message warning visible={projectHasEnded || projectIsNotActive} content={t('warnings.projectIsNotActive')} />

            {/* Title */}
            <Form.Field
                required
                label={t('general.title')}
                //placeholder={t('general.title')}
                maxLength="255"
                control={Input}
                value={title}
                onChange={(ev, {value}) => setTitle(value) }
                type="Text"
            />

            {/* Is activity related to customer or project? */}
            <Container className="fieldcontent">
                <Form.Field
                    control={Radio}
                    label={t('activity.isRelatedToCustomer')}
                    placeholder={t('activity.isRelatedToCustomer')}
                    name='activityRelation'
                    checked={activityRelation === 'customer'}
                    onChange={(ev, {value}) => setActivityRelation('customer') }
                />
                <Form.Field
                    control={Radio}
                    label={t('activity.isRelatedToSupplier')}
                    placeholder={t('activity.isRelatedToSupplier')}
                    name='activityRelation'
                    checked={activityRelation === 'supplier'}
                    onChange={(ev, {value}) => setActivityRelation('supplier') }
                />
                <Form.Field
                    control={Radio}
                    label={t('activity.isRelatedToProject')}
                    placeholder={t('activity.isRelatedToProject')}
                    name='activityRelation'
                    checked={activityRelation === 'project'}
                    onChange={(ev, {value}) => setActivityRelation('project') }
                />
            </Container>

            {/* Search customers if activity is related to business partner */}
            {activityRelation === 'customer' &&
                <>
                    {/* Customer is NOT selected - show searchbox */}
                    {!selectedBusinessPartner &&
                        <SearchBusinessPartners
                            title={t('businessPartner.searchCustomer')}
                            placeholder={t('businessPartner.searchCustomer')}
                            isLoading={isLoading}
                            setIsLoading={setIsLoading}
                            selectedBusinessPartner={selectedBusinessPartner}
                            setSelectedBusinessPartner={setSelectedBusinessPartner}
                            setIsValidSelectedBusinessPartner={setIsValidSelectedCustomer}
                            type="customer"
                        />
                    }
                    {/* Customer is selected - Show selected item */}
                    {selectedBusinessPartner &&
                        <SelectedItemLight
                            title={t('businessPartner.selectedCustomer')}
                            item={selectedBusinessPartner}
                            headers={[t('general.name'), t('businessPartner.area'), t('general.businessId')]}
                            fields={['Name', 'AreaName', 'AssociationNumber']}
                            required={activityRelation === 'customer'}
                            isValidSelectedItem={isValidSelectedCustomer}
                            onRemoveSelection={(ev) => {
                                setSelectedBusinessPartner(null);
                            }}
                        />
                    }
                </>
            }

            {/* Search suppliers if activity is related to supplier */}
            {activityRelation === 'supplier' &&
                <>
                    {/* Supplier is NOT selected - Show searchbox */}
                    {!selectedSupplier &&
                        <SearchBusinessPartners
                            title={t('businessPartner.searchSupplier')}
                            placeholder={t('businessPartner.searchSupplier')}
                            isLoading={isLoading}
                            setIsLoading={setIsLoading}
                            selectedBusinessPartner={selectedSupplier}
                            setSelectedBusinessPartner={setSelectedSupplier}
                            setIsValidSelectedBusinessPartner={setIsValidSelectedSupplier}
                            type="supplier"
                        />
                    }
                    {/* Supplier is selected - Show selected item */}
                    {selectedSupplier &&
                        <SelectedItemLight
                            title={t('businessPartner.selectedSupplier')}
                            item={selectedSupplier}
                            headers={[t('general.name'), t('businessPartner.area'), t('general.businessId')]}
                            fields={['Name', 'AreaName', 'AssociationNumber']}
                            required={activityRelation === 'supplier'}
                            isValidSelectedItem={isValidSelectedSupplier}
                            onRemoveSelection={(ev) => {
                                setSelectedSupplier(null);
                            }}
                        />
                    }
                </>
            }

            {/* Search customers if activity is related to project */}
            {activityRelation === 'project' &&
                <>
                    {/* Project is NOT selected - Show searchbox */}
                    {!selectedProject &&
                        <>
                            <Form.Field label={t('project.searchProjects')} style={{ marginTop: '20px'}} />
                            <SearchProjects
                                //title={t('project.searchProjects')}
                                placeholder={t('project.searchProjects')}
                                isLoading={isLoading}
                                setIsLoading={setIsLoading}
                                selectedProject={selectedProject}
                                setSelectedProject={setSelectedProject}
                                setIsValidSelectedProject={setIsValidSelectedProject}
                            />
                        </>
                    }
                    {/* Project is selected - Show selected item */}
                    {selectedProject &&
                        <SelectedItemLight
                            title={t('project.selectedProject')}
                            item={selectedProject}
                            headers={[t('general.title'), t('general.status'), t('general.businessPartner'), t('businessPartner.businessUnit')]}
                            fields={['Title', 'StatusName', 'BusinessPartnerName', 'BusinessUnitName']}
                            required={activityRelation === 'project'}
                            isValidSelectedItem={isValidSelectedProject}
                            onRemoveSelection={(ev) => {
                                setSelectedProject(null);
                            }}
                        />
                    }
                </>
            }
            
            {/* Project activity Date */}
            <Container className="fieldcontent">
                <Form.Field
                    control={DatePicker}
                    required
                    label={t('activity.projectActivityDate')}
                    selected={activityDate}
                    dateFormat={navigator.language === "fi-FI" ? "dd.MM.yyyy" : "yyyy/MM/dd"}
                    onChange={(date) => setActivityDate(date)}
                />
            </Container>

            {/* Activity Type */}
           
            <div className="fieldcontent">
                <Form.Field
                    control={Dropdown}
                    required
                    disabled={true} // Disabled in order to avoid multible validations
                    placeholder={t('activity.activityType')}
                    label={t('activity.activityType')}
                    fluid
                    search
                    selection
                    options={activityTypes.map(type => {
                        return {
                            key: type.Id,
                            value: type.Id,
                            text: type.Name
                        }
                    })}
                    value={activityType}
                    onChange={(ev, {value}) => setActivityType(value) }
                />
            </div>

            {/* Project status (activity type 5 = project status change) */}
            { activityType && activityType === 5 && 
                <Container className="fieldcontent">
                    <Form.Field label={t('general.status')} />
                    <p>{statusCodes[5].Name}</p>
                </Container>
            }

            {/* Business unit */}
            <div className="fieldcontent">
                <Form.Field
                    disabled={(activityRelation === 'project' && businessUnit ? true : false)}
                    required={activityRelation !== 'project'}
                    label={t('businessPartner.businessUnit')}
                    fluid
                    search
                    selection
                    maxLength="255"
                    options={businessUnits.map(bu => {
                        return { key: bu.Id, value: bu.Id, text: bu.Name }
                    })}
                    control={Dropdown}
                    value={businessUnit}
                    onChange={(ev, {value}) => setBusinessUnit(value) }
                />
            </div>

            {/* External notes */}
            <Form.Field
                //required
                label={t('activity.externalNotes')}
                placeholder={t('activity.externalNotes')}
                control={TextArea}
                value={externalNotes}
                rows={5}
                onChange={(ev, {value}) => setExternalNotes(value) }
                type="Text"
            />

            {/* Internal notes */}
            <Form.Field
                //required
                label={t('activity.internalNotes')}
                placeholder={t('activity.internalNotes')}
                control={TextArea}
                value={internalNotes}
                rows={15}
                onChange={(ev, {value}) => setInternalNotes(value) }
                type="Text"
            />

            {/* Objective */}
            <Form.Field
                //required
                label={t('activity.objective')}
                placeholder={t('activity.objective')}
                maxLength="255"
                control={Input}
                value={objective}
                onChange={(ev, {value}) => setObjective(value) }
                type="Text"
            />

            {/* External attendees */}
            <Form.Field label={t('activity.externalAttendees')} />
            <Dropdown
                placeholder={t('activity.selectExternalAttendeesFromContacts')}
                clearable
                fluid
                multiple
                search
                selection
                options={orderedContacts.map(item => {
                    if (item && item.BusinessPartnerName) {
                        item.NameWithBP = item.Name + " [" + item.BusinessPartnerName + "]";
                    } else {
                        item.NameWithBP = item.Name;
                    }
                    return item;
                }).map(item => {
                    return {
                        key: item.Id,
                        text: item.NameWithBP,
                        value: item.Id,
                    }
                })}
                onChange={(e, { searchQuery, value }) => {
                    setSearchContactQuery(searchQuery);
                    setSearchContactValue(value);
                }}
                onSearchChange={(e, { searchQuery, value }) => {
                    setSearchContactQuery(searchQuery);
                }}
                style={{ marginBottom: "1px" }}
                searchQuery={searchContactQuery}
                value={searchContactValue}
            />
            <Form.Field
                maxLength="255"
                control={Input}
                value={externalAttendees}
                onChange={(ev, {value}) => setExternalAttendees(value) }
                type="Text"
                placeholder={t('activity.externalAttendees')}
            />

            {/* Internal attendees */}
            <Form.Field
                //required
                label={t('activity.internalAttendees')}
                placeholder={t('activity.internalAttendees')}
                maxLength="255"
                control={Input}
                value={internalAttendees}
                onChange={(ev, {value}) => setInternalAttendees(value) }
                type="Text"
            />

            {/* Errors */}
            <Message error visible={!activityId} content={t('errors.missingActivityId')} />

            {/* Buttons */}
            <Container className='button-container'>
                <CancelButton onClick={(ev) => {ev.preventDefault(); history.goBack(); }} />
                <SubmitButton disabled={disableEditing} type="submit" icon="save" className="bb-lblue" title={t('general.save')} />
            </Container>

        </Form>
    );

}

export default EditActivity;
