import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import webApiClient from '../../Utils/webApiClient';
import { msalInstance } from '../../index';
import config from '../../Config/config';
import useRequestHandler from '../useRequestHandler';

/** Hook is used to delete activity entity */
const useDeleteActivity = () => {

    const { t } = useTranslation();
    const { errorHandler, successHandler } = useRequestHandler();
    const apiClient = webApiClient();
    const [ doesNotExist, setDoesNotExist ] = useState(false);

    /** Delete activity
     * @param {integer} activityId - activity id     **/
    const deleteActivity = async (activityId) => {
        try {

            // Account is set in the index.js
            const account = msalInstance.getActiveAccount();

            const response = await msalInstance.acquireTokenSilent({
                scopes: config.webApi.scopes,
                account: account
            });

            // Create resource path
            let resource = "activities/" + activityId.toString();

            const result = await apiClient.delete(response.accessToken, resource);            

            return successHandler(result, "useDeleteActivity", "delete", { header: t('success.delete'), message: t('success.delete') });

        } catch (error) {

            const status = error.status || null;
            const data = error.data || {};
            const errorCode = data.errorCode || 0;

            if (status === 400 && errorCode === -1) setDoesNotExist(true);

            return errorHandler(error, "useDeleteActivity", "delete");

        }
    }

    return { doesNotExist, setDoesNotExist, deleteActivity };

}

export default useDeleteActivity;
