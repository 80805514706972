import React from 'react';
//import { useTranslation } from 'react-i18next';
import { Dimmer, Loader, Container } from 'semantic-ui-react';

/* Page content loading overlay with loader icon */
const Loading = ({ text, active }) => {
    const id = active ? 'overlay': '';
    return (
        <Container>
            <Dimmer active={active} id={id} >
                <Loader size='massive'>{text}</Loader>
            </Dimmer>
        </Container>
    );
}

export {
    Loading
};
