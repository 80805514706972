import React, { useState } from 'react';
import { BrowserRouter as Router} from 'react-router-dom';
import { InteractionType } from "@azure/msal-browser";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { Header, Content, Footer, Messages, MobileNavigation, Loading } from '../Components/UI/Layout';
import Routes from './Routes';
import ErrorComponent from '../Pages/System/Error';
import MessageContextProvider from './Context/MessageContext';
import StateContextProvider from './Context/StateContext';
import 'semantic-ui-css/semantic.min.css';
import './App.css';

function App() {

    const [ mobileMenuOpen, setMobileMenuOpen ] = useState(false);

    return (
        <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            errorComponent={ErrorComponent}
            loadingComponent={Loading}
        >
            <MessageContextProvider>
                <Router>
                    <StateContextProvider>
                        <div className="App">
                            <Header mobileMenuOpen={mobileMenuOpen} setMobileMenuOpen={setMobileMenuOpen} />
                            <Messages />
                            <MobileNavigation mobileMenuOpen={mobileMenuOpen} setMobileMenuOpen={setMobileMenuOpen} >
                                <Content>   
                                    <Routes/>
                                </Content>
                                <Footer/>
                            </MobileNavigation>
                        </div>
                    </StateContextProvider>
                </Router>
            </MessageContextProvider>
        </MsalAuthenticationTemplate>
    );

}

export default App;
