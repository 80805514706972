import { useState } from 'react';
import webApiClient from '../../Utils/webApiClient';
import { msalInstance } from '../../index';
import config from '../../Config/config';
import useRequestHandler from '../useRequestHandler';

/** Hook is used to fetch list of project activities */
const useListProjectActivities = () => {

    const [ projectActivities, setProjectActivities ] = useState([]);
    const { errorHandler, successHandler } = useRequestHandler();
    const apiClient = webApiClient();

    /** List project activities
     * @param {integer} projectId - project id */
    const listProjectActivities = async (projectId) => {
        try {

            // Account is set in the index.js
            const account = msalInstance.getActiveAccount();

            const response = await msalInstance.acquireTokenSilent({
                scopes: config.webApi.scopes,
                account: account
            });

            // Create resource path
            let resource = "projects/" + projectId.toString() + "/activities";

            const result = await apiClient.list(response.accessToken, resource);

            const data = result.data || {};
            const content = data && data.resultData ? data.resultData : [];

            if (content) setProjectActivities(content);

            return successHandler(result, "useListProjectActivities", "list", { hide: true });

        } catch (error) {

            return errorHandler(error, "useListProjectActivities", "list");

        }
    }

    return { projectActivities, listProjectActivities };

}

export default useListProjectActivities;
