import React, { useState, useContext, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { Form, Input, Container, Message, TextArea } from 'semantic-ui-react';
import { Loading } from '../../Components/UI/Loaders';
import { SubmitButton, CancelButton } from '../../Components/UI/Buttons';
import { PageTitle } from '../../Components/UI/Content';
import { StateContext } from '../../Application/Context/StateContext';
import useCreateNotification from '../../Hooks/Notifications/useCreateNotification';
import useReadProject from '../../Hooks/Project/useReadProject';
import useReadBusinessPartner from '../../Hooks/BusinessPartners/useReadBusinessPartner';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import NewCalendarEvent from '../../Components/Outlook/OutlookEvent';
import useCreateCalendarEvent from '../../Hooks/CalendarEvents/useCreateCalendarEvent';

const NewProjectNotifications = (props) => {

    const { t } = useTranslation();
    const history = useHistory();
    const scollToTopRef = useRef();
    const { projectId } = useParams();

    const [ isLoading, setIsLoading ] = useState(false);
    const [ date, setDate ] = useState(new Date());
    const [ title, setTitle ] = useState("");
    const [ description, setDescription ] = useState("");

    const { userProfile } = useContext(StateContext);
    const { createNotification } = useCreateNotification();
    const { createCalendarEvent } = useCreateCalendarEvent();
    const { project, readProject } = useReadProject();
    const { businessPartner, readBusinessPartner } = useReadBusinessPartner();

    /* Outlook event */
    const [ addToOutlook, setAddToOutlook ] = useState(false);
    const [ startDate, setStartDate ] = useState(() => {
        const d = new Date(date);
        d.setHours(d.getHours() + 1);
        d.setMinutes(0);
        d.setSeconds(0);
        d.setMilliseconds(0);
        return d;
    });
    const [ endDate, setEndDate ] = useState(() => {
        const d = new Date(date);
        d.setHours(d.getHours() + 2);
        d.setMinutes(0);
        d.setSeconds(0);
        d.setMilliseconds(0);
        return d;
    });
    const [ location, setLocation ] = useState("");

    // Save data on submit
    const onSubmit = async () => {
        try {

            if (!projectId) return null;

            setIsLoading(true);

            const notificationObj = {
                "date": date ? date.toISOString() : null,
                "title": title ? title.trim() : "Notification title",
                "description": description.trim(),
                "businessPartner": null,
                "activity": null,
                "project": parseInt(projectId),
                "salesPerson": null
            }

            if (userProfile && userProfile.Id) {
                notificationObj.salesPerson = userProfile.Id;
            }

            if (project && project.BusinessPartner) {
                notificationObj.businessPartner = project.BusinessPartner;
            }

            if (addToOutlook) {
                
                const outlookEvent = {
                    "subject": title.trim().toString(),
                    "body": {
                        "contentType": "HTML",
                        "content": description.trim()
                    },
                    "start": {
                        "dateTime": startDate.toISOString(),
                        "timeZone": "FLE Standard Time"
                    },
                    "end": {
                        "dateTime": endDate.toISOString(),
                        "timeZone": "FLE Standard Time"
                    },
                    "location": {
                        "displayName": location.trim().toString()
                    }
                }

                await createCalendarEvent(outlookEvent);
                // Do not block if event creation fails.
                //if (res && res.status && res.status !== 200) return;

            };

            let result = await createNotification(notificationObj);

            if (result && result.status === 200) {
                return history.goBack();
            }

            setIsLoading(false);

        } catch (error) {
            setIsLoading(false);
        }
    }

    // Read project
    useEffect(() => {
        (async() => {
            scollToTopRef.current.scrollIntoView();
            setIsLoading(true);
            await readProject(projectId);
            setIsLoading(false);
        })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Read project business partner
    useEffect(() => {
        if (project && project.BusinessPartner) {
            (async() => {
                setIsLoading(true);
                await readBusinessPartner(project.BusinessPartner);
                setIsLoading(false);
            })();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [project]);

    return (
        <Form className="notification" onSubmit={(ev) => { ev.preventDefault(); onSubmit(); }} >
        
            <div ref={scollToTopRef}></div>

            <Loading active={ isLoading } text={t('general.loading')} />

            <PageTitle active title={t('notification.newProjectNotifications')} />
        
            {/* Title */}
            <Form.Field
                focus
                required
                label={t('general.title')}
                placeholder={t('general.title')}
                maxLength="255"
                control={Input}
                value={title}
                onChange={(ev, {value}) => setTitle(value) }
                type="Text"
            />

            {/* Description */}
            <Form.Field
                required
                label={t('general.description')}
                placeholder={t('general.description')}
                control={TextArea}
                value={description}
                onChange={(ev, {value}) => setDescription(value) }
                type="Text"
            />

            {/* Date */}
            <Form.Field label={t('notification.sendNotificationDate')} style={{ marginBottom: '0px' }}/>
            <DatePicker
                required
                label={t('notification.sendNotificationDate')}
                selected={date}
                dateFormat={navigator.language === "fi-FI" ? "dd.MM.yyyy" : "yyyy/MM/dd"}
                onChange={(date) => setDate(date)}
            />

            {/* Project */}
            <Container className="fieldcontent" style={{ marginTop: "20px" }}>
                <Form.Field label={t('general.project')} />
                <p>{project.Title}</p>
            </Container>

            {/* Business partner */}
            <Container className="fieldcontent">
                <Form.Field label={t('general.businessPartner')} />
                <p>{businessPartner.Name}</p>
            </Container>

            <NewCalendarEvent
                addToOutlook={addToOutlook}
                setAddToOutlook={setAddToOutlook}
                title={title}
                body={description}
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                location={location}
                setLocation={setLocation}
            />
            
            {/* Errors */}
            <Message error visible={!projectId} content={t('errors.missingProjectId')} />

            {/* Buttons */}
            <Container className='button-container'>
                <CancelButton onClick={(ev) => {ev.preventDefault(); history.goBack(); }} />
                <SubmitButton type="submit" className="bb-lblue" icon="save" title={t('general.save')} />
            </Container>

        </Form>
    );

}

export default NewProjectNotifications;
