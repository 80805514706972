//import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import webApiClient from './../../Utils/webApiClient';
import { msalInstance } from '../../index';
import config from '../../Config/config';
import useRequestHandler from '../useRequestHandler';

/** Hook is used to create new contact */
const useCreateContact = () => {
    
    const { t } = useTranslation();
    const { errorHandler, successHandler } = useRequestHandler();
    const apiClient = webApiClient();

    /** Create new contact
     * @example object
     * {
     *      "firstName": "a",
     *      "lastName": "b",
     *      "position": "c",
     *      "phoneNumber1": "+358",
     *      "phoneNumber2": "",
     *      "emailAddress": "abc@example.org",
     *      "businessPartner": 1
     * }
     **/
    const createContact = async (object) => {
        try {

            // Account is set in the index.js
            const account = msalInstance.getActiveAccount();

            const response = await msalInstance.acquireTokenSilent({
                scopes: config.webApi.scopes,
                account: account
            });

            const result = await apiClient.create(response.accessToken, "contacts", object);

            return successHandler(result, "useCreateContact", "create", { message: t('success.create') });

        } catch (error) {
            return errorHandler(error, "useCreateContact", "create");
        }
    }

    return { createContact };

}

export default useCreateContact;
