import React, { useState, useEffect, useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { Form, Container, Message, Grid } from 'semantic-ui-react';
import { Loading } from '../../Components/UI/Loaders';
import { DeleteButton, EditButton, ApproveButton, CancelButton } from '../../Components/UI/Buttons';
import { PageTitle } from '../../Components/UI/Content';
import { YesNoModal } from '../../Components/UI/Modals';

import { StateContext } from '../../Application/Context/StateContext';
import useReadNotification from '../../Hooks/Notifications/useReadNotification';
import useAcknowledgeNotification from '../../Hooks/Notifications/useAcknowledgeNotification';
import useDeleteNotification from '../../Hooks/Notifications/useDeleteNotification';
import useReadProject from '../../Hooks/Project/useReadProject';
import useReadActivity from '../../Hooks/Activities/useReadActivity';
import useReadBusinessPartner from '../../Hooks/BusinessPartners/useReadBusinessPartner';

const ViewProjectNotification = (props) => {

    const { t } = useTranslation();
    const history = useHistory();
    const { projectId, notificationId } = useParams();
    const scollToTopRef = useRef();

    const [ isLoading, setIsLoading ] = useState(true);
    const [ acknowledgeModalIsOpen, setAcknowledgeModalIsOpen ] = useState(false);
    const [ deleteModalIsOpen, setDeleteModalIsOpen ] = useState(false);

    const { userProfile } = useContext(StateContext);
    const { notification, readNotification } = useReadNotification();
    const { deleteNotification } = useDeleteNotification();
    const { acknowledgeNotification } = useAcknowledgeNotification();
    const { project, readProject } = useReadProject([]);
    const { activity, readActivity } = useReadActivity([]);
    const { businessPartner, readBusinessPartner } = useReadBusinessPartner([]);

    const userIsAdmin = userProfile && userProfile.IsAdmin ? true : false;
    const projectHasEnded = project && project.EndDate ? true : false; // If project has end date, project is ended and only admin can edit it!
    const projectIsNotActive = project && project.Status > 7 ? true : false; // if project status is greater than 7 (not active anymore) only admin can edit it!
    const disableEditing = (projectHasEnded || projectIsNotActive) && !userIsAdmin ? true : false;
    
    // Read data
    useEffect(() => {
        (async() => {
            scollToTopRef.current.scrollIntoView();
            setIsLoading(true);
            await readProject(projectId);
            await readNotification(notificationId);
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Read related data
    useEffect(() => {
        if (notification) {
            (async() => {
                if (notification && notification.activity) await readActivity(notification.activity);
                if (notification && notification.businessPartner) await readBusinessPartner(notification.businessPartner);
                setIsLoading(false);
            })();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [notification]);

    return (
        <Form className="notification" >
                
            <div ref={scollToTopRef}></div>

            <Loading active={ isLoading } text={t('general.loading')} />

            <PageTitle active title={t('notification.viewProjectNotification')} />

            <Message warning visible={projectHasEnded || projectIsNotActive} content={t('warnings.projectIsNotActive')} />

            {/* Approve notification modal */}
            <YesNoModal
                type="ready"
                header={t('general.ready')}
                text={t('general.areYouSure')}
                method={async (answer, id) => {
                    setIsLoading(true);
                    if (answer) await acknowledgeNotification(notificationId);
                    await readNotification(notificationId);
                    setIsLoading(false);
                }}
                item={notificationId}
                open={acknowledgeModalIsOpen}
                setOpen={setAcknowledgeModalIsOpen}
                noClick={false}
            />

            {/* Delete notification modal */}
            <YesNoModal
                type='ready'
                header={t('general.delete')}
                text={t('general.areYouSure')}
                method={async (answer) => {
                    setIsLoading(true);
                    if (answer) {
                        await deleteNotification(notificationId);
                        return history.goBack();
                    }
                    setIsLoading(false);
                }}
                item={notificationId}
                open={deleteModalIsOpen}
                setOpen={setDeleteModalIsOpen}
                noClick={false}
            />

            <Grid stackable>

                {/* Date */}
                <Grid.Row>
                    <Grid.Column width={4}><Form.Field label={t('notification.sendNotificationDate')} /></Grid.Column>
                    <Grid.Column width={12}><p>{notification.Date ? new Date(notification.Date).toLocaleDateString("fi-FI") : ""}</p></Grid.Column>
                </Grid.Row>

                {/* Title */}
                <Grid.Row>
                    <Grid.Column width={4}><Form.Field label={t('general.title')} /></Grid.Column>
                    <Grid.Column width={12}><p>{notification.Title}</p></Grid.Column>
                </Grid.Row>

                {/* Description */}
                <Grid.Row>
                    <Grid.Column width={4}><Form.Field label={t('general.description')} /></Grid.Column>
                    <Grid.Column width={12}><p>{notification.Description}</p></Grid.Column>
                </Grid.Row>

                {/* AcknowledgementTime */}
                {notification && notification.AcknowledgementTime && 
                    <Grid.Row>
                        <Grid.Column width={4}><Form.Field title={t('notification.listNotificationInTheFrontPage')} label={t('notification.acknowledgementTime')} /></Grid.Column>
                        <Grid.Column width={12}><p>{notification.AcknowledgementTime ? new Date(notification.AcknowledgementTime).toLocaleDateString("fi-FI") : ""}</p></Grid.Column>
                    </Grid.Row>
                }

                {/* Project name */}
                {project && project.Name && 
                    <Grid.Row>
                        <Grid.Column width={4}><Form.Field label={t('general.project')} /></Grid.Column>
                        <Grid.Column width={12}><p>{project.Name}</p></Grid.Column>
                    </Grid.Row>
                }

                {/* Notification is related to activity title */}
                { notification && notification.activity &&
                    <>

                        {/* Activity title */}
                        {activity && activity.Title && 
                            <Grid.Row>
                                <Grid.Column width={4}><Form.Field label={t('general.activity')} /></Grid.Column>
                                <Grid.Column width={12}><p>{activity.Title}</p></Grid.Column>
                            </Grid.Row>
                        }

                    </>
                }

                {/* Notification is related to businesspartner title */}
                { notification && notification.businessPartner &&
                    <>

                        {/* Business partner name */}
                        {businessPartner && businessPartner.Name && 
                            <Grid.Row>
                                <Grid.Column width={4}><Form.Field label={t('general.businessPartner')} /></Grid.Column>
                                <Grid.Column width={12}><p>{businessPartner.Name}</p></Grid.Column>
                            </Grid.Row>
                        }

                        {/* Business partner association number */}
                        {businessPartner && businessPartner.AssociationNumber && 
                            <Grid.Row>
                                <Grid.Column width={4}><Form.Field label={t('general.businessId')} /></Grid.Column>
                                <Grid.Column width={12}><p>{businessPartner.AssociationNumber}</p></Grid.Column>
                            </Grid.Row>
                        }

                    </>
                }

            </Grid>

            {/* Errors */}
            <Message error visible={!projectId} content={t('errors.missingProjectId')} />
            <Message error visible={!notificationId} content={t('errors.missingNotificationId')} />

            {/* Buttons */}
            <Container className='button-container'>
                <CancelButton onClick={(ev) => { ev.preventDefault(); history.goBack(); }} />
                <div>
                    <EditButton
                        disabled={disableEditing && !userIsAdmin}
                        className="bb-lblue"
                        icon="save"
                        title={t('general.edit')}
                        onClick={(ev) => { ev.preventDefault(); history.push("/projects/" + projectId + "/notifications/edit/" + notificationId); }}
                    />

                    <DeleteButton disabled={disableEditing && !userIsAdmin} color="red" onClick={(ev) => { ev.preventDefault(); setDeleteModalIsOpen(true); } }/>
                    <ApproveButton className="bb-lblue" title={t('general.acknowledge')} onClick={(ev) => { ev.preventDefault(); setAcknowledgeModalIsOpen(true); }} />
                </div>
            </Container>

        </Form>
    );

}

export default ViewProjectNotification;
