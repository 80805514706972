import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Form, Search, Radio, Checkbox } from 'semantic-ui-react';
import useSearchProjects from '../../Hooks/Search/useSearchProjects';
import { StateContext } from '../../Application/Context/StateContext';
import { getSearchProjectStatusCodeFilters } from '../../Utils/ActionArenaUtils';
import './../components.css';

/** Bang & Bonsomer - Search Projects Component */
const SearchProjects = ({
    required,
    title,
    placeholder,
    isLoading,
    setIsLoading,
    setSelectedProject,
    setIsValidSelectedProject
}) => {

    const { t } = useTranslation();
    const [ projectName, setProjectName ] = useState("");
    const { projects, searchProjects } = useSearchProjects();
    const [ myProjectsOnly, setMyProjectsOnly ] = useState(true);
    const [ filterProjects, setFilterProjects ] = useState("active");
    const [ timer, setTimer ] = useState(null);
    const { statusCodes } = useContext(StateContext);

    return (
        <Container className="fieldcontent">

            {/* Select search by */}
            <Container className="filters fieldcontent" style={{ border: '1px solid #e2e2e2', borderRadius: '5px', padding: '5px', marginBottom: '5px' }}>

                {/* My Projects Only */}
                <Form.Field label={t('project.filters')} />

                <Form.Field
                    control={Checkbox}
                    label={t('project.myProjectsOnly')}
                    placeholder={t('project.myProjectsOnly')}
                    checked={myProjectsOnly}
                    onChange={(ev, {checked}) => {
                        setMyProjectsOnly(checked)}
                    }
                />

                <br/>

                {/* Status filters */}
                <Form.Field label={t('project.statusFilters')} />

                <Form.Field
                    control={Radio}
                    label={t('project.allProjects')}
                    name='filterProjects'
                    placeholder={t('project.allProjects')}
                    checked={filterProjects === 'all' ? true : false}
                    onChange={(ev, {checked}) => setFilterProjects('all') }
                />
                <Form.Field
                    control={Radio}
                    label={t('project.activeProjectsOnly')}
                    name='filterProjects'
                    placeholder={t('project.activeProjectsOnly')}
                    checked={filterProjects === 'active' ? true : false}
                    onChange={(ev, {checked}) => setFilterProjects('active') }
                />
                <Form.Field
                    control={Radio}
                    label={t('project.passiveProjectsOnly')}
                    name='filterProjects'
                    placeholder={t('project.passiveProjectsOnly')}
                    checked={filterProjects === 'passive' ? true : false}
                    onChange={(ev, {checked}) => setFilterProjects('passive') }
                />
                <Form.Field
                    control={Radio}
                    label={t('project.closedProjectsOnly')}
                    name='filterProjects'
                    placeholder={t('project.closedProjectsOnly')}
                    checked={filterProjects === 'closed' ? true : false}
                    onChange={(ev, {checked}) => setFilterProjects('closed') }
                />
            </Container>

            <Form.Field
                fluid
                required={required ? required : false}
                loading={isLoading}
                label={title || null}
                placeholder={placeholder || t('project.searchProjects')}
                icon='search'
                className="bb-search"
                control={Search}
                onKeyPress={async (ev,data) => {
                    if (ev.key === 'Enter') {

                        ev.preventDefault();
                        clearTimeout(timer);                       
                        setIsLoading(true);

                        let statusCodeFilters = getSearchProjectStatusCodeFilters(statusCodes, filterProjects);
                        
                        const options = {
                            own: myProjectsOnly,
                            top: 10,
                            status: statusCodeFilters,
                            salesPerson: null
                        }

                        let result = await searchProjects(projectName, 'project', options);

                        if (!result.requestCancelled) setIsLoading(false);
                        setSelectedProject(null);

                    }
                }}
                onResultSelect={(e, {result}) => {
                    clearTimeout(timer);
                    setIsLoading(false);
                    setProjectName(result.title);
                    let proj = projects.find(item => item.Id === result.value);
                    if (proj) setSelectedProject(proj);
                    if (proj && setIsValidSelectedProject) setIsValidSelectedProject(true);
                    if (!proj) setSelectedProject(null);
                }}
                onSearchChange={async (ev, {value}) => {

                    setProjectName(value);

                    // If previous timeout is set, remove it!
                    if (timer) {
                        setIsLoading(false);
                        clearTimeout(timer);
                    }

                    // Set new timeout which calls the search api endpoint
                    let temp = setTimeout(async () => {

                        setIsLoading(true);
                
                        let statusCodeFilters = getSearchProjectStatusCodeFilters(statusCodes, filterProjects)

                        const options = {
                            own: myProjectsOnly,
                            top: 10,
                            status: statusCodeFilters,
                            salesPerson: null
                        }

                        let result = await searchProjects(projectName, 'project', options);

                        if (!result.requestCancelled) setIsLoading(false);
                        setSelectedProject(null);

                    }, 800);

                    setTimer(temp);

                }}
                results={
                    
                    projects.map((pro, index) => {
                        return {
                            key: index,
                            value: pro.Id,
                            title: pro.Title + " (" + pro.Id + ")",
                            description: pro.BusinessPartnerName
                        }
                    })
                }
                value={projectName}
            />
        </Container>

    );
}

export default SearchProjects;
