import { useState } from 'react';
import webApiClient from './../../Utils/webApiClient';
import { msalInstance } from './../../index';
import config from './../../Config/config';
import useRequestHandler from '../useRequestHandler';

/** Hook is used to read business partner entity by id */
const useReadBusinessPartner = () => {

    const { errorHandler, successHandler } = useRequestHandler();
    const apiClient = webApiClient();
    const [ businessPartner, setBusinessPartner ] = useState({});

    /** Read business partner by id
     * @param {integer} id - id of the business partner entity */
    const readBusinessPartner = async (id) => {
        try {

            // Account is set in the index.js
            const account = msalInstance.getActiveAccount();

            const response = await msalInstance.acquireTokenSilent({
                scopes: config.webApi.scopes,
                account: account
            });

            const result = await apiClient.read(response.accessToken, "businesspartners", id);  

            const data = result && result.data ? result.data : null;
            const resultData = data.resultData || null;
            const content = resultData[0] || {};

            if (content) setBusinessPartner(content);

            return successHandler(result, "useReadBusinessPartner", "read", { hide: true });

        } catch (error) {
            return errorHandler(error, "useReadBusinessPartner", "read");
        }
    }

    return { businessPartner, readBusinessPartner };

}

export default useReadBusinessPartner;
