import React, { useContext } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Container, Segment, Menu, Icon, Image, Dropdown, Sidebar, Dimmer, Loader, Message } from 'semantic-ui-react';
import { createMedia } from "@artsy/fresnel";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle, faBars } from '@fortawesome/pro-duotone-svg-icons';
import { MessageContext } from '../../Application/Context/MessageContext';
import { StateContext } from '../../Application/Context/StateContext';
import { useMsal } from "@azure/msal-react";
import Config from '../../Config/config';

const Logo = (props) => {
    return (
        <NavLink className="navlink" to ='/' exact {...props} >
            <Image size='small' src='/images/logo.svg' alt="logo" className="logo"/>
        </NavLink>
    );
};

const MenuLogo = (props) => {
    return (
        <NavLink className="navlink" to ='/' exact {...props} >
            <Image size='small' src='/images/logo_blue.png' alt="logo" className="logo"/>
        </NavLink>
    );
};

const MobileNavigation = ({ children, mobileMenuOpen, setMobileMenuOpen }) => {

    const { t } = useTranslation();
    const { instance } = useMsal();
    const { userProfile } = useContext(StateContext);

    return (
        <div className="mobile-navigation">

            {/* Sticky side bar wont work if transform is not set to none !!! */}
            {/* But If style={{ transform: 'none' }} is added then the app wont work :( 
                These is a patch: add patch class to pusahble and sidebar and add css classes
                .pushable:not(body).patch { -webkit-transform: unset; transform: unset;} .pushable:not(body) .patch.ui.sidebar {position: fixed;}
                (popup visibility caused by this is fxed with z-index in div after body)
                https://topic.alibabacloud.com/a/how-to-use-the-fixed-menu-and-sidebar-of-semantic-ui-in-react_1_51_31205875.html
            */}
            <Sidebar.Pushable as={Segment} className='patch'>

                <Sidebar as={Menu} 
                    fixed='right'
                    animation='overlay'
                    className='patch borderless'
                    style={{ padding: "10px" }}
                    onHide={() => setMobileMenuOpen(false)}
                    vertical
                    visible={mobileMenuOpen}
                >

                    {/* logo */}
                    <Menu.Item header>
                        <MenuLogo onClick={() => setMobileMenuOpen(false)}/>
                    </Menu.Item>

                    {/* Landing */}
                    <Menu.Item>
                        <NavLink activeClassName="activeLink" className="navlink" to='/' exact onClick={() => setMobileMenuOpen(false)}>
                            <i className="icon home" />{t('navigation.landing')}
                        </NavLink>
                    </Menu.Item>

                    {/* Projects */}
                    <Menu.Item>
                        <NavLink
                            /* Project and project products menu items are both underlined without path check! */
                            activeClassName={window.location.pathname.includes("products") ? "" : "activeLink" }
                            className="navlink"
                            to='/projects'
                            onClick={() => setMobileMenuOpen(false)}
                        >
                            <i className="icon archive" />{t('navigation.projects')}
                        </NavLink>
                    </Menu.Item>

                    {/* Project products */}
                    <Menu.Item>
                        <NavLink
                            /* Project and project products menu items are both underlined without path check! */
                            activeClassName={window.location.pathname.includes("products") ? "activeLink" : "" }
                            className="navlink"
                            to='/projects/products'
                            onClick={() => setMobileMenuOpen(false)}
                        >
                            <i className="icon cube" />{t('navigation.projectproducts')}
                        </NavLink>
                    </Menu.Item>

                    {/* Activities */}
                    <Menu.Item>
                        <NavLink
                            activeClassName="activeLink"
                            className="navlink"
                            to='/activities'
                            onClick={() => setMobileMenuOpen(false)}
                        >
                            <i className="icon check" />{t('navigation.activities')}
                        </NavLink>
                    </Menu.Item>

                    {/* Notifications */}
                    <Menu.Item>
                        <NavLink
                            activeClassName="activeLink"
                            className="navlink"
                            to='/notifications'
                            onClick={() => setMobileMenuOpen(false)}
                        >
                            <i className="icon bell" />{t('navigation.notifications')}
                        </NavLink>
                    </Menu.Item>

                    {/* Contacts */}
                    <Menu.Item>
                        <NavLink
                            activeClassName="activeLink"
                            className="navlink"
                            to='/contacts'
                            onClick={() => setMobileMenuOpen(false)}
                        >
                            <i className="icon user" />{t('navigation.contacts')}
                        </NavLink>
                    </Menu.Item>

                    {/* Business Partners */}
                    <Menu.Item>
                        <NavLink
                            activeClassName="activeLink"
                            className="navlink"
                            to='/businessPartners'
                            onClick={() => setMobileMenuOpen(false)}
                        >
                            <i className="icon building" />{t('navigation.customers')}
                        </NavLink>
                    </Menu.Item>

                    {/* Management - Visible to admins only! */}
                    { userProfile && userProfile.IsAdmin && 
                    <Menu.Item>
                            <NavLink
                                activeClassName="activeLink"
                                className="navlink"
                                to='/management'
                                onClick={() => setMobileMenuOpen(false)}
                            >
                                <i className="icon cogs" />{t('navigation.management')}
                            </NavLink>
                        </Menu.Item>
                    }
                    
                    {/* Info 
                    <Menu.Item>
                        <NavLink
                            activeClassName="activeLink"
                            className="navlink"
                            to='/info'
                            onClick={() => setMobileMenuOpen(false)}
                        >
                            <i className="icon info" />{t('general.info')}
                        </NavLink>
                    </Menu.Item>*/}

                    {/* Profile */}
                    <Menu.Item>
                        <NavLink
                            activeClassName="activeLink"
                            className="navlink"
                            to='/profile'
                            onClick={() => setMobileMenuOpen(false)}
                        >
                            <i className="icon user circle" />{t('navigation.profile')}
                        </NavLink>
                    </Menu.Item>

                    {/* Info */}
                    <Menu.Item>
                        <NavLink
                            activeClassName="activeLink"
                            className="navlink"
                            to='/versions'
                            onClick={() => setMobileMenuOpen(false)}
                        >
                            <i className="icon info" />{t('releases.versionHistory')}
                        </NavLink>
                    </Menu.Item>

                    {/* Logout */}
                    <Menu.Item>
                        <NavLink
                            activeClassName="activeLink"
                            className="navlink"
                            onClick={() => {
                                setMobileMenuOpen(false);
                                instance.logout();
                            }}
                            to='/logout'
                        >
                            <i className="icon sign out" />{t('general.logout')}
                        </NavLink>
                    </Menu.Item>

                </Sidebar>

                <Sidebar.Pusher dimmed={mobileMenuOpen}>
                    {children}
                </Sidebar.Pusher>

            </Sidebar.Pushable>
        </div>
    );
};

/** Profile dropdown is visible on large screens, mobile navigation on mobile */
const ProfileDropDown = (props) => {

    const { t } = useTranslation();
    const history = useHistory();

    const { instance } = useMsal();
    const account = instance && instance.getActiveAccount ? instance.getActiveAccount() : null;
    const name = account && account.name ? account.name : "";

    return (
        <Dropdown
            item
            direction='left'
            text={name || t('general.name')}
            icon={<FontAwesomeIcon icon={faUserCircle}/>}
            className="logged-user"
        >
            <Dropdown.Menu>
                {/* Profile */}
                <Dropdown.Item 
                    key='profile' 
                    text={t('general.profile')} 
                    icon='user circle'
                    onClick={() => history.push("/profile/view")}
                />
                {/* logout */}
                <Dropdown.Item 
                    key='logout' 
                    text={t('general.logout')} 
                    icon='sign out'
                    onClick={() => instance.logout()}
                />
            </Dropdown.Menu>
        </Dropdown>
    );

};

/** Profile dropdown is visible on large screens, mobile navigation on mobile */
const ActionsDropDown = (props) => {

    const { t } = useTranslation();
    const history = useHistory();

    return (
        <Dropdown
            item
            direction='left'
            text={t('general.menu')}
            icon={<FontAwesomeIcon icon={faBars}/>}
            className="menu-dropdown"
        >

            <Dropdown.Menu>

                {/* Projects */}
                <Dropdown.Item 
                    key='projects' 
                    text={t('navigation.projects')} 
                    icon='archive'
                    onClick={() => history.push("/projects")}
                />

                {/* Activities */}
                <Dropdown.Item 
                    key='activities' 
                    text={t('navigation.activities')} 
                    icon='check'
                    onClick={() => history.push("/activities")}
                />
                
                {/* Notifications */}
                <Dropdown.Item 
                    key='notifications' 
                    text={t('navigation.notifications')} 
                    icon='exclamation'
                    onClick={() => history.push("/notifications")}
                />

                {/* Contacts */}
                <Dropdown.Item 
                    key='contacts' 
                    text={t('navigation.contacts')} 
                    icon='user'
                    onClick={() => history.push("/contacts")}
                />

                {/* Customers */}
                <Dropdown.Item 
                    key='customers' 
                    text={t('navigation.customers')} 
                    icon='building'
                    onClick={() => history.push("/customers")}
                />

                <Dropdown.Divider/>    

                {/* Management - Visible to admins only! */}
                <Dropdown.Item 
                    key='management' 
                    text={t('navigation.management')} 
                    icon='cogs'
                    onClick={() => history.push("/management")}
                />

                {/* Info 
                <Dropdown.Item 
                    key='info' 
                    text={t('general.info')} 
                    icon='info'
                    onClick={() => history.push("/info")}
                />*/}

                {/*<Dropdown.Divider/>*/}   

                {/* Developer references 
                <Dropdown.Item 
                    key='development1' 
                    text={"New form example"} 
                    icon='cogs'
                    onClick={() => history.push("/developer/forms/new")}
                />
                <Dropdown.Item 
                    key='development2' 
                    text={"Edit form example"} 
                    icon='cogs'
                    onClick={() => history.push("/developer/forms/edit")}
                />
                <Dropdown.Item 
                    key='development3' 
                    text={"View form example"} 
                    icon='cogs'
                    onClick={() => history.push("/developer/forms/view")}
                />
                <Dropdown.Item 
                    key='development4' 
                    text={"Button examples"} 
                    icon='cogs'
                    onClick={() => history.push("/developer/forms/buttons")}
                />
                <Dropdown.Item 
                    key='development5' 
                    text={"Content examples"} 
                    icon='cogs'
                    onClick={() => history.push("/developer/forms/content")}
                />*/}

            </Dropdown.Menu>
        </Dropdown>
    );

};

const Messages = (props) => {

    const { getMessages } = useContext(MessageContext);
    let messages =  getMessages() || [];

    return (
        <div className="message-container">
            <div className={messages.length ? "messages active" : "messages" }>
                {messages}
            </div>
        </div>
    );

};

const Header = ({ setMobileMenuOpen }) => {

    const { t } = useTranslation();
    const { portalUpdate } = useContext(StateContext);

    const { MediaContextProvider, Media } = createMedia({
        breakpoints: {
            sm: 0,
            md: 768,
            lg: 1024,
            xl: 1192,
        },
    });

    let name = t('app.name') + " " + Config.releaseVersion;

    if (Config.environment === 'development') name += " (DEV)"

    return (
        <MediaContextProvider>
            <div className="app-header no-print">

                {/* Desktop */}
                <Media greaterThan='lg'>
                    <div className="top-menu">
                        <Segment vertical>
                            <Menu fixed='top' size='large'>
                                <Container fluid>

                                        <Menu.Item header>
                                            <Logo/>
                                        </Menu.Item>

                                        <Menu.Item className="app-name">{name}</Menu.Item>

                                        {portalUpdate && portalUpdate.body && 
                                            <Menu.Item className="app-name">
                                                <div className="portal-message">{portalUpdate.body}</div>
                                            </Menu.Item>
                                        }
                                        
                                        {/* Large screen menu */}
                                        <Menu.Menu position='right'>

                                            <ProfileDropDown position='right'/>

                                            <Menu.Item position='right'>
                                                <NavLink className="navlink" to ='/' exact >
                                                    <Icon name='home' />
                                                </NavLink>
                                            </Menu.Item>

                                            {/* Hidden in BB project */}
                                            {1 === 0 && <ActionsDropDown position='right'/>}

                                            {/* Mobile menu */}
                                            <Menu.Item
                                                    onClick={() => setMobileMenuOpen(true)}
                                                    position='right'
                                                >      
                                                    <Icon name='sidebar' /> {/*{t('general.menu')}*/}
                                            </Menu.Item>

                                        </Menu.Menu>

                                </Container>
                            </Menu>
                        </Segment>
                    </div>
                </Media>

                {/* Mobile menu */}
                <Media lessThan='xl'>
                    <div className="top-menu">
                        <Segment vertical>
                            <Menu fixed='top' size='large'>
                                <Container fluid className={'mobile'}>

                                    <Menu.Item header>
                                        <Logo/>
                                    </Menu.Item>

                                    <Menu.Item className="app-name">
                                        {t('app.name') + " " + Config.releaseVersion}
                                    </Menu.Item>

                                    <Menu.Item
                                        onClick={() => setMobileMenuOpen(true)}
                                        position='right'
                                    >   
                                        <Icon name='sidebar' /> {/* {t('general.menu')} */}
                                    </Menu.Item>

                               </Container>
                            </Menu>
                        </Segment>
                    </div>
                </Media>
            </div>
        </MediaContextProvider>
    );
};

const Content = ({children}) => {

    const { portalUpdate } = useContext(StateContext);

    const { MediaContextProvider, Media } = createMedia({
        breakpoints: {
            sm: 0,
            md: 768,
            lg: 1024,
            xl: 1192,
        },
    });

    return (
        <Container className="page">

            {/*Show portal message if message is added and user is using table or phone */}
            {portalUpdate && portalUpdate.body &&
                <MediaContextProvider>
                    <Media lessThan='xl'>
                        <div className='portal-message-container-mobile'>
                            <Message size='mini' visible className='portal-message-mobile' style={{ padding: "5px" }}>
                                <p style={{ textAlign: "center" }}>{portalUpdate.body}</p>
                            </Message>
                        </div>
                    </Media>
                </MediaContextProvider>
            }

            {children}

        </Container>
    );
}

const Footer = (props) => {
    return (
        <footer className="footer">
        </footer>
    );
}

const Loading = ({text}) => {
    return (
        <div id='loader'>
            <Segment>
                <Dimmer active>
                    <div>
                        <Image src='/images/logo.svg' className="logo"/>
                    </div>
                    <Loader size='massive'>{text}</Loader>
                </Dimmer>
            </Segment>
        </div>
    );
}

export {
    Loading,
    Messages,
    Header,
    Content,
    Footer,
    MobileNavigation
}