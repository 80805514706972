import { useState, useRef } from 'react';
import webApiClient from './../../Utils/webApiClient';
import { msalInstance } from '../../index';
import config from '../../Config/config';
import useRequestHandler from '../useRequestHandler';
import axios from 'axios';

/** Hook is used to search products by search term */
const useSearchProducts = () => {

    const { errorHandler, successHandler } = useRequestHandler();
    const [ products, setProducts ] = useState([]);
    const apiClient = webApiClient();
    
    const CancelToken = axios.CancelToken;
    const cancelTokenSource = useRef(null);

    /** Search products by search term
     * @param {string} searchterm - search term */
    const searchProducts = async (searchterm = "") => {
        try {

            if (cancelTokenSource.current) {
                //console.log('Cancel previous request');
                cancelTokenSource.current.cancel('Cancel previous request');
            }
            
            cancelTokenSource.current = CancelToken.source();

            // Account is set in the index.js
            const account = msalInstance.getActiveAccount();

            const response = await msalInstance.acquireTokenSilent({
                scopes: config.webApi.scopes,
                account: account
            });

            let resource = "search/products?searchterm=" + searchterm.toString();

            const result = await apiClient.read(response.accessToken, resource, null, { cancelToken: cancelTokenSource.current });  
            const data = result && result.data ? result.data : null;
            const content = data && data.resultData ? data.resultData : [];
            if (content) setProducts(content);

            return successHandler(result, "useSearchProducts", "search", { hide: true });

        } catch (error) {
            return errorHandler(error, "useSearchProducts", "search");
        }
    }

    return { products, setProducts, searchProducts };

}

export default useSearchProducts;
