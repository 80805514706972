import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { Form, Container, Message, Segment } from 'semantic-ui-react';
import { Loading } from '../../Components/UI/Loaders';
import { CancelButton } from '../../Components/UI/Buttons';
import { PageTitle } from '../../Components/UI/Content';

const EditProjectAttachement = (props) => {

    const { t } = useTranslation();
    const history = useHistory();
    const { projectId, attachmentId } = useParams();
    const [ isLoading ] = useState(false);
    const scollToTopRef = useRef();
    //const [ isLoading, setIsLoading ] = useState(false);

    useEffect(() => { 
        scollToTopRef.current.scrollIntoView();
    },[]);

    return (
        <Form className="attachement" >
                
            <div ref={scollToTopRef}></div>
            
            <Loading active={ isLoading } text={t('general.loading')} />

            <PageTitle active title={t('attachment.editProjectAttachement')} />

            <Segment>
                <p>View attachement is not implemented!</p>
            </Segment>

            {/* Errors */}
            <Message error visible={!projectId} content={t('errors.missingProjectId')} />
            <Message error visible={!attachmentId} content={t('errors.missingAttachmentId')} />

            {/* Buttons */}
            <Container className='button-container'>
                <CancelButton onClick={(ev) => {ev.preventDefault(); history.goBack(); }} />
            </Container>

        </Form>
    );

}

export default EditProjectAttachement;
