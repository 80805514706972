import { useState, useRef } from 'react';
import webApiClient from './../../Utils/webApiClient';
import { msalInstance } from '../../index';
import config from '../../Config/config';
import useRequestHandler from '../useRequestHandler';
import axios from 'axios';

/** Hook is used to search notifications by search term */
const useSearchNotifications = () => {

    const { errorHandler, successHandler } = useRequestHandler();
    const [ notifications, setNotifications ] = useState([]);
    const apiClient = webApiClient();

    const CancelToken = axios.CancelToken;
    const cancelTokenSource = useRef(null);

    /** Search notifications by search term
     * @param {string} searchterm - search term
     * @param {boolean} upcoming - upcoming notifications */
    const searchNotifications = async (searchterm = "", upcoming = true) => {
        try {

            if (cancelTokenSource.current) {
                //console.log('Cancel previous request');
                cancelTokenSource.current.cancel('Cancel previous request');
            }
            
            cancelTokenSource.current = CancelToken.source();

            // Account is set in the index.js
            const account = msalInstance.getActiveAccount();

            const response = await msalInstance.acquireTokenSilent({
                scopes: config.webApi.scopes,
                account: account
            });

            let resource = "search/notifications?searchterm=" + searchterm.toString();
            if (upcoming) resource += "&date=" + new Date().toISOString();

            const result = await apiClient.read(response.accessToken, resource, null, { cancelToken: cancelTokenSource.current }); 

            const data = result && result.data ? result.data : null;
            const content = data && data.resultData ? data.resultData : [];

            if (content) setNotifications(content);

            return successHandler(result, "useSearchNotifications", "search", { hide: true });

        } catch (error) {
            return errorHandler(error, "useSearchNotifications", "search");
        }
    }

    return { notifications, setNotifications, searchNotifications };

}

export default useSearchNotifications;
