import { useState } from 'react';
import webApiClient from './../../Utils/webApiClient';
import { msalInstance } from './../../index';
import config from './../../Config/config';
import useRequestHandler from '../useRequestHandler';

/** Hook is used to fetch list of projects */
const useListProjects = () => {

    const [ projects, setProjects ] = useState([]);
    const { errorHandler, successHandler } = useRequestHandler();
    const apiClient = webApiClient();

    /** List projects
     * @param {object} options - options object
     * @example {object} options
     * {
     *      own: true,              - list only my projects
     *      active: true,           - list only active projects
     *      passive: false,         - list only passive projects
     *      top: 200,               - return top n items f.e. 100 or 200 
     *      businesspartner: null   - filter by business partner
     * 
     * }
     * @description 
     * active=true && passive=true      return passive + active projects but no closed projects
     * active=true && passive=false     return active projects only
     * active=false && passive=true     return passive projects only
     * active=false && passive=false    return all projects **/
    const listProjects = async (options) => {
        try {

            // Account is set in the index.js
            const account = msalInstance.getActiveAccount();

            const response = await msalInstance.acquireTokenSilent({
                scopes: config.webApi.scopes,
                account: account
            });

            let own = options && (options.own === true || options.own === false) ? options.own : false
            let active = options && (options.active === true || options.active === false) ? options.active : true
            let passive = options && (options.passive === true || options.own === false) ? options.passive : false
            let top = options && options.top ? options.top : 200
            let businesspartner = options && options.businesspartner ? options.businesspartner : null

            // Create resource path
            let resource = "projects";
            resource += "?own=" + own.toString();
            resource += "&active=" + active.toString();
            resource += "&passive=" + passive.toString();
            resource += "&top=" + top.toString();
            if (businesspartner) resource += "&businesspartner=" + businesspartner.toString();

            const result = await apiClient.list(response.accessToken, resource);

            const data = result.data || {};
            const content = data.resultData || null;

            if (content) setProjects(content);

            return successHandler(result, "useListProjects", "list", { hide: true });

        } catch (error) {

            return errorHandler(error, "useListProjects", "list");

        }
    }

    return { projects, listProjects };

}

export default useListProjects;
