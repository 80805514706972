//import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import webApiClient from '../../Utils/webApiClient';
import { msalInstance } from '../../index';
import config from '../../Config/config';
import useRequestHandler from '../useRequestHandler';

/** Hook is used to create new calendar event to users calendar (f.e. Outlook). Endpoint calls Graph API */
const useCreateCalendarEvent = () => {
    
    const { t } = useTranslation();
    const { errorHandler, successHandler } = useRequestHandler();
    const apiClient = webApiClient();

    /** Create new calendar event
     * @param {object} event - Object which describes the calendar event
     * @example event
     * {
     *      "subject": "event subject",
     *      "body": {
     *          "contentType": "HTML",
     *          "content": "event body"
     *      },
     *      "start": {
     *          "dateTime": "2022-11-09T15:00:00.000",
     *          "timeZone": "FLE Standard Time"
     *      },
     *      "end": {
     *          "dateTime": "2022-11-09T15:30:00.000",
     *          "timeZone": "FLE Standard Time"
     *      },
     *      "location": {
     *          displayName": "event location"
     *      }
     * }
     **/
    const createCalendarEvent = async (event) => {
        try {

            // Account is set in the index.js
            const account = msalInstance.getActiveAccount();

            const response = await msalInstance.acquireTokenSilent({
                scopes: config.webApi.scopes,
                account: account
            });

            const result = await apiClient.create(response.accessToken, "calendarevents", event);

            return successHandler(result, "useCreateCalendarEvent", "create", { message: t('success.createCalendarEvent') });

        } catch (error) {
            return errorHandler(error, "useCreateCalendarEvent", "create", { message: t('errors.calendarEventCreationFailed') });
        }
    }

    return { createCalendarEvent };

}

export default useCreateCalendarEvent;
