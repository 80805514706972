//import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import webApiClient from './../../Utils/webApiClient';
import { msalInstance } from '../../index';
import config from '../../Config/config';
import useRequestHandler from '../useRequestHandler';

/** Hook is used to update contact */
const useUpdateContact = () => {

    const { t } = useTranslation();
    const { errorHandler, successHandler } = useRequestHandler();
    const apiClient = webApiClient();

    /** Update contact
     * @example object
     * {
     *      "id": 1,
     *      "firstName": "a",
     *      "lastName": "b",
     *      "name": "a b",
     *      "position": "c",
     *      "phoneNumber1": "+358",
     *      "phoneNumber2": "",
     *      "emailAddress": "abc@example.org",
     *      "businessPartner": 1,
     *      "deleted": false
     * }
     **/
    const updateContact = async (object) => {
        try {

            // Account is set in the index.js
            const account = msalInstance.getActiveAccount();

            const response = await msalInstance.acquireTokenSilent({
                scopes: config.webApi.scopes,
                account: account
            });

            const result = await apiClient.update(response.accessToken, "contacts", object);  

            return successHandler(result, "useUpdateContact", "update", { message: t('success.update') });

        } catch (error) {

            return errorHandler(error, "useUpdateContact", "update");

        }
    }

    return { updateContact };

}

export default useUpdateContact;
