import { useState, useRef } from 'react';
import webApiClient from './../../Utils/webApiClient';
import { msalInstance } from '../../index';
import config from '../../Config/config';
import useRequestHandler from '../useRequestHandler';
import axios from 'axios';

/** Hook is used to search projects by search term */
const useSearchProjects = () => {

    const { errorHandler, successHandler } = useRequestHandler();
    const [ projects, setProjects ] = useState([]);
    const apiClient = webApiClient();

    const CancelToken = axios.CancelToken;
    const cancelTokenSource = useRef(null);

    /** Search projects by search term
     * @param {String} searchterm - search term
     * @param {String} type - search projects by type "project", "product", "customer", "supplier"
     * @param {Object} options - additional search options
     * @example options
     * {
     *      own: queryOwn,      - list only my projects or all
     *      top: 200,           - list top n projects
     *      status: [1,2,3,4]   - array of status codes
     *      salesPerson: 1      - filter by sales person
     * }
     */
    const searchProjects = async (searchterm = "", type="project", options = {}) => {
        try {

            if (cancelTokenSource.current) {
                //console.log('Cancel previous request');
                cancelTokenSource.current.cancel('Cancel previous request');
            }
            
            cancelTokenSource.current = CancelToken.source();

            // Account is set in the index.js
            const account = msalInstance.getActiveAccount();

            const response = await msalInstance.acquireTokenSilent({
                scopes: config.webApi.scopes,
                account: account
            });

            let resource = "search/projects?searchterm=" + searchterm.toString();
                resource += "&type=" + type.toString();

            if (options) {

                if (options.own) resource += "&own=" + options.own.toString();
                if (options.top) resource += "&top=" + options.top.toString();
                if (options.status) resource += "&status=" + options.status.toString();
                if (options.salesPerson) resource += "&salesperson=" + options.salesPerson.toString();

            }

            const result = await apiClient.read(response.accessToken, resource, null, { cancelToken: cancelTokenSource.current });

            const data = result && result.data ? result.data : null;
            const content = data && data.resultData ? data.resultData : [];

            if (content) setProjects(content);

            return successHandler(result, "useSearchProjects", "search", { hide: true });

        } catch (error) {
            return errorHandler(error, "useSearchProjects", "search");
        }
    }

    return { projects, setProjects, searchProjects };

}

export default useSearchProjects;
