import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import webApiClient from './../../Utils/webApiClient';
import { msalInstance } from '../../index';
import config from '../../Config/config';
import useRequestHandler from '../useRequestHandler';

/** Hook is used to delete product entity */
const useDeleteProjectProduct = () => {

    const { t } = useTranslation();
    const { errorHandler, successHandler } = useRequestHandler();
    const [ doesNotExist, setDoesNotExist ] = useState(false);
    const apiClient = webApiClient();

    /** Delete project product
     * @param {integer} projectId - project id
     * @param {integer} productId - product id
     **/
    const deleteProjectProduct = async (projectId, productId) => {
        try {

            // Account is set in the index.js
            const account = msalInstance.getActiveAccount();

            const response = await msalInstance.acquireTokenSilent({
                scopes: config.webApi.scopes,
                account: account
            });

            // Create resource path
            let resource = "projects/" + projectId.toString() + "/products/" + productId.toString();

            const result = await apiClient.delete(response.accessToken, resource);            

            return successHandler(result, "useDeleteProjectProduct", "delete", { header: t('success.delete'), message: t('success.delete') });

        } catch (error) {

            const status = error.status || null;
            const data = error.data || {};
            const errorCode = data.errorCode || 0;

            if (status === 400 && errorCode === -1) setDoesNotExist(true);

            return errorHandler(error, "useDeleteProjectProduct", "delete");

        }
    }

    return { doesNotExist, setDoesNotExist, deleteProjectProduct };

}

export default useDeleteProjectProduct;
