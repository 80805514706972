import React, { useState, useEffect, useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { Form, Input, Container, Message, Grid, TextArea } from 'semantic-ui-react';
import { Loading } from '../../Components/UI/Loaders';
import { SubmitButton, CancelButton } from '../../Components/UI/Buttons';
import { PageTitle } from '../../Components/UI/Content';
import SearchAndSelectBusinessPartners from './../../Components/BusinessPartners/SearchAndSelectBusinessPartners';
import SearchAndSelectProducts from '../../Components/Product/SearchAndSelectProducts';
import { StateContext } from '../../Application/Context/StateContext';
import useUpdateProjectProduct from '../../Hooks/ProjectProducts/useUpdateProjectProduct';
import useReadProjectProduct from '../../Hooks/ProjectProducts/useReadProjectProduct';
import useReadProject from '../../Hooks/Project/useReadProject';
import useReadBusinessPartner from '../../Hooks/BusinessPartners/useReadBusinessPartner';
import useSearchProducts from '../../Hooks/Search/useSearchProducts';
import Utils from '../../Utils/utils';

const EditProjectProduct = (props) => {

    const { t } = useTranslation();
    const history = useHistory();
    const { projectId, id } = useParams();
    const scollToTopRef = useRef();

    const [ isLoading, setIsLoading ] = useState(true);
    const [ isProductListCollapsed, setIsProductListCollapsed ] = useState(true);
    const [ isValidSelectedProduct, setIsValidSelectedProduct ] = useState(true);
    const [ isValidSelectedSupplier, seIsValidSelectedSupplier ] = useState(true);

    const [ selectedProduct, setSelectedProduct ] = useState(null);
    const [ selectedSupplier, setSelectedSupplier ] = useState(null);
    const [ description, setDescription ] = useState("");
    const [ potentialVolumeInSalesUnitOfMeasure, setPotentialVolumeInSalesUnitOfMeasure ] = useState(0);
    const [ potentialSales, setPotentialSales ] = useState(0);
    const [ potentialMargin, setPotentialMargin ] = useState(0);
    //const [ marginPercentage, setMarginPercentage ] = useState(0);

    const { userProfile } = useContext(StateContext);
    const { project, readProject } = useReadProject();
    const { product, readProjectProduct } = useReadProjectProduct();
    const { businessPartner, readBusinessPartner } = useReadBusinessPartner();

    const { products, setProducts, searchProducts } = useSearchProducts();
    const { updateProjectProduct } = useUpdateProjectProduct();

    const onSubmit = async () => {
        try {

            if (!selectedProduct || !selectedProduct.Id) return setIsValidSelectedProduct(false);
            if (!selectedSupplier || !selectedSupplier.Id) return seIsValidSelectedSupplier(false);

            if (!projectId) return null;
            if (!id) return null;

            setIsLoading(true);

            const object = {
                "id": product.Id,                               // new or old project product row id
                "project": parseInt(projectId),                 // project id
                "product": parseInt(selectedProduct.product),   // new or old product id
                "businessPartner": selectedSupplier.Id,         // new or old supplier id
                "description": description,
                "potentialVolumeInSalesUnitOfMeasure": parseFloat(potentialVolumeInSalesUnitOfMeasure),
                "potentialSales": parseFloat(potentialSales),
                "potentialMargin": parseFloat(potentialMargin),
                "marginPercentage": parseFloat(Utils.calculateMarginPercentage(potentialSales, potentialMargin))
            }

            const result = await updateProjectProduct(projectId, object);

            if (result && result.status && result.status === 200 && result.data) {
                return history.push('/projects/view/'+ projectId);
            }

            setIsLoading(false);

        } catch (error) {
            setIsLoading(false);
        }
    }

    // Read project and product
    useEffect(() => {
        (async() => {
            scollToTopRef.current.scrollIntoView();
            setIsLoading(true);
            await readProject(projectId);
            await readProjectProduct(projectId, id);
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Update state when product data data has been fetched
    useEffect(() => {
        (async() => {
            if (product && product.product) {
                await readBusinessPartner(product.BusinessPartner);
                setDescription(product.Description)
                setPotentialVolumeInSalesUnitOfMeasure(product.PotentialVolumeInSalesUnitOfMeasure);
                setPotentialSales(product.PotentialSales);
                setPotentialMargin(product.PotentialMargin);
                //setMarginPercentage(product.MarginPercentage);
                    product.Name = product.ProductName;
                    product.Code = product.ProductCode;
                    product.SalesUnit = product.ProductSalesUnit;
                setSelectedProduct(product);
            }
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [product]);

    // Update state when business partner data has been fetched
    useEffect(() => { 
        if (businessPartner && businessPartner.Id) {
            setSelectedSupplier(businessPartner);
        }        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [businessPartner]);

    // Stop loader
    useEffect(() => {
        if (product && businessPartner && businessPartner.Id) {
            setIsLoading(false);
        }        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [product, businessPartner]);

    const userIsAdmin = userProfile && userProfile.IsAdmin ? true : false;
    const projectHasEnded = project && project.EndDate ? true : false; // If project has end date, project is ended and only admin can edit it!
    const projectIsNotActive = project && project.Status > 7 ? true : false; // if project status is greater than 7 (not active anymore) only admin can edit it!
    const disableEditing = (projectHasEnded || projectIsNotActive) && !userIsAdmin ? true : false;

    return (
        <Form className="notification" onSubmit={(ev) => { ev.preventDefault(); onSubmit(); }} >
                
            <div ref={scollToTopRef}></div>

            <Loading active={ isLoading } text={t('general.loading')} />

            <PageTitle active title={t('product.editProjectProduct')} />

            <Message warning visible={projectHasEnded || projectIsNotActive} content={t('warnings.projectIsNotActive')} />

            <SearchAndSelectProducts
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                title={t('product.searchProductByName')}
                placeholder={t('product.searchProductByName')}
                productSearchTerm=""
                selectedProduct={selectedProduct}
                setSelectedProduct={setSelectedProduct}
                isProductListCollapsed={isProductListCollapsed}
                setSelectedSupplier={setSelectedSupplier}
                selectedItemIsRequired={true}
                isValidSelectedItem={isValidSelectedProduct}
                products={products}
                setProducts={setProducts}
                searchProducts={searchProducts}
            />

            <SearchAndSelectBusinessPartners
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                title={t('businessPartner.searchSupplierByName')}
                placeholder={t('businessPartner.searchSupplierByName')}
                selectText={t('businessPartner.selectSupplier')}
                businessPartnerType={'supplier'}
                defaultBusinessPartnerSearchTerm={''}
                //selectedSupplier={selectedSupplier}
                selectedBusinessPartner={selectedSupplier}
                setSelectedBusinessPartner={setSelectedSupplier}
                setIsProductListCollapsed={setIsProductListCollapsed}
                selectedProduct={selectedProduct}
                selectedText={t('businessPartner.selectedSupplier')}
                selectedItemIsRequired={true}
                isValidSelectedItem={isValidSelectedSupplier}
                products={products}
                setProducts={setProducts}
            />

            {/* Description */}
            <Form.Field
                label={t('general.description')}
                placeholder={t('product.addOptionalProductDescription')}
                control={TextArea}
                value={description}
                onChange={(ev, {value}) => setDescription(value) }
                type="Text"
                rows={5}
            />

            {/* Potential Volume In Sales Unit Of Measure */}
            <Form.Field
                focus
                required
                label={t('product.potentialVolumeInSalesUnitOfMeasure')}
                placeholder={t('product.potentialVolumeInSalesUnitOfMeasure')}
                maxLength="255"
                control={Input}
                value={potentialVolumeInSalesUnitOfMeasure}
                onChange={(ev, {value}) => setPotentialVolumeInSalesUnitOfMeasure(value) }
                type="Number"
            />

            {/* Potential Sales */}
            <Form.Field
                required
                label={t('product.potentialSales')}
                placeholder={t('product.potentialSales')}
                control={Input}
                value={potentialSales}
                onChange={(ev, {value}) => {
                    setPotentialSales(value);
                }}
                type="Number"
            />

            {/* Potential Margin */}
            <Form.Field
                required
                label={t('product.potentialMargin')}
                placeholder={t('product.potentialMargin')}
                control={Input}
                value={potentialMargin}
                onChange={(ev, {value}) => {
                    setPotentialMargin(value);
                }}
                type="Number"
            />

            <Form.Field label={t('product.marginPercentage')} />
            <p>{Utils.roundNumber(Utils.calculateMarginPercentage(potentialSales, potentialMargin), 2)}</p>

            <p>{t('product.marginPercentageCalculationInfo')}</p>
            <p>{t('product.calculatedUnitPrice')} : {Utils.roundNumber(Utils.calculateUnitPrice(potentialSales, potentialVolumeInSalesUnitOfMeasure), 2) + " €"}</p>

            <Grid stackable>

                {/* Business Partner Name */}
                <Grid.Row>
                    <Grid.Column width={4}><Form.Field label={t('businessPartner.supplier')} /></Grid.Column>
                    <Grid.Column width={12}><p>{product.BusinessPartnerName}</p></Grid.Column>
                </Grid.Row>

                {/* Supplier business code */}
                <Grid.Row>
                    <Grid.Column width={4}><Form.Field label={t('general.businessId')} /></Grid.Column>
                    <Grid.Column width={12}><p>{businessPartner.AssociationNumber}</p></Grid.Column>
                </Grid.Row>

                {/* Supplier area */}
                <Grid.Row>
                    <Grid.Column width={4}><Form.Field label={t('businessPartner.area')} /></Grid.Column>
                    <Grid.Column width={12}><p>{businessPartner.AreaName}</p></Grid.Column>
                </Grid.Row>

                {/* Project Product Name */}
                <Grid.Row>
                    <Grid.Column width={4}><Form.Field label={t('product.product')} /></Grid.Column>
                    <Grid.Column width={12}><p>{product.ProductName}</p></Grid.Column>
                </Grid.Row>

                {/* Product Description */}
                <Grid.Row>
                    <Grid.Column width={4}><Form.Field label={t('general.description')} /></Grid.Column>
                    <Grid.Column width={12}><p>{product.ProductDescription}</p></Grid.Column>
                </Grid.Row>

                {/* Product Code */}
                <Grid.Row>
                    <Grid.Column width={4}><Form.Field label={t('product.code')} /></Grid.Column>
                    <Grid.Column width={12}><p>{product.ProductCode}</p></Grid.Column>
                </Grid.Row>

                {/* ProductPrice */}
                <Grid.Row>
                    <Grid.Column width={4}><Form.Field label={t('product.price')} /></Grid.Column>
                    <Grid.Column width={12}><p>{product.ProductPrice}</p></Grid.Column>
                </Grid.Row>

                {/* ProductInUse */}
                <Grid.Row>
                    <Grid.Column width={4}><Form.Field label={t('product.inUse')} /></Grid.Column>
                    <Grid.Column width={12}><p>{product.ProductInUse ? t('general.yes') : t('general.no')}</p></Grid.Column>
                </Grid.Row>

                {/* Product Stock Unit */}
                <Grid.Row>
                    <Grid.Column width={4}><Form.Field label={t('product.stockUnit')} /></Grid.Column>
                    <Grid.Column width={12}><p>{product.ProductStockUnit}</p></Grid.Column>
                </Grid.Row>

                {/* Product Sales Unit */}
                <Grid.Row>
                    <Grid.Column width={4}><Form.Field label={t('product.salesUnit')} /></Grid.Column>
                    <Grid.Column width={12}><p>{product.ProductSalesUnit}</p></Grid.Column>
                </Grid.Row>

                {/* Product Count Unit */}
                <Grid.Row>
                    <Grid.Column width={4}><Form.Field label={t('product.countUnit')} /></Grid.Column>
                    <Grid.Column width={12}><p>{product.ProductCountUnit}</p></Grid.Column>
                </Grid.Row>

                {/* ProductItemGroup */}
                <Grid.Row>
                    <Grid.Column width={4}><Form.Field label={t('product.itemGroup')} /></Grid.Column>
                    <Grid.Column width={12}><p>{product.ProductItemGroup}</p></Grid.Column>
                </Grid.Row>

                {/* Product Country Of Origin */}
                <Grid.Row>
                    <Grid.Column width={4}><Form.Field label={t('product.countryOfOrigin')} /></Grid.Column>
                    <Grid.Column width={12}><p>{product.ProductCountryOfOrigin}</p></Grid.Column>
                </Grid.Row>

                {/* Product Net Weight */}
                <Grid.Row>
                    <Grid.Column width={4}><Form.Field label={t('product.netWeight')} /></Grid.Column>
                    <Grid.Column width={12}><p>{product.ProductNetWeight}</p></Grid.Column>
                </Grid.Row>

                {/* ProductPackageTypeQty */}
                <Grid.Row>
                    <Grid.Column width={4}><Form.Field label={t('product.packageTypeQty')} /></Grid.Column>
                    <Grid.Column width={12}><p>{product.ProductPackageTypeQty}</p></Grid.Column>
                </Grid.Row>

                {/* ProductPackageType */}
                <Grid.Row>
                    <Grid.Column width={4}><Form.Field label={t('product.packageType')} /></Grid.Column>
                    <Grid.Column width={12}><p>{product.ProductPackageType}</p></Grid.Column>
                </Grid.Row>

                {/* Potential Volume In Sales Unit Of Measuree */}
                <Grid.Row>
                    <Grid.Column width={4}><Form.Field label={t('product.potentialVolumeInSalesUnitOfMeasure')} /></Grid.Column>
                    <Grid.Column width={12}><p>{product.PotentialVolumeInSalesUnitOfMeasure}</p></Grid.Column>
                </Grid.Row>

                {/* Potential Sales */}
                <Grid.Row>
                    <Grid.Column width={4}><Form.Field label={t('project.potentialSales')} /></Grid.Column>
                    <Grid.Column width={12}><p>{product.PotentialSales}</p></Grid.Column>
                </Grid.Row>

                {/* Potential Margin */}
                <Grid.Row>
                    <Grid.Column width={4}><Form.Field label={t('project.potentialMargin')} /></Grid.Column>
                    <Grid.Column width={12}><p>{Utils.roundNumber(product.PotentialMargin, 2)}</p></Grid.Column>
                </Grid.Row>

                {/* Margin Percentage */}
                <Grid.Row>
                    <Grid.Column width={4}><Form.Field label={t('project.marginPercentage')} /></Grid.Column>
                    <Grid.Column width={12}><p>{Utils.roundNumber(product.MarginPercentage, 2)}</p></Grid.Column>
                </Grid.Row>

                {/* Created */}
                <Grid.Row>
                    <Grid.Column width={4}><Form.Field label={t('general.created')} /></Grid.Column>
                    <Grid.Column width={12}><p>{product.Created ? new Date(product.Created).toLocaleDateString("fi-FI") : ''}</p></Grid.Column>
                </Grid.Row>

                {/* Modified */}
                <Grid.Row>
                    <Grid.Column width={4}><Form.Field label={t('general.modified')} /></Grid.Column>
                    <Grid.Column width={12}><p>{product.Modified ? new Date(product.Modified).toLocaleDateString("fi-FI") : ''}</p></Grid.Column>
                </Grid.Row>

                {/* Deleted */}
                <Grid.Row>
                    <Grid.Column width={4}><Form.Field label={t('general.deleted')} /></Grid.Column>
                    <Grid.Column width={12}><p>{product.Deleted ? t('general.yes') : t('general.no')}</p></Grid.Column>
                </Grid.Row>

            </Grid>

            {/* Errors */}
            <Message error visible={!projectId} content={t('errors.missingProjectId')} />
            <Message error visible={!id} content={t('errors.missingProductId')} />

            {/* Buttons */}
            <Container className='button-container'>
                <CancelButton onClick={(ev) => {ev.preventDefault(); history.goBack(); }}/>
                <SubmitButton disabled={!selectedProduct && !disableEditing} className="bb-lblue" icon="save" title={t('general.save')} type="submit" />
            </Container>

        </Form>
    );

}

export default EditProjectProduct;
