import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Form, Search, Checkbox } from 'semantic-ui-react';
import useSearchActivities from '../../Hooks/Search/useSearchActivities';
import './../components.css';

/** Bang & Bonsomer - Search Activities Component */
const SearchActivities = ({

    required,

    title,
    placeholder,

    isLoading,
    setIsLoading,

    selectedActivity,
    setSelectedActivity,

    setIsValidSelectedActivity

}) => {

    const { t } = useTranslation();
    const [ activityTitle, setActivityTitle ] = useState("");
    const { activities, searchActivities } = useSearchActivities();
    const [ myActivitiesOnly, setMyActivitiesOnly ] = useState(true);
    const [ timer, setTimer ] = useState(null);

    return (
        <Container className="fieldcontent">

            {/* My Activities Only */}
            <Container className="fieldcontent">
                <Form.Field
                    control={Checkbox}
                    label={t('activity.myActivitiesOnly')}
                    placeholder={t('activity.myActivitiesOnly')}
                    checked={myActivitiesOnly}
                    onChange={(ev, {checked}) => setMyActivitiesOnly(checked) }
                />
            </Container>

            <Form.Field
                fluid
                required={required ? required : false}
                loading={isLoading}
                label={title || null}
                placeholder={placeholder || t('project.searchActivities')}
                icon='search'
                className="bb-search"
                control={Search}
                onKeyPress={async (ev, data) => {
                    if (ev.key === 'Enter') {
                        ev.preventDefault();
                        setIsLoading(true);

                        const options = {
                            own: myActivitiesOnly,
                            businesspartner: null,
                            project: null,
                            top: 10,
                            salesPerson: null
                        }

                        let result = await searchActivities(activityTitle, options);
                        if (!result.requestCancelled) setIsLoading(false);
                        setSelectedActivity(null);
                    }
                }}
                onResultSelect={(e, {result}) => {
                    clearTimeout(timer);
                    setActivityTitle(result.title);
                    let proj = activities.find(item => item.Id === result.value);
                    if (proj) setSelectedActivity(proj);
                    if (proj && setIsValidSelectedActivity) setIsValidSelectedActivity(true);
                    if (!proj) setSelectedActivity(null);
                }}
                onSearchChange={async (ev, {value}) => {

                    setActivityTitle(value);

                    // If previous timeout is set, remove it!
                    if (timer) {
                        setIsLoading(false);
                        clearTimeout(timer);
                    }

                    // Set new timeout which calls the search api endpoint
                    let temp = setTimeout(async () => {
                        setIsLoading(true);

                        const options = {
                            own: myActivitiesOnly,
                            businesspartner: null,
                            project: null,
                            top: 10,
                            salesPerson: null
                        }

                        let result = await searchActivities(value, options);
                        if (!result.requestCancelled) setIsLoading(false);
                        setSelectedActivity(null);
                    }, 800);

                    setTimer(temp);

                }}
                results={
                    activities.map((ac, index) => {
                        return {
                            key: index,
                            value: ac.Id,
                            title: ac.Title + " (" + ac.Id + ")"
                            //description: ac.Code
                        }
                    })
                }
                value={activityTitle}
            />
        </Container>

    );
}

export default SearchActivities;
