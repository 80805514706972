import React, { useContext } from 'react';
//import { Dimmer } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { MsalContext, UnauthenticatedTemplate  } from "@azure/msal-react";

const Error = () => {

    const { t } = useTranslation();
    const { accounts } = useContext(MsalContext);
    const error = { textAlign: "center", marginTop: "100px" }
    const errorP = { fontSize: "72px", fontWeight: "bold" }

    return (
        <div id='error' style={error}>
            <h1>{t('errors.errorOccurred')}</h1>
            <p style={errorP}>500</p>

            {accounts && accounts.length === 0 && 
                <UnauthenticatedTemplate>
                    <p style={{ color: "red", fontSize: "20px" }}>{t('errors.noPermission')}</p>
                </UnauthenticatedTemplate>
            }
        </div>
    );

}

export default Error;