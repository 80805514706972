import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { Form, Container, Message} from 'semantic-ui-react';
import { Loading } from '../../Components/UI/Loaders';
import { SubmitButton, CancelButton, NewButton } from '../../Components/UI/Buttons';
import PagedTable from '../../Components/UI/Lists';
import { PageTitle, SearchItems } from '../../Components/UI/Content';
import useCreateProjectContact from '../../Hooks/ProjectContacts/useCreateProjectContact';
import useSearchContacts from '../../Hooks/Search/useSearchContacts';

const NewProjectContact = (props) => {

    const { t } = useTranslation();
    const history = useHistory();
    const scollToTopRef = useRef();
    const { projectId } = useParams();
    const [ isLoading, setIsLoading ] = useState(false);
    const { contacts, searchContacts } = useSearchContacts();
    const { createProjectContact } = useCreateProjectContact();

    const onAddContactToProject = async (contactId) => {
        try {
            if (!projectId) return null;
            setIsLoading(true);
            const result = await createProjectContact(projectId, contactId);

            if (result && result.status && result.status === 200) {
                return history.goBack();
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    }
    
    useEffect(() => {
        scollToTopRef.current.scrollIntoView();
    }, []);

    return (
        <Form className="contact">
        
            <div ref={scollToTopRef}></div>

            <Loading active={ isLoading } text={t('general.loading')} />

            <PageTitle active title={t('contact.newProjectContact')} />

            <SearchItems
                title={t('general.search')}
                placeholder={t('contact.searchContactsByName')}
                searchItems={async (searchTerm) => {
                    setIsLoading(true);
                    let result = await searchContacts(searchTerm);
                    if (!result.requestCancelled) setIsLoading(false);                    
                }}
                defaultSearchTerm=""
                setIsLoading={setIsLoading}
                //setError={setError}
            />

            <Container className="fieldcontent">
                <PagedTable unstackable celled striped compact='very' pageSize={20}
                    header={[
                        t('general.name'),
                        //t('general.position'),
                        //t('general.phone'),
                        //t('general.email'),
                        t('businessPartner.businessPartner'),
                        t('general.action')
                    ]}
                    content={contacts}
                    resetPageOnContentChange={true}
                    contentKeys={[
                        {key: 'Name'},
                        //{key: 'Position' },
                        //{key: 'PhoneNumber1'},
                        //{key: 'EmailAddress'},
                        {key: 'BusinessPartnerName'},
                        {key: 'Id', type: 'action', text: t('contact.addContactToProject'), action: (ev, row, cellKey) => { 
                            onAddContactToProject(row.Id);
                        }}
                    ]}
                />
            </Container>

            <Container textAlign="right" className="fieldcontent">
                <NewButton
                    title={t('contact.newContact')}
                    size="large"
                    fluid
                    className="bb-lblue"
                    onClick={(ev) => { ev.preventDefault(); history.push('/contacts/new'); }}
                />

            </Container>

            {/* Errors */}
            <Message error visible={!projectId} content={t('errors.missingProjectId')} />

            {/* Buttons */}
            <Container className="fieldcontent button-container">
                <CancelButton onClick={(ev) => {ev.preventDefault(); history.goBack(); }} />
                <SubmitButton className="bb-lblue" icon="save" title={t('general.save')} type="submit" />
            </Container>

        </Form>
    );

}

export default NewProjectContact;
