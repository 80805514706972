import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Input, Checkbox, Grid } from 'semantic-ui-react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

/** Create new outlook calendar event
 * 
 * @param {State} addToOutlook react state addToOutLook
 * @param {SetState} setAddToOutlook change addToOutlook state
 * 
 * @param {string} title event title
 * @param {string} body event body
 * 
 * @param {State} startDate react state startDate
 * @param {SetState} setStartDate react change state setStartDate
 * 
 * @param {State} endDate react event endDate
 * @param {SetState} setEndDate react change state setEndDate
 * 
 * @param {State} location react state location
 * @param {SetState} setLocation react change state location
 * 
 *  @example event
 * {
 *      "subject": title.toString(),
 *      "body": {
 *          "contentType": "HTML",
 *          "content": body.toString()
 *      },
 *      "start": {
 *          "dateTime": "2022-11-09T15:00:00.000",
 *          "timeZone": "FLE Standard Time"
 *      },
 *      "end": {
 *          "dateTime": "2022-11-09T15:30:00.000",
 *          "timeZone": "FLE Standard Time"
 *      },
 *          "location": {
 *          "displayName": "Test location"
 *      }
 *  } **/
const NewCalendarEvent = ({
    addToOutlook, setAddToOutlook,
    title,
    body,
    startDate, setStartDate,
    endDate, setEndDate,
    location, setLocation
}) => {

    const { t } = useTranslation();
    const borderStyle = { border: '1px solid #e2e2e2', padding: '5px', borderRadius: '5px' };

    return (
        <div className='calendarevent'>

            <Form.Field label={t('calendar.outlookEvent')} />

            <Form.Field
                control={Checkbox}
                label={t('calendar.addToOutlook')}
                placeholder={t('calendar.addToOutlook')}
                checked={addToOutlook}
                onChange={(ev, {checked}) => {
                    setAddToOutlook(checked);
                }}
            />
            
            {addToOutlook && 
                <Grid stackable columns={2} className="compact" style={borderStyle} >
                    <Grid.Row>

                        <Grid.Column width={2}>
                            <Form.Field label={t('calendar.title')} />
                        </Grid.Column>
                        <Grid.Column>
                            <p>{title || "-"}</p>
                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Row>
                        <Grid.Column width={2}>
                            <Form.Field label={t('calendar.body')}/>
                        </Grid.Column>
                        <Grid.Column>
                            <p>{body || "-"}</p>
                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Row>
                        <Grid.Column width={2}>
                            <Form.Field label={t('calendar.startTime')}/>
                        </Grid.Column>
                        <Grid.Column>
                            <DatePicker
                                required
                                label={t('calendar.startTime')}
                                selected={startDate}
                                onChange={(newDate) => {
                                    const d = new Date(newDate);
                                    d.setMilliseconds(0);
                                    d.setSeconds(0);
                                    setStartDate(d)
                                }}
                                timeFormat="HH:mm"
                                dateFormat={"dd.MM.yyyy HH:mm"}
                                showTimeSelect               
                            />
                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Row>
                        <Grid.Column width={2}>
                            <Form.Field label={t('calendar.endTime')}/>
                        </Grid.Column>
                        <Grid.Column>
                            <DatePicker
                                required
                                label={t('calendar.endTime')}
                                selected={endDate}
                                onChange={(newDate) => {
                                    const d = new Date(newDate);
                                    d.setSeconds(0);
                                    d.setMilliseconds(0);
                                    setEndDate(d)
                                }}
                                timeFormat="HH:mm"
                                dateFormat={"dd.MM.yyyy HH:mm"}
                                showTimeSelect               
                            />
                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Row>
                        <Grid.Column width={2}>
                            <Form.Field label={t('calendar.location')}/>
                        </Grid.Column>
                        <Grid.Column>
                            <Form.Field
                                placeholder={t('calendar.location')}
                                maxLength="255"
                                control={Input}
                                value={location}
                                onChange={(ev, {value}) => setLocation(value) }
                                type="Text"
                            />
                        </Grid.Column>
                    </Grid.Row>

                </Grid>
            }

        </div>
    );

}

export default NewCalendarEvent;
