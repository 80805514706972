import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { Form, Container, Input } from 'semantic-ui-react';
import validator from 'validator';
import { Loading } from '../../Components/UI/Loaders';
import { SubmitButton, CancelButton } from '../../Components/UI/Buttons';
import { PageTitle, SelectedItemLight  } from '../../Components/UI/Content';
import useReadContact from '../../Hooks/Contacts/useReadContact';
import useUpdateContact from '../../Hooks/Contacts/useUpdateContact';
import useReadBusinessPartner from '../../Hooks/BusinessPartners/useReadBusinessPartner';
import SearchBusinessPartners from '../../Components/BusinessPartners/SearchBusinessPartners';

const EditContact = (props) => {

    const { t } = useTranslation();
    const history = useHistory();
    const { contactId } = useParams();
    const scollToTopRef = useRef();

    const [ isLoading, setIsLoading ] = useState(true);
    const [ isValidEmail, setIsValidEmail ] = useState(true);

    const [ selectedBusinessPartner, setSelectedBusinessPartner ] = useState(null);
    const [ firstName, setFirstName ] = useState("");
    const [ lastname, setLastname ] = useState("");
    const [ position, setPosition ] = useState("");
    const [ phoneNumber1, setPhoneNumber1 ] = useState("");
    const [ phoneNumber2, setPhoneNumber2 ] = useState("");
    const [ emailAddress, setEmailAddress ] = useState("");

    const { contact, readContact} = useReadContact();
    const { updateContact } = useUpdateContact();
    const { businessPartner, readBusinessPartner } = useReadBusinessPartner();

    const [ isValidSelectedCustomer, setIsValidSelectedCustomer ] = useState(true);

    // Read contact
    useEffect(() => {
        (async() => {
            scollToTopRef.current.scrollIntoView();
            setIsLoading(true);
            await readContact(contactId);
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        (async () => {
            if (contact) {
                if (contact.BusinessPartner) await readBusinessPartner(contact.BusinessPartner);
                if (contact.FirstName) setFirstName(contact.FirstName);
                if (contact.LastName) setLastname(contact.LastName);
                if (contact.Position) setPosition(contact.Position);
                if (contact.PhoneNumber1) setPhoneNumber1(contact.PhoneNumber1);
                if (contact.PhoneNumber2) setPhoneNumber2(contact.PhoneNumber2);
                if (contact.EmailAddress) setEmailAddress(contact.EmailAddress);
            }
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contact]);

    useEffect(() => {
        if (businessPartner && businessPartner.Id) {
            setSelectedBusinessPartner(businessPartner);
        }
        setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [businessPartner]);

    // Save data on submit
    const onSubmit = async () => {
        try {

            if (!selectedBusinessPartner) return setIsValidSelectedCustomer(false);

            setIsLoading(true);

            const editContact = {
                "id": contactId,
                "firstName": firstName.trim(),
                "lastName": lastname.trim(),
                "position": position.trim(),
                "phoneNumber1": phoneNumber1.trim(),
                "phoneNumber2": phoneNumber2.trim(),
                "emailAddress": emailAddress.trim(),
                "businessPartner": null
            }

            if (selectedBusinessPartner && selectedBusinessPartner.Id) {
                editContact.businessPartner = parseInt(selectedBusinessPartner.Id)
            }

            const result = await updateContact(editContact);

            if (result && result.status && result.status === 200) {
                return history.goBack();
            }

            setIsLoading(false);

        } catch (error) {
            setIsLoading(false);
        }
    }

    return (
        <Form className="contact" onSubmit={(ev) => { ev.preventDefault(); onSubmit(); }} >
                
            <div ref={scollToTopRef}></div>
            
            <Loading active={ isLoading } text={t('general.loading')} />

            <PageTitle active title={t('contact.editContact')} />

            {/* Business partner is NOT selected - Show Searchbox */}
            {!selectedBusinessPartner &&
                <SearchBusinessPartners
                    title={t('businessPartner.searchBusinessPartner')}
                    placeholder={t('businessPartner.searchBusinessPartner')}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                    selectedBusinessPartner={selectedBusinessPartner}
                    setSelectedBusinessPartner={setSelectedBusinessPartner}
                    setIsValidSelectedBusinessPartner={setIsValidSelectedCustomer}
                    type={null}
                />
            }

            {/* Business partner is selected - Show selected item */}
            {selectedBusinessPartner &&
                <SelectedItemLight
                    title={t('businessPartner.selectedBusinessPartner')}
                    item={selectedBusinessPartner}
                    headers={[t('general.name'), t('businessPartner.area'), t('general.type'), t('general.businessId')]}
                    fields={['Name', 'AreaName', 'Type', 'AssociationNumber']}
                    required={true}
                    isValidSelectedItem={isValidSelectedCustomer}
                    onRemoveSelection={(ev) => {
                        setSelectedBusinessPartner(null);
                    }}
                />
            }

            {/* Firstname */}
            <Form.Field
                required
                label={t('general.firstName')}
                placeholder={t('general.firstName')}
                maxLength="255"
                control={Input}
                value={firstName}
                onChange={(ev, {value}) => setFirstName(value) }
                type="Text"
            />

            {/* Lastname */}
            <Form.Field
                required
                label={t('general.lastName')}
                placeholder={t('general.lastName')}
                maxLength="255"
                control={Input}
                value={lastname}
                onChange={(ev, {value}) => setLastname(value) }
                type="Text"
            />
           
            {/* Position */}
            <Form.Field
                //required
                label={t('general.position')}
                placeholder={t('general.position')}
                maxLength="255"
                control={Input}
                value={position}
                onChange={(ev, {value}) => setPosition(value) }
                type="Text"
            />

            {/* Phone 1 */}
            <Form.Field
                //required
                label={t('general.phone') + " 1"}
                placeholder={t('general.phone')}
                maxLength="255"
                control={Input}
                value={phoneNumber1}
                onChange={(ev, {value}) => setPhoneNumber1(value) }
                type="Text"
            />

            {/* Phone 2 */}
            <Form.Field
                //required
                label={t('general.phone') + " 2"}
                placeholder={t('general.phone')}
                maxLength="255"
                control={Input}
                value={phoneNumber2}
                onChange={(ev, {value}) => setPhoneNumber2(value) }
                type="Text"
            />

            {/* Email */}
            <Form.Field
                //required
                label={t('general.email')}
                placeholder={t('general.email')}
                maxLength="255"
                control={Input}
                value={emailAddress}
                onChange={(ev, {value}) => {
                    if (value.length === 0) {
                        setIsValidEmail(true);
                    } else {
                        const isValid = validator.isEmail(value);
                        setIsValidEmail(isValid);
                    }
                    setEmailAddress(value);
                }}
                type="Text"
                error={ !isValidEmail ? { 
                    content: t('errors.enterValidEmailAddress'),
                    pointing: 'below'}
                    : false
                }

            />

            {/* Buttons */}
            <Container className='button-container'>
                <CancelButton onClick={(ev) => {ev.preventDefault(); history.goBack(); }} />
                <SubmitButton type="submit" className="bb-lblue" icon="save" title={t('general.save')} />
            </Container>

        </Form>
    );

}

export default EditContact;
