import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { Container, Input } from 'semantic-ui-react';
import { PageTitle } from '../../Components/UI/Content';
import { NewButton, CancelButton } from '../../Components/UI/Buttons';
import { Loading } from '../../Components/UI/Loaders';
import PagedTable from '../../Components/UI/Lists';
import useSearchContacts from '../../Hooks/Search/useSearchContacts';
import { StateContext } from '../../Application/Context/StateContext';
import { Accordion, Header, Grid, Icon, Segment } from 'semantic-ui-react';
import { SearchButton } from '../../Components/UI/Buttons';

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

/** Search items box component
 * @param {Function} setIsLoading - React setState function for updating loading state
 * @param {String} searchTerm - React state value which is added to the searchField
 * @param {Function} setSearchTerm - React setState function which updates the searchField value
 * @param {Function} searchContacts - Search contacts function. f.e. reach hook "searchContacts"
*/
const SearchAllContacts = ({
    setIsLoading,
    searchTerm,
    setSearchTerm,
    searchContacts
}) => {

   const { t } = useTranslation();
   const [ activeIndex, setActiveIndex ] = useState(0);

   /* Search items when user clicks search */
   const onClickSearch = async (searchTerm) => {
        setIsLoading(true);
        let result = await searchContacts(searchTerm);
        if (!result.requestCancelled) setIsLoading(false);
   }

   return (       
       <Container className={"fieldcontent"}>
           <Accordion>
               
               <Accordion.Title
                   active={activeIndex === 0}
                   index={0}
                   onClick={() => { setActiveIndex(activeIndex === 0 ? -1 : 0); }}
                   style={{paddingBottom: 0}}
               >
                   <Header as='h3' attached='top' className="search-items-header">
                       <Grid>
                           <Grid.Column width="8" textAlign="left">
                               <b>{t('general.search')}</b>
                           </Grid.Column>
                           <Grid.Column  width="8" textAlign="right">
                               <Icon name='dropdown' />
                           </Grid.Column>
                       </Grid>
                   </Header>
               </Accordion.Title>
               
               <Accordion.Content active={activeIndex === 0} style={{paddingTop: 0}}>
                   <Segment attached style={{ borderRadius: "0 0 5px 5px" }}>

                       <Input
                           fluid
                           icon='search'
                           placeholder={t('contact.searchContactsByName')}
                           value={searchTerm}
                           onKeyPress={(ev, data) => {
                               if (ev.key === 'Enter') {
                                   ev.preventDefault();
                                   onClickSearch(searchTerm);
                               }
                           }}
                           onChange={(ev, {value}) => {
                                setSearchTerm(value);
                           }}
                           className="fieldcontent"
                       />

                       {/* Search items button */}
                       <Container textAlign="right">
                           <SearchButton className="bb-lblue" type="reset" onClick={(ev) => { onClickSearch(searchTerm); }} />
                       </Container>

                   </Segment>
               </Accordion.Content>

           </Accordion>
       </Container>
   );
}

const Contacts = (props) => {

    const { t } = useTranslation();
    const history = useHistory();
    let query = useQuery();
    const { searchContactsTerm, setSearchContactsTerm } = useContext(StateContext);
    const { contacts, searchContacts } = useSearchContacts();
    const [ isLoading, setIsLoading ] = useState(true);
    const [ filterContactList, setFilterContactList ] = useState('');

    // Load contacts on page load
    useEffect(() => {
        (async() => {

            setIsLoading(true);

            // DEFAULT CASE
            let defaultBusinessPartner = searchContactsTerm;

            // CASE: User navigated from customer statistics page
            if (query.get("name")) defaultBusinessPartner = query.get("name");
            //if (query.get("id")) defaultBusinessPartnerId = query.get("id");

            await searchContacts(defaultBusinessPartner);

            setSearchContactsTerm(defaultBusinessPartner);
            setIsLoading(false);

        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);    

    const filteredContacts = contacts.filter(contact => {
        let matchName = contact.Name ? contact.Name.toLowerCase().indexOf(filterContactList.toLowerCase()) !== -1 : false;
        let matchEmailAddress = contact && contact.EmailAddress ? contact.EmailAddress.toLowerCase().indexOf(filterContactList.toLowerCase()) !== -1 : false;
        let matchPhoneNumber1 = contact && contact.PhoneNumber1 ? contact.PhoneNumber1.toLowerCase().indexOf(filterContactList.toLowerCase()) !== -1 : false;
        if (matchName || matchEmailAddress || matchPhoneNumber1) return true;
        return false;
    });

    return (
        <Container className="contacts">
            
            <Loading active={ isLoading } text={t('general.loading')} />

            <PageTitle
                active
                title={t('contact.contacts')}
                button={
                    <NewButton
                        className="bb-lblue"
                        mini
                        onClick={(ev) => { ev.preventDefault(); history.push('/contacts/new'); }}
                    />
                }
            />

            <SearchAllContacts
                setIsLoading={setIsLoading}
                searchTerm={searchContactsTerm}
                setSearchTerm={setSearchContactsTerm}
                searchContacts={searchContacts}
            />
            
            {/* Filter */}
            <Container className="fieldcontent">
                <Input
                    action={{
                        labelPosition: 'left',
                        icon: 'delete',
                        content: t('general.clear'),
                        style: {margin: 0},
                        onClick:(ev) => {
                            ev.preventDefault();
                            setFilterContactList('');
                        }
                    }}
                    onChange={(ev, {value} ) => {
                        ev.preventDefault();
                        setFilterContactList(value)
                    }}
                    fluid
                    //actionPosition='right'
                    placeholder={t('general.filter')}
                    value={filterContactList}
                />
            </Container>

            {/* Contacts list */}
            <Container className="fieldcontent">
                <PagedTable unstackable celled striped compact='very' pageSize={20}
                    header={[
                        t('general.name'),
                        t('general.email'),
                        t('general.phone') + " 1",
                        t('businessPartner.customer'),
                        t('general.actions'),
                        ''
                    ]}
                    content={filteredContacts}
                    resetPageOnContentChange={true}
                    contentKeys={[
                        {key: 'Name', target: '/contacts/view/', type: 'link', targetKey: 'Id'},
                        {key: 'EmailAddress'},
                        {key: 'PhoneNumber1'},
                        {key: 'BusinessPartnerName'},
                        {key: 'Id', target: '/contacts/view/', type: 'link', text: t('general.view'), targetKey: 'Id'},
                        {key: 'Id', target: '/contacts/edit/', type: 'link', text: t('general.edit'), targetKey: 'Id'}
                    ]}
                />
            </Container>
                            
            {/* Buttons */}
            <Container className='fieldcontent button-container'>
                <CancelButton title={t('general.back')} onClick={(ev) => {ev.preventDefault(); history.goBack(); }} />
            </Container>

        </Container>
    );

}

export default Contacts;
