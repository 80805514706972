//import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import webApiClient from './../../Utils/webApiClient';
import { msalInstance } from '../../index';
import config from '../../Config/config';
import useRequestHandler from '../useRequestHandler';

/** Hook is used to update product entity */
const useUpdateProjectProduct = () => {

    const { t } = useTranslation();
    const { errorHandler, successHandler } = useRequestHandler();
    const apiClient = webApiClient();

    /** Update product entity
     * @param {object} object - Object which describes the product entity
     * @example object
     * {
     *      "id": 1,
     *      "project": 1,
     *      "product": 1,
     *      "businessPartner": 1,
     *      "description": "string",
     *      "potentialVolumeInSalesUnitOfMeasure": 23.0,
     *      "potentialSales": 3.5,
     *      "potentialMargin": 3.5,
     *      "marginPercentage": 3.5,
     *      "deleted": false
     **/
    const updateProjectProduct = async (projectId, object) => {
        try {

            // Account is set in the index.js
            const account = msalInstance.getActiveAccount();

            const response = await msalInstance.acquireTokenSilent({
                scopes: config.webApi.scopes,
                account: account
            });

            // Create resource path
            let resource = "projects/" + projectId.toString() + "/products";

            const result = await apiClient.update(response.accessToken, resource, object);  

            return successHandler(result, "useUpdateProjectProduct", "update", { message: t('success.update') });

        } catch (error) {

            return errorHandler(error, "useUpdateProjectProduct", "update");

        }
    }

    return { updateProjectProduct };

}

export default useUpdateProjectProduct;
