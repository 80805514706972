import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Landing from '../Pages/Landing';
import VersionHistory from '../Pages/VersionHistory';

/* Activities */
import Activities from '../Pages/Activities/Activities';
import NewActivity from '../Pages/Activities/NewActivity';
import ViewActivity from '../Pages/Activities/ViewActivity';
import EditActivity from '../Pages/Activities/EditActivity';
import ChangeActivityType from '../Pages/Activities/ChangeActivityType';

/* Business Partners */
import BusinessPartner from '../Pages/BusinessPartner/BusinessPartners';

/* Contacts */
import Contacts from '../Pages/Contacts/Contacts';
import NewContact from '../Pages/Contacts/NewContact';
import ViewContact from '../Pages/Contacts/ViewContact';
import EditContact from '../Pages/Contacts/EditContact';

/* Management */
import Management from '../Pages/Management/Management';

/* Management / Contacts */
import ManageContacts from '../Pages/Management/Contacts/ManageContacts';

/* Management / Parameters */
import EditParameter from '../Pages/Management/Parameters/EditParameter';
import ManageParameters from '../Pages/Management/Parameters/ManageParameters';
import ViewParameter from '../Pages/Management/Parameters/ViewParameter';

/* Management / Projects */
import ManageProjects from '../Pages/Management/Projects/ManageProjects';

/* Notifications */
import EditNotification from '../Pages/Notifications/EditNotification';
import NewNotification from '../Pages/Notifications/NewNotification';
import Notifications from '../Pages/Notifications/Notifications';
import ViewNotification from '../Pages/Notifications/ViewNotification';

/* Profile */
import Profile from '../Pages/Profile/Profile';

/* Project Activities */
import NewProjectActivity from '../Pages/ProjectActivities/NewProjectActivity';
import ViewProjectActivity from '../Pages/ProjectActivities/ViewProjectActivity';
import EditProjectActivity from '../Pages/ProjectActivities/EditProjectActivity';

/* Project Attachment */
import NewProjectAttachment from '../Pages/ProjectAttachments/NewProjectAttachment';
import ViewProjectAttachment from '../Pages/ProjectAttachments/ViewProjectAttachment';
import EditProjectAttachment from '../Pages/ProjectAttachments/EditProjectAttachment';

/* Project Contacts */
import NewProjectContact from '../Pages/ProjectContacts/NewProjectContact';
import ViewProjectContact from '../Pages/ProjectContacts/ViewProjectContact';
import EditProjectContact from '../Pages/ProjectContacts/EditProjectContact';

/* Project Notifications */
import NewProjectNotification from '../Pages/ProjectNotifications/NewProjectNotification';
import ViewProjectNotification from '../Pages/ProjectNotifications/ViewProjectNotification';
import EditProjectNotification from '../Pages/ProjectNotifications/EditProjectNotification';

/* Project Products */
import SearchProjectProducts from '../Pages/ProjectProducts/SearchProjectProducts';
import NewProjectProduct from '../Pages/ProjectProducts/NewProjectProduct';
import ViewProjectProduct from '../Pages/ProjectProducts/ViewProjectProduct';
import EditProjectProduct from '../Pages/ProjectProducts/EditProjectProduct';

/* Projects */
import Projects from '../Pages/Projects/Projects';
import NewProject from '../Pages/Projects/NewProject';
import ViewProject from '../Pages/Projects/ViewProject';
import EditProject from '../Pages/Projects/EditProject';

/* System */
import PageNotFound from '../Pages/System/PageNotFound';
import Forbidden from '../Pages/System/Forbidden';
import ErrorPage from '../Pages/System/Error';

const Routes = (props) => {
    return (
        <Switch>

            <Route path='/' component={Landing} exact />

            <Route path='/activities'>
                <Switch>
                    <Route exact path='/activities'>
                        <Activities />
                    </Route>                    
                    <Route path='/activities/new'>
                        <NewActivity />
                    </Route>
                    <Route path='/activities/view/:activityId'>
                        <ViewActivity />
                    </Route>
                    <Route path='/activities/edit/:activityId'>
                        <EditActivity />
                    </Route>
                    <Route path='/activities/changetype/:activityId'>
                        <ChangeActivityType />
                    </Route>
                </Switch>
            </Route>

            <Route path='/businessPartners' component={BusinessPartner} />

            <Route path='/contacts'>
                <Switch>
                    <Route exact path='/contacts'>
                        <Contacts />
                    </Route>                    
                    <Route path='/contacts/new'>
                        <NewContact />
                    </Route>
                    <Route path='/contacts/view/:contactId'>
                        <ViewContact />
                    </Route>
                    <Route path='/contacts/edit/:contactId'>
                        <EditContact />
                    </Route>
                </Switch>
            </Route>

            <Route path='/management'>
                <Switch>
                    <Route exact path='/management'>
                        <Management />
                    </Route>
                    <Route path='/management/contacts'>
                        <ManageContacts />
                    </Route>
                    <Route path='/management/parameters'>
                        <Route exact path='/management/parameters'>
                            <ManageParameters />
                        </Route>
                        <Route exact path='/management/parameters/view/:parameterId'>
                            <ViewParameter />
                        </Route>
                        <Route exact path='/management/parameters/edit/:parameterId'>
                            <EditParameter />
                        </Route>
                    </Route>
                    <Route path='/management/projects'>
                        <Route exact path='/management/projects'>
                            <ManageProjects />
                        </Route>
                    </Route>
                </Switch>
            </Route>

            <Route path='/notifications'>
                <Switch>
                    <Route exact path='/notifications'>
                        <Notifications />
                    </Route>                    
                    <Route path='/notifications/new'>
                        <NewNotification />
                    </Route>
                    <Route path='/notifications/view/:notificationId'>
                        <ViewNotification />
                    </Route>
                    <Route path='/notifications/edit/:notificationId'>
                        <EditNotification />
                    </Route>
                </Switch>
            </Route>

            <Route path='/profile' component={Profile} />


            <Route path='/projects/'>
                <Switch>

                    {/* Project activities */}
                    <Route path='/projects/:projectId/activities/new'>
                        <NewProjectActivity />
                    </Route>                    
                    <Route path='/projects/:projectId/activities/view/:activityId'>
                        <ViewProjectActivity />
                    </Route>
                    <Route path='/projects/:projectId/activities/edit/:activityId'>
                        <EditProjectActivity />
                    </Route>

                    {/* Project attachments */}
                    <Route path='/projects/:projectId/attachments/new'>
                        <NewProjectAttachment />
                    </Route>                    
                    <Route path='/projects/:projectId/attachments/view/:attachmentId'>
                        <ViewProjectAttachment />
                    </Route>
                    <Route path='/projects/:projectId/attachments/edit/:attachmentId'>
                        <EditProjectAttachment />
                    </Route>

                    {/* Project contact */}
                    <Route path='/projects/:projectId/contacts/new'>
                        <NewProjectContact />
                    </Route>                    
                    <Route path='/projects/:projectId/contacts/view/:contactId'>
                        <ViewProjectContact />
                    </Route>
                    <Route path='/projects/:projectId/contacts/edit/:contactId'>
                        <EditProjectContact />
                    </Route>

                    {/* Project notification */}
                    <Route path='/projects/:projectId/notifications/new'>
                        <NewProjectNotification />
                    </Route>                    
                    <Route path='/projects/:projectId/notifications/view/:notificationId'>
                        <ViewProjectNotification />
                    </Route>
                    <Route path='/projects/:projectId/notifications/edit/:notificationId'>
                        <EditProjectNotification />
                    </Route>

                    {/* Project products */}
                    <Route path='/projects/:projectId/products/new'>
                        <NewProjectProduct />
                    </Route>                    
                    <Route path='/projects/:projectId/products/view/:id'>
                        <ViewProjectProduct />
                    </Route>
                    <Route path='/projects/:projectId/products/edit/:id'>
                        <EditProjectProduct />
                    </Route>

                    {/* Project */}
                    <Route exact path='/projects'>
                        <Projects />
                    </Route>                    
                    <Route path='/projects/new'>
                        <NewProject />
                    </Route>
                    <Route path='/projects/view/:projectId'>
                        <ViewProject />
                    </Route>
                    <Route path='/projects/edit/:projectId'>
                        <EditProject />
                    </Route>

                    {/* Project products */}
                    <Route path={'/projects/products'}>
                        <SearchProjectProducts />
                    </Route>

                </Switch>

            </Route>           

            {/* System */}           
            <Route path={'/versions'} component={VersionHistory} />
            <Route path={'/error'} component={ErrorPage} />
            <Route path={'/forbidden'} component={Forbidden} />
            <Route component={PageNotFound} />

        </Switch>
    );

}

export default Routes;
