import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { Form, Container, Message, Segment } from 'semantic-ui-react';
import { Loading } from '../../Components/UI/Loaders';
import { CancelButton } from '../../Components/UI/Buttons';
import { PageTitle } from '../../Components/UI/Content';

const EditProjectContact = (props) => {

    const { t } = useTranslation();
    const history = useHistory();
    const { projectId, contactId } = useParams();
    const scollToTopRef = useRef();

    //const [ isLoading, setIsLoading ] = useState(false);
    const [ isLoading ] = useState(false);

    useEffect(() => { 
        scollToTopRef.current.scrollIntoView();
    },[]);
    
    return (
        <Form className="contact" >
                
            <div ref={scollToTopRef}></div>
            
            <Loading active={ isLoading } text={t('general.loading')} />

            <PageTitle active title={t('contact.editProjectContact')} />

            <Segment>
                <p>View EditProjectContact is not implemented!</p>
            </Segment>

            {/* Errors */}
            <Message error visible={!projectId} content={t('errors.missingProjectId')} />
            <Message error visible={!contactId} content={t('errors.missingContactId')} />

            {/* Buttons */}
            <Container className='button-container'>
                <CancelButton onClick={(ev) => {ev.preventDefault(); history.goBack(); }} />
            </Container>

        </Form>
    );

}

export default EditProjectContact;
