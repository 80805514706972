//import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import webApiClient from '../../Utils/webApiClient';
import { msalInstance } from '../../index';
import config from '../../Config/config';
import useRequestHandler from '../useRequestHandler';

/** Hook is used to create new activity entity */
const useCreateActivity = () => {
    
    const { t } = useTranslation();
    const { errorHandler, successHandler } = useRequestHandler();
    const apiClient = webApiClient();

    /** Create new activity
     * @param {object} object - Object which describes the activity entity
     * @example object
     * {
     *      "title": "Test title",
     *      "contactDate": "2011-10-05T14:48:00.000Z",
     *      "type": 1,
     *      "project": 1,
     *      "businessUnit":1,
     *      "projectNewStatus":1,
     *      "businessPartner": 1,
     *      "internalAttendee": "",
     *      "externalAttendee": "",
     *      "objective": "",
     *      "externalNotes": "Test external",
     *      "internalNotes": "Test internal",
     *      "salesPerson": 1,
     *      "contact": 1
     *  }
     **/
    const createActivity = async (object) => {
        try {

            // Account is set in the index.js
            const account = msalInstance.getActiveAccount();

            const response = await msalInstance.acquireTokenSilent({
                scopes: config.webApi.scopes,
                account: account
            });

            const result = await apiClient.create(response.accessToken, "activities", object);

            return successHandler(result, "useCreateActivity", "create", { message: t('success.create') });

        } catch (error) {
            return errorHandler(error, "useCreateActivity", "create");
        }
    }

    return { createActivity };

}

export default useCreateActivity;
