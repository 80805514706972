import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Form , Dropdown, Checkbox, Container, Radio, Input, Message } from 'semantic-ui-react';
import { PageTitle } from '../../Components/UI/Content';
import PagedTable from '../../Components/UI/Lists';
import { CancelButton } from '../../Components/UI/Buttons';
import { Loading } from '../../Components/UI/Loaders';
import { getSearchProjectStatusCodeFilters } from '../../Utils/ActionArenaUtils';
import { StateContext } from '../../Application/Context/StateContext';
import useSearchProjectProducts from '../../Hooks/Search/useSearchProjectProducts';
import useListUsers from '../../Hooks/User/useListUsers';
import SearchAllProjectProducts from './SearchAllProjectProducts';

const SearchProjectProducts = (props) => {

    const { t } = useTranslation();
    const history = useHistory();
    const { users, listUsers } = useListUsers();
    const { projectProducts, setProjectProducts, searchProjectProducts } = useSearchProjectProducts();

    const [ isLoading, setIsLoading ] = useState(false);
    const [ itemsPerPage, setItemsPerPage ] = useState(10);
    const [ filterProjectsListInput, setFilterProjectsListInput ]= useState('');

    const {

        // General global state
        statusCodes,
        businessUnits,
        userProfile,

        // Global state for search project products
        searchTermProjectProducts,
        setSearchTermProjectProducts,

        selectedSalesPersonProjectProducts,
        setSelectedSalesPersonProjectProducts,

        selectedBusinessUnitProjectProducts,
        setSelectedBusinessUnitProjectProducts,

        filterByStatusRadioProjectProducts,
        setFilterByStatusRadioProjectProducts,
        
        filterMyOnlyCheckboxProjectProducts,
        setProjectProductsFilterMyOnlyCheckbox,

        filterBySalesPersonCheckboxProjectProduct,
        setFilterBySalesPersonCheckboxProjectProduct,

        selectedStatusFiltersProjectProducts,
        setSelectedStatusFiltersProjectProducts,

    } = useContext(StateContext);


    /** Calculate selected status code filters when user select status group
     * Set default everytime user select status set.
     * @param {string} group - status group
     * @example status groups
     *  - All = all status codes. "Project removed by user" is filtered out
     *  - Active = Planned,Started,Sample sent, Sample approved, Trial Agrees, Trial Approved, Commercial negotiations
     *  - Passive = Regular business, Project failed, Project automatically passivated,
     *  - Closed = Project automatically closed
     *  - Custom = user select. "Project removed by user" is filtered out
     **/
    const calculateStatusFiltersByStatusGroup = (group) => {
        
        let mappedStatusFilters = [];

        switch (group) {

            case "all":

                // All status codes less that 12
                mappedStatusFilters = selectedStatusFiltersProjectProducts.map(item => {
                    item.Checked = false;
                    if (item.Id < 12) item.Checked = true;
                    return item;
                });
                break;

            case "active":

                // Status codes less than 8
                mappedStatusFilters = selectedStatusFiltersProjectProducts.map(item => {
                    item.Checked = false;
                    if (item.Id < 8) item.Checked = true;
                    return item;
                });
                break;

            case "passive":

                // Status code equals 10
                mappedStatusFilters = selectedStatusFiltersProjectProducts.map(item => {
                    item.Checked = false;
                    if (item.Id === 10) item.Checked = true;
                    return item;
                });
                break;

            case "closed":

                // Status codes equals 8, 9 or 11
                mappedStatusFilters = selectedStatusFiltersProjectProducts.map(item => {
                    item.Checked = false;
                    if (item.Id === 8 || item.Id === 9 || item.Id === 11) item.Checked = true;
                    return item;
                });
                break;

            case "custom":

                // All status codes less that 12
                mappedStatusFilters = selectedStatusFiltersProjectProducts.map(item => {
                    item.Checked = false;
                    if (item.Id < 12) item.Checked = true;
                    return item;
                });
                break;

            default:
                // All status codes less that 12
                mappedStatusFilters = selectedStatusFiltersProjectProducts.map(item => {
                    item.Checked = false;
                    if (item.Id < 12) item.Checked = true;
                    return item;
                });
                break;
        }

        setFilterByStatusRadioProjectProducts(group);
        setSelectedStatusFiltersProjectProducts(mappedStatusFilters);

    }

    /**  Get needed data on page load */
    useEffect(() => {
        (async() => {
            setIsLoading(true);
            await listUsers(true, true);
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /** Set defaults for selectedStatusFiltersProjectProducts on page load */
    useEffect(() => {
        // Set Default status code filter selections if user has not previously selected any
        if (statusCodes && statusCodes.length && selectedStatusFiltersProjectProducts.length === 0) {
            const filteredStatusCodes = statusCodes.filter(item => item.Id < 12);
            const mappedStatusCodes = filteredStatusCodes.map(item => {
                item.Checked = false;
                if (filterByStatusRadioProjectProducts === 'active' && item.Id < 8) item.Checked = true; // CASE ACTIVE
                if (!filterByStatusRadioProjectProducts === 'active' && item.Id >= 8 && item.Id < 12) item.Checked = true; // CASE PASSIVE
                return item;
            });
            setSelectedStatusFiltersProjectProducts(mappedStatusCodes);
        }
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [statusCodes]);

    /** Fetch items with default settings on page load */
    useEffect(() => {
        (async() => {

            // Do not make api call unless all needed properties are found
            if (statusCodes && statusCodes.length) {

                let statusFiltersQuery = [];

                if (selectedStatusFiltersProjectProducts.length > 0) {
                    // User has already selected status code filters
                    statusFiltersQuery = getSearchProjectStatusCodeFilters(selectedStatusFiltersProjectProducts, filterByStatusRadioProjectProducts);
                } else {
                    // User enter fresh form and no selections have been made
                    statusFiltersQuery = getSearchProjectStatusCodeFilters(statusCodes, filterByStatusRadioProjectProducts);
                }

                const options = {
                    own: filterMyOnlyCheckboxProjectProducts,
                    top: 200,
                    status: statusFiltersQuery,
                    salesPerson: selectedSalesPersonProjectProducts
                }

                await searchProjectProducts(searchTermProjectProducts, options);

            }

            setIsLoading(false);
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [statusCodes]);

    return (
        <div className="projects">

            <PageTitle active title={t('project.searchprojectproducts')} />

            <Loading active={ isLoading } text={t('general.loading')} />

            <SearchAllProjectProducts
                setIsLoading={setIsLoading}
                statusCodes={statusCodes}
                searchTerm={searchTermProjectProducts}
                setSearchTerm={setSearchTermProjectProducts}
                filterByStatuRadio={filterByStatusRadioProjectProducts}
                filterMyOnlyCheckbox={filterMyOnlyCheckboxProjectProducts}
                filterBySalesPersonCheckbox={filterBySalesPersonCheckboxProjectProduct}
                selectedSalesPerson={selectedSalesPersonProjectProducts}
                selectedBusinessUnit={selectedBusinessUnitProjectProducts}
                searchProjectProducts={searchProjectProducts}
                filters={
                    <>
                        {/* Filter checkbox selections */}
                        <Container className="fieldcontent">

                            <Form.Field
                                control={Checkbox}
                                label={t('project.filterProductsRelatedToMyProjectsOnly')}
                                placeholder={t('project.filterProductsRelatedToMyProjectsOnly')}
                                checked={filterMyOnlyCheckboxProjectProducts}
                                onChange={(ev, {checked}) => {
                                    setProjectProducts([]);
                                    setFilterBySalesPersonCheckboxProjectProduct(false);
                                    setProjectProductsFilterMyOnlyCheckbox(checked);
                                }}
                            />

                            <Form.Field
                                control={Checkbox}
                                label={t('project.filterProductsByProjectsSalesPerson')}
                                placeholder={t('project.filterProductsByProjectsSalesPerson')}
                                checked={filterBySalesPersonCheckboxProjectProduct}
                                onChange={(ev, {checked}) => {
                                    setProjectProducts([]);
                                    setProjectProductsFilterMyOnlyCheckbox(false);
                                    setFilterBySalesPersonCheckboxProjectProduct(checked);
                                }}
                            />

                        </Container> 

                        {/* Select sales person from users */}
                        {filterBySalesPersonCheckboxProjectProduct && 
                            <Container className="fieldcontent">
                                <Form.Field>
                                    <b>{t('general.salesPerson')}</b>
                                </Form.Field>
                                <Dropdown
                                    placeholder={t('general.salesPerson')}
                                    fluid
                                    search
                                    selection
                                    value={selectedSalesPersonProjectProducts}
                                    options={users.map(item => {
                                        return {
                                            key: item.Id,
                                            value: item.Id,
                                            text: item.Name ? item.Name : "<Missing name>"
                                        }
                                    })}
                                    onChange={(ev, {value}) => {
                                        setSelectedSalesPersonProjectProducts(value);
                                    }}
                                />
                            </Container>
                        }

                        {/* Status choices - Set default everytime user select status set.
                            - All = all status codes. "Project removed by user" is filtered out
                            - Active = Planned,Started,Sample sent, Sample approved, Trial Agrees, Trial Approved, Commercial negotiations
                            - Passive = Regular business, Project failed, Project automatically passivated,
                            - Closed = Project automatically closed
                            - Custom = user select. "Project removed by user" is filtered out
                        */}
                        <Container className="fieldcontent">
                            <Form.Field>
                                <b>{t('project.chooseStatusSet')}</b>
                            </Form.Field>
                            <Form.Field
                                control={Radio}
                                label={t('general.all')}
                                name='chooseStatusSet'
                                placeholder={t('general.all')}
                                checked={filterByStatusRadioProjectProducts === 'all' ? true : false}
                                onChange={(ev) => calculateStatusFiltersByStatusGroup("all")}
                            />
                            <Form.Field
                                control={Radio}
                                label={t('general.active')}
                                name='chooseStatusSet'
                                placeholder={t('general.active')}
                                checked={filterByStatusRadioProjectProducts === 'active' ? true : false}
                                onChange={(ev) => calculateStatusFiltersByStatusGroup("active")}
                            />
                            <Form.Field
                                control={Radio}
                                label={t('general.passive')}
                                name='chooseStatusSet'
                                placeholder={t('general.passive')}
                                checked={filterByStatusRadioProjectProducts === 'passive' ? true : false}
                                onChange={(ev) => calculateStatusFiltersByStatusGroup("passive")}
                            />
                            <Form.Field
                                control={Radio}
                                label={t('general.closed')}
                                name='chooseStatusSet'
                                placeholder={t('general.closed')}
                                checked={filterByStatusRadioProjectProducts === 'closed' ? true : false}
                                onChange={(ev) => calculateStatusFiltersByStatusGroup("closed")}
                            />
                            <Form.Field
                                control={Radio}
                                label={t('general.custom')}
                                name='chooseStatusSet'
                                placeholder={t('general.custom')}
                                checked={filterByStatusRadioProjectProducts === 'custom' ? true : false}
                                onChange={(ev) => calculateStatusFiltersByStatusGroup("custom")}
                            />
                        </Container>

                        {/* Show select status codes
                            - Set filterByStatusRadioProjectProducts to custom everytime user select or unselect single status.
                            - change status filter selection */}
                        <Container className="fieldcontent">
                            <Form.Field>
                                <b>{t('status.statusFilters')}</b>
                            </Form.Field>
                            {selectedStatusFiltersProjectProducts.map((code, index) => {
                                return <Form.Field
                                    key={index}
                                    control={Checkbox}
                                    label={code.Name}
                                    placeholder={code.Name}
                                    name='statusFilters'
                                    checked={code.Checked}
                                    onChange={(ev, {checked}) => {
                                        const mappedStatusFilters = selectedStatusFiltersProjectProducts.map(item => {
                                            if (item.Id === code.Id) item.Checked = checked;
                                            return item;
                                        })
                                        setFilterByStatusRadioProjectProducts('custom');
                                        setSelectedStatusFiltersProjectProducts(mappedStatusFilters);
                                    }}
                                />
                            })}
                        </Container>

                        {/* ADMIN: Filter by business unit */}
                        {userProfile && userProfile.IsAdmin && 
                            <Container className="fieldcontent">
                                <Form.Field>
                                    <b>{t('businessPartner.businessUnit')}</b>
                                </Form.Field>
                                <Dropdown
                                    placeholder={t('project.filterByBusinessUnit')}
                                    fluid
                                    search
                                    selection
                                    value={selectedBusinessUnitProjectProducts}
                                    options={businessUnits
                                        ? 
                                            (function () {

                                                let data = businessUnits.map(item => {
                                                    return {
                                                        key: item.Id,
                                                        value: item.Id,
                                                        text: item.Name ? item.Name : "<Missing name>"
                                                    }
                                                });
                                            
                                                data.unshift({
                                                    key: 0,
                                                    value: 0,
                                                    text: "No business unit filter"
                                                });

                                                return data;

                                            })() // => function which is called automatically
                                        :
                                            []
                                    }
                                    onChange={(ev, {value}) => {
                                        setSelectedBusinessUnitProjectProducts(value);
                                    }}
                                />
                            </Container>

                        }
                    </>
                }

            />

            <Message
                info
                size='mini'
                hidden={
                    (filterBySalesPersonCheckboxProjectProduct || 
                    filterMyOnlyCheckboxProjectProducts) || 
                    (projectProducts.length < 201 && !filterBySalesPersonCheckboxProjectProduct && !filterMyOnlyCheckboxProjectProducts)
                }>
                <p>{t('warnings.searchReturnedOver200Results')}</p>
            </Message>

            <Container className="fieldcontent" textAlign="right">
                <Form.Field>
                    <b>{t('general.pageSize')}</b>
                </Form.Field>
                <Dropdown
                    selection
                    value={itemsPerPage}
                    options={[
                        { key: 0, text: 5, value: 5 },
                        { key: 1, text: 10, value: 10 },
                        { key: 2, text: 25, value: 25 },
                        { key: 3, text: 50, value: 50 }
                    ]}
                    onChange={(ev, {value}) => setItemsPerPage(value) }
                />
            </Container>

            {/* Filter search results*/}
            <Container className="fieldcontent">
                <Input
                    action={{
                        labelPosition: 'left',
                        icon: 'delete',
                        content: t('general.clear'),
                        style: {margin: 0},
                        onClick:(ev) => {
                            ev.preventDefault();
                            setFilterProjectsListInput('');
                        }
                    }}
                    onChange={(ev, {value} ) => {
                        ev.preventDefault();
                        setFilterProjectsListInput(value)
                    }}
                    fluid
                    //actionPosition='right'
                    placeholder={t('general.filter')}
                    value={filterProjectsListInput}
                />
            </Container>

            <Container className="fieldcontent">
                <PagedTable unstackable celled striped compact='very' pageSize={itemsPerPage}
                    header={[
                        t('project.productName'),
                        t('businessPartner.customer'),
                        t('general.code'),
                        t('project.projectTitle'),
                        t('project.startDate'),
                        t('businessPartner.businessUnit'),
                        t('general.salesPerson')
                    ]}
                    content={projectProducts ? projectProducts.filter(project => {
                        let matchTitle = project.Title ? project.Title.toLowerCase().indexOf(filterProjectsListInput.toLowerCase()) !== -1 : false;
                        let matchProductName = project.ProductName ? project.ProductName.toLowerCase().indexOf(filterProjectsListInput.toLowerCase()) !== -1 : false;
                        let matchSalesPersonName = project && project.SalesPersonName ? project.SalesPersonName.toLowerCase().indexOf(filterProjectsListInput.toLowerCase()) !== -1 : false;
                        let matchBusinessPartnerName = project && project.BusinessPartnerName ? project.BusinessPartnerName.toLowerCase().indexOf(filterProjectsListInput.toLowerCase()) !== -1 : false;
                        let matchBusinessUnitName = project && project.BusinessUnitName ? project.BusinessUnitName.toLowerCase().indexOf(filterProjectsListInput.toLowerCase()) !== -1 : false;
                        let matchStartDate = project && project.StartDate ? new Date(project.StartDate).toLocaleDateString("fi-FI").indexOf(filterProjectsListInput.toLowerCase()) !== -1 : false;
                        let matchStatusName = project && project.StatusName ? project.StatusName.toLowerCase().indexOf(filterProjectsListInput.toLowerCase()) !== -1 : false;
                        if (matchTitle || matchProductName || matchSalesPersonName || matchBusinessPartnerName || matchBusinessUnitName || matchStartDate  || matchStatusName) return true;
                        return false;
                    }) : []}
                    resetPageOnContentChange={true}
                    contentKeys={[
                        {key: 'ProductName'},
                        {key: 'ProjectBusinessPartnerName' },
                        {key: 'ProductCode' },
                        {key: 'ProjectTitle', target: '/projects/view/', type: 'link', targetKey: 'ProjectId'},
                        {key: 'ProjectStartDate', type: 'date'},
                        {key: 'BusinessUnitName' },
                        {key: 'SalesPersonName' }
                    ]}
                />
            </Container>

            {/* Buttons */}
            <Container className="fieldcontent button-container">
                <CancelButton title={t('general.back')} onClick={(ev) => {ev.preventDefault(); history.goBack(); }} />
            </Container>

        </div>
    );

}

export default SearchProjectProducts;
