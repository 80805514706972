import React, { useState, useEffect, useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { Form, Input, Container, Message, Dropdown, TextArea } from 'semantic-ui-react';
import { Loading } from '../../Components/UI/Loaders';
import { SubmitButton, CancelButton } from '../../Components/UI/Buttons';
import { PageTitle, SelectedItemLight } from '../../Components/UI/Content';
import { StateContext } from '../../Application/Context/StateContext';
import useReadProject from '../../Hooks/Project/useReadProject';
//import useListProjectContacts from '../../../Hooks/ProjectContacts/useListProjectContacts';
import useSearchContacts from '../../Hooks/Search/useSearchContacts';
import useReadActivity from '../../Hooks/Activities/useReadActivity';
import useReadBusinessPartner from '../../Hooks/BusinessPartners/useReadBusinessPartner';
import useUpdateActivity from '../../Hooks/Activities/useUpdateActivity';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { createExternalAttendeesJson, editExternalAttendees } from '../../Utils/ActionArenaUtils';

const EditProjectActivity = (props) => {

    const { t } = useTranslation();
    const history = useHistory();
    const scollToTopRef = useRef();

    const { projectId, activityId } = useParams();
    const [ isLoading, setIsLoading ] = useState(false);
    const [ title, setTitle ] = useState("");
    const [ activityDate, setActivityDate ] = useState(new Date());
    const [ activityType, setActivityType ] = useState(null);
    const [ businessUnit, setBusinessUnit ] = useState(0);   

    const [ internalAttendees, setInternalAttendees ] = useState("");
    const [ externalAttendees, setExternalAttendees ] = useState(""); // Saved as JSON string
    const [ searchContactQuery, setSearchContactQuery ] = useState('');
    const [ searchContactValue, setSearchContactValue ] = useState([]);

    const [ objective, setObjective ] = useState("");
    const [ internalNotes, setInternalNotes ] = useState("");
    const [ externalNotes, setExternalNotes ] = useState("");
    const { userProfile, activityTypes, businessUnits } = useContext(StateContext);
    const { project, readProject } = useReadProject();
    const { activity, readActivity } = useReadActivity();
    const { businessPartner, readBusinessPartner } = useReadBusinessPartner();
    const { updateActivity } = useUpdateActivity();
    //const { projectContacts, listProjectContacts } = useListProjectContacts();
    const { contacts, searchContacts } = useSearchContacts();

    const userIsAdmin = userProfile && userProfile.IsAdmin ? true : false;
    const projectHasEnded = project && project.EndDate ? true : false; // If project has end date, project is ended and only admin can edit it!
    const projectIsNotActive = project && project.Status > 7 ? true : false; // if project status is greater than 7 (not active anymore) only admin can edit it!
    const disableEditing = (projectHasEnded || projectIsNotActive) && !userIsAdmin ? true : false;

    // Save data on submit
    const onSubmit = async () => {
        try {

            if (!projectId) return null;
            if (!activityId) return null;
            if (!project) return null;
            if (!project.BusinessPartner) return null;

            setIsLoading(true);

            const activityObj = {
                "id": activityId,
                "title": title,
                //"contactDate": activityDate ? activityDate.toISOString() : null,
                "type": activityType,
                //"project": selectedProject && selectedProject.Id ? selectedProject.Id : null,
                "project": projectId,
                //"businessUnit": businessUnit,
                //"businessPartner": selectedBusinessPartner && selectedBusinessPartner.Id ? selectedBusinessPartner.Id : null,
                "businessPartner": project.BusinessPartner,
                "internalAttendee": internalAttendees,
                "externalAttendee": JSON.stringify(createExternalAttendeesJson(externalAttendees, searchContactValue, contacts)),
                "objective": objective,
                "externalNotes": externalNotes,
                "internalNotes": internalNotes,
                "salesPerson": userProfile && userProfile.Id ? userProfile.Id : null,
                "contact": null
            }

            // don´t allow status update / change in edit

            // Add date only if type is not status change!
            if (activityType && activityType !== 5) {
                activityObj.contactDate = activityDate ? activityDate.toISOString() : null;
            }

            // Add date only if type is not status change!
            if (businessUnit && businessUnit !== 0) {
                activityObj.businessUnit = businessUnit;
            }

            let result = await updateActivity(activityObj);

            if (result && result.status === 200 && result.data) {
                return history.goBack();
            }

            setIsLoading(false);

        } catch (error) {
            setIsLoading(false);
        }
    };

    // Read data on page load
    useEffect(() => {
        (async() => {
            scollToTopRef.current.scrollIntoView();
            setIsLoading(true);
            if (projectId) {
                await readProject(projectId);
                //await listProjectContacts(projectId);
                await searchContacts();
            }
            if (activityId) await readActivity(activityId);
            setIsLoading(false);
        })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Set activity data
    useEffect(() => {
        if (activity) {
            if (activity.Title) setTitle(activity.Title);
            if (activity.ContactDate) setActivityDate(new Date(activity.ContactDate));
            if (activity.Type) setActivityType(activity.Type);
            if (activity.Businessunit) setBusinessUnit(activity.Businessunit);
            if (activity.InternalAttendee) setInternalAttendees(activity.InternalAttendee);
            if (activity.ExternalAttendee) {
                let extAttendeeContactsArray = editExternalAttendees(activity.ExternalAttendee, 'Dropdown');
                let extAttendeeString = editExternalAttendees(activity.ExternalAttendee, 'FormField');
                setSearchContactValue(extAttendeeContactsArray);
                setExternalAttendees(extAttendeeString);
            }
            if (activity.Objective) setObjective(activity.Objective);
            if (activity.InternalNotes)  setInternalNotes(activity.InternalNotes);
            if (activity.ExternalNotes) setExternalNotes(activity.ExternalNotes);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activity]);

    // Read businessPartner
    useEffect(() => {
        if (project) {

            if (project.BusinessPartner) {
                (async() => {
                    setIsLoading(true);
                    if(projectId) await readBusinessPartner(project.BusinessPartner);
                    setIsLoading(false);
                })();
            }
            
            if (project.BusinessUnit) {
                setBusinessUnit(project.BusinessUnit);
            }

            //if (project.Status) setStatus(project.Status);

        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [project]);

    let mappedBusinessUnits = [];
    if (businessUnits) {
        mappedBusinessUnits = businessUnits.map(bu => {
            return { key: bu.Id, value: bu.Id, text: bu.Name }
        });
        mappedBusinessUnits.unshift({ key: 0, value: 0, text: t('general.select')+"..." });
    };

    // Sort contacts by selected businesspartner or project
    let businessPartnerIdfilter = businessPartner && businessPartner.Id ? businessPartner.Id : 0;

    // 1.Filter contacts by business partner of project selection
    const filteredBusinessPartnerContacts = contacts.filter(item => {
        return item.BusinessPartner === businessPartnerIdfilter;
    });

    // 2.Filter other contacts
    const filteredOtherContacts = contacts.filter(item => {
        return item.BusinessPartner !== businessPartnerIdfilter;
    });

    // 3. Combine arrays.
    let orderedContacts = [];
    if (filteredBusinessPartnerContacts.length > 0 || filteredOtherContacts.length > 0 ) {
        orderedContacts = [...filteredBusinessPartnerContacts, ...filteredOtherContacts];
    } else {
        orderedContacts = [...contacts];
    }

    return (
        <Form className="activity" onSubmit={(ev) => { ev.preventDefault(); onSubmit(); }} >
                
            <div ref={scollToTopRef}></div>
            
            <Loading active={ isLoading } text={t('general.loading')} />

            <PageTitle active title={t('activity.editProjectActivity')} />

            <Message warning visible={projectHasEnded || projectIsNotActive} content={t('warnings.projectIsNotActive')} />

            {/* Activity Type */}
            <Form.Field label={t('activity.activityType')} />

            <div className="fieldcontent">
                <Dropdown
                    required
                    disabled={true} // Disabled in order to avoid multible validations
                    placeholder={t('activity.activityType')}
                    fluid
                    search
                    selection
                    options={activityTypes.map(type => {
                        return {
                            key: type.Id,
                            value: type.Id,
                            text: type.Name
                        }
                    })}
                    value={activityType}
                    onChange={(ev, {value}) => setActivityType(value) }
                />
            </div>

            {/* Project status (activity type 5 = project status change) */}
            { activityType && activityType === 5 && 
                <Container className="fieldcontent">
                    <Form.Field label={t('general.status')} />
                    <p>{activity && activity.ProjectNewStatusName}</p>
                </Container>
            }

            {/* Title */}
            <Form.Field
                required
                label={t('general.title')}
                //placeholder={t('general.title')}
                maxLength="255"
                control={Input}
                value={title}
                onChange={(ev, {value}) => setTitle(value) }
                type="Text"
            />

            {/* Project activity Date */}
            {activityType !== 5 &&
                <>
                    <Form.Field label={t('activity.projectActivityDate')} style={{ marginBottom: '0px' }}/>
                    <DatePicker
                        required={activityType !== 5}
                        label={t('activity.projectActivityDate')}
                        selected={activityDate}
                        dateFormat={navigator.language === "fi-FI" ? "dd.MM.yyyy" : "yyyy/MM/dd"}
                        onChange={(date) => setActivityDate(date)}
                    />
                </>
            }

            <SelectedItemLight
                title={t('businessPartner.customer')}
                item={businessPartner}
                headers={[t('general.name'), t('businessPartner.area'), t('general.businessId')]}
                fields={['Name', 'AreaName', 'AssociationNumber']}
                //required={true}
                //isValidSelectedItem ={true}
                //setIsValidSelectedBusinessPartner={true}
            />

            <SelectedItemLight
                title={t('project.project')}
                item={project}
                headers={[t('general.title'), t('general.status'), t('general.businessPartner'), t('businessPartner.businessUnit')]}
                fields={['Title', 'StatusName', 'BusinessPartnerName', 'BusinessUnitName']}
                //required={true}
                //isValidSelectedItem={true}
            />

            {/* Business unit */}
            <Form.Field label={t('businessPartner.businessUnit')} />
            <div className="fieldcontent">
                <Dropdown
                    disabled
                    //required
                    placeholder={t('businessPartner.businessUnit')}
                    fluid
                    search
                    selection
                    options={mappedBusinessUnits}
                    onChange={(ev, {value}) => setBusinessUnit(value) }
                    value={businessUnit}
                />
            </div>

            {/* External attendees */}
            <Form.Field label={t('activity.externalAttendees')} />
            <Dropdown
                placeholder={t('activity.selectExternalAttendeesFromContacts')}
                clearable
                fluid
                multiple
                search
                selection
                options={orderedContacts.map(item => {
                    if (item && item.BusinessPartnerName) {
                        item.NameWithBP = item.Name + " [" + item.BusinessPartnerName + "]";
                    } else {
                        item.NameWithBP = item.Name;
                    }
                    return item;
                }).map(item => {
                    return {
                        key: item.Id,
                        text: item.NameWithBP,
                        value: item.Id,
                    }
                })}
                onChange={(e, { searchQuery, value }) => {
                    setSearchContactQuery(searchQuery);
                    setSearchContactValue(value);
                }}
                onSearchChange={(e, { searchQuery, value }) => {
                    setSearchContactQuery(searchQuery);
                }}
                style={{ marginBottom: "1px" }}
                searchQuery={searchContactQuery}
                value={searchContactValue}
            />
            <Form.Field
                maxLength="255"
                control={Input}
                value={externalAttendees}
                onChange={(ev, {value}) => setExternalAttendees(value) }
                type="Text"
                placeholder={t('activity.externalAttendees')}
            />

            {/* Internal attendees */}
            <Form.Field
                label={t('activity.internalAttendees')}
                placeholder={t('activity.internalAttendees')}
                maxLength="255"
                control={Input}
                value={internalAttendees}
                onChange={(ev, {value}) => setInternalAttendees(value) }
                type="Text"
            />

            {/* Objective */}
            <Form.Field
                //required={activityType !== 5}
                label={t('activity.objective')}
                placeholder={t('activity.objective')}
                maxLength="255"
                control={Input}
                value={objective}
                onChange={(ev, {value}) => setObjective(value) }
                type="Text"
            />

            {/* Internal notes */}
            <Form.Field
                label={t('activity.internalNotes')}
                placeholder={t('activity.internalNotes')}
                control={TextArea}
                value={internalNotes}
                rows={15}
                onChange={(ev, {value}) => setInternalNotes(value) }
                type="Text"
            />

            {/* External notes */}
            <Form.Field
                //required={activityType !== 5}
                label={t('activity.externalNotes')}
                placeholder={t('activity.externalNotes')}
                control={TextArea}
                value={externalNotes}
                rows={5}
                onChange={(ev, {value}) => setExternalNotes(value) }
                type="Text"
            />

            {/* Errors */}
            <Message error visible={!projectId} content={t('errors.missingProjectId')} />
            <Message error visible={!activityId} content={t('errors.missingProjectId')} />
            
            {/* Buttons */}
            <Container className='button-container'>
                <CancelButton onClick={(ev) => {ev.preventDefault(); history.goBack(); }} />
                <SubmitButton disabled={disableEditing} className="bb-lblue" icon="save" title={t('general.save')} type="submit" />
            </Container>

        </Form>
    );

}

export default EditProjectActivity;
