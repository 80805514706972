import React, { useState, useContext, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Form, Container, Input, TextArea, Checkbox } from 'semantic-ui-react';
import { Loading } from '../../Components/UI/Loaders';
import { SubmitButton, CancelButton } from '../../Components/UI/Buttons';
import { PageTitle, SelectedItemLight } from '../../Components/UI/Content';
import { StateContext } from '../../Application/Context/StateContext';
import useCreateNotification from '../../Hooks/Notifications/useCreateNotification';
import useCreateCalendarEvent from '../../Hooks/CalendarEvents/useCreateCalendarEvent';
import SearchBusinessPartners from '../../Components/BusinessPartners/SearchBusinessPartners';
import SearchProjects from '../../Components/Projects/SearchProjects';
import SearchActivities from '../../Components/Activities/SearchActivities';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import NewCalendarEvent from '../../Components/Outlook/OutlookEvent';

const NewNotification = (props) => {

    const { t } = useTranslation();
    const history = useHistory();
    const scollToTopRef = useRef();

    // Notification
    const [ isLoading, setIsLoading ] = useState(false);
    const [ date, setDate ] = useState(new Date());
    const [ title, setTitle ] = useState("");
    const [ description, setDescription ] = useState("");
    const [ isRelatedToCustomer, setIsRelatedToCustomer ] = useState(false);
    const [ isRelatedToActivity, setIsRelatedToActivity ] = useState(false);
    const [ isRelatedToProject, setIsRelatedToProject ] = useState(false);
    const [ selectedBusinessPartner, setSelectedBusinessPartner ] = useState(null); // Business partner object
    const [ selectedActivity, setSelectedActivity ] = useState(null);
    const [ selectedProject, setSelectedProject ] = useState(null);

    /* Outlook event */
    const [ addToOutlook, setAddToOutlook ] = useState(false);
    const [ startDate, setStartDate ] = useState(() => {
        const d = new Date(date);
        d.setHours(d.getHours() + 1);
        d.setMinutes(0);
        d.setSeconds(0);
        d.setMilliseconds(0);
        return d;
    });
    const [ endDate, setEndDate ] = useState(() => {
        const d = new Date(date);
        d.setHours(d.getHours() + 2);
        d.setMinutes(0);
        d.setSeconds(0);
        d.setMilliseconds(0);
        return d;
    });
    const [ location, setLocation ] = useState("");

    // Validation
    const [ isValidSelectedCustomer, setIsValidSelectedCustomer ] = useState(true);
    const [ isValidSelectedProject, setIsValidSelectedProject ] = useState(true);
    const [ isValidSelectedActivity, setIsValidSelectedActivity ] = useState(true);

    const { userProfile } = useContext(StateContext);
    const { createNotification } = useCreateNotification();
    const { createCalendarEvent } = useCreateCalendarEvent();

    // Save data on submit
    const onSubmit = async () => {
        try {

            if (isRelatedToCustomer && !selectedBusinessPartner) return setIsValidSelectedCustomer(false);
            if (isRelatedToProject && !selectedProject) return setIsValidSelectedProject(false);
            if (isRelatedToActivity && !selectedActivity) return setIsValidSelectedActivity(false);

            setIsLoading(true);

            const notificationObj = {
                "date": date ? date.toISOString() : null,
                "title": title ? title.trim() : "Notification title",
                "description": description.trim(),
                //"businessUnit": parseInt(businessUnit),
                "businessPartner": null,
                "activity": null,
                "project": null,
                "salesPerson": null
                //"acknowledgementTime": null
            }

            if (isRelatedToCustomer && selectedBusinessPartner && selectedBusinessPartner.Id) {
                notificationObj.businessPartner = parseInt(selectedBusinessPartner.Id);
            }
            if (isRelatedToActivity && selectedActivity && selectedActivity.Id) {
                notificationObj.activity = parseInt(selectedActivity.Id);
            }
            if (isRelatedToProject && selectedProject && selectedProject.Id) {
                notificationObj.project = parseInt(selectedProject.Id);
            }
            if (userProfile && userProfile.Id) {
                notificationObj.salesPerson = userProfile.Id;
            }

            if (addToOutlook) {
                
                const outlookEvent = {
                    "subject": title.trim().toString(),
                    "body": {
                        "contentType": "HTML",
                        "content": description.trim()
                    },
                    "start": {
                        "dateTime": startDate.toISOString(),
                        "timeZone": "FLE Standard Time"
                    },
                    "end": {
                        "dateTime": endDate.toISOString(),
                        "timeZone": "FLE Standard Time"
                    },
                    "location": {
                        "displayName": location.trim().toString()
                    }
                }

                await createCalendarEvent(outlookEvent);
                // Do not block if event creation fails.
                //if (res && res.status && res.status !== 200) return;

            }

            let result = await createNotification(notificationObj);

            if (result && result.status === 200 && result.data) {
                return history.goBack();
            }

            setIsLoading(false);

        } catch (error) {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        scollToTopRef.current.scrollIntoView();
    }, []);

    return (
        <Form className="notification" onSubmit={(ev) => { ev.preventDefault(); onSubmit(); }} >
        
            <div ref={scollToTopRef}></div>

            <Loading active={ isLoading } text={t('general.loading')} />

            <PageTitle active title={t('notification.newNotification')} />

            {/* Title */}
            <Form.Field
                focus
                required
                label={t('general.title')}
                placeholder={t('general.title')}
                maxLength="255"
                control={Input}
                value={title}
                onChange={(ev, {value}) => setTitle(value) }
                type="Text"
            />

            {/* Notification is related to customer, activity or project? */}
            <Container className="fieldcontent">
                <Form.Field
                    control={Checkbox}
                    label={t('notification.isRelatedToCustomer')}
                    placeholder={t('notification.isRelatedToCustomer')}
                    checked={isRelatedToCustomer}
                    onChange={(ev, {checked}) => setIsRelatedToCustomer(checked) }
                />
                <Form.Field
                    control={Checkbox}
                    label={t('notification.isRelatedToActivity')}
                    placeholder={t('notification.isRelatedToActivity')}
                    checked={isRelatedToActivity}
                    onChange={(ev, {checked}) => setIsRelatedToActivity(checked) }
                />
                <Form.Field
                    control={Checkbox}
                    label={t('notification.isRelatedToProject')}
                    placeholder={t('notification.isRelatedToProject')}
                    checked={isRelatedToProject}
                    onChange={(ev, {checked}) => setIsRelatedToProject(checked) }
                />
            </Container>

            {/* Search and select business partners if notification is related to business partner */}
            {isRelatedToCustomer && 
                <>
                    { /* Customer is NOT selected - Show searchbox */}
                    {!selectedBusinessPartner &&
                        <SearchBusinessPartners
                            required
                            title={t('businessPartner.searchCustomer')}
                            placeholder={t('businessPartner.searchCustomer')}
                            isLoading={isLoading}
                            setIsLoading={setIsLoading}
                            selectedBusinessPartner={selectedBusinessPartner}
                            setSelectedBusinessPartner={setSelectedBusinessPartner}
                            setIsValidSelectedBusinessPartner={setIsValidSelectedCustomer}
                            type="customer"
                        />
                    }
                    {/* Customer is selected - Show selected item */}
                    {selectedBusinessPartner &&
                        <SelectedItemLight
                            title={t('businessPartner.selectedCustomer')}
                            item={selectedBusinessPartner}
                            headers={[t('general.name'), t('businessPartner.area'), t('general.businessId')]}
                            fields={['Name', 'AreaName', 'AssociationNumber']}
                            required={true}
                            isValidSelectedItem={isValidSelectedCustomer}
                            onRemoveSelection={(ev) => {
                                setSelectedBusinessPartner(null);
                            }}
                        />
                    }
                </>
            }

            {/* Search and select activities if notification is related to activity */}               
            {isRelatedToActivity && 
                <>
                    { /* Activity is NOT selected - Show searchbox */}
                    {!selectedActivity &&
                        <>
                            <Form.Field  label={t('activity.searchActivities')} style={{ marginTop: '20px'}} />
                            <SearchActivities
                                required
                                //title={t('activity.searchActivities')}
                                placeholder={t('activity.searchActivities')}
                                isLoading={isLoading}
                                setIsLoading={setIsLoading}
                                selectedActivity={selectedActivity}
                                setSelectedActivity={setSelectedActivity}
                                setIsValidSelectedActivity={setIsValidSelectedActivity}
                            />
                        </>
                    }
                    {/* Activity is selected - Show selected item */}
                    {selectedActivity &&
                        <SelectedItemLight
                            title={t('activity.selectedActivity')}
                            item={selectedActivity}
                            headers={[t('general.title'), t('general.objective'), t('general.businessPartner'), t('businessPartner.businessUnit')]}
                            fields={['Title', 'Objective', 'BusinessUnitName']}
                            required={true}
                            isValidSelectedItem={isValidSelectedActivity}
                            onRemoveSelection={(ev) => {
                                setSelectedActivity(null);
                            }}
                        />
                    }
                </>
            }

            {/* Search and select projects if notification is related to Project */}
            {isRelatedToProject && 
                <>
                    { /* Project is NOT selected - Show searchbox */}
                    {!selectedProject &&
                        <>
                            <Form.Field  label={t('project.searchProjects')} style={{ marginTop: '20px'}} />
                            <SearchProjects
                                required
                                //title={t('project.searchProjects')}
                                placeholder={t('project.searchProjects')}
                                isLoading={isLoading}
                                setIsLoading={setIsLoading}
                                selectedProject={selectedProject}
                                setSelectedProject={setSelectedProject}
                                setIsValidSelectedProject={setIsValidSelectedProject}
                            />
                        </>
                    }
                    {/* Project is selected - Show selected item */}
                    {selectedProject &&
                        <SelectedItemLight
                            title={t('project.selectedProject')}
                            item={selectedProject}
                            headers={[t('general.title'), t('general.status'), t('general.businessPartner'), t('businessPartner.businessUnit')]}
                            fields={['Title', 'StatusName', 'BusinessPartnerName', 'BusinessUnitName']}
                            required={true}
                            isValidSelectedItem={isValidSelectedProject}
                            onRemoveSelection={(ev) => {
                                setSelectedProject(null);
                            }}
                        />
                    }
                </>
            }

            {/* Description */}
            <Form.Field
                required
                label={t('general.description')}
                placeholder={t('general.description')}
                control={TextArea}
                value={description}
                onChange={(ev, {value}) => setDescription(value) }
                type="Text"
            />

            {/* Date */}
            <Container className="fieldcontent">
                <Form.Field label={t('notification.sendNotificationDate')} style={{ marginBottom: '0px' }}/>
                <DatePicker
                    required
                    label={t('notification.sendNotificationDate')}
                    selected={date}
                    dateFormat={navigator.language === "fi-FI" ? "dd.MM.yyyy" : "yyyy/MM/dd"}
                    onChange={(date) => setDate(date)}
                />
            </Container>

            <NewCalendarEvent
                addToOutlook={addToOutlook}
                setAddToOutlook={setAddToOutlook}
                title={title}
                body={description}
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                location={location}
                setLocation={setLocation}
            />

            {/* Buttons */}
            <Container className='button-container'>
                <CancelButton onClick={(ev) => {ev.preventDefault(); history.goBack(); }} />
                <SubmitButton type="submit" className="bb-lblue" icon="save" title={t('general.save')} />
            </Container>

        </Form>
    );

}

export default NewNotification;
