import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Container } from 'semantic-ui-react';
import { YesNoModal } from '../UI/Modals';
import PagedTable from '../UI/Lists';
import useAcknowledgeNotification from '../../Hooks/Notifications/useAcknowledgeNotification';

const MyTop5Notifications = ({setIsLoading, notifications, listNotifications }) => {

    const { t } = useTranslation();

    const [ modalIsOpen, setModalIsOpen ] = useState(false);
    const [ ackNotificationId, setAckNotificationId ] = useState(0);
    const { acknowledgeNotification } = useAcknowledgeNotification();

    return (
        <Container>

            {/* Notifications list header */}
            <h2>{t("notification.activeNotifications")}</h2>

            {/* Approve notification modal */}
            <YesNoModal
                type="ready"
                header={t('general.ready')}
                text={t('general.areYouSure')}
                method={async (answer, id) => {
                    setIsLoading(true);
                    if (answer) await acknowledgeNotification(id);
                    await listNotifications(true, true, 5);
                    setIsLoading(false);
                }}
                item={ackNotificationId}
                open={modalIsOpen}
                setOpen={setModalIsOpen}
                noClick={false}
            />

            {/* Notifications list */}
            <PagedTable unstackable celled striped compact='very' pageSize={5}
                header={[
                    t('general.title'),
                    t('general.activity'),
                    t('general.project'),
                    t('general.customer'),
                    t('notification.sendNotificationDate'),
                    t('general.description'),
                    t('general.action')
                ]}
                content={notifications.filter(item => item.AcknowledgementTime === null)}
                resetPageOnContentChange={true}
                contentKeys={[
                    {key: 'Title', target: '/notifications/view/', type: 'link', targetKey: 'Id'},
                    {key: 'ActivityTitle'},
                    {key: 'ProjectTitle'},
                    {key: 'BusinessPartnerName'},
                    {key: 'Date', type: 'date'},
                    {key: 'Description' },
                    {key: 'Id', type: 'action', text: t('general.acknowledge'), action: (ev, row, cellKey) => { 
                        setAckNotificationId(row.Id);
                        setModalIsOpen(true);
                    }}
                ]}
                style={{ marginBottom: '20px' }}
            />

            {/* Link to all notifications */}
            <Container textAlign="right">
                <Link to="/notifications">{t('notification.notifications') + " >>>"}</Link>
            </Container>

        </Container>
    );
}

export default MyTop5Notifications