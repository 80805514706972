import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Button, Icon } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next';

/* All buttons accept semantic ui button props and added propertied described below.
 *
 *      title: add title to button
 *      hide: return null which means button is not visible
 *      mini: return button with out title (icon only).
 *      type: change button type (reset, submit). Default is reset.
 *      color: button color
 *      size: button size. Default is small.
 *      icon: Button icon name. All buttons have default icon!
 * 
 *      EXAMPLE: Change button to link
 *      as='a'
 *      target='_blank'
 * 
 *      EXAMPLE: Add onClickEvent
 *      onClick={(ev) => { // do something }}
 * 
*/

const NewButton = (props) => {

    const { t } = useTranslation();

    const { title, hide, mini, type, color, size, icon, ...rest } = props;

    if (hide) return null;

    /* Return button with ICON only */
    if (mini) return (
        <Button
            icon
            type={type ? type : 'reset'}
            color={color ? color : null}
            size={size  ? size : 'small'}            
            {...rest}
        >
            <Icon name={icon ? icon : 'add'} />
        </Button>
    );

    /* Return button with icon and title */
    return (
        <Button
            icon
            type={type ? type : 'reset'}
            color={color ? color : null}
            size={size  ? size : 'small'}
            labelPosition={'left'}
            {...rest}
        >
            <Icon name={icon ? icon : 'add'} />
            {title ? title : t('general.new')}
        </Button>
    );

}

const ViewButton = (props) => {

    const { t } = useTranslation();

    const { title, hide, mini, type, color, size, icon, ...rest } = props;

    if (hide) return null;

    /* Return button with ICON only */
    if (mini) return (
        <Button
            icon
            type={type ? type : 'reset'}
            color={color ? color : null}
            size={size  ? size : 'small'}
            {...rest}
        >
            <Icon name={icon ? icon : 'arrow right'} />
        </Button>
    );

    /* Return button with icon and title */
    return (
        <Button
            icon
            type={type ? type : 'reset'}
            color={color ? color : null}
            size={size  ? size : 'small'}
            labelPosition={'left'}
            {...rest}
        >
            <Icon name={icon ? icon : 'arrow right'} />
            {title ? title : t('general.view')}
        </Button>
    );

}

const EditButton = (props) => {

    const { t } = useTranslation();

    const { title, hide, mini, type, color, size, icon, ...rest } = props;

    if (hide) return null;

    /* Return button with ICON only */
    if (mini) return (
        <Button
            icon
            type={type ? type : 'reset'}
            color={color ? color : null}
            size={size  ? size : 'small'}
            {...rest}
        >
            <Icon name={icon ? icon : 'pencil'} />
        </Button>
    );

    /* Return button with icon and title */
    return (
        <Button
            icon
            type={type ? type : 'reset'}
            color={color ? color : null}
            size={size  ? size : 'small'}
            labelPosition={'left'}
            {...rest}
        >
            <Icon name={icon ? icon : 'pencil'} />
            {title ? title : t('general.edit')}
        </Button>
    );

}

const DeleteButton = (props) => {

    const { t } = useTranslation();

    const { title, hide, mini, type, color, size, icon, ...rest } = props;

    if (hide) return null;

    /* Return button with ICON only */
    if (mini) return (
        <Button
            icon
            type={type ? type : 'reset'}
            color={color ? color : null}
            size={size  ? size : 'small'}
            {...rest}
        >
            <Icon name={icon ? icon : 'delete'} />
        </Button>
    );

    /* Return button with icon and title */
    return (
        <Button
            icon
            type={type ? type : 'reset'}
            color={color ? color : null}
            size={size  ? size : 'small'}
            labelPosition={'left'}
            {...rest}
        >
            <Icon name={icon ? icon : 'delete'} />
            {title ? title : t('general.delete')}
        </Button>
    );

}

const SubmitButton = (props) => {

    const { t } = useTranslation();

    const { title, hide, mini, type, color, size, icon, ...rest } = props;

    if (hide) return null;

    /* Return button with ICON only */
    if (mini) return (
        <Button
            icon
            type={type ? type : 'reset'}
            color={color ? color : null}
            size={size  ? size : 'small'}
            {...rest}
        >
            <Icon name={icon ? icon : 'save'} />
        </Button>
    );

    /* Return button with icon and title */
    return (
        <Button
            icon
            type={type ? type : 'reset'}
            color={color ? color : null}
            size={size  ? size : 'small'}
            labelPosition={'left'}
            {...rest}
        >
            <Icon name={icon ? icon : 'save'} />
            {title ? title : t('general.submit')}
        </Button>
    );

}

const SaveButton = (props) => {

    const { t } = useTranslation();

    const { title, hide, mini, type, color, size, icon, ...rest } = props;

    if (hide) return null;

    /* Return button with ICON only */
    if (mini) return (
        <Button
            icon
            type={type ? type : 'reset'}
            color={color ? color : null}
            size={size  ? size : 'small'}
            {...rest}
        >
            <Icon name={icon ? icon : 'save'} />
        </Button>
    );

    /* Return button with icon and title */
    return (
        <Button
            icon
            type={type ? type : 'reset'}
            color={color ? color : null}
            size={size  ? size : 'small'}
            labelPosition={'left'}
            {...rest}
        >
            <Icon name={icon ? icon : 'save'} />
            {title ? title : t('general.save')}
        </Button>
    );

}

const YesButton = (props) => {

    const { t } = useTranslation();

    const { title, hide, mini, type, color, size, icon, ...rest } = props;

    if (hide) return null;

    /* Return button with ICON only */
    if (mini) return (
        <Button
            icon
            type={type ? type : 'reset'}
            color={color ? color : null}
            size={size  ? size : 'small'}
            {...rest}
        >
            <Icon name={icon ? icon : 'check'} />
        </Button>
    );

    /* Return button with icon and title */
    return (
        <Button
            icon
            type={type ? type : 'reset'}
            color={color ? color : null}
            size={size  ? size : 'small'}
            labelPosition={'left'}
            {...rest}
        >
            <Icon name={icon ? icon : 'check'} />
            {title ? title : t('general.yes')}
        </Button>
    );

}

const NoButton = (props) => {

    const { t } = useTranslation();

    const { title, hide, mini, type, color, size, icon, ...rest } = props;

    if (hide) return null;

    /* Return button with ICON only */
    if (mini) return (
        <Button
            icon
            type={type ? type : 'reset'}
            color={color ? color : null}
            size={size  ? size : 'small'}
            {...rest}
        >
            <Icon name={icon ? icon : 'ban'} />
        </Button>
    );

    /* Return button with icon and title */
    return (
        <Button
            icon
            type={type ? type : 'reset'}
            color={color ? color : null}
            size={size  ? size : 'small'}
            labelPosition={'left'}
            {...rest}
        >
            <Icon name={icon ? icon : 'ban'} />
            {title ? title : t('general.no')}
        </Button>
    );

}

const OpenButton = (props) => {

    const { t } = useTranslation();

    const { title, hide, mini, type, color, size, icon, ...rest } = props;

    if (hide) return null;

    /* Return button with ICON only */
    if (mini) return (
        <Button
            icon
            type={type ? type : 'reset'}
            color={color ? color : null}
            size={size  ? size : 'small'}
            {...rest}
        >
            <Icon name={icon ? icon : 'arrow right'} />
        </Button>
    );

    /* Return button with icon and title */
    return (
        <Button
            icon
            type={type ? type : 'reset'}
            color={color ? color : null}
            size={size  ? size : 'small'}
            labelPosition={'left'}
            {...rest}
        >
            <Icon name={icon ? icon : 'arrow right'} />
            {title ? title : t('general.open')}
        </Button>
    );

}

const CloseButton = (props) => {

    const { t } = useTranslation();

    const { title, hide, mini, type, color, size, icon, ...rest } = props;

    if (hide) return null;

    /* Return button with ICON only */
    if (mini) return (
        <Button
            icon
            type={type ? type : 'reset'}
            color={color ? color : null}
            size={size  ? size : 'small'}
            {...rest}
        >
            <Icon name={icon ? icon : 'cancel'} />
        </Button>
    );

    /* Return button with icon and title */
    return (
        <Button
            icon
            type={type ? type : 'reset'}
            color={color ? color : null}
            size={size  ? size : 'small'}
            labelPosition={'left'}
            {...rest}
        >
            <Icon name={icon ? icon : 'cancel'} />
            {title ? title : t('general.close')}
        </Button>
    );

}

const BackButton = (props) => {

    const { t } = useTranslation();

    const { title, hide, mini, type, color, size, icon, ...rest } = props;

    if (hide) return null;

    /* Return button with ICON only */
    if (mini) return (
        <Button
            icon
            type={type ? type : 'reset'}
            color={color ? color : null}
            size={size  ? size : 'small'}
            {...rest}
        >
            <Icon name={icon ? icon : 'arrow left'} />
        </Button>
    );

    /* Return button with icon and title */
    return (
        <Button
            icon
            type={type ? type : 'reset'}
            color={color ? color : null}
            size={size  ? size : 'small'}
            labelPosition={'left'}
            {...rest}
        >
            <Icon name={icon ? icon : 'arrow left'} />
            {title ? title : t('general.back')}
        </Button>
    );

}

const CancelButton = (props) => {

    const { t } = useTranslation();

    const { title, hide, mini, type, color, size, icon, ...rest } = props;

    if (hide) return null;

    /* Return button with ICON only */
    if (mini) return (
        <Button
            icon
            type={type ? type : 'reset'}
            color={color ? color : null}
            size={size  ? size : 'small'}
            {...rest}
        >
            <Icon name={icon ? icon : 'arrow left'} />
        </Button>
    );

    /* Return button with icon and title */
    return (
        <Button
            icon
            type={type ? type : 'reset'}
            color={color ? color : null}
            size={size  ? size : 'small'}
            labelPosition={'left'}
            {...rest}
        >
            <Icon name={icon ? icon : 'arrow left'} />
            {title ? title : t('general.cancel')}
        </Button>
    );

}

const ReportButton = (props) => {

    const { t } = useTranslation();

    const { title, hide, mini, type, color, size, icon, ...rest } = props;

    if (hide) return null;

    /* Return button with ICON only */
    if (mini) return (
        <Button
            icon
            type={type ? type : 'reset'}
            color={color ? color : null}
            size={size  ? size : 'small'}
            {...rest}
        >
            <Icon name={icon ? icon : 'chart bar outline'} />
        </Button>
    );

    /* Return button with icon and title */
    return (
        <Button
            icon
            type={type ? type : 'reset'}
            color={color ? color : null}
            size={size  ? size : 'small'}
            labelPosition={'left'}
            {...rest}
        >
            <Icon name={icon ? icon : 'chart bar outline'} />
            {title ? title : t('general.report')}
        </Button>
    );

}

const CreatePDFButton = (props) => {

    const { t } = useTranslation();

    const { title, hide, mini, type, color, size, icon, ...rest } = props;

    if (hide) return null;

    /* Return button with ICON only */
    if (mini) return (
        <Button
            icon
            type={type ? type : 'reset'}
            color={color ? color : null}
            size={size  ? size : 'small'}
            {...rest}
        >
            <Icon name={icon ? icon : 'file pdf outline'} />
        </Button>
    );

    /* Return button with icon and title */
    return (
        <Button
            icon
            type={type ? type : 'reset'}
            color={color ? color : null}
            size={size  ? size : 'small'}
            labelPosition={'left'}
            {...rest}
        >
            <Icon name={icon ? icon : 'file pdf outline'} />
            {title ? title : t('general.createPdf')}
        </Button>
    );

}

const CreateCSVButton = (props) => {

    const { t } = useTranslation();

    const { title, hide, mini, type, color, size, icon, ...rest } = props;

    if (hide) return null;

    /* Return button with ICON only */
    if (mini) return (
        <Button
            icon
            type={type ? type : 'reset'}
            color={color ? color : null}
            size={size  ? size : 'small'}
            {...rest}
        >
            <Icon name={icon ? icon : 'file alternate outline'} />
        </Button>
    );

    /* Return button with icon and title */
    return (
        <Button
            icon
            type={type ? type : 'reset'}
            color={color ? color : null}
            size={size  ? size : 'small'}
            labelPosition={'left'}
            {...rest}
        >
            <Icon name={icon ? icon : 'file alternate outline'} />
            {title ? title : t('general.createCsv')}
        </Button>
    );

}

const DownloadButton = (props) => {

    const { t } = useTranslation();

    const { title, hide, mini, type, color, size, icon, ...rest } = props;

    if (hide) return null;

    /* Return button with ICON only */
    if (mini) return (
        <Button
            icon
            type={type ? type : 'reset'}
            color={color ? color : null}
            size={size  ? size : 'small'}
            {...rest}
        >
            <Icon name={icon ? icon : 'download'} />
        </Button>
    );

    /* Return button with icon and title */
    return (
        <Button
            icon
            type={type ? type : 'reset'}
            color={color ? color : null}
            size={size  ? size : 'small'}
            labelPosition={'left'}
            {...rest}
        >
            <Icon name={icon ? icon : 'download'} />
            {title ? title : t('general.download')}
        </Button>
    );

}

const UploadButton = (props) => {

    const { t } = useTranslation();

    const { title, hide, mini, type, color, size, icon, ...rest } = props;

    if (hide) return null;

    /* Return button with ICON only */
    if (mini) return (
        <Button
            icon
            type={type ? type : 'reset'}
            color={color ? color : null}
            size={size  ? size : 'small'}
            {...rest}
        >
            <Icon name={icon ? icon : 'upload'} />
        </Button>
    );

    /* Return button with icon and title */
    return (
        <Button
            icon
            type={type ? type : 'reset'}
            color={color ? color : null}
            size={size  ? size : 'small'}
            labelPosition={'left'}
            {...rest}
        >
            <Icon name={icon ? icon : 'upload'} />
            {title ? title : t('general.upload')}
        </Button>
    );

}

const UserButton = (props) => {

    const { t } = useTranslation();

    const { title, hide, mini, type, color, size, icon, ...rest } = props;

    if (hide) return null;

    /* Return button with ICON only */
    if (mini) return (
        <Button
            icon
            type={type ? type : 'reset'}
            color={color ? color : null}
            size={size  ? size : 'small'}
            {...rest}
        >
            <Icon name={icon ? icon : 'user'} />
        </Button>
    );

    /* Return button with icon and title */
    return (
        <Button
            icon
            type={type ? type : 'reset'}
            color={color ? color : null}
            size={size  ? size : 'small'}
            labelPosition={'left'}
            {...rest}
        >
            <Icon name={icon ? icon : 'user'} />
            {title ? title : t('general.user')}
        </Button>
    );

}

const HelpButton = (props) => {

    const { t } = useTranslation();

    const { title, hide, mini, type, color, size, icon, ...rest } = props;

    if (hide) return null;

    /* Return button with ICON only */
    if (mini) return (
        <Button
            icon
            type={type ? type : 'reset'}
            color={color ? color : null}
            size={size  ? size : 'small'}
            {...rest}
        >
            <Icon name={icon ? icon : 'help'} />
        </Button>
    );

    /* Return button with icon and title */
    return (
        <Button
            icon
            type={type ? type : 'reset'}
            color={color ? color : null}
            size={size  ? size : 'small'}
            labelPosition={'left'}
            {...rest}
        >
            <Icon name={icon ? icon : 'help'} />
            {title ? title : t('general.help')}
        </Button>
    );

}

const QuestionButton = (props) => {

    const { t } = useTranslation();

    const { title, hide, mini, type, color, size, icon, ...rest } = props;

    if (hide) return null;

    /* Return button with ICON only */
    if (mini) return (
        <Button
            icon
            type={type ? type : 'reset'}
            color={color ? color : null}
            size={size  ? size : 'small'}
            {...rest}
        >
            <Icon name={icon ? icon : 'question'} />
        </Button>
    );

    /* Return button with icon and title */
    return (
        <Button
            icon
            type={type ? type : 'reset'}
            color={color ? color : null}
            size={size  ? size : 'small'}
            labelPosition={'left'}
            {...rest}
        >
            <Icon name={icon ? icon : 'question'} />
            {title ? title : t('general.question')}
        </Button>
    );

}

const EmailButton = (props) => {

    const { t } = useTranslation();

    const { title, hide, mini, type, color, size, icon, ...rest } = props;

    if (hide) return null;

    /* Return button with ICON only */
    if (mini) return (
        <Button
            icon
            type={type ? type : 'reset'}
            color={color ? color : null}
            size={size  ? size : 'small'}
            {...rest}
        >
            <Icon name={icon ? icon : 'mail'} />
        </Button>
    );

    /* Return button with icon and title */
    return (
        <Button
            icon
            type={type ? type : 'reset'}
            color={color ? color : null}
            size={size  ? size : 'small'}
            labelPosition={'left'}
            {...rest}
        >
            <Icon name={icon ? icon : 'mail'} />
            {title ? title : t('general.email')}
        </Button>
    );

}

const PrintPageButton = (props) => {

    const { t } = useTranslation();

    const { title, hide, mini, type, color, size, icon, ...rest } = props;

    if (hide) return null;

    /* Return button with ICON only */
    if (mini) return (
        <Button
            icon
            type={type ? type : 'reset'}
            color={color ? color : null}
            size={size  ? size : 'small'}
            {...rest}
        >
            <Icon name={icon ? icon : 'print'} />
        </Button>
    );

    /* Return button with icon and title */
    return (
        <Button
            icon
            type={type ? type : 'reset'}
            color={color ? color : null}
            size={size  ? size : 'small'}
            labelPosition={'left'}
            {...rest}
        >
            <Icon name={icon ? icon : 'print'} />
            {title ? title : t('general.print')}
        </Button>
    );

}

const InfoButton = (props) => {

    const { t } = useTranslation();

    const { title, hide, mini, type, color, size, icon, ...rest } = props;

    if (hide) return null;

    /* Return button with ICON only */
    if (mini) return (
        <Button
            icon
            type={type ? type : 'reset'}
            color={color ? color : 'blue'}
            size={size  ? size : 'small'}
            {...rest}
        >
            <Icon name={icon ? icon : 'info'} />
        </Button>
    );

    /* Return button with icon and title */
    return (
        <Button
            icon
            type={type ? type : 'reset'}
            color={color ? color : 'blue'}
            size={size  ? size : 'small'}
            labelPosition={'left'}
            {...rest}
        >
            <Icon name={icon ? icon : 'info'} />
            {title ? title : t('general.info')}
        </Button>
    );

}

const SearchButton = (props) => {

    const { t } = useTranslation();

    const { title, hide, mini, type, color, size, icon, ...rest } = props;

    if (hide) return null;

    /* Return button with ICON only */
    if (mini) return (
        <Button
            icon
            type={type ? type : 'reset'}
            color={color ? color : null}
            size={size  ? size : 'small'}
            {...rest}
        >
            <Icon name={icon ? icon : 'search'} />
        </Button>
    );

    /* Return button with icon and title */
    return (
        <Button
            icon
            type={type ? type : 'reset'}
            color={color ? color : null}
            size={size  ? size : 'small'}
            labelPosition={'left'}
            {...rest}
        >
            <Icon name={icon ? icon : 'search'} />
            {title ? title : t('general.search')}
        </Button>
    );

}

const ApproveButton = (props) => {

    const { t } = useTranslation();

    const { title, hide, mini, type, color, size, icon, ...rest } = props;

    if (hide) return null;

    /* Return button with ICON only */
    if (mini) return (
        <Button
            icon
            type={type ? type : 'reset'}
            color={color ? color : null}
            size={size  ? size : 'small'}
            {...rest}
        >
            <Icon name={icon ? icon : 'check'} />
        </Button>
    );

    /* Return button with icon and title */
    return (
        <Button
            icon
            type={type ? type : 'reset'}
            color={color ? color : null}
            size={size  ? size : 'small'}
            labelPosition={'left'}
            {...rest}
        >
            <Icon name={icon ? icon : 'check'} />
            {title ? title : t('general.approve')}
        </Button>
    );

}

/** React routed dom link styled as button
 * @param {String} title - button title
 * @param {Boolean} hide - hide button
 * @param {Boolean} mini - show mini button without title
 * @param {String} color - button color f.e 'grey'
 * @param {String} size - button size f.e 'small'
 * @param {String} icon - button icon name f.e. 'add'
 * @param {String} className - additional classname
 * @param {Boolean} fluid - Change button width to 100% */
const LinkButton = (props) => {

    const { t } = useTranslation();
    const history = useHistory();

    const { title, hide, mini, color, size, icon, className, fluid, ...rest } = props;
    
    let cls = props.className ? props.className : '';
        cls += ' ui icon button';
        if (!mini) cls += ' left labeled'
        if (size) cls += ' ' + size;
        if (!size) cls += ' small';
        if (fluid) cls += ' fluid';

    if (hide) return null;

    /* Return button with ICON only */
    if (mini) return (
        <Link
            color={color ? color : null}
            className={cls}
            onClick={() => history.push("/activities/new")}
            {...rest}
        >   
            <Icon name={icon ? icon : 'linkify'} />
        </Link>
    );

    /* Return button with icon and title */
    return (
        <Link
            color={color ? color : null}
            className={cls}
            onClick={() => history.push("/activities/new")}
            {...rest}
        >
            <Icon name={icon ? icon : 'linkify'} />
            {title || t('general.www') }
        </Link>
    );

}

export {

    NewButton,
    ViewButton,
    EditButton,
    DeleteButton,
    YesButton,
    NoButton,
    OpenButton,
    CloseButton,
    BackButton,
    SubmitButton,
    SaveButton,
    CancelButton,

    ReportButton,
    CreatePDFButton,
    CreateCSVButton,
    DownloadButton,
    UploadButton,
    UserButton,
    HelpButton,
    QuestionButton,
    EmailButton,
    PrintPageButton,
    InfoButton,
    LinkButton,
    SearchButton,
    ApproveButton

}