
function capitalizeFirstLetter (string) {
    if (string && typeof string === 'string') {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
    return "";
}

function sortByProperty (array, param, asc = true) {
    if (!Array.isArray(array)) return;
    return array?.slice().sort((a, b) => {
                                if(!a[param]) a[param] = ''
                                if(!b[param]) b[param] = '' 
                                return asc ? (a[param] > b[param]) ? 1 : ((b[param] > a[param]) ? -1 : 0) :
                                             (a[param] > b[param]) ? -1 : ((b[param] > a[param]) ? 1 : 0)
                                });
}

function sortByPropertyAsInt (array, param, asc = true) {
    if (!Array.isArray(array)) return;
    const numbers = array
                        .slice()
                        .filter(item => !isNaN(parseInt(item[param])))
                        .sort((a, b) => {
                            if(!a[param]) a[param] = ''
                            if(!b[param]) b[param] = '' 
                            return asc ? (parseInt(a[param]) > parseInt(b[param])) ? 1 : ((parseInt(b[param]) > parseInt(a[param])) ? -1 : 0) :
                                         (parseInt(a[param]) > parseInt(b[param])) ? -1 : (parseInt((b[param]) > parseInt(a[param])) ? 1 : 0)
                            });
                        
 
    const strings = sortByProperty(array.filter(item => isNaN(parseInt(item[param]))), param, asc)
 
    return asc ? numbers.concat(strings) : strings.concat(numbers);
}

function sortByDate (array, param, asc = true) {
    if (!Array.isArray(array)) return;
    return array?.slice().sort((a, b) => asc ? new Date(a[param]) - new Date(b[param]) : new Date(b[param]) - new Date(a[param]))
}

/*

function groupArrayBy (array, property) {
    if (!Array.isArray(array)) return;
    const param = String(property);
    
    const grouped = array.reduce((memo, item) => {
      if (!memo[item[param]]) {
           memo[item[param]] = []; 
      }
      memo[item[param]].push(item);
      return memo;
    }, []);
    return grouped;
}
*/

/*

function groupArrayByObject (array, property) {
    if (!Array.isArray(array)) return;
    const param = String(property);
    
    const grouped = array.reduce((memo, item) => {
      if (!memo[item[param]]) {
           memo[item[param]] = []; 
      }
      memo[item[param]].push(item);
      return memo;
    }, {});
    return grouped;
}
*/

/*

function groupBy(array, property) {
    return array.reduce(function (r, a) {
        r[a[property]] = r[a[property]] || [];
        r[a[property]].push(a);
        return r;
    }, {})
}
*/

/*

function convertImageToBase64 (img) {
    try {
        const canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        return canvas.toDataURL("image/png");
    } catch (error) {
        return error;
    }
}
*/

/*

// Convert image buffer data to JPEG image @see: https://gist.github.com/candycode/f18ae1767b2b0aba568e
async function bufferDataToJpeg (buf) {
    try {
        // Obtain a blob: URL for the image data.
        const arrayBufferView = new Uint8Array( buf );
        const blob = new Blob( [ arrayBufferView ], { type: "image/jpeg" } );
        const urlCreator = window.URL || window.webkitURL;
        const imageUrl = urlCreator.createObjectURL( blob );
        return imageUrl;
    } catch (error) {
        return error;
    }

}
*/

/*

function parseZonelessTime (timestring) {
    if (!timestring) return '';
    const time = timestring.split('T')[1];
    const hoursAndMinutes = time.split(':');
    return `${hoursAndMinutes[0]}.${hoursAndMinutes[1]}`
}
*/

/*

function parseIntOrFloat (number) {
    if (!number) return number;
    if (number.indexOf(',') > -1) {
        const convertComma = number.replace(',', '.');
        const rounded = parseFloat(convertComma).toFixed(2);
        return String(rounded).replace('.', ',');
    }
    if (number.indexOf('.') > -1) {
        const rounded = parseFloat(number).toFixed(2);
        return String(rounded).replace('.', ',');
    }    
    return parseInt(number);
}
*/

/** Round to n digits.
 * @param {number} number - number to be rounded
 * @param {number} decimals - decimals
 * @example roundNumber(3.14159265359, 2) => "3.14"**/
let roundNumber = (number, decimals) => {
    if (decimals === undefined) decimals = 0;
    const factorOfTen = Math.pow(10, decimals);
    return Math.round(number * factorOfTen) / factorOfTen;
}

const calculateMarginPercentage = (sales, margin) => {

    /*  Margin percentage formula:
         - marginPercentage = ((margin * 100) / sales)
        MarginPercentage is not calculated if "sales" or "margin" is erroneous (0, null etc..)
    */

    let percentage = null;

    if (sales > 0) percentage = ((margin * 100) / sales);

    return percentage;

}

const calculateUnitPrice = (potentialSales, potentialVolume) => {
    let volume = potentialVolume ? potentialVolume : 1
    return (potentialSales / volume);
}

const lib = {
    capitalizeFirstLetter,
    sortByProperty,
    sortByPropertyAsInt,
    sortByDate,
    //groupArrayBy,
    //groupArrayByObject,
    //groupBy,
    //convertImageToBase64,
    //bufferDataToJpeg,
    //parseZonelessTime,
    //parseIntOrFloat,
    calculateMarginPercentage,
    calculateUnitPrice,
    roundNumber
};

export default lib;
