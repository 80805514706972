import { useState } from 'react';
import webApiClient from './../../Utils/webApiClient';
import useRequestHandler from './../useRequestHandler';
import { msalInstance } from './../../index';
import config from './../../Config/config';

/** VerifyAuthenticatedUser hook is used to read users Azure AD profile and AD security groups and verify that user exist.
 * API Endpoint creates or updates changed roles. This is the first endpoint to call after the user has logged in to the app. **/
const useVerifyAuthenticatedUser = () => {

    const { errorHandler, successHandler } = useRequestHandler();
    const [ verifiedUserProfile, setVerifiedUserProfile ] = useState({});
    const apiClient = webApiClient();

    const verifyAuthenticatedUser = async (id) => {
        try {

            // Account is set in the index.js
            const account = msalInstance.getActiveAccount();

            const response = await msalInstance.acquireTokenSilent({
                scopes: config.webApi.scopes,
                account: account
            });

            const result = await apiClient.read(response.accessToken, "users/verify");

            const data = result && result.data ? result.data : null;
            const resultData = data.resultData || null;
            const content = resultData[0] || null;

            if (content) setVerifiedUserProfile(content);

            return successHandler(result, "useVerifyAuthenticatedUser", "read", { hide: true });

        } catch (error) {
            return errorHandler(error, "useVerifyAuthenticatedUser", "read");
        }
    }

    return { verifiedUserProfile, verifyAuthenticatedUser };

}

export default useVerifyAuthenticatedUser;
