import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '../../Components/UI/Content';

const PageNotFound = (props) => {

    const { t } = useTranslation();

    return (
        <div className="pagenotfound">
            <PageTitle title={t('navigation.pagenotfound')} />
            <p>404</p>
        </div>
    );

}

export default PageNotFound;
