import { useState, useRef } from 'react';
import webApiClient from './../../Utils/webApiClient';
import { msalInstance } from '../../index';
import config from '../../Config/config';
import useRequestHandler from '../useRequestHandler';
import axios from 'axios';

/** Hook is used to search business partners by search term */
const useSearchBusinessPartners = () => {

    const { errorHandler, successHandler } = useRequestHandler();
    const [ businessPartners, setBusinessPartners ] = useState([]);
    const apiClient = webApiClient();

    const CancelToken = axios.CancelToken;
    const cancelTokenSource = useRef(null);

    /** Search business partners by search term
     * @param {string} searchterm - search term
     * @param {integer} top - select top n business partners
     * @param {string} businessPartnerType - businesspartner type or null - "customer", "supplier", "prospect"
     * @param {boolean} own - list only my projects or all
     * @param {boolean} status status: [1,2,3,4,5,6,7] - array of status codes*/
    const searchBusinessPartners = async (searchterm = "", top = 20, businessPartnerType = null, own = false, status = [1,2,3,4,5,6,7,8,9,10,11]) => {
        try {

            if (cancelTokenSource.current) {
                //console.log('Cancel previous request');
                cancelTokenSource.current.cancel('Cancel previous request');
            }
            
            cancelTokenSource.current = CancelToken.source();

            // Account is set in the index.js
            const account = msalInstance.getActiveAccount();

            const response = await msalInstance.acquireTokenSilent({
                scopes: config.webApi.scopes,
                account: account
            });

            let resource = "search/businesspartners?searchterm=" + searchterm.toString();
            if (businessPartnerType) resource += "&businessPartnerType=" + businessPartnerType.toString();
            resource += "&top=" + top.toString();
            resource += "&own=" + own.toString();
            resource += "&status=" + status.toString();

            const result = await apiClient.read(response.accessToken, resource, null, { cancelToken: cancelTokenSource.current });
            const data = result && result.data ? result.data : null;
            const content = data && data.resultData ? data.resultData : [];

            if (content) setBusinessPartners(content);

            return successHandler(result, "useSearchBusinessPartners", "search", { hide: true });

        } catch (error) {
            return errorHandler(error, "useSearchBusinessPartners", "search");
        }
    }

    return { businessPartners, setBusinessPartners, searchBusinessPartners };

}

export default useSearchBusinessPartners;
