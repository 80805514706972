import React, { createContext, useState } from 'react';
import { Message, Icon } from 'semantic-ui-react';
import { v4 as uuidv4 } from 'uuid';

const InfoMessage = (props) => {
    const onDismiss = () => {
        if (props && props.onDismiss) props.onDismiss();
    }
    return (
        <Message size='tiny' info onDismiss={() => onDismiss()}>
            <Message.Content>
                <Message.Header>
                    <Icon name='circle notched' loading />
                    {props.header}
                </Message.Header>
                {props.content}
            </Message.Content>
        </Message>
    )
}

const PositiveMessage = (props) => {
    const onDismiss = () => {
        if (props && props.onDismiss) props.onDismiss();
    }
    return (
        <Message size='tiny'  positive onDismiss={() => onDismiss()}>
            <Message.Content>
                <Message.Header>
                    <Icon name='circle notched' />
                    {props.header}</Message.Header>
                {props.content}
                <br/>
                {props.optional}
            </Message.Content>
        </Message>
    )
}

const WarningMessage = (props) => {
    const onDismiss = () => {
        if (props && props.onDismiss) props.onDismiss();
    }
    return (
        <Message size='tiny' warning onDismiss={() => onDismiss()}>
            <Message.Content>
                <Message.Header>
                    <Icon name='exclamation' />    
                    {props.header}
                </Message.Header>
                {props.content}
                <br/>
                {props.optional}
            </Message.Content>
        </Message>
    )
}

const ErrorMessage = (props) => {
    const onDismiss = () => {
        if (props && props.onDismiss) props.onDismiss();
    }
    return (
        <Message size='tiny' negative onDismiss={() => onDismiss()}>
            <Message.Content>
                <Message.Header>
                    <Icon name='exclamation triangle'/>
                    {props.header}
                </Message.Header>
                {props.content}
                <br/>
                {props.optional}
            </Message.Content>
        </Message>
    )
}

const FetchingMessage = (props) => {
    const onDismiss = () => {
        if (props && props.onDismiss) props.onDismiss();
    }
    return (
        <Message size='tiny' positive onDismiss={() => onDismiss()}>
            <Message.Content>
                <Message.Header>
                    <Icon name='circle notched' loading />
                    {props.header}
                </Message.Header>
                {props.content}
            </Message.Content>
        </Message>
    )
}

const SavingMessage = (props) => {
    const onDismiss = () => {
        if (props && props.onDismiss) props.onDismiss();
    }
    return (
        <Message size='tiny' info icon onDismiss={() => onDismiss()}>
            <Message.Content>
                <Message.Header>
                    <Icon name='check' />
                    {props.header}
                </Message.Header>
                {props.content}
            </Message.Content>
        </Message>
    )
}

export const MessageContext = createContext();

/** Message provider for the application
 *  Add all message properties and methods here. */
const MessageContextProvider = ({children}) => {

    /*
    const testMessages = [
        { "id": 1, "header": "header1", "body": "body1", "type": "info"},
        { "id": 2, "header": "header2", "body": "body2", "type": "success"},
        { "id": 3, "header": "header3", "body": "body3", "type": "warning"},
        { "id": 4, "header": "header4", "body": "body4", "type": "error"},
        { "id": 5, "header": "header5", "body": "body5", "type": "loading"},
        { "id": 6, "header": "header6", "body": "body6", "type": "saving"}
    ];
    */

    const [ messages, setMessages ] = useState([]);
    //const [ currentLocation, setCurrentLocation ] = useState(window.location.pathname);

    // Remove message from the messages array (dont remove errors automatically!)
    const removeMessageAfterTimeout = (id) => {
        return setTimeout(() => {
            setMessages(messages => {
                return messages.filter(msg => {
                    if (msg.type === 'error') return true;
                    if (msg.type === 'portal') return true; // Portal message is visible until removed by user
                    if (msg.id === id) return false;
                    return true;
                });
            });
        }, 5000);
    }

    const removeErrorMessageAfterTimeout = (id) => {
        return setTimeout(() => {
            setMessages(messages => {
                return messages.filter(msg => {
                    if (msg.type === 'error') return false;
                    if (msg.type === 'portal') return false; // Portal message is visible until removed by user
                    if (msg.id === id) return true;
                    return true;
                });
            });
        }, 10000);
    }

    /** Function adds message to the container
     * @description type, header and body are all mandatory!!!
     * @param {object || array} msg - Message object or array of objects
     * @example
     *    Array:  [{type:"", header:"", body:""},{type:"", header:"", body:""}]
     *    Object: {type:"", header:"", body:""} */
    const addMessage = (msg) => {

        const header = msg && msg.header ? msg.header : " ";
        const body = msg && msg.body ? msg.body : " ";
        
        // Message is array of objects 
        if (Array.isArray(msg)) {

            return setMessages(messages => {
                const clone = [...messages]; //cloneArray(messages);
                msg.forEach(item => {
                    item.id = uuidv4();
                    clone.push(item);
                    if (item.type === 'error') removeErrorMessageAfterTimeout(item.id);
                    if (item.type !== 'error') removeMessageAfterTimeout(item.id);
                });
                return clone;
            });

        // Message is object 
        } else if (msg && msg.type && header && body) {

                msg.id = uuidv4();
                setMessages(messages => {
                    const clone = [...messages]; //cloneArray(messages);
                    clone.push(msg);
                    if (msg.type === 'error') removeErrorMessageAfterTimeout(msg.id);
                    if (msg.type !== 'error') removeMessageAfterTimeout(msg.id);
                    return clone;
                });
                return msg.id;
        
        // Error
        } else {
            throw new Error("Message is not and object or array!");
        }
    }

    /* Function removes message from the container */
    const removeMessage = (id) => {
        setMessages(messages => {
            return messages.filter(item => {
                return item.id !== id; 
             });
        });
    }

    // Remove all errors automatically when window.location change
    /*
    useEffect(() => {
        if (window.location.pathname !== currentLocation && messages) {
            setCurrentLocation(window.location.pathname);
            setMessages(messages => {
                let msg = messages.filter(item => {
                    return item.type !== 'error'; 
                });
                console.log(msg);
                //return msg
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [window.location]);*/

    // Get all messages - return Array of Semantic UI react Message componentes
    const getMessages = () => {

        const msg = messages ? messages.map(msg => {

            let selectedType = null;
    
            switch (msg.type) {
                case "success":
                    selectedType = <PositiveMessage key={msg.id} header={msg.header} content={msg.body} option={msg.option} onDismiss={() => removeMessage(msg.id)} />
                    break;
                case "warning":
                    selectedType = <WarningMessage key={msg.id} header={msg.header} content={msg.body} option={msg.option} onDismiss={() => removeMessage(msg.id)} />
                    break;
                case "error":
                    selectedType = <ErrorMessage key={msg.id} header={msg.header} content={msg.body} option={msg.option} onDismiss={() => removeMessage(msg.id)} />
                    break;
                case "saving":
                    selectedType = <SavingMessage key={msg.id} header={msg.header} content={msg.body} option={msg.option} onDismiss={() => removeMessage(msg.id)} />
                    break;
                case "loading":
                    selectedType = <FetchingMessage key={msg.id} header={msg.header} content={msg.body} option={msg.option} onDismiss={() => removeMessage(msg.id)} />
                    break;
                case "portal":
                    selectedType = <WarningMessage key={msg.id} header={msg.header} content={msg.body} option={msg.option} onDismiss={() => removeMessage(msg.id)} />
                    break;
                default:
                    selectedType = <InfoMessage key={msg.id} header={msg.header} content={msg.body} option={msg.option} onDismiss={() => removeMessage(msg.id)} />
                    break;
            }

            return selectedType;

        }) : [];

        return msg;
    }

    return (
        <MessageContext.Provider value={{ messages, addMessage, removeMessage, getMessages }}>
            {children}
        </MessageContext.Provider>
    )
}

export default MessageContextProvider;