import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Form, Search } from 'semantic-ui-react';
import useSearchBusinessPartners from '../../Hooks/Search/useSearchBusinessPartners';
import Utils from '../../Utils/utils';
import './../components.css';

/** Bang & Bonsomer - Search Business Partners Component */
const SearchBusinessPartners = ({
    title,
    placeholder,
    isLoading,
    setIsLoading,
    setSelectedBusinessPartner,
    setIsValidSelectedBusinessPartner,
    type,
    required
}) => {

    const { t } = useTranslation();
    const [ businessPartnerName, setBusinessPartnerName ] = useState("");
    const { businessPartners, searchBusinessPartners } = useSearchBusinessPartners();
    const [ timer, setTimer ] = useState(null);

    return (
        <Container className="fieldcontent">

            <Form.Field
                fluid
                required={required ? required : false}
                loading={isLoading}
                label={title || null}
                placeholder={placeholder || t('general.search')}
                icon='search'
                className="bb-search"
                control={Search}
                onKeyPress={async (ev, data) => {
                    if (ev.key === 'Enter') {
                        ev.preventDefault();
                        clearTimeout(timer);
                        setIsLoading(true);
                        //setBusinessPartnerName(businessPartnerName);
                        let result = await searchBusinessPartners(businessPartnerName, 200, type);
                        if (!result.requestCancelled) setIsLoading(false);
                        setSelectedBusinessPartner(null);
                    }
                }}
                onResultSelect={(e, {result}) => {
                    clearTimeout(timer);
                    setIsLoading(false);
                    setBusinessPartnerName(result.title);
                    let bp = businessPartners.find(item => item.Id === result.value);
                    if (bp) setSelectedBusinessPartner(bp);
                    if (bp && setIsValidSelectedBusinessPartner) setIsValidSelectedBusinessPartner(true);
                    if (!bp) setSelectedBusinessPartner(null);
                }}
                onSearchChange={async (ev, {value}) => {

                    setBusinessPartnerName(value);

                    // If previous timeout is set, remove it!
                    if (timer) {
                        setIsLoading(false);
                        clearTimeout(timer);
                    }

                    // Set new timeout which calls the search api endpoint
                    let temp = setTimeout(async () => {
                        setIsLoading(true);
                        let result = await searchBusinessPartners(value, 200, type);
                        if (!result.requestCancelled) setIsLoading(false);
                        setSelectedBusinessPartner(null);
                    }, 800);

                    setTimer(temp);

                }}
                results={
                    businessPartners.map(bp => {

                        let desc = "";

                        if (bp.AssociationNumber) desc += bp.AssociationNumber;
                        if (bp.AssociationNumber && bp.AreaName) desc += ", ";
                        if (bp.AreaName) desc += bp.AreaName;
                        if (bp.Type) desc += ", " + Utils.capitalizeFirstLetter(bp.Type);

                        return {
                            key: bp.Id,
                            value: bp.Id,
                            title: bp.Name + " (" + bp.Code + ")",
                            description: desc
                        }
                    })
                }
                value={businessPartnerName}
            />
        </Container>
    )

}

export default SearchBusinessPartners;
